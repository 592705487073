import React, { useState } from 'react';
import { LineChart, Bar, BarChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

// Types and Interfaces
interface FormData {
    name: string;
    email: string;
    company: string;
    interest: 'partnership' | 'investment' | 'sponsorship';
    message: string;
  }
  
  interface GalleryImage {
    id: string;
    category: string;
    description: string;
  }
  
  interface PerformanceData {
    month: string;
    events: number;
    attendance: number;
    revenue: number;
    recordings: number;
  }


// Image naming convention and path construction
const constructImagePath = (category: string, identifier: string): string => {
  const bucketUrl = 'https://storage.googleapis.com/showrunnr-bucket/investor/cooler';
  return `${bucketUrl}/${category}_${identifier}.jpg`;
};

// Testimonial data structure
const testimonialData = [
  {
    category: "Artists",
    testimonials: [
      {
        artist: "Max Zaska",
        quote: "The Cooler is an absolute necessity to the Dublin music scene! This space has helped my own development as an artist hugely and is such a positive thing to have in the scene!",
        image: "artist_zaska"
      },
      {
        artist: "Robbie Blake",
        organization: "Tonnta Vocal Ensemble",
        quote: "The Cooler is a Lifeline.",
        image: "artist_blake"
      },
      {
        artist: "Dr Matthew Jacobson",
        quote: "Huge benefit to my continued personal development as an artist and I would class the continued support of this initiative as vital to the creation of world class output from Ireland's music community.",
        image: "artist_jacobson"
      },
      {
        artist: "Patrick Stefan",
        quote: "The Space has been so helpful for my band preparing for small shows and bigger festival performances.",
        image: "artist_stefan"
      }
    ]
  },
  {
    category: "Ensembles",
    testimonials: [
      {
        artist: "Cathy",
        organization: "The Gospel Project",
        quote: "This is a superb resource, in an environment conducive to creativity. The location is hard to beat…It's brilliant to be able to use public transport and not have to transport gear.",
        image: "ensemble_gospel"
      },
      {
        artist: "Tapestry",
        quote: "The Cooler has been integral to getting our 10-piece vocal group up and running. Having a place so central without additional cost means we can reinvest our ticket sales into the group.",
        image: "ensemble_tapestry"
      }
    ]
  },
  {
    category: "Development",
    testimonials: [
      {
        artist: "Bricknasty",
        quote: "Having a space like the Cooler to get the music and the show into shape has been absolutely invaluable for us!",
        image: "development_bricknasty"
      },
      {
        artist: "The Jazzabelles",
        quote: "Women-led collective working on their debut EP, developed and recorded at The Cooler",
        image: "development_jazzabelles"
      }
    ]
  }
];

// Venue metrics
const venueMetrics = {
  capacity: 55,
  location: {
    transport: "Minutes from all major transport points",
    area: "Dublin 7's fruit and vegetable market district"
  },
  facilities: [
    "Full backline",
    "International standard grand piano",
    "Professional drum kit",
    "Electric stage piano",
    "Three camera live-streaming setup",
    "Professional recording capability",
    "Bar facilities",
    "Co-working space"
  ]
};

// Component
const InvestorDashboard: React.FC = () => {
    const [activeSection, setActiveSection] = useState<string>('overview');
    const [activeTestimonialCategory, setActiveTestimonialCategory] = useState<string>('Artists');
    const [showGallery, setShowGallery] = useState<boolean>(false);
    const [selectedImage, setSelectedImage] = useState<GalleryImage | null>(null);
    
    const [formData, setFormData] = useState<FormData>({
      name: '',
      email: '',
      company: '',
      interest: 'partnership',
      message: ''
    });
  
    const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      console.log('Form submitted:', formData);
    };
  
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
      const { name, value } = e.target;
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    };

  // Sample performance data - would be replaced with real data
  const performanceData = [
    { month: 'Jan', events: 12, attendance: 540, revenue: 8100, recordings: 4 },
    { month: 'Feb', events: 15, attendance: 675, revenue: 10125, recordings: 6 },
    { month: 'Mar', events: 18, attendance: 810, revenue: 12150, recordings: 5 },
    { month: 'Apr', events: 20, attendance: 900, revenue: 13500, recordings: 8 }
  ];


    // Technical specifications for the venue
    const technicalSpecs = {
      recording: {
        title: "Recording Capabilities",
        specs: [
          "Three camera live-streaming setup",
          "Live vision mix opportunities",
          "High-quality audio recording",
          "Professional mixing console"
        ]
      },
      equipment: {
        title: "Musical Equipment",
        specs: [
          "International standard grand piano",
          "Professional drum kit",
          "Electric stage piano",
          "Full backline available"
        ]
      },
      venue: {
        title: "Venue Features",
        specs: [
          "55 person capacity",
          "Professional acoustics",
          "Bar facilities",
          "Climate controlled environment"
        ]
      }
    };
  
    // Gallery images following naming convention
    const galleryImages = [
      {
        id: 'venue_main_20240101',
        category: 'venue',
        description: 'Main performance space'
      },
      {
        id: 'equipment_piano_20240101',
        category: 'equipment',
        description: 'Grand Piano'
      },
      {
        id: 'event_performance_20240101',
        category: 'events',
        description: 'Live Performance'
      }
      // Add more images as needed
    ];
  
    return (
        <div className="min-h-screen bg-gray-50">
          {/* Header Section */}
          <div 
            className="bg-blue-900 text-white p-8 relative"
            style={{
              backgroundImage: `url(${constructImagePath('venue', 'header')})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          >
            <div className="absolute inset-0 bg-black opacity-50"></div>
            <div className="relative z-10">
              <h1 className="text-4xl font-bold mb-4">The Cooler</h1>
              <p className="text-xl">The New Home for Irish Jazz</p>
            </div>
          </div>
      
          {/* Navigation */}
          <div className="bg-white border-b sticky top-0 z-50">
            <div className="max-w-7xl mx-auto px-4">
              <nav className="flex space-x-8">
                {['overview', 'facilities', 'testimonials', 'impact', 'partnership'].map((section) => (
                  <button
                    key={section}
                    onClick={() => setActiveSection(section)}
                    className={`py-4 px-2 border-b-2 font-medium ${
                      activeSection === section
                        ? 'border-blue-500 text-blue-600'
                        : 'border-transparent text-gray-500 hover:text-gray-700'
                    }`}
                  >
                    {section.charAt(0).toUpperCase() + section.slice(1)}
                  </button>
                ))}
              </nav>
            </div>
          </div>
      
          {/* Main Content Container */}
          <div className="max-w-7xl mx-auto px-4 py-8">
            {/* Overview Section */}
            {activeSection === 'overview' && (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="bg-white p-6 rounded-lg shadow">
                <img 
  src={constructImagePath('venue', 'interior')} 
  alt="The Cooler Interior"
  className="w-full h-32 object-cover rounded-lg mb-4"
  style={{ maxHeight: '32rem' }}  // Explicit max-height
/>
                  <h2 className="text-2xl font-bold mb-4">Venue Overview</h2>
                  <p className="text-gray-600 mb-4">
                    An atmospheric arts space with industrial vibes, reflecting the surrounding history of Dublin 7's
                    fruit and vegetable market district.
                  </p>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="bg-blue-50 p-4 rounded-lg">
                      <h3 className="font-bold text-blue-800">Capacity</h3>
                      <p className="text-2xl font-bold text-blue-600">{venueMetrics.capacity}</p>
                    </div>
                    <div className="bg-blue-50 p-4 rounded-lg">
                      <h3 className="font-bold text-blue-800">Events YTD</h3>
                      <p className="text-2xl font-bold text-blue-600">
                        {performanceData.reduce((acc, curr) => acc + curr.events, 0)}
                      </p>
                    </div>
                  </div>
                </div>
      
                <div className="bg-white p-6 rounded-lg shadow">
                  <h2 className="text-2xl font-bold mb-4">Performance Metrics</h2>
                  <div className="h-80">
                    <LineChart width={600} height={300} data={performanceData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="month" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="events" fill="#8884d8" />
                      <Bar dataKey="attendance" fill="#82ca9d" />
                      <Bar dataKey="recordings" fill="#ffc658" />
                    </LineChart>
                  </div>
                </div>
              </div>
            )}
      
            {/* Facilities Section */}
            {activeSection === 'facilities' && (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {venueMetrics.facilities.map((facility, index) => (
                  <div key={index} className="bg-white p-6 rounded-lg shadow">
                    <img 
                      src={constructImagePath('facility', `facility_${index}`)} 
                      alt="facility"
                      className="w-full h-32 object-cover rounded-lg mb-4"
                      style={{ maxHeight: '32rem' }}  // Explicit max-height
                    />
                    <h3 className="font-bold text-lg mb-2">{facility}</h3>
                    <p className="text-gray-600">
                      Professional grade equipment and facilities for artists and performers.
                    </p>
                  </div>
                ))}
              </div>
            )}
      
            {/* Testimonials Section */}
            {activeSection === 'testimonials' && (
              <div className="space-y-8">
                <div className="flex space-x-4 mb-8">
                  {testimonialData.map(category => (
                    <button
                      key={category.category}
                      onClick={() => setActiveTestimonialCategory(category.category)}
                      className={`px-4 py-2 rounded-full ${
                        activeTestimonialCategory === category.category
                          ? 'bg-blue-600 text-white'
                          : 'bg-gray-200 text-gray-700'
                      }`}
                    >
                      {category.category}
                    </button>
                  ))}
                </div>
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  {testimonialData
                    .find(cat => cat.category === activeTestimonialCategory)
                    ?.testimonials.map((testimonial, index) => (
                      <div key={index} className="bg-white p-6 rounded-lg shadow">
                        <div className="flex items-start space-x-4">
                          <img 
                            src={constructImagePath('testimonial', testimonial.image)} 
                            alt={testimonial.artist}
                            className="w-16 h-16 rounded-full object-cover"
                          />
                          <div>
                            <p className="italic text-gray-600 mb-4">{testimonial.quote}</p>
                            <p className="font-bold">{testimonial.artist}</p>
                            {testimonial.organization && (
                              <p className="text-sm text-gray-500">{testimonial.organization}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
      
            {/* Impact Section */}
            {activeSection === 'impact' && (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="bg-white p-6 rounded-lg shadow">
                  <h2 className="text-2xl font-bold mb-4">Artist Development</h2>
                  <ul className="space-y-4">
                    <li className="flex items-center space-x-3">
                      <span className="w-4 h-4 bg-blue-500 rounded-full"></span>
                      <span>Professional recording opportunities</span>
                    </li>
                    <li className="flex items-center space-x-3">
                      <span className="w-4 h-4 bg-blue-500 rounded-full"></span>
                      <span>Live streaming capabilities</span>
                    </li>
                    <li className="flex items-center space-x-3">
                      <span className="w-4 h-4 bg-blue-500 rounded-full"></span>
                      <span>Rehearsal space for development</span>
                    </li>
                  </ul>
                </div>
      
                <div className="bg-white p-6 rounded-lg shadow">
                  <h2 className="text-2xl font-bold mb-4">Community Impact</h2>
                  <div className="space-y-4">
                    <div className="border-l-4 border-blue-500 pl-4">
                      <h3 className="font-bold">Cultural Hub</h3>
                      <p className="text-gray-600">Central meeting point for musicians from across Ireland and around the world</p>
                    </div>
                    <div className="border-l-4 border-blue-500 pl-4">
                      <h3 className="font-bold">Artist Support</h3>
                      <p className="text-gray-600">Affordable, accessible space for emerging artists</p>
                    </div>
                    <div className="border-l-4 border-blue-500 pl-4">
                      <h3 className="font-bold">Scene Development</h3>
                      <p className="text-gray-600">Contributing to the growth of Irish jazz and improvised music</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
      
            {/* Partnership Section */}
            {activeSection === 'partnership' && (
              <div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  <div className="bg-white p-6 rounded-lg shadow">
                    <h2 className="text-2xl font-bold mb-4">Partnership Benefits</h2>
                    <div className="space-y-6">
                      <div className="flex items-start space-x-4">
                        <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center">
                          <span className="text-blue-600 font-bold">01</span>
                        </div>
                        <div>
                          <h3 className="font-bold text-lg">Brand Visibility</h3>
                          <p className="text-gray-600">Primary commercial sponsorship across all public-facing elements</p>
                        </div>
                      </div>
                      <div className="flex items-start space-x-4">
                        <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center">
                          <span className="text-blue-600 font-bold">02</span>
                        </div>
                        <div>
                          <h3 className="font-bold text-lg">Event Access</h3>
                          <p className="text-gray-600">Exclusive launch events and hospitality packages</p>
                        </div>
                      </div>
                      <div className="flex items-start space-x-4">
                        <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center">
                          <span className="text-blue-600 font-bold">03</span>
                        </div>
                        <div>
                          <h3 className="font-bold text-lg">Digital Presence</h3>
                          <p className="text-gray-600">Featured in livestreams and marketing campaigns</p>
                        </div>
                      </div>
                    </div>
                  </div>
      
                  <div className="bg-white p-6 rounded-lg shadow">
                    <form onSubmit={handleFormSubmit} className="space-y-6">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Name
                          </label>
                          <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                            required
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Email
                          </label>
                          <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                            required
                          />
                        </div>
                      </div>
      
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Company
                        </label>
                        <input
                          type="text"
                          name="company"
                          value={formData.company}
                          onChange={handleInputChange}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        />
                      </div>
      
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Interest Area
                        </label>
                        <select
                          name="interest"
                          value={formData.interest}
                          onChange={handleInputChange}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        >
                          <option value="partnership">Partnership Opportunity</option>
                          <option value="investment">Investment Interest</option>
                          <option value="sponsorship">Sponsorship Programs</option>
                        </select>
                      </div>
      
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Message
                        </label>
                        <textarea
                          name="message"
                          value={formData.message}
                          onChange={handleInputChange}
                          rows={4}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                          required
                        />
                      </div>
      
                      <button
                        type="submit"
                        className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition-colors"
                      >
                        Submit Inquiry
                      </button>
                    </form>
                  </div>
                </div>
      
                {/* Technical Specifications Section */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-8">
                  {Object.entries(technicalSpecs).map(([key, section]) => (
                    <div key={key} className="bg-white p-6 rounded-lg shadow">
                      <h3 className="text-xl font-bold mb-4">{section.title}</h3>
                      <ul className="space-y-2">
                        {section.specs.map((spec, index) => (
                          <li key={index} className="flex items-center space-x-2">
                            <span className="w-2 h-2 bg-blue-500 rounded-full"></span>
                            <span>{spec}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
                 {/* Media Gallery */}
                 <div className="mt-8">
            <h2 className="text-2xl font-bold mb-4">Venue Gallery</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {galleryImages.map((image) => (
                <div 
                  key={image.id} 
                  className="relative cursor-pointer"
                  onClick={() => {
                    setSelectedImage(image);
                    setShowGallery(true);
                  }}
                >
                  <img
                    src={constructImagePath(image.category, image.id)}
                    alt={image.description}
                    className="w-full h-64 object-cover rounded-lg"
                  />
                  <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-2 rounded-b-lg">
                    <p className="text-sm">{image.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>

    {/* Modal - Outside main content but inside root div */}
    {showGallery && selectedImage && (
      <div className="fixed inset-0 bg-black bg-opacity-75 z-50 flex items-center justify-center">
        <div className="max-w-4xl mx-auto p-4">
          <img
            src={constructImagePath(selectedImage.category, selectedImage.id)}
            alt={selectedImage.description}
            className="max-h-[80vh] w-auto"
          />
          <button
            onClick={() => setShowGallery(false)}
            className="absolute top-4 right-4 text-white text-xl"
          >
            ×
          </button>
        </div>
      </div>
    )}
  </div>
);
}
export default InvestorDashboard;