import React, { useState } from 'react';
import {
  Autocomplete,
  TextField,
  Chip,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  Paper,
  Switch,
  Divider
} from '@mui/material';
import { instruments } from '../../constants/instrumentation';
import { SelectedInstrument } from '../../types/instrumentTypes';
import { Performer, InstrumentWithPerformer } from '../../types/BandRecommendations';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import PersonIcon from '@mui/icons-material/Person';

interface InstrumentSelectionProps {
  value: SelectedInstrument[];
  onChange: (instruments: SelectedInstrument[]) => void;
}

const InstrumentSelection = ({ value, onChange }: InstrumentSelectionProps) => {
  const [inputValue, setInputValue] = useState('');
  const [showEffectsFor, setShowEffectsFor] = useState<string | null>(null);
  const [includePerformers, setIncludePerformers] = useState(false);

  const allOptions = instruments.flatMap(category => 
    category.options.map(instrument => ({
      ...instrument,
      category: category.label
    }))
  );

  const handleInstrumentAdd = (newValue: any) => {
    if (!newValue) return;
    
    const newInstrument: SelectedInstrument = {
      id: newValue.id,
      name: newValue.name,
      withEffects: false
    };
    
    onChange([...value, newInstrument]);
    setShowEffectsFor(newValue.id);
  };

  const handleEffectsToggle = (instrumentId: string) => {
    const updatedInstruments = value.map(instrument => 
      instrument.id === instrumentId
        ? { ...instrument, withEffects: !instrument.withEffects }
        : instrument
    );
    onChange(updatedInstruments);
  };

  const handlePerformerNameChange = (instrumentId: string, performerName: string) => {
    const updatedInstruments = value.map(instrument => 
      instrument.id === instrumentId
        ? { 
            ...instrument, 
            performer: performerName.trim() ? { name: performerName } : undefined 
          }
        : instrument
    );
    onChange(updatedInstruments);
  };

  const handleDelete = (instrumentId: string) => {
    const updatedInstruments = value.filter(instrument => instrument.id !== instrumentId);
    onChange(updatedInstruments);
    if (showEffectsFor === instrumentId) {
      setShowEffectsFor(null);
    }
  };

  return (
    <Box>
      <Autocomplete
        options={allOptions}
        groupBy={(option) => option.category}
        getOptionLabel={(option) => option.name}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        onChange={(event, newValue) => handleInstrumentAdd(newValue)}
        value={null}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Add Instrument"
            placeholder="Start typing to search instruments..."
            fullWidth
          />
        )}
      />

      <Box sx={{ mt: 2, mb: 2 }}>
        <FormControlLabel
          control={
            <Switch
              checked={includePerformers}
              onChange={(e) => setIncludePerformers(e.target.checked)}
              color="primary"
            />
          }
          label={
            <Typography variant="body2" color="textSecondary">
              Include performer names with instruments
            </Typography>
          }
        />
      </Box>

      <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
        {value.map((instrument) => (
          <Paper
            key={instrument.id}
            elevation={1}
            sx={{ p: 1 }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1 }}>
              <Chip
                label={`${instrument.name}${instrument.withEffects ? ' (with effects)' : ''}`}
                onDelete={() => handleDelete(instrument.id)}
                color={instrument.withEffects ? "primary" : "default"}
                onClick={() => setShowEffectsFor(showEffectsFor === instrument.id ? null : instrument.id)}
                icon={instrument.performer ? <PersonIcon /> : <MusicNoteIcon />}
              />
              
              {showEffectsFor === instrument.id && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={instrument.withEffects}
                      onChange={() => handleEffectsToggle(instrument.id)}
                      size="small"
                    />
                  }
                  label={
                    <Typography variant="body2">
                      With Effects
                    </Typography>
                  }
                />
              )}

              {includePerformers && (
                <TextField
                  size="small"
                  placeholder="Enter performer name"
                  value={instrument.performer?.name || ''}
                  onChange={(e) => handlePerformerNameChange(instrument.id, e.target.value)}
                  sx={{ 
                    ml: 1, 
                    flexGrow: 1,
                    '& .MuiInputBase-input': {
                      fontSize: '0.875rem'
                    }
                  }}
                />
              )}
            </Box>
          </Paper>
        ))}
      </Box>
    </Box>
  );
};

export default InstrumentSelection;