import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Chip,
  Stack,
  Tooltip,
  List,
  ListItem,
} from '@mui/material';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import PersonIcon from '@mui/icons-material/Person';
import { BandRecommendation } from '../../../../types/BandRecommendations';
import { Performer } from '../../../../types/shared';
import { useBandView } from '../../../../contexts/BandViewContext';

interface InstrumentWithPerformer {
  instrumentId: string;
  instrumentName: string;
  withEffects: boolean;
  performer?: Performer;
}

interface BandInstrumentationProps {
  band: BandRecommendation;
}

const BandInstrumentation: React.FC<BandInstrumentationProps> = ({ band }) => {
  const { viewConfig } = useBandView();

  if (!band.instrumentation?.length) return null;

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Band Instrumentation
        </Typography>

        <List disablePadding>
          {band.instrumentation.map((instrument: InstrumentWithPerformer) => (
            <ListItem
              key={instrument.instrumentId}
              disableGutters
              sx={{
                mb: 1,
                p: 2,
                bgcolor: 'background.default',
                borderRadius: 1,
                border: '1px solid',
                borderColor: 'divider',
                '&:last-child': { mb: 0 }
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                width="100%"
              >
                {/* Instrument Icon */}
                <MusicNoteIcon color="primary" />

                {/* Instrument Name and Effects */}
                <Box flexGrow={1}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="subtitle1">
                      {instrument.instrumentName}
                    </Typography>
                    {instrument.withEffects && (
                      <Chip
                        label="With Effects"
                        size="small"
                        variant="outlined"
                        sx={{ height: 24 }}
                      />
                    )}
                  </Stack>
                </Box>

                {/* Performer Info */}
                {instrument.performer && viewConfig.showContactInfo && (
                  <Tooltip title={`Performer: ${instrument.performer.name}`}>
                    <Stack 
                      direction="row" 
                      spacing={1} 
                      alignItems="center"
                    >
                      <Box
                        sx={{
                          width: 32,
                          height: 32,
                          borderRadius: '50%',
                          bgcolor: 'primary.main',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          color: 'white'
                        }}
                      >
                        <PersonIcon sx={{ fontSize: 20 }} />
                      </Box>
                      <Typography variant="body2" color="text.secondary">
                        {instrument.performer.name}
                      </Typography>
                    </Stack>
                  </Tooltip>
                )}
              </Stack>
            </ListItem>
          ))}
        </List>

        {band.instrumentation.some(i => i.performer) && (
          <Typography 
            variant="caption" 
            color="text.secondary"
            sx={{ 
              display: 'block', 
              mt: 2,
              fontStyle: 'italic'
            }}
          >
            * Personnel information visible based on view permissions
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default BandInstrumentation;