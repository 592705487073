import { createTheme } from '@mui/material/styles';

// Muted, relaxing color palette
const deepCharcoal = '#2C3E50';
const softTeal = '#3A9188';
const mutedCoral = '#E57373';
const warmGray = '#A4B0BE';
const lightSage = '#DAE1E7';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: softTeal,
      contrastText: '#ffffff',
    },
    secondary: {
      main: mutedCoral,
      contrastText: deepCharcoal,
    },
    background: {
      default: deepCharcoal,
      paper: '#34495E',
    },
    text: {
      primary: '#ffffff',
      secondary: warmGray,
    },
    error: {
      main: '#E74C3C',
    },
    divider: softTeal,
  },
  typography: {
    fontFamily: 'RocGrotesk, "Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: { fontWeight: 700, fontSize: '2.5rem', color: softTeal }, // Uses bold variant
    h2: { fontWeight: 700, fontSize: '2rem', color: softTeal },
    body1: { fontWeight: 400, fontSize: '1rem', color: '#ffffff' }, // Regular weight
    body2: { fontWeight: 400, fontSize: '0.875rem', color: warmGray },
    button: {
      textTransform: 'none',
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          padding: '8px 16px',
        },
        containedPrimary: {
          backgroundColor: softTeal,
          '&:hover': {
            backgroundColor: '#2D7A72',
          },
        },
        containedSecondary: {
          backgroundColor: mutedCoral,
          '&:hover': {
            backgroundColor: '#D35F5F',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label.Mui-focused': {
            color: softTeal,
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: softTeal,
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: warmGray,
            },
            '&:hover fieldset': {
              borderColor: softTeal,
            },
            '&.Mui-focused fieldset': {
              borderColor: softTeal,
            },
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: '#34495E',
          color: lightSage,
          '& .MuiChip-deleteIcon': {
            color: lightSage,
            '&:hover': {
              color: mutedCoral,
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#34495E',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: softTeal,
          '&:hover': {
            color: mutedCoral,
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#34495E',
          border: `1px solid ${softTeal}`,
        },
      },
    },
  },
});

export default theme;