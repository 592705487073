// src/components/TicketSalesSection.tsx

import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { PieChart, Pie, Cell, Tooltip, BarChart, Bar, XAxis, YAxis, Legend } from 'recharts';

interface TicketSalesSectionProps {
  ticketsSold: number;
  ticketSales: number;
  totalIncome: number;
  totalCost: number;
  costBreakdown: {
    fee: number;
    travel: number;
    accommodation: number;
    perDiem: number;
    equipment: number;
    other: number;
    contingency: number;
  };
}

const TicketSalesSection: React.FC<TicketSalesSectionProps> = ({
  ticketsSold,
  ticketSales,
  totalIncome,
  totalCost,
  costBreakdown,
}) => {
  const incomeExpenseData = [
    { name: 'Total Income', value: totalIncome },
    { name: 'Total Costs', value: totalCost },
  ];

  const costBreakdownData = [
    { name: 'Performance Fee', value: costBreakdown.fee },
    { name: 'Travel', value: costBreakdown.travel },
    { name: 'Accommodation', value: costBreakdown.accommodation },
    { name: 'Per Diem', value: costBreakdown.perDiem },
    { name: 'Equipment', value: costBreakdown.equipment },
    { name: 'Other Costs', value: costBreakdown.other },
    { name: 'Contingency', value: costBreakdown.contingency },
  ];

  return (
    <Card sx={{ mb: 4 }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Ticket Sales Data
        </Typography>
        <Typography variant="body1">
          Tickets Sold: {ticketsSold}
        </Typography>
        <Typography variant="body1">
          Total Ticket Sales: €{ticketSales.toFixed(2)}
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          Income vs. Costs
        </Typography>
        <PieChart width={400} height={300}>
          <Pie data={incomeExpenseData} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={80}>
            {incomeExpenseData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={index === 0 ? '#0088FE' : '#FF8042'} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>

        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          Cost Breakdown
        </Typography>
        <BarChart width={500} height={300} data={costBreakdownData}>
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="value" fill="#8884d8" />
        </BarChart>
      </CardContent>
    </Card>
  );
};

export default TicketSalesSection;