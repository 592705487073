import React, { useState } from 'react';
import {
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  IconButton,
  Collapse,
  Paper,
  Chip,
  Tooltip
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PublicIcon from '@mui/icons-material/Public';
import PersonIcon from '@mui/icons-material/Person';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import GavelIcon from '@mui/icons-material/Gavel';
import { ViewMode, useBandView } from '../../contexts/BandViewContext';

const viewOptions = [
  { 
    value: 'admin', 
    label: 'Admin',
    icon: <AdminPanelSettingsIcon className="w-4 h-4" />,
    description: 'Full access to all features'
  },
  { 
    value: 'artist', 
    label: 'Artist',
    icon: <PersonIcon className="w-4 h-4" />,
    description: 'Artist view'
  },
  { 
    value: 'public', 
    label: 'Public',
    icon: <PublicIcon className="w-4 h-4" />,
    description: 'Public information'
  },
  { 
    value: 'strand', 
    label: 'Strand',
    icon: <AccountTreeIcon className="w-4 h-4" />,
    description: 'Strand view'
  },
  { 
    value: 'judge', 
    label: 'Judge',
    icon: <GavelIcon className="w-4 h-4" />,
    description: 'Judge view'
  }
];

const AdminViewSwitcher = () => {
  const { viewMode, setViewMode, permissions, viewConfig } = useBandView();
  const [expanded, setExpanded] = useState(false);

  if (!permissions.canEdit) return null;

  const currentView = viewOptions.find(option => option.value === viewMode);

  return (
    <Paper className="relative mb-2">
      <Box className="flex items-center p-2 cursor-pointer" onClick={() => setExpanded(!expanded)}>
        <Box className="flex items-center flex-1">
          <Box className="flex items-center">
            {currentView?.icon}
            <Typography variant="body2" className="ml-1">
              {currentView?.label}
            </Typography>
          </Box>
        </Box>
        <IconButton size="small">
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>

      <Collapse in={expanded}>
        <Box className="p-2 pt-0">
          <RadioGroup
            value={viewMode}
            onChange={(e) => setViewMode(e.target.value as ViewMode)}
            row
          >
            {viewOptions.map((option) => (
              <Tooltip key={option.value} title={option.description}>
                <FormControlLabel
                  value={option.value}
                  control={<Radio size="small" />}
                  label={
                    <Box className="flex items-center">
                      {option.icon}
                      <Typography variant="body2" className="ml-1">
                        {option.label}
                      </Typography>
                    </Box>
                  }
                />
              </Tooltip>
            ))}
          </RadioGroup>

          <Box className="mt-2">
            <Typography variant="caption" color="textSecondary" className="mb-1 block">
              Showing:
            </Typography>
            <Box className="flex flex-wrap gap-1">
              {Object.entries(viewConfig).map(([key, value]) => 
                value && (
                  <Chip
                    key={key}
                    label={key.replace(/([A-Z])/g, ' $1').toLowerCase()}
                    size="small"
                    className="text-xs"
                  />
                )
              )}
            </Box>
          </Box>
        </Box>
      </Collapse>
    </Paper>
  );
};

export default AdminViewSwitcher;