// components/AssociatePersonnelModal.tsx

import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Tab,
  Tabs
} from '@mui/material';
import axios from 'axios';


interface Event {
  _id: string;
  name: string;
  date: string;
}

interface Performance {
  _id: string;
  name: string;
  date: string;
}

interface AssociatePersonnelModalProps {
  open: boolean;
  onClose: () => void;
  personnelId: string;
}

const AssociatePersonnelModal: React.FC<AssociatePersonnelModalProps> = ({ open, onClose, personnelId }) => {
  const [events, setEvents] = useState<Event[]>([]);
  const [performances, setPerformances] = useState<Performance[]>([]);
  const [selectedEvents, setSelectedEvents] = useState<string[]>([]);
  const [selectedPerformances, setSelectedPerformances] = useState<string[]>([]);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    const fetchEventsAndPerformances = async () => {
      try {
        const [eventsResponse, performancesResponse] = await Promise.all([
          axios.get<Event[]>(`${process.env.REACT_APP_API_URL}/api/events`),
          axios.get<Performance[]>(`${process.env.REACT_APP_API_URL}/api/performances`)
        ]);
        setEvents(eventsResponse.data);
        setPerformances(performancesResponse.data);
      } catch (error) {
        console.error('Error fetching events and performances:', error);
      }
    };
    fetchEventsAndPerformances();
  }, []);

  const handleToggleEvent = (id: string) => {
    setSelectedEvents(prev => 
      prev.includes(id) ? prev.filter(eventId => eventId !== id) : [...prev, id]
    );
  };

  const handleTogglePerformance = (id: string) => {
    setSelectedPerformances(prev => 
      prev.includes(id) ? prev.filter(perfId => perfId !== id) : [...prev, id]
    );
  };

  const handleAssociate = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/personnel/${personnelId}/associate`, {
        events: selectedEvents,
        performances: selectedPerformances
      });
      onClose();
    } catch (error) {
      console.error('Error associating personnel:', error);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
      }}>
        <Typography variant="h6" component="h2" gutterBottom>
          Associate Personnel with Events/Performances
        </Typography>
        <Tabs value={tabValue} onChange={(_, newValue) => setTabValue(newValue)}>
          <Tab label="Events" />
          <Tab label="Performances" />
        </Tabs>
        {tabValue === 0 && (
          <List>
            {events.map((event) => (
              <ListItem key={event._id} dense button onClick={() => handleToggleEvent(event._id)}>
                <Checkbox
                  edge="start"
                  checked={selectedEvents.includes(event._id)}
                  tabIndex={-1}
                  disableRipple
                />
                <ListItemText primary={event.name} secondary={new Date(event.date).toLocaleDateString()} />
              </ListItem>
            ))}
          </List>
        )}
        {tabValue === 1 && (
          <List>
            {performances.map((performance) => (
              <ListItem key={performance._id} dense button onClick={() => handleTogglePerformance(performance._id)}>
                <Checkbox
                  edge="start"
                  checked={selectedPerformances.includes(performance._id)}
                  tabIndex={-1}
                  disableRipple
                />
                <ListItemText primary={performance.name} secondary={new Date(performance.date).toLocaleDateString()} />
              </ListItem>
            ))}
          </List>
        )}
        <Button onClick={handleAssociate} variant="contained" color="primary" sx={{ mt: 2 }}>
          Associate
        </Button>
      </Box>
    </Modal>
  );
};

export default AssociatePersonnelModal;