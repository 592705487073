import React from 'react';
import { Chip } from '@mui/material';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import ScheduleIcon from '@mui/icons-material/Schedule';
import CancelIcon from '@mui/icons-material/Cancel';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import TourIcon from '@mui/icons-material/Tour';
import RestoreIcon from '@mui/icons-material/Restore';
import ProjectsIcon from '@mui/icons-material/AccountTree';
import HelpIcon from '@mui/icons-material/Help';
import { BandActivityStatus } from '../../../../types/BandRecommendations';

interface ActivityStatusDisplayProps {
  status: BandActivityStatus;
  showLabel?: boolean;
}

const ActivityStatusDisplay: React.FC<ActivityStatusDisplayProps> = ({ 
  status, 
  showLabel = true 
}) => {
  const getStatusConfig = () => {
    switch (status) {
      case BandActivityStatus.ACTIVE:
        return {
          icon: <RadioButtonCheckedIcon />,
          label: 'Active',
          color: 'success',
          backgroundColor: 'rgba(46, 125, 50, 0.9)'
        };
      case BandActivityStatus.DORMANT:
        return {
          icon: <PauseCircleIcon />,
          label: 'Dormant',
          color: 'warning',
          backgroundColor: 'rgba(237, 108, 2, 0.9)'
        };
      case BandActivityStatus.HIATUS:
        return {
          icon: <ScheduleIcon />,
          label: 'On Hiatus',
          color: 'warning',
          backgroundColor: 'rgba(237, 108, 2, 0.9)'
        };
      case BandActivityStatus.DISBANDED:
        return {
          icon: <CancelIcon />,
          label: 'Disbanded',
          color: 'error',
          backgroundColor: 'rgba(211, 47, 47, 0.9)'
        };
      case BandActivityStatus.RECORDING:
        return {
          icon: <MusicNoteIcon />,
          label: 'Recording',
          color: 'info',
          backgroundColor: 'rgba(2, 136, 209, 0.9)'
        };
      case BandActivityStatus.TOURING:
        return {
          icon: <TourIcon />,
          label: 'Touring',
          color: 'success',
          backgroundColor: 'rgba(46, 125, 50, 0.9)'
        };
      case BandActivityStatus.REFORMING:
        return {
          icon: <RestoreIcon />,
          label: 'Reforming',
          color: 'info',
          backgroundColor: 'rgba(2, 136, 209, 0.9)'
        };
      case BandActivityStatus.PROJECT:
        return {
          icon: <ProjectsIcon />,
          label: 'Project',
          color: 'info',
          backgroundColor: 'rgba(2, 136, 209, 0.9)'
        };
      default:
        return {
          icon: <HelpIcon />,
          label: 'Unknown',
          color: 'default',
          backgroundColor: 'rgba(97, 97, 97, 0.9)'
        };
    }
  };

  const config = getStatusConfig();

  return (
    <Chip
      icon={config.icon}
      label={showLabel ? config.label : undefined}
      color={config.color as any}
      sx={{
        position: 'absolute',
        bottom: 20,
        right: 20,
        backdropFilter: 'blur(4px)',
        backgroundColor: config.backgroundColor,
        color: 'white',
        '& .MuiChip-icon': {
          color: 'inherit'
        },
        minWidth: showLabel ? 'auto' : '32px',
        width: showLabel ? 'auto' : '32px',
        height: '32px',
        '& .MuiChip-label': {
          padding: showLabel ? undefined : 0
        }
      }}
    />
  );
};

export default ActivityStatusDisplay;