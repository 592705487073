import React from 'react';
import { 
  Box, 
  Card, 
  CardContent, 
  Typography,
  useTheme
} from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';
import { BandRecommendation } from '../../../types/BandRecommendations';
import { useBandView } from '../../../contexts/BandViewContext';

interface BandBasicInfoProps {
  band: BandRecommendation;
}

const BandBasicInfo: React.FC<BandBasicInfoProps> = ({ band }) => {
  const theme = useTheme();
  const { viewMode } = useBandView();

  const genderData = [
    { 
      name: 'Male', 
      value: band.maleMembers, 
      color: theme.palette.primary.main 
    },
    { 
      name: 'Female', 
      value: band.femaleMembers, 
      color: theme.palette.secondary.main 
    },
  ];

  const CustomizedLegend = () => (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box display="flex" alignItems="center" mr={2}>
        <MaleIcon style={{ color: theme.palette.primary.main }} />
        <Typography variant="body2" ml={1}>Male ({band.maleMembers})</Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <FemaleIcon style={{ color: theme.palette.secondary.main }} />
        <Typography variant="body2" ml={1}>Female ({band.femaleMembers})</Typography>
      </Box>
    </Box>
  );

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>Band Demographics</Typography>
        <Box height={200}>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={genderData}
                cx="50%"
                cy="50%"
                innerRadius={50}
                outerRadius={70}
                paddingAngle={5}
                dataKey="value"
                nameKey="name"
              >
                {genderData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip 
                formatter={(value: number, name: string) => 
                  [`${value} members`, name]
                }
              />
              <Legend content={<CustomizedLegend />} />
            </PieChart>
          </ResponsiveContainer>
        </Box>
        
        <Box mt={2}>
          <Typography variant="body1" align="center">
            Total Members: {band.maleMembers + band.femaleMembers}
          </Typography>
          <Typography variant="body2" align="center" color="textSecondary">
            {band.genderRatio}
          </Typography>
        </Box>

        {viewMode === 'admin' && band.status === 'pending' && (
          <Typography 
            variant="body2" 
            color="warning.main" 
            align="center" 
            sx={{ mt: 2 }}
          >
            Pending Review
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default BandBasicInfo;