// src/components/ContactInfoEditor.tsx

import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

interface ContactInfo {
  id: string;
  type: string;
  value: string;
  link?: string;
}

interface ContactInfoEditorProps {
  contactInfo: ContactInfo[];
  onChange: (newContactInfo: ContactInfo[]) => void;
}

const ContactInfoEditor: React.FC<ContactInfoEditorProps> = ({ contactInfo, onChange }) => {
  const [newContactType, setNewContactType] = useState('');
  const [newContactValue, setNewContactValue] = useState('');
  const [newContactLink, setNewContactLink] = useState('');

  const handleAddContact = () => {
    if (newContactType && newContactValue) {
      const newContact: ContactInfo = {
        id: Date.now().toString(),
        type: newContactType,
        value: newContactValue,
        link: newContactLink || undefined,
      };
      onChange([...contactInfo, newContact]);
      setNewContactType('');
      setNewContactValue('');
      setNewContactLink('');
    }
  };

  const handleRemoveContact = (id: string) => {
    onChange(contactInfo.filter(contact => contact.id !== id));
  };

  const handleEditContact = (id: string, field: keyof ContactInfo, value: string) => {
    const updatedContacts = contactInfo.map(contact =>
      contact.id === id ? { ...contact, [field]: value } : contact
    );
    onChange(updatedContacts);
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>Contact Information</Typography>
      {contactInfo.map((contact) => (
        <Grid container spacing={2} key={contact.id} alignItems="center">
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel>Type</InputLabel>
              <Select
                value={contact.type}
                onChange={(e) => handleEditContact(contact.id, 'type', e.target.value)}
                label="Type"
              >
                <MenuItem value="email">Email</MenuItem>
                <MenuItem value="phone">Phone</MenuItem>
                <MenuItem value="website">Website</MenuItem>
                <MenuItem value="address">Address</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Value"
              value={contact.value}
              onChange={(e) => handleEditContact(contact.id, 'value', e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Link (optional)"
              value={contact.link || ''}
              onChange={(e) => handleEditContact(contact.id, 'link', e.target.value)}
            />
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={() => handleRemoveContact(contact.id)} color="error">
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Box mt={2}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel>Type</InputLabel>
              <Select
                value={newContactType}
                onChange={(e) => setNewContactType(e.target.value)}
                label="Type"
              >
                <MenuItem value="email">Email</MenuItem>
                <MenuItem value="phone">Phone</MenuItem>
                <MenuItem value="website">Website</MenuItem>
                <MenuItem value="address">Address</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Value"
              value={newContactValue}
              onChange={(e) => setNewContactValue(e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Link (optional)"
              value={newContactLink}
              onChange={(e) => setNewContactLink(e.target.value)}
            />
          </Grid>
          <Grid item xs={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddContact}
              disabled={!newContactType || !newContactValue}
              startIcon={<AddIcon />}
            >
              Add
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ContactInfoEditor;