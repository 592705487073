import React, { useState } from 'react';
import { 
  Grid, 
  TextField, 
  Tooltip, 
  Button, 
  CircularProgress, 
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography
} from '@mui/material';
import { 
  BandRecommendationFormData, 
  InstrumentWithPerformer,
  BandActivityStatus,
  getActivityStatusInfo 
} from '../../types/BandRecommendations';
import InstrumentSelection from './InstrumentSelection';
import { SelectedInstrument } from '../../types/instrumentTypes';
import axios from 'axios';

interface BandInfoProps {
  formData: BandRecommendationFormData;
  setFormData: React.Dispatch<React.SetStateAction<BandRecommendationFormData>>;
}

const BandInfo: React.FC<BandInfoProps> = ({ formData, setFormData }) => {
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleActivityStatusChange = (event: any) => {
    const newStatus = event.target.value as BandActivityStatus;
    setFormData(prevData => ({
      ...prevData,
      activityStatus: newStatus,
      activityStatusDate: new Date()
    }));
  };

  const handleActivityNoteChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData(prevData => ({
      ...prevData,
      activityStatusNote: event.target.value
    }));
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setError(null);
    setUploading(true);

    if (file.size > 50 * 1024 * 1024) {
      setError('File size exceeds 50MB limit');
      setUploading(false);
      return;
    }

    if (!['image/jpeg', 'image/png', 'image/gif'].includes(file.type)) {
      setError('Please upload a JPEG, PNG, or GIF file');
      setUploading(false);
      return;
    }

    const formDataPayload = new FormData();
    formDataPayload.append('image', file);

    try {
      const token = localStorage.getItem('token');

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/upload/band`, 
        formDataPayload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            ...(token && { Authorization: `Bearer ${token}` }),
          },
          withCredentials: true,
        }
      );

      setFormData(prevData => ({
        ...prevData,
        mainImage: response.data.imageUrl,
      }));
    } catch (error: any) {
      console.error('Error uploading image:', error);
      setError(
        error.response?.data?.message || 
        error.response?.data?.error || 
        'Failed to upload image. Please try again.'
      );

      if (error.response?.status === 401) {
        setError('Please log in to upload images');
      } else if (error.response?.status === 413) {
        setError('Image file is too large');
      }
    } finally {
      setUploading(false);
    }
  };

  const handleInstrumentationChange = (instruments: SelectedInstrument[]) => {
    setFormData(prevData => ({
      ...prevData,
      instrumentation: instruments.map(inst => ({
        instrumentId: inst.id,
        instrumentName: inst.name,
        withEffects: inst.withEffects,
        performer: inst.performer
      }))
    }));
  };

  return (
    <Grid container spacing={2}>
      {error && (
        <Grid item xs={12}>
          <Alert 
            severity="error" 
            onClose={() => setError(null)}
            sx={{ mb: 2 }}
          >
            {error}
          </Alert>
        </Grid>
      )}

      <Grid item xs={12}>
        <Tooltip title="Enter the name of the artist or band.">
          <TextField
            required
            name="artistName"
            label="Artist/Band Name"
            value={formData.artistName}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
        </Tooltip>
      </Grid>

      {/* Activity Status Section */}
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel id="activity-status-label">Activity Status</InputLabel>
          <Select
            labelId="activity-status-label"
            id="activityStatus"
            value={formData.activityStatus || BandActivityStatus.ACTIVE}
            onChange={handleActivityStatusChange}
            label="Activity Status"
          >
            {Object.values(BandActivityStatus).map((status) => {
              const statusInfo = getActivityStatusInfo(status);
              return (
                <MenuItem key={status} value={status}>
                  <Tooltip title={statusInfo.description} placement="right">
                    <Typography>{statusInfo.label}</Typography>
                  </Tooltip>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          name="activityStatusNote"
          label="Status Note"
          value={formData.activityStatusNote || ''}
          onChange={handleActivityNoteChange}
          variant="outlined"
          fullWidth
          multiline
          rows={2}
          placeholder="Add any additional context about the band's current status"
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          name="about"
          label="About the Artist/Band"
          value={formData.about || ''}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          multiline
          rows={3}
          placeholder="Provide a description of the band/artist"
        />
      </Grid>

      <Grid item xs={6} sm={3}>
        <Tooltip title="Number of male members in the band.">
          <TextField
            required
            name="maleMembers"
            label="Male Members"
            type="number"
            InputProps={{ inputProps: { min: 0, max: 20 } }}
            value={formData.maleMembers}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
        </Tooltip>
      </Grid>

      <Grid item xs={6} sm={3}>
        <Tooltip title="Number of female members in the band.">
          <TextField
            required
            name="femaleMembers"
            label="Female Members"
            type="number"
            InputProps={{ inputProps: { min: 0, max: 20 } }}
            value={formData.femaleMembers}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
        </Tooltip>
      </Grid>

      <Grid item xs={12}>
        <TextField
          name="performanceLink"
          label="Main Website where content can be found"
          value={formData.performanceLink}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          placeholder="Enter the main website URL"
        />
      </Grid>

      <Grid item xs={12}>
        <InstrumentSelection
          value={formData.instrumentation?.map(inst => ({
            id: inst.instrumentId,
            name: inst.instrumentName,
            withEffects: inst.withEffects,
            performer: inst.performer
          })) || []}
          onChange={handleInstrumentationChange}
        />
      </Grid>

      <Grid item xs={12}>
        <div>
          <input
            accept="image/jpeg,image/png,image/gif"
            style={{ display: 'none' }}
            id="main-image-upload"
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="main-image-upload">
            <Button 
              variant="contained" 
              component="span" 
              disabled={uploading}
            >
              {uploading ? (
                <CircularProgress size={24} />
              ) : formData.mainImage ? (
                'Change Image'
              ) : (
                'Upload Main Image'
              )}
            </Button>
          </label>
        </div>
        {formData.mainImage && (
          <div style={{ marginTop: '1rem' }}>
            <img 
              src={formData.mainImage} 
              alt="Band preview" 
              style={{ 
                maxWidth: '100%', 
                maxHeight: '200px', 
                objectFit: 'cover' 
              }} 
            />
            <TextField
              fullWidth
              margin="normal"
              label="Main Image URL"
              value={formData.mainImage}
              InputProps={{
                readOnly: true,
              }}
            />
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default BandInfo;