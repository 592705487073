import React from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TitoEvent } from '../../types/dashboard';
import CurrentSalesAnalysis from '../CurrentSalesAnalysis';

interface SalesAnalysisSectionProps {
  events: TitoEvent[];
  expanded: string | false;
  onAccordionChange: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
}

const SalesAnalysisSection: React.FC<SalesAnalysisSectionProps> = ({
  events,
  expanded,
  onAccordionChange
}) => {
  return (
    <Box sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>
        Current Sales Analysis
      </Typography>
      {events.length > 0 ? (
        events.map((event, index) => (
          <Accordion
            key={event.title}
            expanded={expanded === `panel${index}`}
            onChange={onAccordionChange(`panel${index}`)}
            sx={{ mb: 2 }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                <Typography variant="h6">{event.title}</Typography>
                <Typography variant="body2">
                  {event.totalSold} / {event.totalAvailable} tickets sold
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <CurrentSalesAnalysis event={event} />
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <Typography>No sales data available.</Typography>
      )}
    </Box>
  );
};

export default SalesAnalysisSection;