import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  Container, 
  Typography, 
  Grid, 
  Card, 
  CardContent, 
  CardActions, 
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  SelectChangeEvent,
  Chip
} from '@mui/material';
import axios from 'axios';

interface Performance {
  _id: string;
  band: {
    _id: string;
    artistName: string;
  };
  event?: {
    _id: string;
    name: string;
  };
  startTime: string;
  endTime: string;
  status: 'pending' | 'confirmed' | 'cancelled';
}

const PerformanceList: React.FC = () => {
  const [performances, setPerformances] = useState<Performance[]>([]);
  const [filteredPerformances, setFilteredPerformances] = useState<Performance[]>([]);
  const [events, setEvents] = useState<string[]>([]);
  const [bands, setBands] = useState<string[]>([]);
  const [eventFilter, setEventFilter] = useState<string>('');
  const [bandFilter, setBandFilter] = useState<string>('');
  const [statusFilter, setStatusFilter] = useState<string>('');

  useEffect(() => {
    const fetchPerformances = async () => {
      try {
        const response = await axios.get<Performance[]>(`${process.env.REACT_APP_API_URL}/api/performances`);
        setPerformances(response.data);
        setFilteredPerformances(response.data);
        
        // Only include events that exist
        const uniqueEvents = Array.from(new Set(
          response.data
            .filter(performance => performance.event)
            .map(performance => performance.event!.name)
        ));
        setEvents(uniqueEvents);
        
        const uniqueBands = Array.from(new Set(
          response.data.map(performance => performance.band.artistName)
        ));
        setBands(uniqueBands);
      } catch (error) {
        console.error('Error fetching performances:', error);
      }
    };
    fetchPerformances();
  }, []);

  useEffect(() => {
    const applyFilters = () => {
      let filtered = performances;
      
      if (eventFilter) {
        filtered = filtered.filter(performance => 
          performance.event && performance.event.name === eventFilter
        );
      }
      
      if (bandFilter) {
        filtered = filtered.filter(performance => 
          performance.band.artistName === bandFilter
        );
      }
      
      if (statusFilter) {
        filtered = filtered.filter(performance => 
          performance.status === statusFilter
        );
      }
      
      setFilteredPerformances(filtered);
    };
    
    applyFilters();
  }, [performances, eventFilter, bandFilter, statusFilter]);

  const getPerformanceDateString = (performance: Performance) => {
    const startDate = new Date(performance.startTime);
    const endDate = new Date(performance.endTime);
    return `${startDate.toLocaleDateString()} ${startDate.toLocaleTimeString()} - ${endDate.toLocaleTimeString()}`;
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'confirmed': return 'success';
      case 'pending': return 'warning';
      case 'cancelled': return 'error';
      default: return 'default';
    }
  };

  const handleEventFilterChange = (event: SelectChangeEvent<string>) => {
    setEventFilter(event.target.value);
  };

  const handleBandFilterChange = (event: SelectChangeEvent<string>) => {
    setBandFilter(event.target.value);
  };

  const handleStatusFilterChange = (event: SelectChangeEvent<string>) => {
    setStatusFilter(event.target.value);
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Performances
      </Typography>
      <Button 
        component={Link} 
        to="/performances/new" 
        variant="contained" 
        color="primary" 
        sx={{ mb: 2 }}
      >
        Create New Performance
      </Button>
      
      <Box sx={{ mb: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel>Filter by Event</InputLabel>
              <Select
                value={eventFilter}
                onChange={handleEventFilterChange}
                label="Filter by Event"
              >
                <MenuItem value="">All Events</MenuItem>
                {events.map((event) => (
                  <MenuItem key={event} value={event}>{event}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel>Filter by Band</InputLabel>
              <Select
                value={bandFilter}
                onChange={handleBandFilterChange}
                label="Filter by Band"
              >
                <MenuItem value="">All Bands</MenuItem>
                {bands.map((band) => (
                  <MenuItem key={band} value={band}>{band}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel>Filter by Status</InputLabel>
              <Select
                value={statusFilter}
                onChange={handleStatusFilterChange}
                label="Filter by Status"
              >
                <MenuItem value="">All Statuses</MenuItem>
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="confirmed">Confirmed</MenuItem>
                <MenuItem value="cancelled">Cancelled</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={3}>
        {filteredPerformances.map((performance) => (
          <Grid item xs={12} sm={6} md={4} key={performance._id}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h2">
                  {performance.band.artistName}
                </Typography>
                <Typography color="text.secondary">
                  Event: {performance.event ? performance.event.name : 'Not assigned to event'}
                </Typography>
                <Typography variant="body2" sx={{ mt: 1, mb: 1 }}>
                  Date: {getPerformanceDateString(performance)}
                </Typography>
                <Chip 
                  label={performance.status}
                  color={getStatusColor(performance.status) as "success" | "warning" | "error" | "default"}
                  size="small"
                />
              </CardContent>
              <CardActions>
                <Button 
                  size="small" 
                  component={Link} 
                  to={`/performances/${performance._id}`}
                >
                  View Details
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
        {filteredPerformances.length === 0 && (
          <Grid item xs={12}>
            <Typography variant="body1" align="center" color="text.secondary">
              No performances found matching the selected filters.
            </Typography>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default PerformanceList;