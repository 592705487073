import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  TableSortLabel, TextField, MenuItem, Box, CircularProgress, IconButton
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

interface Artist {
  _id: string;
  artistName: string;
}

interface Prospect {
  _id: string;
  strandId: string;
  date: string;
  eventConcept: string;
  potentialArtists: (string | Artist)[];
  venueIdeas: string[];
  fees: number;
  boxOffice: number;
  expenses: number;
  sponsorship: number;
  net: number;
  notes: string;
  status: 'idea' | 'prospective' | 'negotiation' | 'confirmed';
  createdAt: string;
  updatedAt: string;
  strandName: string;
}

interface Strand {
  _id: string;
  name: string;
}

type SortField = 'date' | 'eventConcept' | 'status' | 'fees' | 'net' | 'strandName' | 'potentialArtists';

const AllProspectsPage: React.FC = () => {
  const navigate = useNavigate();
  const [prospects, setProspects] = useState<Prospect[]>([]);
  const [strands, setStrands] = useState<Strand[]>([]);
  const [loading, setLoading] = useState(true);
  const [sortField, setSortField] = useState<SortField>('date');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [filterStatus, setFilterStatus] = useState<string>('');
  const [filterDateFrom, setFilterDateFrom] = useState<Date | null>(null);
  const [filterDateTo, setFilterDateTo] = useState<Date | null>(null);
  const [filterStrand, setFilterStrand] = useState<string>('');

  useEffect(() => {
    fetchProspectsAndStrands();
  }, []);

  const fetchProspectsAndStrands = async () => {
    setLoading(true);
    try {
      const strandsResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/strands`);

      if (!strandsResponse.ok) {
        throw new Error('Failed to fetch strands');
      }

      const strandsData: Strand[] = await strandsResponse.json();
      setStrands(strandsData);

      const allProspects = await Promise.all(
        strandsData.map(async (strand: Strand) => {
          const prospectsResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/strands/${strand._id}/prospects`);
          if (!prospectsResponse.ok) {
            console.error(`Failed to fetch prospects for strand ${strand._id}`);
            return [];
          }
          const prospectsData: Prospect[] = await prospectsResponse.json();
          return prospectsData.map((prospect: Prospect) => ({ ...prospect, strandName: strand.name }));
        })
      );

      setProspects(allProspects.flat());
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle error (e.g., show error message to user)
    } finally {
      setLoading(false);
    }
  };

  const handleSort = (field: SortField) => {
    const isAsc = sortField === field && sortDirection === 'asc';
    setSortField(field);
    setSortDirection(isAsc ? 'desc' : 'asc');
  };

  const sortedProspects = [...prospects].sort((a, b) => {
    let aValue: any = a[sortField];
    let bValue: any = b[sortField];

    if (sortField === 'potentialArtists') {
      aValue = a.potentialArtists.map(artist => typeof artist === 'string' ? artist : artist.artistName).join(', ');
      bValue = b.potentialArtists.map(artist => typeof artist === 'string' ? artist : artist.artistName).join(', ');
    }

    if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
    if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
    return 0;
  });

  const filteredProspects = sortedProspects.filter(prospect => 
    (filterStatus ? prospect.status === filterStatus : true) &&
    (filterDateFrom ? new Date(prospect.date) >= filterDateFrom : true) &&
    (filterDateTo ? new Date(prospect.date) <= filterDateTo : true) &&
    (filterStrand ? prospect.strandId === filterStrand : true)
  );

  const handleEdit = (id: string, strandId: string) => {
    navigate(`/admin/prospects/${strandId}/edit/${id}`);
  };

  const handleDelete = async (id: string) => {
    if (window.confirm('Are you sure you want to delete this prospect?')) {
      try {
        await fetch(`${process.env.REACT_APP_API_URL}/api/prospects/${id}`, {
          method: 'DELETE',
        });
        fetchProspectsAndStrands(); // Refresh the list after deletion
      } catch (error) {
        console.error('Error deleting prospect:', error);
      }
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom>All Prospects</Typography>
        
        {/* Filters */}
        <Box sx={{ mb: 2, display: 'flex', gap: 2 }}>
          <TextField
            select
            label="Status"
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
            sx={{ minWidth: 120 }}
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="idea">Idea</MenuItem>
            <MenuItem value="prospective">Prospective</MenuItem>
            <MenuItem value="negotiation">Negotiation</MenuItem>
            <MenuItem value="confirmed">Confirmed</MenuItem>
          </TextField>
          <DatePicker
            label="From Date"
            value={filterDateFrom}
            onChange={(date) => setFilterDateFrom(date)}
          />
          <DatePicker
            label="To Date"
            value={filterDateTo}
            onChange={(date) => setFilterDateTo(date)}
          />
          <TextField
            select
            label="Strand"
            value={filterStrand}
            onChange={(e) => setFilterStrand(e.target.value)}
            sx={{ minWidth: 120 }}
          >
            <MenuItem value="">All</MenuItem>
            {strands.map(strand => (
              <MenuItem key={strand._id} value={strand._id}>{strand.name}</MenuItem>
            ))}
          </TextField>
        </Box>

        {loading ? (
          <CircularProgress />
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={sortField === 'date'}
                      direction={sortField === 'date' ? sortDirection : 'asc'}
                      onClick={() => handleSort('date')}
                    >
                      Date
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortField === 'eventConcept'}
                      direction={sortField === 'eventConcept' ? sortDirection : 'asc'}
                      onClick={() => handleSort('eventConcept')}
                    >
                      Event Concept
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortField === 'potentialArtists'}
                      direction={sortField === 'potentialArtists' ? sortDirection : 'asc'}
                      onClick={() => handleSort('potentialArtists')}
                    >
                      Potential Artists
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortField === 'status'}
                      direction={sortField === 'status' ? sortDirection : 'asc'}
                      onClick={() => handleSort('status')}
                    >
                      Status
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortField === 'fees'}
                      direction={sortField === 'fees' ? sortDirection : 'asc'}
                      onClick={() => handleSort('fees')}
                    >
                      Fees
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Box Office</TableCell>
                  <TableCell>Expenses</TableCell>
                  <TableCell>Sponsorship</TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortField === 'net'}
                      direction={sortField === 'net' ? sortDirection : 'asc'}
                      onClick={() => handleSort('net')}
                    >
                      Net
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortField === 'strandName'}
                      direction={sortField === 'strandName' ? sortDirection : 'asc'}
                      onClick={() => handleSort('strandName')}
                    >
                      Strand
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredProspects.map((prospect) => (
                  <TableRow key={prospect._id}>
                    <TableCell>{new Date(prospect.date).toLocaleDateString()}</TableCell>
                    <TableCell>{prospect.eventConcept}</TableCell>
                    <TableCell>
                      {prospect.potentialArtists.map((artist, index) => (
                        <span key={index}>
                          {typeof artist === 'string' ? (
                            artist
                          ) : (
                            <a
                              href={`/band/${artist._id}`}
                              onClick={(e) => {
                                e.preventDefault();
                                navigate(`/band/${artist._id}`);
                              }}
                            >
                              {artist.artistName}
                            </a>
                          )}
                          {index < prospect.potentialArtists.length - 1 ? ', ' : ''}
                        </span>
                      ))}
                    </TableCell>
                    <TableCell>{prospect.status}</TableCell>
                    <TableCell>{prospect.fees}</TableCell>
                    <TableCell>{prospect.boxOffice}</TableCell>
                    <TableCell>{prospect.expenses}</TableCell>
                    <TableCell>{prospect.sponsorship}</TableCell>
                    <TableCell>{prospect.net}</TableCell>
                    <TableCell>{prospect.strandName}</TableCell>
                    <TableCell>
                    <IconButton onClick={() => handleEdit(prospect._id, prospect.strandId)}>
  <EditIcon />
</IconButton>
                      <IconButton onClick={() => handleDelete(prospect._id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Container>
    </LocalizationProvider>
  );
};

export default AllProspectsPage;