import React from 'react';
import { Container, Typography, Breadcrumbs, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';
import PerformanceList from '../components/PerformanceList';

const PerformancesPage: React.FC = () => {
  return (
    <Container maxWidth="lg">
      <Breadcrumbs aria-label="breadcrumb" sx={{ my: 2 }}>
        <MuiLink component={Link} to="/" color="inherit">
          Home
        </MuiLink>
        <Typography color="text.primary">Performances</Typography>
      </Breadcrumbs>

      <Typography variant="h3" component="h1" gutterBottom>
        Performances
      </Typography>

      <Typography variant="body1" paragraph>
        View and manage all performances across events. Use the filters to narrow down the list.
      </Typography>

      <PerformanceList />
    </Container>
  );
};

export default PerformancesPage;