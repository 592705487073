import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { 
  Container, 
  Typography, 
  Button, 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  IconButton, 
  CircularProgress, 
  Alert, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle,
  Switch,
  Box,
  Tooltip,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PublicIcon from '@mui/icons-material/Public';
import FilterListIcon from '@mui/icons-material/FilterList';
import axios from 'axios';

interface Strand {
  _id: string;
  name: string;
  type: string;
  status: string;
  eventType: string;
  startDate: string;
  numberOfProposedEvents: number;
  endDate: string;
  published: boolean;
  slug: string;
}

interface Filters {
  type: string;
  status: string;
  eventType: string;
  published: string;
  dateRange: {
    start: string;
    end: string;
  };
}

type SortDirection = 'asc' | 'desc';

interface SortConfig {
  field: keyof Strand | '';
  direction: SortDirection;
}

const API_BASE_URL = process.env.REACT_APP_API_URL;

const StrandListPage: React.FC = () => {
  const [strands, setStrands] = useState<Strand[]>([]);
  const [filteredStrands, setFilteredStrands] = useState<Strand[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [strandToDelete, setStrandToDelete] = useState<string | null>(null);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [sortConfig, setSortConfig] = useState<SortConfig>({ field: '', direction: 'asc' });
  const navigate = useNavigate();

  const [filters, setFilters] = useState<Filters>({
    type: '',
    status: '',
    eventType: '',
    published: '',
    dateRange: {
      start: '',
      end: ''
    }
  });

  useEffect(() => {
    fetchStrands();
  }, []);

  useEffect(() => {
    applyFiltersAndSort();
  }, [strands, filters, sortConfig]);

  const fetchStrands = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get<Strand[]>(`${API_BASE_URL}/api/strands`);
      setStrands(response.data);
      setFilteredStrands(response.data);
    } catch (error) {
      console.error('Error fetching strands:', error);
      setError('Failed to fetch strands. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSort = (field: keyof Strand) => {
    setSortConfig(prevConfig => ({
      field,
      direction: prevConfig.field === field && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const handleFilterChange = (field: keyof Filters, value: any) => {
    setFilters(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const resetFilters = () => {
    setFilters({
      type: '',
      status: '',
      eventType: '',
      published: '',
      dateRange: {
        start: '',
        end: ''
      }
    });
  };

  const applyFiltersAndSort = () => {
    let filtered = [...strands];

    // Apply filters
    if (filters.type) {
      filtered = filtered.filter(strand => strand.type === filters.type);
    }
    if (filters.status) {
      filtered = filtered.filter(strand => strand.status === filters.status);
    }
    if (filters.eventType) {
      filtered = filtered.filter(strand => strand.eventType === filters.eventType);
    }
    if (filters.published) {
      filtered = filtered.filter(strand => 
        filters.published === 'true' ? strand.published : !strand.published
      );
    }
    if (filters.dateRange.start) {
      filtered = filtered.filter(strand => 
        new Date(strand.startDate) >= new Date(filters.dateRange.start)
      );
    }
    if (filters.dateRange.end) {
      filtered = filtered.filter(strand => 
        new Date(strand.endDate) <= new Date(filters.dateRange.end)
      );
    }

    // Apply sort
    if (sortConfig.field) {
      filtered.sort((a, b) => {
        const aValue = a[sortConfig.field as keyof Strand];
        const bValue = b[sortConfig.field as keyof Strand];
        
        if (aValue < bValue) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }

    setFilteredStrands(filtered);
  };

  const handleDeleteClick = (id: string) => {
    setStrandToDelete(id);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (strandToDelete) {
      setLoading(true);
      setError(null);
      try {
        await axios.delete(`${API_BASE_URL}/api/strands/${strandToDelete}`);
        fetchStrands();
      } catch (error) {
        console.error('Error deleting strand:', error);
        setError('Failed to delete strand. Please try again.');
      } finally {
        setLoading(false);
        setDeleteDialogOpen(false);
        setStrandToDelete(null);
      }
    }
  };

  const handlePublishToggle = async (strand: Strand) => {
    setLoading(true);
    setError(null);
    try {
      const updatedStrand = { ...strand, published: !strand.published };
      const response = await axios.put(`${API_BASE_URL}/api/strands/${strand._id}`, updatedStrand);
      setStrands(prevStrands => prevStrands.map(s => s._id === strand._id ? response.data : s));
    } catch (error) {
      console.error('Error updating strand:', error);
      setError('Failed to update strand. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleStrandClick = (strand: Strand) => {
    navigate(`/admin/strands/${strand._id}`);
  };

  const handlePublishedBandsClick = (strand: Strand) => {
    navigate(`/strands/${strand.slug}`);
  };

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  const uniqueTypes = Array.from(new Set(strands.map(strand => strand.type)));
  const uniqueStatuses = Array.from(new Set(strands.map(strand => strand.status)));
  const uniqueEventTypes = Array.from(new Set(strands.map(strand => strand.eventType)));

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Strands</Typography>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button 
            component={Link} 
            to="/strands/new" 
            variant="contained" 
            color="primary"
          >
            Create New Strand
          </Button>
          <Button 
            component={Link} 
            to="/publicstrands" 
            variant="outlined" 
            color="primary"
          >
            View Published Strands
          </Button>
        </Box>
        <Button
          startIcon={<FilterListIcon />}
          onClick={() => setShowFilters(!showFilters)}
          variant="outlined"
        >
          {showFilters ? 'Hide Filters' : 'Show Filters'}
        </Button>
      </Box>

      {showFilters && (
        <Paper sx={{ p: 2, mb: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={2}>
              <FormControl fullWidth>
                <InputLabel>Type</InputLabel>
                <Select
                  value={filters.type}
                  onChange={(e) => handleFilterChange('type', e.target.value)}
                  label="Type"
                >
                  <MenuItem value="">All</MenuItem>
                  {uniqueTypes.map(type => (
                    <MenuItem key={type} value={type}>{type}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  value={filters.status}
                  onChange={(e) => handleFilterChange('status', e.target.value)}
                  label="Status"
                >
                  <MenuItem value="">All</MenuItem>
                  {uniqueStatuses.map(status => (
                    <MenuItem key={status} value={status}>{status}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <FormControl fullWidth>
                <InputLabel>Event Type</InputLabel>
                <Select
                  value={filters.eventType}
                  onChange={(e) => handleFilterChange('eventType', e.target.value)}
                  label="Event Type"
                >
                  <MenuItem value="">All</MenuItem>
                  {uniqueEventTypes.map(type => (
                    <MenuItem key={type} value={type}>{type}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <FormControl fullWidth>
                <InputLabel>Published</InputLabel>
                <Select
                  value={filters.published}
                  onChange={(e) => handleFilterChange('published', e.target.value)}
                  label="Published"
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="true">Published</MenuItem>
                  <MenuItem value="false">Not Published</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                type="date"
                label="Start Date"
                InputLabelProps={{ shrink: true }}
                fullWidth
                value={filters.dateRange.start}
                onChange={(e) => handleFilterChange('dateRange', { ...filters.dateRange, start: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                type="date"
                label="End Date"
                InputLabelProps={{ shrink: true }}
                fullWidth
                value={filters.dateRange.end}
                onChange={(e) => handleFilterChange('dateRange', { ...filters.dateRange, end: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <Button onClick={resetFilters} variant="outlined">
                Reset Filters
              </Button>
            </Grid>
          </Grid>
        </Paper>
      )}

{filteredStrands.length === 0 ? (
  <Typography>No strands found.</Typography>
) : (
  <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <TableSortLabel
              active={sortConfig.field === 'name'}
              direction={sortConfig.field === 'name' ? sortConfig.direction : 'asc'}
              onClick={() => handleSort('name')}
            >
              Strand Name
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={sortConfig.field === 'type'}
              direction={sortConfig.field === 'type' ? sortConfig.direction : 'asc'}
              onClick={() => handleSort('type')}
            >
              Type
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={sortConfig.field === 'numberOfProposedEvents'}
              direction={sortConfig.field === 'numberOfProposedEvents' ? sortConfig.direction : 'asc'}
              onClick={() => handleSort('numberOfProposedEvents')}
            >
              # Events
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={sortConfig.field === 'status'}
              direction={sortConfig.field === 'status' ? sortConfig.direction : 'asc'}
              onClick={() => handleSort('status')}
            >
              Status
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={sortConfig.field === 'eventType'}
              direction={sortConfig.field === 'eventType' ? sortConfig.direction : 'asc'}
              onClick={() => handleSort('eventType')}
            >
              Event Type
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={sortConfig.field === 'startDate'}
              direction={sortConfig.field === 'startDate' ? sortConfig.direction : 'asc'}
              onClick={() => handleSort('startDate')}
            >
              Dates
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={sortConfig.field === 'published'}
              direction={sortConfig.field === 'published' ? sortConfig.direction : 'asc'}
              onClick={() => handleSort('published')}
            >
              Publish Status
            </TableSortLabel>
          </TableCell>
          <TableCell align="right">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {filteredStrands.map((strand) => (
          <TableRow key={strand._id} hover>
            <TableCell>
              <Button
                onClick={() => handleStrandClick(strand)}
                sx={{ textAlign: 'left', textTransform: 'none' }}
              >
                {strand.name}
              </Button>
            </TableCell>
            <TableCell>{strand.type}</TableCell>
            <TableCell>{strand.numberOfProposedEvents}</TableCell>
            <TableCell>{strand.status}</TableCell>
            <TableCell>{strand.eventType}</TableCell>
            <TableCell>
              {`${new Date(strand.startDate).toLocaleDateString()} - ${new Date(strand.endDate).toLocaleDateString()}`}
            </TableCell>
            <TableCell>
              <Button
                onClick={() => handlePublishedBandsClick(strand)}
                startIcon={<PublicIcon />}
                color={strand.published ? "primary" : "inherit"}
                sx={{ textTransform: 'none' }}
              >
                {strand.published ? 'Published & Accepting Applications' : 'Closed Strand'}
              </Button>
            </TableCell>
            <TableCell align="right">
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                <Tooltip title={strand.published ? "Unpublish Strand" : "Publish Strand"}>
                  <Switch
                    edge="end"
                    onChange={() => handlePublishToggle(strand)}
                    checked={strand.published}
                  />
                </Tooltip>
                <Tooltip title="View Published Bands">
                  <IconButton 
                    onClick={() => handlePublishedBandsClick(strand)}
                    color="primary"
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edit Strand">
                  <IconButton 
                    component={Link} 
                    to={`/strands/edit/${strand._id}`}
                    color="primary"
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete Strand">
                  <IconButton 
                    onClick={() => handleDeleteClick(strand._id)}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
)}

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm Deletion
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this strand? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default StrandListPage;