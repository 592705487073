import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent, Typography, CardActions, Button, Chip, CardMedia } from '@mui/material';

interface PublishedBandCardProps {
  band: {
    _id: string;
    artistName: string;
    genres: string[];
    shortDescription: string;
    mainImage: string;
  };
  strandSlug: string;
}

const PublishedBandCard: React.FC<PublishedBandCardProps> = ({ band, strandSlug }) => {
  return (
    <Card>
      <CardMedia
        component="img"
        height="200"
        image={band.mainImage || '/placeholder-image.jpg'} // Fallback to placeholder if no image
        alt={band.artistName}
        sx={{ objectFit: 'cover' }}
      />
      <CardContent>
        <Typography variant="h5" component="h2">
          {band.artistName}
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          {band.shortDescription}
        </Typography>
        {band.genres.map((genre) => (
          <Chip key={genre} label={genre} size="small" style={{ margin: '2px' }} />
        ))}
      </CardContent>
      <CardActions>
        <Button component={Link} to={`/strands/${strandSlug}/band/${band._id}`}>
          View Details
        </Button>
      </CardActions>
    </Card>
  );
};

export default PublishedBandCard;