import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Typography, Alert, CircularProgress } from '@mui/material';
import axios from 'axios';
import StrandForm from '../components/StrandForm';
import { Strand } from '../types/Strand';

const API_BASE_URL = process.env.REACT_APP_API_URL;

const StrandFormPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [strand, setStrand] = useState<Strand | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (id) {
      fetchStrand();
    }
  }, [id]);

  const fetchStrand = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get<Strand>(`${API_BASE_URL}/api/strands/${id}`);
      setStrand(response.data);
    } catch (error) {
      console.error('Error fetching strand:', error);
      setError('Failed to fetch strand details. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (data: Strand) => {
    setLoading(true);
    setError(null);
    try {
      let response;
      if (id) {
        // If we have an id, it's an update operation
        response = await axios.put(`${API_BASE_URL}/api/strands/${id}`, data);
        console.log('Updated strand:', response.data);
      } else {
        // If we don't have an id, it's a create operation
        response = await axios.post(`${API_BASE_URL}/api/strands`, data);
        console.log('Created new strand:', response.data);
      }
      navigate('/admin/strands');
    } catch (error) {
      console.error('Error saving strand:', error);
      setError('Failed to save strand. Please check your inputs and try again.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        {id ? 'Edit Strand' : 'Create New Strand'}
      </Typography>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      <StrandForm initialData={strand} onSubmit={handleSubmit} />
    </Container>
  );
};

export default StrandFormPage;