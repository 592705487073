import axios, { AxiosInstance, AxiosError } from 'axios';

// Type for API Error Response
interface ApiErrorResponse {
  message: string;
  status: number;
}

// Create axios instance
const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    
    // Debug logging
    console.log('Request Config:', {
      url: config.url,
      method: config.method,
      headers: config.headers,
      token: token ? 'Present' : 'Not Present', // Don't log actual token
    });

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    
    config.withCredentials = true;
    return config;
  },
  (error: AxiosError) => {
    console.error('Request Interceptor Error:', error.message);
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Debug logging
    console.log('Response:', {
      status: response.status,
      url: response.config.url,
      dataPresent: !!response.data, // Log presence of data instead of actual data
    });
    
    return response;
  },
  (error: AxiosError<ApiErrorResponse>) => {
    console.error('Response Error:', {
      status: error.response?.status,
      url: error.config?.url,
      message: error.response?.data?.message || error.message,
    });

    // Handle authentication errors
    if (error.response?.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/login';
    }

    return Promise.reject(error);
  }
);

// Export as default
export default axiosInstance;

// Helper function to handle API errors
export const handleApiError = (error: unknown): string => {
  if (axios.isAxiosError(error)) {
    return error.response?.data?.message || error.message;
  }
  return 'An unexpected error occurred';
};