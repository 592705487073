import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Alert,
  SelectChangeEvent,
} from '@mui/material';
import { X as DeleteIcon } from 'lucide-react';
import axiosInstance from '../../utils/axiosConfig';

interface Strand {
  _id: string;
  name: string;
  slug: string;
}

interface PublishedStrand {
  strandId: {
    _id: string;
    name: string;
    slug: string;
  };
  shortDescription?: string;
  publishedDate?: string;
  _id?: string;
  customFields?: {
    availabilityOverride?: {
      from: string;
      to: string;
    };
  };
}

interface StrandPublishingManagerProps {
  bandId: string;
  open: boolean;
  onClose: () => void;
}

const StrandPublishingManager: React.FC<StrandPublishingManagerProps> = ({
  bandId,
  open,
  onClose,
}) => {
  const [strands, setStrands] = useState<Strand[]>([]);
  const [selectedStrand, setSelectedStrand] = useState<string>('');
  const [publishedStrands, setPublishedStrands] = useState<PublishedStrand[]>([]);
  const [shortDescription, setShortDescription] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  useEffect(() => {
    if (open) {
      fetchData();
    }
  }, [open, bandId]);

  const fetchData = async () => {
    try {
      setError(null);
      const [strandsResponse, bandResponse] = await Promise.all([
        axiosInstance.get<Strand[]>('/api/strands'),
        axiosInstance.get(`/api/bandrecommendations/${bandId}`),
      ]);
      
      setStrands(strandsResponse.data);
      setPublishedStrands(bandResponse.data.publishedTo || []);
      console.log('Published strands:', bandResponse.data.publishedTo);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to fetch data. Please try again.');
    }
  };

  const handleStrandChange = (event: SelectChangeEvent<string>) => {
    setSelectedStrand(event.target.value as string);
  };

  const handlePublish = async () => {
    if (!selectedStrand) return;
    
    setLoading(true);
    setError(null);
    
    try {
      await axiosInstance.post(`/api/bandrecommendations/${bandId}/publish`, {
        strandIds: [selectedStrand],
        shortDescription
      });

      setSuccessMessage('Band published successfully');
      await fetchData();
      setSelectedStrand('');
      setShortDescription('');
    } catch (error: any) {
      console.error('Error publishing band:', error);
      setError(error.response?.data?.message || 'Failed to publish band');
    } finally {
      setLoading(false);
    }
  };

  const handleUnpublish = async (strandId: { _id: string; name: string; slug: string }) => {
    if (!strandId._id) {
      setError('Invalid strand ID');
      return;
    }
  
    setLoading(true);
    setError(null);
    
    try {
      console.log('Attempting to unpublish strandId:', strandId._id);
      
      await axiosInstance.delete(
        `/api/bandrecommendations/${bandId}/unpublish/${strandId._id}`
      );
  
      setSuccessMessage('Band unpublished successfully');
      await fetchData();
    } catch (error: any) {
      console.error('Error unpublishing band:', error);
      setError(error.response?.data?.message || 'Failed to unpublish. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Manage Publication Status</DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        {successMessage && (
          <Alert severity="success" sx={{ mb: 2 }} onClose={() => setSuccessMessage(null)}>
            {successMessage}
          </Alert>
        )}

        {/* Currently Published Section */}
        <Box mb={3}>
          <Typography variant="h6" gutterBottom>
            Currently Published To
          </Typography>
          <List>
            {publishedStrands.length > 0 ? (
              publishedStrands.map((pub) => (
                <ListItem
                  key={pub._id || pub.strandId._id}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="unpublish"
                      onClick={() => handleUnpublish(pub.strandId)}
                      disabled={loading}
                    >
                      <DeleteIcon className="h-4 w-4" />
                    </IconButton>
                  }
                >
                  <ListItemText 
                    primary={pub.strandId.name}
                    secondary={pub.shortDescription}
                  />
                </ListItem>
              ))
            ) : (
              <ListItem>
                <ListItemText primary="Not published to any strands" />
              </ListItem>
            )}
          </List>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Publish to New Strand Section */}
        <Typography variant="h6" gutterBottom>
          Publish to New Strand
        </Typography>

        <FormControl fullWidth margin="normal">
          <InputLabel>Select Strand</InputLabel>
          <Select
            value={selectedStrand}
            onChange={handleStrandChange}
            label="Select Strand"
          >
            {strands.map((strand) => (
              <MenuItem
                key={strand._id}
                value={strand._id}
                disabled={publishedStrands.some(pub => pub.strandId._id === strand._id)}
              >
                {strand.name}
                {publishedStrands.some(pub => pub.strandId._id === strand._id) && 
                  ' (Already published)'}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          fullWidth
          margin="normal"
          label="Short Description"
          multiline
          rows={4}
          value={shortDescription}
          onChange={(e) => setShortDescription(e.target.value)}
          helperText="Optional: Provide a short description useful for programmers. This will be public."
          placeholder="Enter a short description..."
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button
          onClick={handlePublish}
          variant="contained"
          color="primary"
          disabled={!selectedStrand || loading}
        >
          Publish to Selected Strand
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StrandPublishingManager;