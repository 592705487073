import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Typography, 
  TextField, 
  Button, 
  Grid, 
  Box, 
  Select, 
  MenuItem, 
  InputLabel, 
  FormControl,
  SelectChangeEvent,
  CircularProgress,
  Alert,
  Autocomplete,
  Chip,
  Tooltip
} from '@mui/material';
import { IrishCounty } from '../../types/BandRecommendations';
import jazzSubgenres from '../../data/jazzSubgenres';

const ArtistProfileEditPage = () => {
  const [profileData, setProfileData] = useState({
    name: '',
    email: '',
    streetAddress1: '',
    streetAddress2: '',
    city: '',
    county: '',
    postalCode: '',
    phonePrefix: '',
    phoneNumber: '',
    bio: '',
    genres: [] as string[],
    website: '',
    facebookPage: '',
    twitterHandle: '',
    instagramHandle: '',
    tikTokHandle: '',
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  useEffect(() => {
    fetchProfileData();
  }, []);

  const fetchProfileData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/me`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch profile data');
      }

      const data = await response.json();
      setProfileData({
        ...data,
        genres: data.genres || [],
      });
      setError(null);
    } catch (err) {
      setError('Failed to load profile data. Please try again later.');
      console.error('Error fetching profile data:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name as string]: value });
  };

  const handleSelectChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  };

  const handleGenreChange = (event: React.SyntheticEvent, value: string[]) => {
    setProfileData({ ...profileData, genres: value.slice(0, 3) });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/profile`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(profileData),
      });

      if (!response.ok) {
        throw new Error('Failed to update profile');
      }

      setSuccessMessage('Profile updated successfully!');
      setError(null);
    } catch (err) {
      setError('Failed to update profile. Please try again later.');
      console.error('Error updating profile:', err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Container maxWidth="md" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Typography variant="h4" component="h1" gutterBottom>
        Edit Your Artist Profile
      </Typography>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      {successMessage && <Alert severity="success" sx={{ mb: 2 }}>{successMessage}</Alert>}
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="name"
              label="Full Name"
              value={profileData.name}
              InputProps={{
                readOnly: true,
              }}
              variant="filled"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="email"
              label="Email Address"
              type="email"
              value={profileData.email}
              InputProps={{
                readOnly: true,
              }}
              variant="filled"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="streetAddress1"
              label="Street Address"
              value={profileData.streetAddress1}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="streetAddress2"
              label="Street Address Line 2"
              value={profileData.streetAddress2}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="city"
              label="City"
              value={profileData.city}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>County</InputLabel>
              <Select
                name="county"
                value={profileData.county ?? ''} // or value={profileData.county || ''}
                onChange={handleSelectChange}
                label="County"
              >
                {Object.values(IrishCounty).map((county) => (
                  <MenuItem key={county} value={county}>{county}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="postalCode"
              label="Postal Code / Eircode"
              value={profileData.postalCode}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth
              name="phonePrefix"
              label="Phone Prefix"
              value={profileData.phonePrefix}
              onChange={handleChange}
              inputProps={{ maxLength: 3 }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              name="phoneNumber"
              label="Phone Number"
              value={profileData.phoneNumber}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={4}
              name="bio"
              label="Bio"
              value={profileData.bio}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Tooltip title="Select up to 3 relevant jazz sub-genres.">
              <Autocomplete
                multiple
                id="genres"
                options={jazzSubgenres}
                value={profileData.genres}
                onChange={handleGenreChange}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Jazz Sub-genres (Select up to 3)" fullWidth />
                )}
                renderTags={(value: string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} key={index} />
                  ))
                }
                limitTags={3}
                fullWidth
              />
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="website"
              label="Website"
              value={profileData.website}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="facebookPage"
              label="Facebook Page"
              value={profileData.facebookPage}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="twitterHandle"
              label="Twitter Handle"
              value={profileData.twitterHandle}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="instagramHandle"
              label="Instagram Handle"
              value={profileData.instagramHandle}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="tikTokHandle"
              label="TikTok Handle"
              value={profileData.tikTokHandle}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Button type="submit" variant="contained" sx={{ mt: 3 }} disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Save Profile'}
        </Button>
      </Box>
    </Container>
  );
};

export default ArtistProfileEditPage;