// DynamicForm.tsx

import React, { useState } from 'react';
import { FormSchema, FormField, FieldType } from '../../../types//FormSchema';
import { TextField, Checkbox, Button, Grid, Typography } from '@mui/material';

interface DynamicFormProps {
  formSchema: FormSchema;
}

const DynamicForm: React.FC<DynamicFormProps> = ({ formSchema }) => {
  const [formData, setFormData] = useState<Record<string, any>>({});
  const [errors, setErrors] = useState<Record<string, string>>({});

  const handleChange = (id: string, value: any) => {
    setFormData({ ...formData, [id]: value });
  };

  const validateField = (field: FormField, value: any): string | null => {
    const { validation } = field;
    if (validation?.required && !value) {
      return 'This field is required.';
    }
    if (
      validation?.maxLength &&
      typeof value === 'string' &&
      value.length > validation.maxLength
    ) {
      return `Maximum length is ${validation.maxLength} characters.`;
    }
    // Add more validation as needed
    return null;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const newErrors: Record<string, string> = {};

    formSchema.fields.forEach((field) => {
      const error = validateField(field, formData[field.id]);
      if (error) {
        newErrors[field.id] = error;
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      // Submit form data to backend
      console.log('Form submitted:', formData);
    }
  };

  const renderField = (field: FormField) => {
    switch (field.type) {
      case FieldType.Text:
      case FieldType.Email:
      case FieldType.Url:
        return (
          <TextField
            key={field.id}
            fullWidth
            required={field.validation?.required}
            name={field.name}
            label={field.label}
            type={field.type === FieldType.Email ? 'email' : 'text'}
            placeholder={field.placeholder}
            value={formData[field.id] || ''}
            onChange={(e) => handleChange(field.id, e.target.value)}
            helperText={errors[field.id] || field.helperText}
            error={!!errors[field.id]}
          />
        );
      case FieldType.TextArea:
        return (
          <TextField
            key={field.id}
            fullWidth
            required={field.validation?.required}
            name={field.name}
            label={field.label}
            placeholder={field.placeholder}
            value={formData[field.id] || ''}
            onChange={(e) => handleChange(field.id, e.target.value)}
            helperText={errors[field.id] || field.helperText}
            error={!!errors[field.id]}
            multiline
            rows={4}
          />
        );
      case FieldType.Checkbox:
        return (
          <Checkbox
            key={field.id}
            checked={!!formData[field.id]}
            onChange={(e) => handleChange(field.id, e.target.checked)}
            name={field.name}
          />
        );
      case FieldType.Upload:
        // Implement file upload logic
        return (
          <div key={field.id}>
            <Typography variant="body1">{field.label}</Typography>
            {/* FileUploadComponent should handle file selection and uploading */}
            {/* Update formData with the file URL or metadata */}
          </div>
        );
      // Handle other field types
      default:
        return null;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
<Grid container spacing={3}>
  {formSchema.fields.map((field) => (
    <Grid
      item
      xs={field.grid?.xs || 12}
      sm={field.grid?.sm}
      md={field.grid?.md}
      lg={field.grid?.lg}
      key={field.id}
    >
      {renderField(field)}
    </Grid>
        ))}
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            Submit Application
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default DynamicForm;
