import React, { useState } from 'react';
import { 
  Grid, 
  TextField, 
  Typography, 
  Box, 
  Link, 
  InputAdornment, 
  Alert,
  IconButton
} from '@mui/material';
import {
  Instagram as InstagramIcon,
  Twitter as TwitterIcon,
  Facebook as FacebookIcon,
  MusicNote as MusicNoteIcon,
  Image as ImageIcon
} from '@mui/icons-material';
import { BandRecommendationFormData } from '../../types/BandRecommendations';

interface AdditionalMediaProps {
  formData: BandRecommendationFormData;
  setFormData: React.Dispatch<React.SetStateAction<BandRecommendationFormData>>;
}

interface SocialMediaPreview {
  instagram: string;
  twitter: string;
  facebook: string;
  tiktok: string;
}

const AdditionalMedia: React.FC<AdditionalMediaProps> = ({ formData, setFormData }) => {
  const [previews, setPreviews] = useState<SocialMediaPreview>({
    instagram: '',
    twitter: '',
    facebook: '',
    tiktok: ''
  });

  // Helper to remove @ symbol and extra characters
  const cleanHandle = (handle: string) => handle.replace(/^@/, '').trim();

  const handleSocialMediaChange = (
    name: 'instagramHandle' | 'twitterHandle' | 'facebookPage' | 'tikTokHandle',
    value: string
  ) => {
    const cleanedValue = cleanHandle(value);
    setFormData(prev => ({ ...prev, [name]: cleanedValue }));

    // Update previews based on platform
    switch (name) {
      case 'instagramHandle':
        setPreviews(prev => ({
          ...prev,
          instagram: `https://instagram.com/${cleanedValue}`
        }));
        break;
      case 'twitterHandle':
        setPreviews(prev => ({
          ...prev,
          twitter: `https://twitter.com/${cleanedValue}`
        }));
        break;
      case 'facebookPage':
        setPreviews(prev => ({
          ...prev,
          facebook: value.includes('facebook.com') ? value : `https://facebook.com/${cleanedValue}`
        }));
        break;
      case 'tikTokHandle':
        setPreviews(prev => ({
          ...prev,
          tiktok: `https://tiktok.com/@${cleanedValue}`
        }));
        break;
    }
  };

  const handleAdditionalImagesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const images = value.split(',').map(url => url.trim())
      .filter(url => url.startsWith('http'));
    setFormData(prev => ({ ...prev, additionalImages: images }));
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>Additional Media</Typography>
        <Alert severity="info" sx={{ mb: 2 }}>
          Add your band's social media profiles and additional images. For social media handles, you can include or exclude the @ symbol.
        </Alert>
      </Grid>

      <Grid item xs={12}>
        <TextField
          name="additionalImages"
          label="Additional Image URLs"
          value={formData.additionalImages?.join(', ') ?? ''}
          onChange={handleAdditionalImagesChange}
          variant="outlined"
          fullWidth
          multiline
          rows={2}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ImageIcon color="action" />
              </InputAdornment>
            ),
          }}
          helperText="Enter full image URLs (http:// or https://), separated by commas"
          error={formData.additionalImages?.some(url => !url.startsWith('http'))}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          name="instagramHandle"
          label="Instagram Handle"
          value={formData.instagramHandle}
          onChange={(e) => handleSocialMediaChange('instagramHandle', e.target.value)}
          variant="outlined"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <InstagramIcon color="action" />
              </InputAdornment>
            ),
            placeholder: '@yourband'
          }}
          helperText={
            formData.instagramHandle && 
            <Link href={previews.instagram} target="_blank" rel="noopener noreferrer">
              Preview Profile
            </Link>
          }
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          name="twitterHandle"
          label="Twitter Handle"
          value={formData.twitterHandle}
          onChange={(e) => handleSocialMediaChange('twitterHandle', e.target.value)}
          variant="outlined"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <TwitterIcon color="action" />
              </InputAdornment>
            ),
            placeholder: '@yourband'
          }}
          helperText={
            formData.twitterHandle && 
            <Link href={previews.twitter} target="_blank" rel="noopener noreferrer">
              Preview Profile
            </Link>
          }
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          name="facebookPage"
          label="Facebook Page URL or Username"
          value={formData.facebookPage}
          onChange={(e) => handleSocialMediaChange('facebookPage', e.target.value)}
          variant="outlined"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FacebookIcon color="action" />
              </InputAdornment>
            ),
            placeholder: 'facebook.com/yourband'
          }}
          helperText={
            formData.facebookPage && 
            <Link href={previews.facebook} target="_blank" rel="noopener noreferrer">
              Preview Page
            </Link>
          }
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          name="tikTokHandle"
          label="TikTok Handle"
          value={formData.tikTokHandle}
          onChange={(e) => handleSocialMediaChange('tikTokHandle', e.target.value)}
          variant="outlined"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MusicNoteIcon color="action" />
              </InputAdornment>
            ),
            placeholder: '@yourband'
          }}
          helperText={
            formData.tikTokHandle && 
            <Link href={previews.tiktok} target="_blank" rel="noopener noreferrer">
              Preview Profile
            </Link>
          }
        />
      </Grid>
    </Grid>
  );
};

export default AdditionalMedia;