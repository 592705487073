// FormBuilder.tsx

import React, { useState } from 'react';
import { 
  DndContext, 
  DragEndEvent,
  DragStartEvent,
  useSensor,
  useSensors,
  PointerSensor,
  DragOverlay 
} from '@dnd-kit/core';
import FieldPalette from '../components/form/formbuilder/FieldPalette';
import FormCanvas from '../components/form/formbuilder/FormCanvas';
import FormPreviewDialog from '../components/form/shared/FormPreviewDialog';
import { FormField, FieldType, Strand, BaseField } from '../types/FormSchema';
import { useQuery } from 'react-query';
import axiosInstance from '../utils/axiosConfig'; // Ensure this path is correct
import { 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  Typography, 
  SelectChangeEvent 
} from '@mui/material';

// Helper function to extract error messages
const getErrorMessage = (error: unknown): string => {
  if (error instanceof Error) return error.message;
  if (typeof error === 'string') return error;
  return 'An unknown error occurred while loading strands.';
};

const FormBuilder: React.FC = () => {
  const [fields, setFields] = useState<FormField[]>([]);
  const [activeId, setActiveId] = useState<string | null>(null);
  const [strandId, setStrandId] = useState<string>('');

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  // Fetch strands from the database
  const fetchStrands = async (): Promise<Strand[]> => {
    const response = await axiosInstance.get('/api/strands');
    return response.data;
  };

  const { data: strands, isLoading: isStrandsLoading, error: strandsError } = useQuery<Strand[], Error>(
    'strands',
    fetchStrands
  );

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id as string);
    console.log('Drag started:', event.active);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    setActiveId(null);

    console.log('Drag ended:', {
      activeId: active.id,
      activeData: active.data.current,
      overId: over?.id
    });

    if (!over || over.id !== 'canvas') return;

    const dragData = active.data.current;
    if (!dragData) return;

    if (dragData.type === 'field' && dragData.fieldType) {
      addField(dragData.fieldType);
    } else if (dragData.type === 'block' && dragData.block?.fields) {
      const newFields = dragData.block.fields.map((field: FormField) => ({
        ...field,
        id: `${field.id}-${Date.now()}`,
        strandSpecific: true,
        strands: [strandId],
      }));
      setFields(prev => [...prev, ...newFields]);
    }
  };

  const addField = (fieldType: string) => {
    if (!strandId) {
      alert('Please select a Strand before adding fields.');
      return;
    }

    // Exclude 'type' from baseField
    const baseField: Omit<BaseField, 'type'> = {
      id: `field-${Date.now()}`,
      name: '',
      label: `New ${fieldType.charAt(0).toUpperCase() + fieldType.slice(1)} Field`,
      placeholder: '',
      helperText: '',
      validation: {
        required: false
      },
      strandSpecific: true,
      strands: [strandId],
      grid: { xs: 12 }, // Default to full width
    };

    let newField: FormField;

    switch (fieldType.toLowerCase()) {
      case 'upload':
        newField = {
          ...baseField,
          type: FieldType.Upload,
          accept: '*/*',
          multiple: false
        };
        break;

      case 'select':
        newField = {
          ...baseField,
          type: FieldType.Select,
          options: []
        };
        break;

      case 'checkbox':
        newField = {
          ...baseField,
          type: FieldType.Checkbox,
          defaultValue: false
        };
        break;

      case 'text':
        newField = {
          ...baseField,
          type: FieldType.Text,
          defaultValue: ''
        };
        break;

      case 'textarea':
        newField = {
          ...baseField,
          type: FieldType.TextArea,
          defaultValue: ''
        };
        break;

      case 'email':
        newField = {
          ...baseField,
          type: FieldType.Email,
          defaultValue: ''
        };
        break;

      case 'url':
        newField = {
          ...baseField,
          type: FieldType.Url,
          defaultValue: ''
        };
        break;

      default:
        throw new Error(`Unhandled field type: ${fieldType}`);
    }

    console.log('Adding new field:', newField);
    setFields(prev => [...prev, newField]);
  };

  const updateField = (updatedField: FormField) => {
    setFields(prev =>
      prev.map(field => (field.id === updatedField.id ? updatedField : field))
    );
  };

  const removeField = (fieldId: string) => {
    setFields(prev => prev.filter(field => field.id !== fieldId));
  };

  console.log('Current fields:', fields);

  const handleStrandChange = (event: SelectChangeEvent<string>) => {
    setStrandId(event.target.value as string);
  };

  return (
    <DndContext 
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <div style={{ 
        display: 'flex', 
        gap: '24px',
        padding: '20px',
        minHeight: 'calc(100vh - 100px)',
        backgroundColor: 'transparent',
      }}>
        <div style={{ width: '300px' }}>
          {/* Strand Selection Dropdown */}
          <FormControl fullWidth margin="normal" required>
            <InputLabel id="strand-select-label" style={{ color: '#fff' }}>Select Strand</InputLabel>
            <Select
              labelId="strand-select-label"
              value={strandId}
              onChange={handleStrandChange}
              label="Select Strand"
              sx={{
                color: '#fff',
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(255, 255, 255, 0.2)',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#fff',
                },
                '.MuiSvgIcon-root': {
                  color: '#fff',
                },
              }}
            >
              {isStrandsLoading && <MenuItem value=""><em>Loading...</em></MenuItem>}
              {strands && strands.map((strand: Strand) => (
                <MenuItem key={strand._id} value={strand._id}>
                  {strand.name}
                </MenuItem>
              ))}
              {strands && strands.length === 0 && (
                <MenuItem value=""><em>No Strands Available</em></MenuItem>
              )}
              {strandsError && (
                <MenuItem value="">
                  <em>{getErrorMessage(strandsError)}</em>
                </MenuItem>
              )}
            </Select>
          </FormControl>

          <FieldPalette />
        </div>
        <div style={{ flex: 1 }}>
          <div style={{ 
            display: 'flex', 
            justifyContent: 'flex-end', 
            marginBottom: '16px' 
          }}>
            <FormPreviewDialog fields={fields} />
          </div>
          <FormCanvas 
            fields={fields}
            onUpdateField={updateField}
            onRemoveField={removeField}
          />
        </div>
      </div>

      <DragOverlay>
        {activeId && (
          <div style={{
            padding: '12px 16px',
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            border: '1px solid rgba(255, 255, 255, 0.2)',
            borderRadius: '6px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
            cursor: 'grabbing',
            color: '#fff'
          }}>
            {activeId.replace('field-', '').replace('block-', '').replace(/-/g, ' ')}
          </div>
        )}
      </DragOverlay>
    </DndContext>
  );
};

export default FormBuilder;