import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Paper,
  Container,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  SelectChangeEvent,
  Checkbox,
  FormGroup,
  FormControlLabel,
  IconButton,
  Popover,
} from '@mui/material';
import { DateTimePicker, TimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CalculateIcon from '@mui/icons-material/Calculate';
import axios from 'axios';
import { Venue } from '../types/mongodb';
import PromoCalc from './PromoCalc';
import { EventType } from '../types/EventTypes';

interface EventFormData {
  eventId?: string;
  name: string;
  eventType: EventType;
  description: string;
  venue?: string;
  isOnline: boolean;
  zoomLink?: string;
  date?: Date | null;
  ticketPrice?: number;
  startDate?: Date | null;
  endDate?: Date | null;
  recurringDays?: string[];
  status: 'planned' | 'confirmed' | 'cancelled';
  focus: 'Audience' | 'Artist' | 'Industry' | 'Other';
  isSeries: boolean;
  seriesStartTime?: Date | null;
  seriesEndTime?: Date | null;
  seriesFrequency?: 'weekly' | 'biweekly' | 'monthly';
  isFreeEvent: boolean;
}

interface EventFormProps {
  eventId?: string;
}

const EventForm: React.FC<EventFormProps> = ({ eventId }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<EventFormData>({
    name: '',
    eventType: EventType.Single,
    description: '',
    isOnline: false,
    status: 'planned',
    focus: 'Audience',
    isSeries: false,
    isFreeEvent: false,
    ticketPrice: 0,
  });
  const [venues, setVenues] = useState<Venue[]>([]);
  const [calculatorAnchorEl, setCalculatorAnchorEl] = useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    const fetchVenues = async () => {
      try {
        const response = await axios.get<Venue[]>(`${process.env.REACT_APP_API_URL}/api/venues`);
        setVenues(response.data);
      } catch (error) {
        console.error('Error fetching venues:', error);
      }
    };
    fetchVenues();

    if (eventId) {
      const fetchEvent = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/events/${eventId}`);
          const eventData = response.data;
          setFormData({
            ...eventData,
            eventType: eventData.eventType as EventType,
            isOnline: eventData.isOnline || false,
            date: eventData.date ? new Date(eventData.date) : null,
            startDate: eventData.startDate ? new Date(eventData.startDate) : null,
            endDate: eventData.endDate ? new Date(eventData.endDate) : null,
            seriesStartTime: eventData.seriesStartTime ? new Date(eventData.seriesStartTime) : null,
            seriesEndTime: eventData.seriesEndTime ? new Date(eventData.seriesEndTime) : null,
            isFreeEvent: eventData.isFreeEvent || false,
          });
        } catch (error) {
          console.error('Error fetching event:', error);
        }
      };
      fetchEvent();
    }
  }, [eventId]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>
  ) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: checked,
      venue: name === 'isOnline' && checked ? undefined : prevState.venue,
      ticketPrice: name === 'isFreeEvent' && checked ? 0 : prevState.ticketPrice,
    }));
  };

  const handleDateChange = (field: keyof EventFormData) => (newValue: Date | null) => {
    setFormData(prevState => ({
      ...prevState,
      [field]: newValue
    }));
  };

  const handleRecurringDaysChange = (day: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRecurringDays = event.target.checked
      ? [...(formData.recurringDays || []), day]
      : (formData.recurringDays || []).filter(d => d !== day);
    setFormData(prevState => ({
      ...prevState,
      recurringDays: newRecurringDays
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const dataToSubmit = {
        ...formData,
        date: formData.date?.toISOString(),
        startDate: formData.startDate?.toISOString(),
        endDate: formData.endDate?.toISOString(),
        seriesStartTime: formData.seriesStartTime?.toISOString(),
        seriesEndTime: formData.seriesEndTime?.toISOString(),
      };

      if (eventId) {
        await axios.put(`${process.env.REACT_APP_API_URL}/api/events/${eventId}`, dataToSubmit);
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/events`, dataToSubmit);
      }
      navigate('/events');
    } catch (error) {
      console.error('Error saving event:', error);
    }
  };

  const handleCalculatorOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setCalculatorAnchorEl(event.currentTarget);
  };

  const handleCalculatorClose = () => {
    setCalculatorAnchorEl(null);
  };

  const handleCalculatorSubmit = (value: number) => {
    setFormData(prevState => ({
      ...prevState,
      ticketPrice: value
    }));
    handleCalculatorClose();
  };

  const calculatorOpen = Boolean(calculatorAnchorEl);
  const calculatorId = calculatorOpen ? 'calculator-popover' : undefined;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container maxWidth="md">
        <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
          <Typography variant="h4" gutterBottom>
            {eventId ? 'Edit Event' : 'Create New Event'}
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Event Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Event Type</InputLabel>
                  <Select
                    name="eventType"
                    value={formData.eventType}
                    onChange={handleChange}
                    required
                  >
                    {Object.values(EventType).map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.isOnline}
                      onChange={handleCheckboxChange}
                      name="isOnline"
                      color="primary"
                    />
                  }
                  label="Online Event"
                />
              </Grid>
              {!formData.isOnline && (
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Venue</InputLabel>
                    <Select
                      name="venue"
                      value={formData.venue || ''}
                      onChange={handleChange}
                      required={!formData.isOnline}
                    >
                      {venues.map((venue) => (
                        <MenuItem key={venue._id} value={venue._id}>
                          {venue.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {formData.isOnline && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Zoom Link"
                    name="zoomLink"
                    value={formData.zoomLink || ''}
                    onChange={handleChange}
                    required={formData.isOnline}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.isSeries}
                      onChange={handleCheckboxChange}
                      name="isSeries"
                      color="primary"
                    />
                  }
                  label="Is this a series event?"
                />
              </Grid>
              {formData.isSeries ? (
                <>
                  <Grid item xs={12} sm={6}>
                    <DateTimePicker
                      label="Series Start Date"
                      value={formData.startDate}
                      onChange={handleDateChange('startDate')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DateTimePicker
                      label="Series End Date"
                      value={formData.endDate}
                      onChange={handleDateChange('endDate')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TimePicker
                      label="Series Start Time"
                      value={formData.seriesStartTime}
                      onChange={handleDateChange('seriesStartTime')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TimePicker
                      label="Series End Time"
                      value={formData.seriesEndTime}
                      onChange={handleDateChange('seriesEndTime')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Series Frequency</InputLabel>
                      <Select
                        name="seriesFrequency"
                        value={formData.seriesFrequency || ''}
                        onChange={handleChange}
                      >
                        <MenuItem value="weekly">Weekly</MenuItem>
                        <MenuItem value="biweekly">Bi-weekly</MenuItem>
                        <MenuItem value="monthly">Monthly</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup row>
                      {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
                        <FormControlLabel
                          key={day}
                          control={
                            <Checkbox
                              checked={formData.recurringDays?.includes(day) || false}
                              onChange={handleRecurringDaysChange(day)}
                              name={day}
                            />
                          }
                          label={day}
                        />
                      ))}
                    </FormGroup>
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  <DateTimePicker
                    label="Event Date and Time"
                    value={formData.date}
                    onChange={handleDateChange('date')}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.isFreeEvent}
                      onChange={handleCheckboxChange}
                      name="isFreeEvent"
                      color="primary"
                    />
                  }
                  label="Free Event"
                />
              </Grid>
              {!formData.isFreeEvent && (
                <Grid item xs={12} container alignItems="center" spacing={1}>
                  <Grid item xs>
                    <TextField
                      fullWidth
                      label="Ticket Price"
                      name="ticketPrice"
                      type="number"
                      value={formData.ticketPrice}
                      onChange={handleChange}
                      InputProps={{
                        startAdornment: <Typography>€</Typography>,
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <IconButton onClick={handleCalculatorOpen} aria-describedby={calculatorId}>
                      <CalculateIcon />
                    </IconButton>
                    <Popover
                      id={calculatorId}
                      open={calculatorOpen}
                      anchorEl={calculatorAnchorEl}
                      onClose={handleCalculatorClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <PromoCalc
                        initialValue={formData.ticketPrice || 0}
                        onSubmit={handleCalculatorSubmit}
                        onClose={handleCalculatorClose}
                      />
                    </Popover>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Summary Description"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Status</InputLabel>
                  <Select
                    name="status"
                    value={formData.status}
                    onChange={handleChange}
                  >
                    <MenuItem value="planned">Planned</MenuItem>
                    <MenuItem value="confirmed">Confirmed</MenuItem>
                    <MenuItem value="cancelled">Cancelled</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Focus</InputLabel>
                  <Select
                    name="focus"
                    value={formData.focus}
                    onChange={handleChange}
                  >
                    <MenuItem value="Audience">Audience</MenuItem>
                    <MenuItem value="Artist">Artist</MenuItem>
                    <MenuItem value="Industry">Industry</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary">
                  {eventId ? 'Update Event' : 'Create Event'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    </LocalizationProvider>
  );
};

export default EventForm;