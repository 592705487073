import React, { useState } from 'react';
import { 
  Grid, 
  TextField, 
  Typography, 
  Tooltip, 
  Button,
  CircularProgress,
  Box,
  Alert,
  IconButton
} from '@mui/material';
import { BandRecommendationFormData } from '../../types/BandRecommendations';
import { Upload, X, FileText, Image } from 'lucide-react';
import axios from 'axios';

interface TechnicalRequirementsProps {
  formData: BandRecommendationFormData;
  setFormData: React.Dispatch<React.SetStateAction<BandRecommendationFormData>>;
}

interface FileUploadState {
  stagePlot: { uploading: boolean; error: string | null };
  techSpecs: { uploading: boolean; error: string | null };
  lightingSpec: { uploading: boolean; error: string | null };
  stageImage: { uploading: boolean; error: string | null };
}

const TechnicalRequirements: React.FC<TechnicalRequirementsProps> = ({ formData, setFormData }) => {
  const [uploadState, setUploadState] = useState<FileUploadState>({
    stagePlot: { uploading: false, error: null },
    techSpecs: { uploading: false, error: null },
    lightingSpec: { uploading: false, error: null },
    stageImage: { uploading: false, error: null }
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      technicalRider: {
        ...(prevData.technicalRider || {}),
        [name]: value,
      },
    }));
  };

  const handleAdditionalEquipmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const equipment = value.split(',').map(item => item.trim());
    setFormData(prevData => ({
      ...prevData,
      technicalRider: {
        ...prevData.technicalRider,
        additionalEquipment: equipment
      }
    }));
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    fileType: 'stagePlot' | 'techSpecs' | 'lightingSpec' | 'stageImage'
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    // Update uploading state
    setUploadState(prev => ({
      ...prev,
      [fileType]: { ...prev[fileType], uploading: true, error: null }
    }));

    // Validate file size (50MB limit)
    if (file.size > 50 * 1024 * 1024) {
      setUploadState(prev => ({
        ...prev,
        [fileType]: { uploading: false, error: 'File size exceeds 50MB limit' }
      }));
      return;
    }

    // Validate file type
    const validTypes = fileType === 'stageImage' 
      ? ['image/jpeg', 'image/png', 'image/gif']
      : ['application/pdf'];
    
    if (!validTypes.includes(file.type)) {
      setUploadState(prev => ({
        ...prev,
        [fileType]: { 
          uploading: false, 
          error: fileType === 'stageImage' 
            ? 'Please upload a JPEG, PNG, or GIF file'
            : 'Please upload a PDF file'
        }
      }));
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileType', fileType);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/upload/technical`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            ...(token && { Authorization: `Bearer ${token}` }),
          },
          withCredentials: true,
        }
      );

      setFormData(prevData => ({
        ...prevData,
        technicalRider: {
          ...prevData.technicalRider,
          [fileType]: response.data.fileUrl,
        },
      }));

      setUploadState(prev => ({
        ...prev,
        [fileType]: { uploading: false, error: null }
      }));
    } catch (error: any) {
      console.error(`Error uploading ${fileType}:`, error);
      setUploadState(prev => ({
        ...prev,
        [fileType]: { 
          uploading: false, 
          error: error.response?.data?.message || 'Upload failed. Please try again.'
        }
      }));
    }
  };

  const handleRemoveFile = (fileType: 'stagePlot' | 'techSpecs' | 'lightingSpec' | 'stageImage') => {
    setFormData(prevData => ({
      ...prevData,
      technicalRider: {
        ...prevData.technicalRider,
        [fileType]: '',
      },
    }));
  };

  const renderFileUploadSection = (
    fileType: 'stagePlot' | 'techSpecs' | 'lightingSpec' | 'stageImage',
    label: string,
    tooltip: string,
    required: boolean = false
  ) => {
    const { uploading, error } = uploadState[fileType];
    const currentFile = formData.technicalRider?.[fileType];

    return (
      <Grid item xs={12} sm={6}>
        <Tooltip title={tooltip}>
          <Box>
            <Typography variant="subtitle2" gutterBottom>
              {label} {required && '*'}
            </Typography>
            {currentFile ? (
              <Box display="flex" alignItems="center" gap={1}>
                {fileType === 'stageImage' ? (
                  <Image size={24} />
                ) : (
                  <FileText size={24} />
                )}
                <Typography variant="body2" sx={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {currentFile.split('/').pop()}
                </Typography>
                <IconButton onClick={() => handleRemoveFile(fileType)} size="small">
                  <X size={16} />
                </IconButton>
              </Box>
            ) : (
              <Button
                variant="outlined"
                component="label"
                disabled={uploading}
                startIcon={uploading ? <CircularProgress size={20} /> : <Upload />}
                fullWidth
              >
                {uploading ? 'Uploading...' : 'Upload File'}
                <input
                  type="file"
                  hidden
                  accept={fileType === 'stageImage' ? "image/*" : ".pdf"}
                  onChange={(e) => handleFileUpload(e, fileType)}
                />
              </Button>
            )}
            {error && (
              <Alert severity="error" sx={{ mt: 1 }}>
                {error}
              </Alert>
            )}
          </Box>
        </Tooltip>
      </Grid>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>Technical Requirements</Typography>
      </Grid>

      {/* File upload sections */}
      {renderFileUploadSection(
        'stagePlot',
        'Stage Plot',
        'Upload a PDF showing the stage layout and equipment placement.',
        false
      )}
      {renderFileUploadSection(
        'techSpecs',
        'Technical Specifications',
        'Upload a PDF with detailed technical specifications.',
        false
      )}
      {renderFileUploadSection(
        'lightingSpec',
        'Lighting Specification',
        'Upload a PDF with lighting requirements (if needed).'
      )}
      {renderFileUploadSection(
        'stageImage',
        'Stage Image',
        'Upload an image of the band on stage.',
        false
      )}

      {/* Existing text fields */}
      <Grid item xs={12} sm={6}>
        <Tooltip title="Required stage size for the performance.">
          <TextField
            name="stageSize"
            label="Stage Size"
            value={formData.technicalRider?.stageSize ?? ''}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
        </Tooltip>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Tooltip title="Audio requirements for the performance.">
          <TextField
            name="audioRequirements"
            label="Audio Requirements"
            value={formData.technicalRider?.audioRequirements ?? ''}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
        </Tooltip>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Tooltip title="Lighting requirements for the performance.">
          <TextField
            name="lightingRequirements"
            label="Lighting Requirements"
            value={formData.technicalRider?.lightingRequirements ?? ''}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
        </Tooltip>
      </Grid>
      <Grid item xs={12}>
        <Tooltip title="Additional equipment needed (comma-separated).">
          <TextField
            name="additionalEquipment"
            label="Additional Equipment (Comma-separated)"
            value={formData.technicalRider?.additionalEquipment?.join(', ') ?? ''}
            onChange={handleAdditionalEquipmentChange}
            variant="outlined"
            fullWidth
            multiline
            rows={3}
          />
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default TechnicalRequirements;