// src/pages/AuthCallback.tsx
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const AuthCallback: React.FC = () => {
  const navigate = useNavigate();
  const { setToken } = useAuth();

  useEffect(() => {
    // Extract token from URL
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');

    if (token) {
      console.log('AuthCallback: Token received:', token);
      setToken(token); // Store token and fetch user
      navigate('/dashboard'); // Redirect to a protected route
    } else {
      console.error('AuthCallback: No token found in URL');
      navigate('/login'); // Redirect back to login on failure
    }
  }, [navigate, setToken]);

  return (
    <div>
      <p>Authenticating...</p>
    </div>
  );
};

export default AuthCallback;