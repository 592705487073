import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  Container, 
  Typography, 
  Grid, 
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  SelectChangeEvent,
} from '@mui/material';
import axios from 'axios';
import { Event, EventType } from '../types/mongodb'; // Adjust import path as needed
import EventCard from '../components/EventCard'; // Assuming you've created this component

const EventList: React.FC = () => {
  const [events, setEvents] = useState<Event[]>([]);
  const [filteredEvents, setFilteredEvents] = useState<Event[]>([]);
  const [venues, setVenues] = useState<string[]>([]);
  const [months, setMonths] = useState<string[]>([]);
  const [focuses, setFocuses] = useState<string[]>([]);
  const [venueFilter, setVenueFilter] = useState<string>('');
  const [typeFilter, setTypeFilter] = useState<string>('');
  const [monthFilter, setMonthFilter] = useState<string>('');
  const [focusFilter, setFocusFilter] = useState<string>('');

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get<Event[]>(`${process.env.REACT_APP_API_URL}/api/events`);
        setEvents(response.data);
        setFilteredEvents(response.data);
        
        // Extract unique venues, months, and focuses
        const uniqueVenues = Array.from(new Set(response.data.map(event => 
          event.isOnline ? 'Online' : (event.venue && typeof event.venue !== 'string' ? event.venue.name : 'Not specified')
        )));
        setVenues(uniqueVenues as string[]);
        
        const uniqueMonths = Array.from(new Set(response.data.map(event => {
          const date = event.date || event.startDate;
          return date ? new Date(date).toLocaleString('default', { month: 'long' }) : null;
        }).filter(Boolean)));
        setMonths(uniqueMonths as string[]);

        const uniqueFocuses = Array.from(new Set(response.data.map(event => event.focus).filter(Boolean)));
        setFocuses(uniqueFocuses as string[]);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };
    fetchEvents();
  }, []);

  useEffect(() => {
    const applyFilters = () => {
      let filtered = events;
      
      if (venueFilter) {
        filtered = filtered.filter(event => 
          event.isOnline ? venueFilter === 'Online' : 
          (event.venue && typeof event.venue !== 'string' ? event.venue.name === venueFilter : false)
        );
      }
      
      if (typeFilter) {
        filtered = filtered.filter(event => event.eventType === typeFilter);
      }
      
      if (monthFilter) {
        filtered = filtered.filter(event => {
          const date = event.date || event.startDate;
          return date ? new Date(date).toLocaleString('default', { month: 'long' }) === monthFilter : false;
        });
      }

      if (focusFilter) {
        filtered = filtered.filter(event => event.focus === focusFilter);
      }
      
      setFilteredEvents(filtered);
    };
    
    applyFilters();
  }, [events, venueFilter, typeFilter, monthFilter, focusFilter]);

  const handleFilterChange = (
    filterSetter: React.Dispatch<React.SetStateAction<string>>
  ) => (event: SelectChangeEvent<string>) => {
    filterSetter(event.target.value);
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Events
      </Typography>
      <Button component={Link} to="/events/new" variant="contained" color="primary" sx={{ mb: 3 }}>
        Create New Event
      </Button>
      
      <Box sx={{ mb: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <InputLabel>Filter by Venue</InputLabel>
              <Select
                value={venueFilter}
                onChange={handleFilterChange(setVenueFilter)}
              >
                <MenuItem value="">All Venues</MenuItem>
                {venues.map((venue) => (
                  <MenuItem key={venue} value={venue}>{venue}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <InputLabel>Filter by Type</InputLabel>
              <Select
                value={typeFilter}
                onChange={handleFilterChange(setTypeFilter)}
              >
                <MenuItem value="">All Types</MenuItem>
                {Object.values(EventType).map((type) => (
                  <MenuItem key={type} value={type}>{type}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <InputLabel>Filter by Month</InputLabel>
              <Select
                value={monthFilter}
                onChange={handleFilterChange(setMonthFilter)}
              >
                <MenuItem value="">All Months</MenuItem>
                {months.map((month) => (
                  <MenuItem key={month} value={month}>{month}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <InputLabel>Filter by Focus</InputLabel>
              <Select
                value={focusFilter}
                onChange={handleFilterChange(setFocusFilter)}
              >
                <MenuItem value="">All Focuses</MenuItem>
                {focuses.map((focus) => (
                  <MenuItem key={focus} value={focus}>{focus}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={3}>
        {filteredEvents.map((event) => (
          <Grid item xs={12} sm={6} md={4} key={event._id}>
            <EventCard event={event} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default EventList;