import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import FormViewer from '../formviewer/FormViewer';
import { FormField, FormSchema } from '../../../types/FormSchema';

interface FormPreviewDialogProps {
  fields: FormField[];
}

const FormPreviewDialog: React.FC<FormPreviewDialogProps> = ({ fields }) => {
  const [open, setOpen] = useState(false);
  const [previewValues, setPreviewValues] = useState<Record<string, any>>({});

  const previewSchema: FormSchema = {
    formId: `preview-${Date.now()}`,
    title: 'Form Preview',
    description: 'This is a preview of how your form will appear to users.',
    strand: 'preview',
    fields: fields
  };

  const handleFieldChange = (fieldId: string, value: any) => {
    setPreviewValues(prev => ({
      ...prev,
      [fieldId]: value
    }));
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<VisibilityIcon />}
        onClick={() => setOpen(true)}
      >
        Preview Form
      </Button>
      
      <Dialog 
        open={open} 
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          Form Preview
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <FormViewer
            schema={previewSchema}
            values={previewValues}
            onChange={handleFieldChange}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FormPreviewDialog;