import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Typography,
  Button,
  CircularProgress,
  Chip
} from '@mui/material';
import axios from 'axios';

interface NavigatorApplication {
  _id: string;
  name: string;
  email: string;
  instrument: string[];
  status: string;
  createdAt: string;
  locationDates: string[];
}

type Order = 'asc' | 'desc';

const dateRanges = [
  'Ballina Arts Centre - Add Dates Here',
  'The Source Arts Centre, Thurles - Add Dates Here',
  'An Grianán Theatre, Letterkenny - Add Dates Here',
  'Courthouse Arts, Tinahely - Add Dates Here',
  'Garter Lane, Waterford - Add Dates Here',
  'Crescent Arts Centre, Belfast - Add Dates Here',
  'Riverbank Arts Centre, Kildare. - Add Dates Here',
  'I would like to be consider for all locations - Add Dates Here',
];

const NavigatorApplicationList: React.FC = () => {
  const [applications, setApplications] = useState<NavigatorApplication[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof NavigatorApplication>('name');

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const response = await axios.get<NavigatorApplication[]>(`${process.env.REACT_APP_API_URL}/api/navigator`);
        setApplications(response.data);
      } catch (err: any) {
        setError(err.message || 'An error occurred while fetching applications.');
      } finally {
        setLoading(false);
      }
    };

    fetchApplications();
  }, []);

  const handleRequestSort = (property: keyof NavigatorApplication) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortApplications = (a: NavigatorApplication, b: NavigatorApplication) => {
    if (orderBy === 'locationDates') {
      const aValue = a.locationDates.length;
      const bValue = b.locationDates.length;
      return (aValue < bValue ? -1 : 1) * (order === 'asc' ? 1 : -1);
    } else {
      const aValue = a[orderBy];
      const bValue = b[orderBy];
      return (aValue < bValue ? -1 : 1) * (order === 'asc' ? 1 : -1);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <div>
      <Typography variant="h4" gutterBottom>Navigator Applications</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={orderBy === 'name' ? order : 'asc'}
                  onClick={() => handleRequestSort('name')}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Instrument(s)</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'status'}
                  direction={orderBy === 'status' ? order : 'asc'}
                  onClick={() => handleRequestSort('status')}
                >
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'createdAt'}
                  direction={orderBy === 'createdAt' ? order : 'asc'}
                  onClick={() => handleRequestSort('createdAt')}
                >
                  Submitted On
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'locationDates'}
                  direction={orderBy === 'locationDates' ? order : 'asc'}
                  onClick={() => handleRequestSort('locationDates')}
                >
                  Date Ranges
                </TableSortLabel>
              </TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {applications.sort(sortApplications).map((application) => (
              <TableRow key={application._id}>
                <TableCell>{application.name}</TableCell>
                <TableCell>{application.email}</TableCell>
                <TableCell>{application.instrument.join(', ')}</TableCell>
                <TableCell>{application.status}</TableCell>
                <TableCell>{new Date(application.createdAt).toLocaleDateString()}</TableCell>
                <TableCell>
                  {application.locationDates.includes('I would like to be consider for all locations - Add Dates Here') ? (
                    <Chip label="All Locations" color="primary" />
                  ) : (
                    application.locationDates.map((range, index) => (
                      <Chip key={index} label={range.split(' - ')[0]} style={{ margin: '2px' }} />
                    ))
                  )}
                </TableCell>
                <TableCell>
                  <Button
                    component={Link}
                    to={`/navigator-applications/${application._id}`}
                    variant="contained"
                    color="primary"
                  >
                    View Details
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default NavigatorApplicationList;