import React, { useState, useEffect } from 'react';
import { Checkbox, FormControlLabel, CircularProgress, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { green } from '@mui/material/colors';
import axios from 'axios';

interface PrePopulateCheckboxProps {
  onPrePopulate: (profileData: any) => void;
}

const GreenCheckbox = styled(Checkbox)(({ theme }) => ({
  color: green[300],
  '&.Mui-checked': {
    color: green[500],
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    marginLeft: 0,
    marginRight: theme.spacing(2),
    padding: theme.spacing(1.5), // Increased padding
    marginTop: theme.spacing(2), // Added top margin
    marginBottom: theme.spacing(5), // Added bottom margin
    borderRadius: theme.shape.borderRadius,
    backgroundColor: green[50],
    '&:hover': {
      backgroundColor: green[100],
    },
  }));

const PrePopulateCheckbox: React.FC<PrePopulateCheckboxProps> = ({ onPrePopulate }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [hasArtistProfile, setHasArtistProfile] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    checkLoginStatus();
  }, []);

  const checkLoginStatus = async () => {
    try {
      const token = localStorage.getItem('token'); // Assuming you store the token in localStorage
      if (!token) {
        setIsLoggedIn(false);
        setIsLoading(false);
        return;
      }

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/me`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      setIsLoggedIn(true);
      setHasArtistProfile(response.data.isArtist);
    } catch (error) {
      console.error('Error checking login status:', error);
      setIsLoggedIn(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePrePopulate = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/me`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      onPrePopulate(response.data);
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  if (isLoading) {
    return <CircularProgress size={24} />;
  }

  if (!isLoggedIn || !hasArtistProfile) {
    return null;
  }

  return (
    <StyledFormControlLabel
      control={
        <GreenCheckbox 
          onChange={handlePrePopulate}
          icon={<AutoFixHighIcon />}
          checkedIcon={<AutoFixHighIcon />}
        />
      }
      label={
        <Box display="flex" alignItems="center">
          <Typography variant="body2" fontWeight="bold" color="primary">
            Pre-populate with my artist profile
          </Typography>
        </Box>
      }
    />
  );
};

export default PrePopulateCheckbox;