import React from 'react';
import { Card, CardContent, Typography, TextField, Grid } from '@mui/material';
import { Performance, Event, Venue } from '../../types/mongodb';

interface PerformanceRevenueProps {
  performance: Performance;
  onUpdate: (updatedPerformance: Performance) => void;
}

const PerformanceRevenue: React.FC<PerformanceRevenueProps> = ({ performance, onUpdate }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    onUpdate({
      ...performance,
      revenue: {
        ...performance.revenue,
        [name]: parseFloat(value) || 0,
      },
    });
  };

  // Modified to handle both event and strand cases
  const getVenueCapacity = (): number => {
    if (performance.event) {
      const event = performance.event as Event;
      if (typeof event === 'string') return 0;
      
      const venue = event.venue;
      if (typeof venue === 'string') return 0;
      
      return (venue as Venue)?.capacity || 0;
    }
    
    // For strand-based performances or no event/venue
    return performance.revenue?.expectedCapacity || 0;
  };

  // Modified to handle both event and strand cases
  const updateVenueCapacity = (capacity: number) => {
    if (performance.event) {
      const event = performance.event as Event;
      if (typeof event === 'string') return;
      if (typeof event.venue === 'string') return;
      
      const updatedVenue: Venue = {
        ...(event.venue as Venue),
        capacity,
      };
      
      onUpdate({
        ...performance,
        event: {
          ...event,
          venue: updatedVenue,
        },
      });
    } else {
      // For strand-based performances, store capacity in revenue
      onUpdate({
        ...performance,
        revenue: {
          ...performance.revenue,
          expectedCapacity: capacity,
        },
      });
    }
  };

  const getCapacityLabel = () => {
    if (performance.event) {
      return "Venue Capacity";
    }
    return "Expected Capacity";
  };

  return (
    <Card sx={{ mb: 4 }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Performance Revenue
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Ticket Price (€)"
              type="number"
              fullWidth
              name="ticketPrice"
              value={performance.revenue?.ticketPrice || 0}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={getCapacityLabel()}
              type="number"
              fullWidth
              value={getVenueCapacity()}
              onChange={(e) => updateVenueCapacity(parseInt(e.target.value) || 0)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Expected Occupancy (%)"
              type="number"
              fullWidth
              name="expectedOccupancy"
              value={performance.revenue?.expectedOccupancy || 0}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Sponsorship (€)"
              type="number"
              fullWidth
              name="sponsorship"
              value={performance.revenue?.sponsorship || 0}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Merchandise Sales (€)"
              type="number"
              fullWidth
              name="merchandiseSales"
              value={performance.revenue?.merchandiseSales || 0}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Other Revenue (€)"
              type="number"
              fullWidth
              name="otherRevenue"
              value={performance.revenue?.otherRevenue || 0}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PerformanceRevenue;