import React, { useEffect, useState } from 'react';
import { 
  Typography, 
  List, 
  ListItem, 
  ListItemText, 
  ListItemSecondaryAction,
  Button,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Chip,
  SelectChangeEvent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  IconButton,
  Stack,
} from '@mui/material';
import { Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { BanBamApplication } from '../types/BanBam';
import { useAuth } from '../contexts/AuthContext';

const BanBamApplicationList: React.FC = () => {
  const [applications, setApplications] = useState<BanBamApplication[]>([]);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [applicationToDelete, setApplicationToDelete] = useState<string | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const { user, getToken } = useAuth(); // Add getToken from useAuth
  const isAdmin = user && user.role === 'admin';


  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/banbam`);
        setApplications(response.data);
      } catch (error) {
        console.error('Error fetching applications:', error);
      }
    };
    fetchApplications();
  }, []);

  const handleDeleteClick = (applicationId: string) => {
    setApplicationToDelete(applicationId);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!applicationToDelete) return;

    setIsDeleting(true);
    try {
      const token = await getToken();
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/banbam/${applicationToDelete}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      setApplications(applications.filter(app => app._id !== applicationToDelete));
    } catch (error) {
      console.error('Error deleting application:', error);
    } finally {
      setIsDeleting(false);
      setDeleteDialogOpen(false);
      setApplicationToDelete(null);
    }
  };


  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setApplicationToDelete(null);
  };

  const sortedApplications = [...applications].sort((a, b) => {
    const dateA = new Date(a.createdAt).getTime();
    const dateB = new Date(b.createdAt).getTime();
    return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
  });

  const handleSortChange = (event: SelectChangeEvent<'asc' | 'desc'>) => {
    setSortOrder(event.target.value as 'asc' | 'desc');
  };

  const handleStatusChange = async (applicationId: string, newStatus: 'pending' | 'approved' | 'rejected' | 'archived' | 'jury') => {
    try {
      await axios.patch(`${process.env.REACT_APP_API_URL}/api/banbam/${applicationId}`, { status: newStatus });
      setApplications(applications.map(app => 
        app._id === applicationId ? { ...app, status: newStatus } : app
      ));
    } catch (error) {
      console.error('Error updating application status:', error);
    }
  };

  const formatDate = (date: string) => {
    const d = new Date(date);
    return `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`;
  };

  const renderStatus = (application: BanBamApplication) => {
    if (isAdmin) {
      return (
        <FormControl size="small">
          <Select
            value={application.status}
            onChange={(e) => handleStatusChange(application._id, e.target.value as 'pending' | 'approved' | 'rejected' | 'archived' | 'jury')}
            sx={{ minWidth: 120 }}
          >
            <MenuItem value="pending">Pending</MenuItem>
            <MenuItem value="approved">Approved</MenuItem>
            <MenuItem value="rejected">Rejected</MenuItem>
            <MenuItem value="archived">Archived</MenuItem>
            <MenuItem value="jury">Jury</MenuItem>
            <MenuItem value="unsuitable">Unsuitable</MenuItem>
          </Select>
        </FormControl>
      );
    } else {
      return (
        <Chip 
          label={application.status} 
          color={application.status === 'pending' ? 'warning' : application.status === 'approved' ? 'success' : 'error'}
          size="small"
        />
      );
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 3, my: 2 }}>
      <Typography variant="h4" gutterBottom>
        BAN BAM Applications
      </Typography>
      <Box sx={{ mb: 2 }}>
        <FormControl variant="outlined" size="small">
          <InputLabel id="sort-order-label">Sort by Date</InputLabel>
          <Select
            labelId="sort-order-label"
            value={sortOrder}
            onChange={handleSortChange}
            label="Sort by Date"
          >
            <MenuItem value="desc">Newest First</MenuItem>
            <MenuItem value="asc">Oldest First</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <List>
        {sortedApplications.map((application) => (
          <ListItem key={application._id} alignItems="flex-start">
            <ListItemText
              primary={
                <Typography variant="subtitle1">
                  {application.firstName} {application.lastName}
                </Typography>
              }
              secondary={
                <>
                  <Typography component="span" variant="body2" color="text.primary">
                    Submitted: {formatDate(application.createdAt)}
                  </Typography>
                  <br />
                  <Typography component="span" variant="body2">
                    County: {application.county}
                  </Typography>
                  <br />
                  {renderStatus(application)}
                </>
              }
            />
            <ListItemSecondaryAction>
              <Stack direction="row" spacing={1}>
                <Button 
                  component={Link} 
                  to={`/banbam-applications/${application._id}`}
                  variant="contained"
                >
                  View Details
                </Button>
                {isAdmin && (
                  <IconButton 
                    edge="end" 
                    aria-label="delete"
                    onClick={() => handleDeleteClick(application._id)}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Stack>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          Confirm Deletion
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this application? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} disabled={isDeleting}>
            Cancel
          </Button>
          <Button 
            onClick={handleDeleteConfirm} 
            color="error" 
            disabled={isDeleting}
            autoFocus
          >
            {isDeleting ? 'Deleting...' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default BanBamApplicationList;