import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent, Typography, CardActions, Button } from '@mui/material';

interface VenueCardProps {
  venue: {
    _id: string;
    name: string;
    capacity: number;
    location: string;
  };
}

const VenueCard: React.FC<VenueCardProps> = ({ venue }) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="h2">
          {venue.name}
        </Typography>
        <Typography color="textSecondary">
          Capacity: {venue.capacity}
        </Typography>
        <Typography variant="body2" component="p">
          Location: {venue.location}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" component={Link} to={`/venues/${venue._id}`}>
          View Details
        </Button>
      </CardActions>
    </Card>
  );
};

export default VenueCard;