import React, { useState, useEffect } from 'react';
import { 
  TextField, 
  Button, 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel, 
  Box, 
  Grid, 
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
  FormControlLabel,
  Paper,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { SelectChangeEvent } from '@mui/material/Select';
import { EventType } from '../types/EventTypes';
import { Strand } from '../types/Strand';

interface StrandFormProps {
  initialData?: Partial<Strand>;
  onSubmit: (data: Strand) => void;
}

const StrandForm: React.FC<StrandFormProps> = ({ initialData, onSubmit }) => {
  const [isQuickStrand, setIsQuickStrand] = useState(false);
  const [expanded, setExpanded] = useState<string | false>('artsCouncil');

  const parseDateValue = (dateValue: string | Date | undefined | null): Date => {
    if (!dateValue) return new Date();
    if (dateValue instanceof Date) {
      return isNaN(dateValue.getTime()) ? new Date() : dateValue;
    }
    const parsedDate = new Date(dateValue);
    return isNaN(parsedDate.getTime()) ? new Date() : parsedDate;
  };

  const [formData, setFormData] = useState<Strand>({
    name: '',
    description: '',
    type: 'domestic',
    startDate: new Date(),
    endDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
    eventType: EventType.Concert,
    status: 'planned',
    published: false,
    mainImage: '',
    dates: '',
    isQuickStrand: false,
    artsCouncilActivityType: 'Ticketed Performance or Event',
    artsCouncilActivityTarget: 'General audiences',
    artsCouncilStatus: 'Not Started',
    overallFinance: { totalIncome: 0, totalExpenditure: 0 },
    associations: { venues: [], performances: [], strands: [], personnel: [], bands: [], applications: [] },
    employmentFigures: { artists: 0, others: 0, associatedEmploy: 0, volunteers: 0 },
    audiences: { venuesCaps: 0, paying: 0, free: 0, total: 0, ratio: 0 },
    numberOfProposedEvents: 0,
  });

  useEffect(() => {
    if (initialData) {
      setFormData(prev => ({
        ...prev,
        ...initialData,
        startDate: parseDateValue(initialData.startDate),
        endDate: parseDateValue(initialData.endDate),
        isQuickStrand: initialData.isQuickStrand || false,
        overallFinance: initialData.overallFinance || { totalIncome: 0, totalExpenditure: 0 },
        employmentFigures: initialData.employmentFigures || { artists: 0, others: 0, associatedEmploy: 0, volunteers: 0 },
        audiences: initialData.audiences || { venuesCaps: 0, paying: 0, free: 0, total: 0, ratio: 0 },
        associations: initialData.associations || { venues: [], performances: [], strands: [], personnel: [], bands: [], applications: [] },
      }));
      setIsQuickStrand(initialData.isQuickStrand || false);
    }
  }, [initialData]);

  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleQuickStrandToggle = () => {
    setIsQuickStrand(!isQuickStrand);
    // Reset accordion expansion when switching modes
    setExpanded(isQuickStrand ? 'artsCouncil' : false);
    setFormData(prev => ({ ...prev, isQuickStrand: !isQuickStrand }));
  };


  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleDateChange = (field: 'startDate' | 'endDate') => (newDate: Date | null) => {
    if (newDate && !isNaN(newDate.getTime())) {
      setFormData(prev => ({ ...prev, [field]: newDate }));
    }
  };

  const handleNestedChange = (category: keyof Strand, field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value) || 0;
    setFormData(prev => ({
      ...prev,
      [category]: {
        ...(prev[category] as Record<string, unknown>),
        [field]: value
      }
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const submissionData = {
      ...formData,
      startDate: parseDateValue(formData.startDate),
      endDate: parseDateValue(formData.endDate),
      isQuickStrand,
    };
    onSubmit(submissionData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={isQuickStrand}
                  onChange={handleQuickStrandToggle}
                  color="primary"
                />
              }
              label={
                <Typography variant="h6">
                  {isQuickStrand ? "Quick Strand (External)" : "Internal Strand"}
                </Typography>
              }
            />
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              {isQuickStrand 
                ? "Quick strands are used for external sharing and require minimal information."
                : "Internal strands require full details for proper event management."
              }
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      <Grid container spacing={2}>
        {/* Basic Information - Always Required */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>Basic Information</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="name"
            label="Strand Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="description"
            label="Description"
            multiline
            rows={4}
            value={formData.description}
            onChange={handleChange}
            required={!isQuickStrand}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Type</InputLabel>
            <Select
              name="type"
              value={formData.type}
              onChange={handleChange}
              required={!isQuickStrand}
            >
              <MenuItem value="domestic">Domestic</MenuItem>
              <MenuItem value="international">International</MenuItem>
              <MenuItem value="hybrid">Hybrid</MenuItem>
              <MenuItem value="quick">Quick</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Only show these sections for internal strands */}
        {!isQuickStrand && (
          <>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Event Type</InputLabel>
                <Select
                  name="eventType"
                  value={formData.eventType}
                  onChange={handleChange}
                  required
                >
                  {Object.values(EventType).map((type) => (
                    <MenuItem key={type} value={type}>{type}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                label="Start Date"
                value={formData.startDate}
                onChange={handleDateChange('startDate')}
                slotProps={{ textField: { fullWidth: true } }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                label="End Date"
                value={formData.endDate}
                onChange={handleDateChange('endDate')}
                slotProps={{ textField: { fullWidth: true } }}
              />
            </Grid>

            {/* Detailed Sections */}
            <Grid item xs={12}>
            <Accordion 
              expanded={expanded === 'artsCouncil'}
              onChange={handleAccordionChange('artsCouncil')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="arts-council-content"
                id="arts-council-header"
              >
                <Typography>Arts Council Information</Typography>
              </AccordionSummary>
              <AccordionDetails>
             <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Arts Council Activity Type</InputLabel>
                    <Select
                      name="artsCouncilActivityType"
                      value={formData.artsCouncilActivityType}
                      onChange={handleChange}
                    >
                      <MenuItem value="Ticketed Performance or Event">Ticketed Performance or Event</MenuItem>
                      <MenuItem value="Non-Ticketed Performance or Event">Non-Ticketed Performance or Event</MenuItem>
                      <MenuItem value="Book Publication">Book Publication</MenuItem>
                      <MenuItem value="Production and / or Distribution of an Artefact">Production and / or Distribution of an Artefact</MenuItem>
                      <MenuItem value="Broadcast / online / digital distribution of work">Broadcast / online / digital distribution of work</MenuItem>
                      <MenuItem value="Exhibition">Exhibition</MenuItem>
                      <MenuItem value="Participatory / education / outreach activity">Participatory / education / outreach activity</MenuItem>
                      <MenuItem value="New work development activity">New work development activity</MenuItem>
                      <MenuItem value="Research/archiving/digitisation">Research/archiving/digitisation</MenuItem>
                      <MenuItem value="Artist-focused / artform development activity">Artist-focused / artform development activity</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Arts Council Activity Target</InputLabel>
                    <Select
                      name="artsCouncilActivityTarget"
                      value={formData.artsCouncilActivityTarget}
                      onChange={handleChange}
                    >
                      <MenuItem value="General audiences">General audiences</MenuItem>
                      <MenuItem value="Families">Families</MenuItem>
                      <MenuItem value="Children (0-15)">Children (0-15)</MenuItem>
                      <MenuItem value="Young People (16-23)">Young People (16-23)</MenuItem>
                      <MenuItem value="Targeted communities">Targeted communities</MenuItem>
                      <MenuItem value="Artists / arts professionals">Artists / arts professionals</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Arts Council Status</InputLabel>
                    <Select
                      name="artsCouncilStatus"
                      value={formData.artsCouncilStatus}
                      onChange={handleChange}
                    >
                      <MenuItem value="Not Started">Not Started</MenuItem>
                      <MenuItem value="Initial Draft">Initial Draft</MenuItem>
                      <MenuItem value="GM Draft Reviewed">GM Draft Reviewed</MenuItem>
                      <MenuItem value="Director Draft Reviewed">Director Draft Reviewed</MenuItem>
                      <MenuItem value="Final Reviewed Draft">Final Reviewed Draft</MenuItem>
                      <MenuItem value="Budget Submission">Budget Submission</MenuItem>
                      <MenuItem value="Revised Draft">Revised Draft</MenuItem>
                      <MenuItem value="Revised Submission">Revised Submission</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12}>
            <Accordion 
              expanded={expanded === 'financial'}
              onChange={handleAccordionChange('financial')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="financial-content"
                id="financial-header"
              >
                <Typography>Financial Information</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="overallFinance.totalIncome"
                    label="Total Income"
                    type="number"
                    value={formData.overallFinance.totalIncome}
                    onChange={handleNestedChange('overallFinance', 'totalIncome')}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="overallFinance.totalExpenditure"
                    label="Total Expenditure"
                    type="number"
                    value={formData.overallFinance.totalExpenditure}
                    onChange={handleNestedChange('overallFinance', 'totalExpenditure')}
                  />
                </Grid>
              </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12}>
            <Accordion 
              expanded={expanded === 'employment'}
              onChange={handleAccordionChange('employment')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="employment-content"
                id="employment-header"
              >
                <Typography>Employment Figures</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="employmentFigures.artists"
                    label="Artists Employed"
                    type="number"
                    value={formData.employmentFigures.artists}
                    onChange={handleNestedChange('employmentFigures', 'artists')}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="employmentFigures.others"
                    label="Others Employed"
                    type="number"
                    value={formData.employmentFigures.others}
                    onChange={handleNestedChange('employmentFigures', 'others')}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="employmentFigures.associatedEmploy"
                    label="Associated Employment"
                    type="number"
                    value={formData.employmentFigures.associatedEmploy}
                    onChange={handleNestedChange('employmentFigures', 'associatedEmploy')}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="employmentFigures.volunteers"
                    label="Volunteers"
                    type="number"
                    value={formData.employmentFigures.volunteers}
                    onChange={handleNestedChange('employmentFigures', 'volunteers')}
                  />
                </Grid>
              </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12}>
            <Accordion 
              expanded={expanded === 'audience'}
              onChange={handleAccordionChange('audience')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="audience-content"
                id="audience-header"
              >
                <Typography>Audience Information</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="audiences.venuesCaps"
                    label="Venues Capacity"
                    type="number"
                    value={formData.audiences.venuesCaps}
                    onChange={handleNestedChange('audiences', 'venuesCaps')}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="audiences.paying"
                    label="Paying Audience"
                    type="number"
                    value={formData.audiences.paying}
                    onChange={handleNestedChange('audiences', 'paying')}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="audiences.free"
                    label="Free Audience"
                    type="number"
                    value={formData.audiences.free}
                    onChange={handleNestedChange('audiences', 'free')}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="audiences.total"
                    label="Total Audience"
                    type="number"
                    value={formData.audiences.total}
                    onChange={handleNestedChange('audiences', 'total')}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="audiences.ratio"
                    label="Audience Ratio"
                    type="number"
                    value={formData.audiences.ratio}
                    onChange={handleNestedChange('audiences', 'ratio')}
                  />
                </Grid>
              </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                name="numberOfProposedEvents"
                label="Number of Proposed Events"
                type="number"
                value={formData.numberOfProposedEvents}
                onChange={handleChange}
                required
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Button 
            type="submit" 
            variant="contained" 
            color="primary" 
            size="large"
            fullWidth
          >
            {initialData ? 'Update Strand' : 'Create Strand'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default StrandForm;