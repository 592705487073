import React, { useState, useEffect } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Button,
  Box,
  Typography,
  IconButton,
  Tooltip
} from '@mui/material';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AssociatePersonnelModal from './AssociatePersonnelModal';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LinkIcon from '@mui/icons-material/Link';

interface Personnel {
  _id: string;
  name: string;
  role: string;
  phone?: string;
  email?: string;
  performances?: string[];
}

interface Performance {
  _id: string;
  startTime: string;
  endTime: string;
  status: 'pending' | 'confirmed' | 'cancelled';
  band: {
    _id: string;
    artistName: string;
  };
}

const API_BASE_URL = process.env.REACT_APP_API_URL;

const PersonnelList: React.FC = () => {
  const [personnel, setPersonnel] = useState<Personnel[]>([]);
  const [performances, setPerformances] = useState<{ [key: string]: Performance }>({});
  const [error, setError] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPersonnelId, setSelectedPersonnelId] = useState<string | null>(null);

  useEffect(() => {
    const fetchPersonnelAndPerformances = async () => {
      try {
        const [personnelResponse, performancesResponse] = await Promise.all([
          axios.get<Personnel[]>(`${API_BASE_URL}/api/personnel`),
          axios.get<Performance[]>(`${API_BASE_URL}/api/performances?populate=band`)
        ]);

        setPersonnel(personnelResponse.data);

        const performanceMap = performancesResponse.data.reduce((acc, performance) => {
          acc[performance._id] = performance;
          return acc;
        }, {} as { [key: string]: Performance });

        setPerformances(performanceMap);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to fetch data. Please try again later.');
      }
    };

    fetchPersonnelAndPerformances();
  }, []);

  const getLatestPerformance = (performanceIds: string[] | undefined) => {
    if (!performanceIds || performanceIds.length === 0) return 'No performances';
    
    const personPerformances = performanceIds
      .map(id => performances[id])
      .filter(Boolean)
      .sort((a, b) => new Date(b.startTime).getTime() - new Date(a.startTime).getTime());

    if (personPerformances.length === 0) return 'Performance data unavailable';

    const latestPerformance = personPerformances[0];
    const bandName = latestPerformance.band.artistName;
    const performanceDate = latestPerformance.startTime ? new Date(latestPerformance.startTime).toLocaleDateString() : 'No Date';
    const performanceStatus = latestPerformance.status;

    return `${bandName} on ${performanceDate} (${performanceStatus})`;
  };

  const handleAssociate = (personnelId: string) => {
    setSelectedPersonnelId(personnelId);
    setIsModalOpen(true);
  };

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Button 
          variant="contained" 
          color="primary" 
          component={Link} 
          to="/personnel/new"
        >
          Add New Personnel
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Contact</TableCell>
              <TableCell>Latest Performance</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {personnel.map((person) => (
              <TableRow key={person._id}>
                <TableCell>{person.name}</TableCell>
                <TableCell>{person.role}</TableCell>
                <TableCell>
                  {person.phone && (
                    <Tooltip title="Call">
                      <IconButton onClick={() => window.location.href = `tel:${person.phone}`}>
                        <PhoneIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {person.email && (
                    <Tooltip title="Email">
                      <IconButton onClick={() => window.location.href = `mailto:${person.email}`}>
                        <EmailIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell>{getLatestPerformance(person.performances)}</TableCell>
                <TableCell>
                  <Tooltip title="View">
                    <IconButton component={Link} to={`/personnel/${person._id}`} color="primary">
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit">
                    <IconButton component={Link} to={`/personnel/edit/${person._id}`} color="secondary">
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Associate">
                    <IconButton onClick={() => handleAssociate(person._id)}>
                      <LinkIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedPersonnelId && (
        <AssociatePersonnelModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          personnelId={selectedPersonnelId}
        />
      )}
    </Box>
  );
};

export default PersonnelList;