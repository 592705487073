// src/components/ActionSection.tsx

import React from 'react';
import { Card, CardContent, Typography, Button, Box } from '@mui/material';

const ActionSection: React.FC = () => {
  return (
    <Card sx={{ mb: 4 }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Actions
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="contained" color="primary">
            Contact Artist
          </Button>
          <Button variant="contained" color="secondary">
            Contact Venue
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ActionSection;