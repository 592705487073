import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Divider,
  useTheme
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { BandRecommendation } from '../../../../types/BandRecommendations';

interface BandAboutProps {
  band: BandRecommendation;
}

const BandAboutStylized: React.FC<BandAboutProps> = ({ band }) => {
  const theme = useTheme();

  if (!band.about) return null;

  return (
    <Card 
      elevation={2}
      sx={{ 
        mb: 3,
        position: 'relative',
        overflow: 'visible'
      }}
    >
      <CardContent sx={{ position: 'relative', p: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
          <InfoIcon sx={{ mr: 1, color: 'primary.main' }} />
          <Typography 
            variant="h5"
            sx={{
              fontWeight: 600,
              color: theme.palette.primary.main
            }}
          >
            About {band.artistName}
          </Typography>
        </Box>
        
        <Divider sx={{ mb: 3 }} />
        
        <Typography 
          variant="body1" 
          sx={{ 
            whiteSpace: 'pre-line',
            lineHeight: 1.8,
            color: 'text.primary',
            fontSize: '1rem',
            fontStyle: 'normal',
            pl: 2,
            borderLeft: `4px solid ${theme.palette.primary.main}`,
            '& > p': {
              mb: 2
            }
          }}
        >
          {band.about}
        </Typography>

      </CardContent>
    </Card>
  );
};

export default BandAboutStylized;