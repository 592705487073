import React from 'react';
import { Box, Grid, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { BandRecommendation } from '../../types/BandRecommendations';
import { IEvent } from '../../types/Event';
import { useBandView } from '../../contexts/BandViewContext';
import BandHero from './sections/BandHero/BandHero';
import BandBasicInfo from './sections/BandBasicInfo';
import BandMedia from './sections/BandMedia';
import BandActions from './sections/BandActions';
import BandAdditionalInfo from './sections/BandAdditionalInfo';
import BandInstrumentation from './sections/BandInstrumentation/BandInstrumentation';
import BandAboutStylized from './sections/BandAbout/BandAboutStylized';
import PublishedStrandsCard from '../PublishedStrandsCard';


export interface BandLayoutProps {
  band: BandRecommendation;
  onAddToEvent: () => void;
  onDelete: () => void;
  events: IEvent[];
  onPublish: (strandId: string, shortDescription: string) => Promise<void>;
}

const BandLayout: React.FC<BandLayoutProps> = ({
  band,
  onAddToEvent,
  onDelete,
  events,
  onPublish
}) => {
  const navigate = useNavigate();
  const { viewMode, permissions } = useBandView();
  const { viewConfig } = useBandView();


  const handleEdit = () => {
    navigate(`/bandrecommendations/${band._id}/edit`);
  };

  return (
    <Box>
      {/* Hero Section */}
      <BandHero band={band} />
  
      {/* About Section */}
      <BandAboutStylized band={band} />
  
      {/* Main Content */}
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* Left Column */}
          <Grid item xs={12} md={8}>
            {/* Info Cards Row */}
            <Grid container spacing={4} mb={4}>
              <Grid item xs={12} md={4}>
                <BandBasicInfo band={band} />
              </Grid>
              <Grid item xs={12} md={8}>
                <BandInstrumentation band={band} />
              </Grid>
            </Grid>
  
            {/* Media Section */}
            <BandMedia band={band} />
          </Grid>
  
          {/* Right Column */}
          <Grid item xs={12} md={4}>
            {permissions.canEdit && (
              <BandActions 
                band={band}
                onEdit={handleEdit}
                onDelete={onDelete}
                onAddToEvent={onAddToEvent}
                onPublish={onPublish}
                events={events}
              />
            )}
  {viewConfig.showPublishedStrandCard && (
    <PublishedStrandsCard bandId={band._id || ''} />
  )}
            <BandAdditionalInfo band={band} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
  
  }
  
  export default BandLayout;