import React from 'react';
import { Grid, TextField, Typography, Tooltip } from '@mui/material';
import { BandRecommendationFormData } from '../../types/BandRecommendations';

interface AvailabilityProps {
  formData: BandRecommendationFormData;
  setFormData: React.Dispatch<React.SetStateAction<BandRecommendationFormData>>;
}

const Availability: React.FC<AvailabilityProps> = ({ formData, setFormData }) => {
  const handleDateChange = (field: 'from' | 'to') => (event: React.ChangeEvent<HTMLInputElement>) => {
    const dateValue = event.target.value;
    setFormData(prevData => ({
      ...prevData,
      availability: {
        ...prevData.availability,
        [field]: dateValue
      }
    }));
  };

  const handleExcludedDatesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const dates = value.split(',').map(dateStr => new Date(dateStr.trim()));
    setFormData(prevData => ({
      ...prevData,
      availability: {
        ...prevData.availability,
        excludedDates: dates
      }
    }));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="subtitle1" gutterBottom>
          Availability
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Tooltip title="Date from which the band is available.">
          <TextField
            label="From"
            type="date"
            name="availability.from"
            value={formData.availability.from}
            onChange={handleDateChange('from')}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            fullWidth
          />
        </Tooltip>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Tooltip title="Date until which the band is available.">
          <TextField
            label="To"
            type="date"
            name="availability.to"
            value={formData.availability.to}
            onChange={handleDateChange('to')}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            fullWidth
          />
        </Tooltip>
      </Grid>
      <Grid item xs={12}>
        <Tooltip title="Specific dates the band is not available (comma-separated YYYY-MM-DD).">
          <TextField
            label="Excluded Dates"
            value={formData.availability.excludedDates?.join(', ') ?? ''}
            onChange={handleExcludedDatesChange}
            variant="outlined"
            fullWidth
          />
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default Availability;