import React from 'react';
import { 
  FormSchema, 
  FormField, 
  FieldType 
} from '../../../types/FormSchema';
import {
  Box,
  TextField,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Typography,
  IconButton,
  Tooltip,
  Paper,
  Container
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Grid } from '@mui/material';

interface FormViewerProps {
  schema: FormSchema;
  values: Record<string, any>;
  onChange: (fieldId: string, value: any) => void;
  disabled?: boolean;
}

const FormViewer = ({ schema, values, onChange, disabled = false }: FormViewerProps) => {
  const renderField = (field: FormField) => {
    const value = values[field.id] ?? (field as any).defaultValue ?? '';
    const commonProps = {
      id: field.id,
      name: field.name,
      value,
      onChange: (e: any) => onChange(field.id, e.target.value),
      disabled,
      placeholder: field.placeholder,
      fullWidth: true,
      required: field.validation?.required,
      variant: "outlined" as const,
      margin: "normal" as const,
    };

    const fieldLabel = (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
        <Typography variant="subtitle1">{field.label}</Typography>
        {field.tooltip && (
          <Tooltip title={field.tooltip}>
            <IconButton size="small">
              <HelpOutlineIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    );

    switch (field.type) {
      case FieldType.Text:
      case FieldType.Email:
      case FieldType.Url:
        return (
          <Box sx={{ mb: 2 }}>
            {fieldLabel}
            <TextField
              {...commonProps}
              type={field.type}
              helperText={field.helperText}
            />
          </Box>
        );

      case FieldType.TextArea:
        return (
          <Box sx={{ mb: 2 }}>
            {fieldLabel}
            <TextField
              {...commonProps}
              multiline
              rows={4}
              helperText={field.helperText}
            />
          </Box>
        );

      case FieldType.Select:
        return (
          <Box sx={{ mb: 2 }}>
            {fieldLabel}
            <FormControl fullWidth variant="outlined">
              <InputLabel>{field.label}</InputLabel>
              <Select
                {...commonProps}
                label={field.label}
                margin="none"
              >
                {field.options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {field.helperText && (
                <FormHelperText>{field.helperText}</FormHelperText>
              )}
            </FormControl>
          </Box>
        );

      case FieldType.Checkbox:
        return (
          <Box sx={{ mb: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!value}
                  onChange={(e) => onChange(field.id, e.target.checked)}
                  disabled={disabled}
                />
              }
              label={field.label}
            />
            {field.helperText && (
              <FormHelperText>{field.helperText}</FormHelperText>
            )}
          </Box>
        );

      case FieldType.Upload:
        return (
          <Box sx={{ mb: 2 }}>
            {fieldLabel}
            <input
              type="file"
              accept={field.accept}
              multiple={field.multiple}
              onChange={(e) => {
                const files = Array.from(e.target.files || []);
                onChange(field.id, field.multiple ? files : files[0]);
              }}
              disabled={disabled}
              style={{
                width: '100%',
                padding: '8px',
                border: '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: '4px'
              }}
            />
            {field.helperText && (
              <FormHelperText>{field.helperText}</FormHelperText>
            )}
          </Box>
        );

      default:
        return null;
    }
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ py: 4 }}>
        <Typography variant="h4" gutterBottom>
          {schema.title}
        </Typography>
        
        {schema.description && (
          <Typography 
            variant="body1" 
            color="text.secondary" 
            sx={{ mb: 4 }}
          >
            {schema.description}
          </Typography>
        )}
  
        <form>
          <Grid container spacing={2}>
            {schema.fields.map((field) => (
              <Grid
                item
                xs={field.grid?.xs || 12}
                sm={field.grid?.sm || 12}
                md={field.grid?.md || 12}
                lg={field.grid?.lg || 12}
                key={field.id}
              >
                {renderField(field)}
              </Grid>
            ))}
          </Grid>
        </form>
        
      </Box>
    </Container>
  );
}

export default FormViewer;