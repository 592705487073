import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { 
  Typography, 
  CircularProgress, 
  Paper, 
  Grid, 
  Chip,
  List,
  ListItem,
  ListItemText,
  Divider
} from '@mui/material';
import axios from 'axios';

// Define the NavigatorApplication type based on your model
interface NavigatorApplication {
  _id: string;
  name: string;
  email: string;
  phone: string;
  instrument: string[];
  location: string;
  pronouns: string;
  linkToWork: string;
  webLink: string;
  residencyGoals: string;
  locationDates: string[];
  parentalDuties: string;
  status: 'pending' | 'approved' | 'rejected';
  createdAt: string;
}

const NavigatorApplicantDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [application, setApplication] = useState<NavigatorApplication | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchApplication = async () => {
      try {
        const response = await axios.get<NavigatorApplication>(`${process.env.REACT_APP_API_URL}/api/navigator/${id}`);
        setApplication(response.data);
      } catch (err: any) {
        setError(err.message || 'An error occurred while fetching the application.');
      } finally {
        setLoading(false);
      }
    };

    fetchApplication();
  }, [id]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!application) {
    return <Typography>No application data found.</Typography>;
  }

  return (
    <Paper elevation={3} sx={{ padding: 3, marginTop: 3 }}>
      <Typography variant="h4" gutterBottom>Navigator Application Details</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Applicant Information</Typography>
          <List>
            <ListItem>
              <ListItemText primary="Name" secondary={application.name} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Email" secondary={application.email} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Phone" secondary={application.phone} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Location" secondary={application.location} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Pronouns" secondary={application.pronouns || 'Not specified'} />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Application Details</Typography>
          <List>
            <ListItem>
              <ListItemText 
                primary="Instruments" 
                secondary={application.instrument.join(', ')} 
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Link to Work" 
                secondary={<a href={application.linkToWork} target="_blank" rel="noopener noreferrer">{application.linkToWork}</a>} 
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Web Link" 
                secondary={<a href={application.webLink} target="_blank" rel="noopener noreferrer">{application.webLink}</a>} 
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6">Residency Goals</Typography>
          <Typography paragraph>{application.residencyGoals}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Location Preferences</Typography>
          <List>
            {application.locationDates.map((location, index) => (
              <ListItem key={index}>
                <ListItemText primary={location} />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Parental Duties Support</Typography>
          <Typography paragraph>{application.parentalDuties || 'Not specified'}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6">Application Status</Typography>
          <Chip 
            label={application.status.toUpperCase()} 
            color={application.status === 'approved' ? 'success' : application.status === 'rejected' ? 'error' : 'default'}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary">
            Application submitted on: {new Date(application.createdAt).toLocaleString()}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default NavigatorApplicantDetailPage;