// src/components/AdminTopNav.tsx
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, Menu, MenuItem, Box, IconButton, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuIcon from '@mui/icons-material/Menu';
import ShowrunnrLogo from '../ShowrunnrLogo';
import { useAuth } from '../../contexts/AuthContext';
import MobileNav from './MobileNav';

const AdminTopNav: React.FC = () => {
  const { user, logout } = useAuth();
  const [anchorElEvents, setAnchorElEvents] = useState<null | HTMLElement>(null);
  const [anchorElVenues, setAnchorElVenues] = useState<null | HTMLElement>(null);
  const [anchorElStrands, setAnchorElStrands] = useState<null | HTMLElement>(null);
  const [anchorElAdmin, setAnchorElAdmin] = useState<null | HTMLElement>(null);
  const [anchorElTestbed, setAnchorElTestbed] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>) => (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>) => () => {
    setAnchorEl(null);
  };

  const createDropdownMenu = (
    anchorEl: HTMLElement | null,
    handleClose: () => void,
    items: { to: string; label: string }[]
  ) => (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    >
      {items.map((item, index) => (
        <MenuItem key={index} component={Link} to={item.to} onClick={handleClose}>
          {item.label}
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <MobileNav
      role="admin"
      logo={<ShowrunnrLogo width={150} height={50} />}
      onLogout={logout}
      userEmail={user?.email}
    />
  );
};

export default AdminTopNav;