import React from 'react';
import { BaseBandComponentProps } from '../types';
import { useBandView } from '../../../contexts/BandViewContext';
import SocialMediaSection from './additional/SocialMediaSection';
import TechnicalSection from './additional/TechnicalSection';
import FinancialSection from './additional/FinancialSection';
import ContactSection from './additional/ContactSection';
import AvailabilitySection from './additional/AvailabilitySection';
import SimilarArtistsSection from './additional/SimilarArtistsSection';

const BandAdditionalInfo: React.FC<BaseBandComponentProps> = ({ band }) => {
  const { viewConfig } = useBandView();

  return (
    <>
      <SocialMediaSection band={band} />
      <TechnicalSection band={band} />
      <FinancialSection band={band} />
      <AvailabilitySection band={band} />
      <SimilarArtistsSection band={band} />
      <ContactSection band={band} />
    </>
  );
};

export default BandAdditionalInfo;