import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Chip, Box, CircularProgress } from '@mui/material';
import axiosInstance from '../utils/axiosConfig';

interface Strand {
  _id: string;
  name: string;
  description: string;
  type: string;
  startDate: string;
  endDate: string;
  eventType: string;
  status: string;
  published: boolean;
  slug: string;
}

interface BandRecommendation {
  _id: string;
  publishedTo: Array<{
    strandId: {
      _id: string;
      name: string;
      slug: string;
    };
    shortDescription?: string;
    publishedDate?: string;
  }>;
}

interface PublishedStrandsCardProps {
  bandId: string;
}

const PublishedStrandsCard: React.FC<PublishedStrandsCardProps> = ({ bandId }) => {
  const [publishedStrands, setPublishedStrands] = useState<Array<{
    _id: string;
    name: string;
    slug: string;
  }>>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchPublishedStrandsData = async () => {
    try {
      setLoading(true);
      setError(null);

      // Fetch the band details with populated strand data
      const response = await axiosInstance.get<BandRecommendation>(
        `/api/bandrecommendations/${bandId}`
      );

      // Extract strand information from publishedTo array
      const strands = response.data.publishedTo.map(pub => ({
        _id: pub.strandId._id,
        name: pub.strandId.name,
        slug: pub.strandId.slug
      }));

      console.log('Published strands:', strands);
      setPublishedStrands(strands);

    } catch (error) {
      console.error('Error fetching published strands:', error);
      setError('Failed to fetch published strands');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bandId) {
      fetchPublishedStrandsData();
    }
  }, [bandId]);

  const refetchStrands = () => {
    if (bandId) {
      setLoading(true);
      fetchPublishedStrandsData();
    }
  };

  if (loading) {
    return (
      <Card>
        <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </CardContent>
      </Card>
    );
  }

  if (error) {
    return (
      <Card>
        <CardContent>
          <Typography color="error">{error}</Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Published Strands
        </Typography>
        {publishedStrands.length === 0 ? (
          <Typography>This band is not published to any strands.</Typography>
        ) : (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {publishedStrands.map((strand) => (
              <Chip 
                key={strand._id} 
                label={strand.name}
                color="primary"
                variant="outlined"
                onClick={() => window.open(`/strands/${strand.slug}`, '_blank')}
              />
            ))}
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default PublishedStrandsCard;