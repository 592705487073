import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Link
} from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import MusicVideoIcon from '@mui/icons-material/MusicVideo';
import { BandRecommendation } from '../../../../types/BandRecommendations';
import { useBandView } from '../../../../contexts/BandViewContext';

interface SocialMediaSectionProps {
  band: BandRecommendation;
}

const SocialMediaSection: React.FC<SocialMediaSectionProps> = ({ band }) => {
  const { viewConfig } = useBandView();

  if (!viewConfig.showSocialMedia) return null;

  // Only render if there are social media links
  if (!band.instagramHandle && !band.twitterHandle && 
      !band.facebookPage && !band.tikTokHandle) {
    return null;
  }

  return (
    <Card sx={{ mb: 3 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>Social Media</Typography>
        <List>
          {band.instagramHandle && (
            <ListItem>
              <InstagramIcon sx={{ mr: 2 }} />
              <ListItemText>
                <Link href={`https://instagram.com/${band.instagramHandle}`} target="_blank">
                  @{band.instagramHandle}
                </Link>
              </ListItemText>
            </ListItem>
          )}
          {band.twitterHandle && (
            <ListItem>
              <TwitterIcon sx={{ mr: 2 }} />
              <ListItemText>
                <Link href={`https://twitter.com/${band.twitterHandle}`} target="_blank">
                  @{band.twitterHandle}
                </Link>
              </ListItemText>
            </ListItem>
          )}
          {band.facebookPage && (
            <ListItem>
              <FacebookIcon sx={{ mr: 2 }} />
              <ListItemText>
                <Link href={band.facebookPage} target="_blank">
                  Facebook Page
                </Link>
              </ListItemText>
            </ListItem>
          )}
          {band.tikTokHandle && (
            <ListItem>
              <MusicVideoIcon sx={{ mr: 2 }} />
              <ListItemText>
                <Link href={`https://tiktok.com/@${band.tikTokHandle}`} target="_blank">
                  @{band.tikTokHandle}
                </Link>
              </ListItemText>
            </ListItem>
          )}
        </List>
      </CardContent>
    </Card>
  );
};

export default SocialMediaSection;