import React from 'react';
import { Card, CardContent, CardActions, Typography, Button, Chip, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Performance } from '../../types/dashboard';

interface PerformanceListProps {
  performances: Performance[];
  type: 'pending' | 'confirmed';
  emptyMessage?: string;
}

const PerformanceList: React.FC<PerformanceListProps> = ({ 
  performances, 
  type,
  emptyMessage = "No performances found." 
}) => {
  const isUpcoming = (performance: Performance) => {
    const now = new Date();
    const endTime = new Date(performance.endTime);
    return endTime >= now;
  };

  const filteredPerformances = performances.filter(isUpcoming);

  const formatDateTime = (dateTime: string) => {
    const date = new Date(dateTime);
    return {
      date: date.toLocaleDateString(),
      time: date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    };
  };

  const getBandName = (band: Performance['band']) => {
    if (typeof band === 'string') return 'Loading...';
    return band.artistName || 'Unknown Band';
  };

  const getEventName = (event: Performance['event'], strand: Performance['strandId']) => {
    // First check if it's a strand-based performance
    if (strand) {
      if (typeof strand === 'string') return 'Loading strand...';
      return `Strand: ${strand.name}`;
    }
  
    // Then check for event-based performance
    if (!event) return 'No Event/Strand';
    if (typeof event === 'string') return 'Loading...';
    return event.name || 'No Event';
  };

  return filteredPerformances.length > 0 ? (
    <>
      {filteredPerformances.map(performance => {
        const start = formatDateTime(performance.startTime);
        const end = formatDateTime(performance.endTime);

        return (
          <Card key={performance._id} sx={{ mb: 2 }}>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
                <Typography variant="h5" component="h2">
                  {getBandName(performance.band)}
                </Typography>
                <Chip 
                  label={type === 'pending' ? 'Pending' : 'Confirmed'} 
                  color={type === 'pending' ? 'warning' : 'success'} 
                />
              </Box>

              <Typography color="text.secondary" sx={{ mb: 1 }}>
  {getEventName(performance.event, performance.strandId)}
</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <AccessTimeIcon sx={{ mr: 1, fontSize: '1rem' }} />
                <Typography variant="body2">
                  {start.date} | {start.time} - {end.time}
                </Typography>
              </Box>

              <Box sx={{ mt: 2 }}>
                {performance.ticketPrice > 0 && (
                  <Chip 
                    label={`€${performance.ticketPrice}`} 
                    size="small" 
                    sx={{ mr: 1 }}
                  />
                )}
                {performance.fee > 0 && (
                  <Chip 
                    label={`Fee: €${performance.fee}`} 
                    size="small" 
                    variant="outlined"
                  />
                )}
              </Box>
            </CardContent>
            <CardActions>
              <Button 
                size="small" 
                component={Link} 
                to={`/performances/${performance._id}`}
              >
                {type === 'pending' ? 'Manage Performance' : 'View Details'}
              </Button>
            </CardActions>
          </Card>
        );
      })}
    </>
  ) : (
    <Typography>{emptyMessage}</Typography>
  );
};

export default PerformanceList;