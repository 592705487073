import React, { useMemo } from 'react';
import {
  Typography,
  Box,
  Card,
  CardContent,
  Grid,
} from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
  CartesianGrid,
  Cell,
  Brush,
} from 'recharts';

interface TitoRelease {
  quantity: number;
  quantity_sold: number;
  title: string;
}

interface TitoEvent {
  title: string;
  totalSold: number;
  totalAvailable: number;
  start_date: string;
  releases: TitoRelease[];
  registrations: Array<{
    completed_at: string;
    tickets_count: number;
  }>;
}

interface CurrentSalesAnalysisProps {
  event: TitoEvent;
}

const CurrentSalesAnalysis: React.FC<CurrentSalesAnalysisProps> = ({ event }) => {
  const percentageSold = ((event.totalSold / event.totalAvailable) * 100).toFixed(2);

  const daysUntilEvent = useMemo(() => {
    const eventDate = new Date(event.start_date);
    const today = new Date();
    const diffTime = eventDate.getTime() - today.getTime();
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }, [event.start_date]);

  const salesRate = event.totalSold / Math.max(daysUntilEvent, 1);

  const ticketSalesData = useMemo(
    () => [
      { name: 'Sold', value: event.totalSold },
      { name: 'Available', value: event.totalAvailable - event.totalSold },
    ],
    [event.totalSold, event.totalAvailable]
  );

  const salesOverTimeData = useMemo(() => {
    const data: { [date: string]: number } = {};
    event.registrations.forEach((registration) => {
      const date = new Date(registration.completed_at).toISOString().split('T')[0];
      data[date] = (data[date] || 0) + registration.tickets_count;
    });
    return Object.entries(data)
      .map(([date, count]) => ({ date, count }))
      .sort((a, b) => a.date.localeCompare(b.date));
  }, [event.registrations]);

  const releaseData = event.releases.map((release) => ({
    name: release.title,
    sold: release.quantity_sold,
    available: Math.max(release.quantity - release.quantity_sold, 0),
  }));

  const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042', '#8dd1e1'];

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Card elevation={3}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Ticket Summary
            </Typography>
            <Typography variant="body1">
              <strong>Tickets Sold:</strong> {event.totalSold} / {event.totalAvailable} (
              {percentageSold}%)
            </Typography>
            <Typography variant="body1">
              <strong>Days until event:</strong> {daysUntilEvent}
            </Typography>
            <Typography variant="body1">
              <strong>Average sales per day:</strong> {salesRate.toFixed(2)}
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={6}>
        <Card elevation={3}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Ticket Sales Progress
            </Typography>
            <ResponsiveContainer width="100%" height={150}>
              <BarChart
                data={ticketSalesData}
                layout="vertical"
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <XAxis type="number" hide />
                <YAxis type="category" dataKey="name" width={100} />
                <Tooltip formatter={(value: number) => `${value} tickets`} />
                <Bar dataKey="value" stackId="a">
                  {ticketSalesData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={entry.name === 'Sold' ? '#8884d8' : '#82ca9d'}
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card elevation={3}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Sales Over Time
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                data={salesOverTimeData}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="count" stroke="#8884d8" activeDot={{ r: 8 }} />
                <Brush dataKey="date" height={30} stroke="#8884d8" />
              </LineChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card elevation={3}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Ticket Types Sales
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                data={releaseData}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="sold" stackId="a" fill="#8884d8" name="Sold">
                  {releaseData.map((entry, index) => (
                    <Cell key={`cell-sold-${index}`} fill={colors[index % colors.length]} />
                  ))}
                </Bar>
                <Bar dataKey="available" stackId="a" fill="#82ca9d" name="Available">
                  {releaseData.map((entry, index) => (
                    <Cell key={`cell-available-${index}`} fill={colors[index % colors.length]} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CurrentSalesAnalysis;