import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container, 
  Typography, 
  Grid, 
  CircularProgress, 
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Box,
  Checkbox,
  ListItemText,
  SelectChangeEvent
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axiosInstance from '../utils/axiosConfig';

// Components
import PublishedBandCard from '../components/PublishedBandCard';

interface BandRecommendation {
  _id: string;
  artistName: string;
  genres: string[];
  shortDescription?: string;
  mainImage: string;
  publishedTo: Array<{
    strandId: string;
    shortDescription?: string;
  }>;
  maleMembers: number;
  femaleMembers: number;
  recommenderCountry: string;
  createdAt: string;
}

interface Strand {
  _id: string;
  name: string;
  description: string;
  slug: string;
}

interface Filters {
  country: string;
  genre: string[];
  minMaleMembers: number;
  minFemaleMembers: number;
  lastViewedDate: string;
  searchTerm: string;
  bandSize: string;
}

const bandSizeOptions = [
  { label: 'Soloist', min: 1, max: 1 },
  { label: 'Duo', min: 2, max: 2 },
  { label: 'Trio', min: 3, max: 3 },
  { label: 'Quartet', min: 4, max: 4 },
  { label: 'Quintet', min: 5, max: 5 },
  { label: 'Sextet', min: 6, max: 6 },
  { label: 'Septet', min: 7, max: 7 },
  { label: 'Octet', min: 8, max: 8 },
  { label: 'Nonet', min: 9, max: 9 },
  { label: 'Big Band', min: 10, max: Infinity },
];

const FilteredPublishedBandListPage: React.FC = () => {
  const { strandSlug } = useParams<{ strandSlug: string }>();
  const [bands, setBands] = useState<BandRecommendation[]>([]);
  const [filteredBands, setFilteredBands] = useState<BandRecommendation[]>([]);
  const [strand, setStrand] = useState<Strand | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Initialize filters
  const [filters, setFilters] = useState<Filters>({
    country: '',
    genre: [],
    minMaleMembers: 0,
    minFemaleMembers: 0,
    lastViewedDate: '',
    searchTerm: '',
    bandSize: '',
  });

  // Fetch data
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        
        // Get the strand
        const strandResponse = await axiosInstance.get<Strand>(
          `/api/strands/${strandSlug}`
        );
        const strandData = strandResponse.data;
        setStrand(strandData);

        // Get all bands
        const bandsResponse = await axiosInstance.get<BandRecommendation[]>(
          `/api/bandrecommendations`
        );

        // Filter bands published to this strand
        const publishedBands = bandsResponse.data.filter(band => 
          band.publishedTo.some(pub => pub.strandId === strandData._id)
        );

        setBands(publishedBands);
        setFilteredBands(publishedBands);
        setError(null);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    if (strandSlug) {
      fetchData();
    }
  }, [strandSlug]);

  // Apply filters
  useEffect(() => {
    const applyFilters = () => {
      const filtered = bands.filter((band) => {
        const totalMembers = band.maleMembers + band.femaleMembers;
        return (
          (filters.country === '' || band.recommenderCountry === filters.country) &&
          (filters.genre.length === 0 || filters.genre.some(genre => band.genres.includes(genre))) &&
          band.maleMembers >= filters.minMaleMembers &&
          band.femaleMembers >= filters.minFemaleMembers &&
          (filters.lastViewedDate === '' || new Date(band.createdAt) > new Date(filters.lastViewedDate)) &&
          (filters.searchTerm === '' ||
            band.artistName.toLowerCase().includes(filters.searchTerm.toLowerCase()) ||
            band.shortDescription?.toLowerCase().includes(filters.searchTerm.toLowerCase())) &&
          (filters.bandSize === '' || (() => {
            const option = bandSizeOptions.find(opt => opt.label === filters.bandSize);
            if (!option) return true;
            return totalMembers >= option.min && totalMembers <= option.max;
          })())
        );
      });
      setFilteredBands(filtered);
    };

    applyFilters();
  }, [bands, filters]);

  // Handle text input changes
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Handle select changes
  const handleSelectChange = (
    event: SelectChangeEvent<string>
  ) => {
    const { name, value } = event.target;
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Handle multi-select changes
  const handleMultiSelectChange = (
    event: SelectChangeEvent<string[]>
  ) => {
    const { name, value } = event.target;
    setFilters(prev => ({
      ...prev,
      [name]: typeof value === 'string' ? value.split(',') : value
    }));
  };

  // Reset filters
  const resetFilters = () => {
    setFilters({
      country: '',
      genre: [],
      minMaleMembers: 0,
      minFemaleMembers: 0,
      lastViewedDate: '',
      searchTerm: '',
      bandSize: '',
    });
  };

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;
  if (!strand) return <Typography>Strand not found.</Typography>;

  return (
    <Container maxWidth="lg">
      <Typography variant="h2" component="h1" gutterBottom>
        {strand.name}
      </Typography>
      
      <Typography variant="body1" paragraph>
        {strand.description}
      </Typography>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Filter Bands</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {/* Band Size Filter */}
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <InputLabel>Band Size</InputLabel>
                <Select
                  name="bandSize"
                  value={filters.bandSize}
                  onChange={handleSelectChange}
                  label="Band Size"
                >
                  <MenuItem value="">All Sizes</MenuItem>
                  {bandSizeOptions.map((option) => (
                    <MenuItem key={option.label} value={option.label}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Genre Filter */}
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <InputLabel>Genres</InputLabel>
                <Select
                  multiple
                  name="genre"
                  value={filters.genre}
                  onChange={handleMultiSelectChange}
                  renderValue={(selected) => (selected as string[]).join(', ')}
                  label="Genres"
                >
                  {Array.from(new Set(bands.flatMap(band => band.genres))).map(genre => (
                    <MenuItem key={genre} value={genre}>
                      <Checkbox checked={filters.genre.indexOf(genre) > -1} />
                      <ListItemText primary={genre} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Gender Distribution Filters */}
            <Grid item xs={6} sm={3}>
              <TextField
                fullWidth
                type="number"
                name="minMaleMembers"
                label="Min Male Members"
                value={filters.minMaleMembers}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                fullWidth
                type="number"
                name="minFemaleMembers"
                label="Min Female Members"
                value={filters.minFemaleMembers}
                onChange={handleInputChange}
              />
            </Grid>

            {/* Search */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="searchTerm"
                label="Search Bands"
                value={filters.searchTerm}
                onChange={handleInputChange}
              />
            </Grid>

            {/* Reset Button */}
            <Grid item xs={12} sm={6}>
              <Button 
                variant="outlined" 
                onClick={resetFilters}
                fullWidth
              >
                Reset Filters
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Box mt={4}>
        {filteredBands.length === 0 ? (
          <Typography>No bands found matching your filters.</Typography>
        ) : (
          <Grid container spacing={3}>
            {filteredBands.map((band) => {
              const publication = band.publishedTo.find(
                pub => pub.strandId === strand._id
              );
              
              return (
                <Grid item xs={12} sm={6} md={4} key={band._id}>
                  <PublishedBandCard 
                    band={{
                      _id: band._id,
                      artistName: band.artistName,
                      genres: band.genres,
                      shortDescription: publication?.shortDescription || '',
                      mainImage: band.mainImage
                    }}
                    strandSlug={strandSlug || ''}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
      </Box>
    </Container>
  );
};

export default FilteredPublishedBandListPage;