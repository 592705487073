import { BandRecommendation } from '../types/BandRecommendations';

export interface PlaylistItem {
  bandId: string;
  videoId: string;
  bandName: string;
}

export const extractYouTubeId = (embedCode: string): string | null => {
  console.log('Extracting ID from:', embedCode);
  
  try {
    // Handle full iframe embed codes
    const iframeMatch = embedCode.match(/embed\/([a-zA-Z0-9_-]+)/i);
    if (iframeMatch && iframeMatch[1]) {
      console.log('Found iframe ID:', iframeMatch[1]);
      return iframeMatch[1];
    }
    
    // Handle direct YouTube URLs
    const urlMatch = embedCode.match(/(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([^"&?\/\s]{11})/i);
    if (urlMatch && urlMatch[1]) {
      console.log('Found URL ID:', urlMatch[1]);
      return urlMatch[1];
    }

    console.warn('Could not extract YouTube ID from:', embedCode);
    return null;
  } catch (error) {
    console.error('Error extracting YouTube ID:', error);
    return null;
  }
};

export const getPlaylistFromBands = (bands: BandRecommendation[]): PlaylistItem[] => {
  console.log('Getting playlist from bands:', bands.length);
  
  const playlist: PlaylistItem[] = [];
  
  bands.forEach(band => {
    // Type guard to ensure band._id exists and is a string
    const bandId = band._id?.toString();
    if (!bandId) {
      console.warn(`Skipping band ${band.artistName} - no ID`);
      return;
    }

    console.log(`Processing band ${band.artistName}, ${band.youtubeEmbeds.length} videos`);
    
    band.youtubeEmbeds.forEach(embed => {
      const videoId = extractYouTubeId(embed);
      if (videoId) {
        console.log(`Found video ID ${videoId} for band ${band.artistName}`);
        playlist.push({
          bandId, // Now TypeScript knows this is a string
          videoId,
          bandName: band.artistName
        });
      }
    });
  });

  console.log('Generated playlist:', playlist);
  return playlist;
};

export const createYouTubeEmbed = (videoId: string): string => {
  return `https://www.youtube.com/embed/${videoId}`;
};

export const validateYouTubeIds = (ids: string[]): string[] => {
  return ids.filter(id => id && id.length === 11);
};