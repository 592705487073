import React, { useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  Chip,
  Tooltip,
  IconButton,
  Card,
  CardContent,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PreviewIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import { BandRecommendationFormData } from '../../types/BandRecommendations';
import AttentionAddButton from './AttentionAddButton';

interface MediaLinksProps {
  formData: BandRecommendationFormData;
  setFormData: React.Dispatch<React.SetStateAction<BandRecommendationFormData>>;
}

const MediaLinks: React.FC<MediaLinksProps> = ({ formData, setFormData }) => {
  const [embedInput, setEmbedInput] = useState({
    youtube: '',
    spotify: '',
    soundcloud: '',
    bandcamp: '',
  });

  const [previewEmbed, setPreviewEmbed] = useState<{ platform: string; embed: string } | null>(null);

  const tooltips = {
    youtube: 'Enter a YouTube video URL and click "Add", you can then preview the embed using the EYE symbol below, and add another, if you wish',
    spotify: 'Enter a Spotify track, album, playlist, or artist URL, and click "Add", you can then preview the embed using the EYE symbol below, and add another, if you wish',
    soundcloud: 'Enter a SoundCloud track, playlist, or profile URL, and click "Add", you can then preview the embed using the EYE symbol below, and add another, if you wish',
    bandcamp:
      'Enter a Bandcamp URL or embed code. For embed codes, go to the Bandcamp page, click "Share / Embed", and copy the embed code. Then click "Add", you can then preview the embed using the EYE symbol below, and add another, if you wish',
  };

  const handleEmbedChange = (platform: keyof typeof embedInput) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEmbedInput((prev) => ({ ...prev, [platform]: event.target.value }));
  };

  const handleAddEmbed = (platform: keyof typeof embedInput) => async () => {
    if (embedInput[platform]) {
      const embedCode = await getEmbedCode(platform, embedInput[platform]);
      if (embedCode) {
        setFormData((prevData) => ({
          ...prevData,
          [`${platform}Embeds`]: [
            ...(prevData[`${platform}Embeds` as keyof BandRecommendationFormData] as string[]),
            embedCode,
          ],
        }));
        setEmbedInput((prev) => ({ ...prev, [platform]: '' }));
      } else {
        alert('Invalid URL or embed code. Please check and try again.');
      }
    }
  };

  const handleRemoveEmbed = (platform: keyof typeof embedInput, index: number) => () => {
    setFormData((prevData) => ({
      ...prevData,
      [`${platform}Embeds`]: (
        prevData[`${platform}Embeds` as keyof BandRecommendationFormData] as string[]
      ).filter((_, i) => i !== index),
    }));
    // If the previewed embed is deleted, remove the preview
    if (
      previewEmbed &&
      previewEmbed.platform === platform &&
      index ===
        (formData[`${platform}Embeds` as keyof BandRecommendationFormData] as string[]).length - 1
    ) {
      setPreviewEmbed(null);
    }
  };

  const handlePreviewEmbed = (platform: string, embed: string) => {
    setPreviewEmbed({ platform, embed });
  };

  const getEmbedCode = async (platform: string, input: string): Promise<string | null> => {
    switch (platform) {
      case 'youtube':
        return getYouTubeEmbed(input);
      case 'spotify':
        return getSpotifyEmbed(input);
      case 'soundcloud':
        return await getSoundCloudEmbed(input);
      case 'bandcamp':
        return getBandcampEmbed(input);
      default:
        return null;
    }
  };

  const getYouTubeEmbed = (url: string): string | null => {
    const regExp =
      /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    if (match && match[2].length === 11) {
      return `<iframe width="560" height="315" src="https://www.youtube.com/embed/${match[2]}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
    }
    return null;
  };

  const getSpotifyEmbed = (url: string): string | null => {
    try {
      const parsedUrl = new URL(url);
      const pathParts = parsedUrl.pathname.split('/');
      if (pathParts.length >= 3) {
        const contentType = pathParts[1];
        const contentId = pathParts[2];
        const embedUrl = `https://open.spotify.com/embed/${contentType}/${contentId}`;
  
        // Adjust iframe dimensions based on content type
        let width = '100%';
        let height = '380'; // Default height
        switch (contentType) {
          case 'track':
            height = '80';
            break;
          case 'episode':
            height = '232';
            break;
          case 'show':
            height = '232';
            break;
          // Add more cases if needed
        }
  
        return `<iframe src="${embedUrl}" width="${width}" height="${height}" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>`;
      }
    } catch (e) {
      // Invalid URL
      return null;
    }
    return null;
  };

  const getSoundCloudEmbed = async (url: string): Promise<string | null> => {
    try {
      const oEmbedUrl = `https://soundcloud.com/oembed?format=json&url=${encodeURIComponent(
        url
      )}`;
      const response = await fetch(oEmbedUrl);
      const data = await response.json();
      return data.html;
    } catch (error) {
      console.error('Error fetching SoundCloud embed code:', error);
      return null;
    }
  };

  const getBandcampEmbed = (input: string): string | null => {
    // If input contains <iframe, treat it as embed code
    if (input.includes('<iframe')) {
      return input;
    }

    // Otherwise, treat it as a URL and create a clickable link
    if (input.startsWith('http')) {
      return `<a href="${input}" target="_blank" rel="noopener noreferrer">${input}</a>`;
    }

    return null;
  };

  const handleClosePreview = () => {
    setPreviewEmbed(null);
  };

  return (
    <>
      {Object.keys(embedInput).map((platform) => (
        <Box key={platform} sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            {platform.charAt(0).toUpperCase() + platform.slice(1)} Links
            <Tooltip title={tooltips[platform as keyof typeof tooltips]} arrow>
              <HelpOutlineIcon
                fontSize="small"
                sx={{ ml: 1, verticalAlign: 'middle', cursor: 'help' }}
              />
            </Tooltip>
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={8}>
              <TextField
                label={
                  platform === 'bandcamp'
                    ? `${platform.charAt(0).toUpperCase() + platform.slice(1)} URL or Embed Code`
                    : `${platform.charAt(0).toUpperCase() + platform.slice(1)} URL`
                }
                value={embedInput[platform as keyof typeof embedInput]}
                onChange={handleEmbedChange(platform as keyof typeof embedInput)}
                variant="outlined"
                fullWidth
                multiline={platform === 'bandcamp'}
                minRows={platform === 'bandcamp' ? 3 : 1}
                placeholder={
                  platform === 'bandcamp'
                    ? `Paste your Bandcamp URL or embed code here`
                    : `Paste your ${platform} link here`
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
            <AttentionAddButton
  hasInput={Boolean(embedInput[platform as keyof typeof embedInput])}
  onClick={handleAddEmbed(platform as keyof typeof embedInput)}
  fullWidth
/>
            </Grid>
          </Grid>
          <Box sx={{ mt: 2 }}>
            {(formData[`${platform}Embeds` as keyof BandRecommendationFormData] as string[]).map(
              (embed, index) => (
                <Chip
                  key={index}
                  label={`${platform.charAt(0).toUpperCase() + platform.slice(1)} ${index + 1}`}
                  onDelete={handleRemoveEmbed(platform as keyof typeof embedInput, index)}
                  sx={{ m: 0.5 }}
                  color="secondary"
                  deleteIcon={<DeleteIcon />}
                  icon={
                    <IconButton
                      size="small"
                      onClick={() => handlePreviewEmbed(platform, embed)}
                    >
                      <PreviewIcon fontSize="small" />
                    </IconButton>
                  }
                />
              )
            )}
          </Box>
        </Box>
      ))}
      {previewEmbed && (
        <Card sx={{ mt: 4, position: 'relative' }}>
          <IconButton
            aria-label="close"
            onClick={handleClosePreview}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Preview: {previewEmbed.platform.charAt(0).toUpperCase() + previewEmbed.platform.slice(1)}{' '}
              Embed
            </Typography>
            <Box
              sx={{ mt: 2 }}
              dangerouslySetInnerHTML={{ __html: previewEmbed.embed }}
            />
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default MediaLinks;