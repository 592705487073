// src/constants/imcCodes.ts

export const IMC_INCOME_CODES = {
  GRANTS_ARTS_COUNCIL: '2',
  IMC_WALK_UP_SALES: '20',
  IMC_TICKET_SALES: '21',
  IMC_TICKET_AGENTS_VENUES: '23',
  IMC_TICKET_AGENTS_EVENTBRITE: '24',
  SERVICES_PROGRAMME_PRODUCTION_FEES: '26',
  CD_SALES_MERCHANDISE_OTHER_INCOME: '30',
  SERVICES_EQUIPMENT_HIRE_OTHER_INCOME: '31',
  MASTERCLASS_SERVICES_OTHER_INCOME: '33',
  TOURING_ARTISTS_SALE_COMMISSION: '37',
  GRANTS_OTHER_ARTS_COUNCIL_FUNDING: '5',
  GRANTS_LOCAL_AUTHORITY_FUNDING: '7',
  GRANTS_OTHER_NATIONAL_FUNDING: '6',
  GRANTS_SPONSORSHIP_CULTURAL_AGENCY_DOMESTIC: '8',
  GRANTS_SPONSORSHIP_CULTURAL_AGENCY_INTERNATIONAL: '9',
  SPONSORSHIP_CORPORATE: '10',
  SPONSORSHIP_IN_KIND: '11',
  INCOME_FROM_EXPENSES: '25',
  TOURING_CONCERTS_PROMOTERS_GUARANTEES: '34',
  ONCE_OFF_PROJECTS_PROMOTERS_GUARANTEES: '27',
  FUNDRAISING_DEVELOPMENT_INCOME: '13',
  FUNDRAISED_DONATIONS_FRIENDS_SCHEME: '29'
};

export const IMC_EXPENSE_CODES = {
  ARTISTS_FEES_INTERNATIONAL_JAZZ: '110',
  ARTISTS_FEES_INTERNATIONAL_OTHER: '111',
  ARTISTS_FEES_INTERNATIONAL_COLLABORATION: '112',
  VAT_ON_ARTISTS_FEES: '113',
  ARTISTS_FEES_DOMESTIC_JAZZ: '114',
  ARTISTS_FEES_DOMESTIC_OTHER: '115',
  DOMESTIC_ARTISTS_ENGAGEMENT: '154',
  ARTISTS_DEVELOPMENT_PROJECT_SUBVENTION: '160',
  PRODUCTION_FRONT_OF_HOUSE_CREW: '127',
  PRODUCTION_PA_ENGINEERS: '131',
  PRODUCTION_LIGHTING_ENGINEERS: '134',
  PRODUCTION_PHOTOGRAPHY_VIDEOGRAPHY_PERSONNEL: '149',
  RECORDINGS_EXPENSES_ARTISTS_RECORDING_FEES: '150',
  RECORDING_EXPENSES_RECORDING_TECHNICIANS: '152',
  RECORDING_EXPENSES_DESIGN: '153',
  PRODUCTION_STAGE_CREW: '135',
  PRODUCTION_SPECIALIST_CREW: '147',
  PRODUCTION_EMP_SAFETY_OFFICERS: '142',
  PRODUCTION_OTHER_CREW: '126',
  ARTISTS_INTERNATIONAL_TRAVEL: '116',
  ARTISTS_LOCAL_TRANSPORT: '117',
  ARTISTS_ACCOMMODATION: '118',
  ARTISTS_CATERING_HOSPITALITY: '119',
  ARTISTS_PER_DIEMS: '120',
  ARTISTS_MERCHANDISE: '122',
  SUNDRY_PRODUCTION_EXPENSES_CONTINGENCIES: '141',
  VENUE_HIRE: '143',
  RECORDING_EXPENSES_STUDIO_RENTAL: '151',
  PRODUCTION_PIANO_HIRE: '128',
  PRODUCTION_BACKLINE_HIRE: '129',
  PRODUCTION_PA_HIRE: '130',
  PRODUCTION_BACKLINE_TRANSPORT: '148',
  PRODUCTION_EQUIPMENT_HIRE: '132',
  PRODUCTION_LIGHTING_HIRE: '133',
  PRODUCTION_PROPS_STAGING: '136',
  PRODUCTION_EQUIPMENT_PURCHASES: '161',
  ADVERTISING_WEBSITE_DESIGN_EXPENSES: '165',
  ADVERTISING_WEBSITE_MAINTENANCE: '166',
  ADVERTISING_WEBSITE_HOSTING_DOMAINS: '167',
  MARKETING_POSTER_ADVERTISING: '169',
  MARKETING_DESIGN: '170',
  MARKETING_PRINT_MATERIALS: '171',
  MARKETING_POSTER_FLYER_DISTRIBUTION: '172',
  MARKETING_PHOTOGRAPHY: '173',
  MARKETING_VIDEOGRAPHY: '174',
  MARKETING_PUBLICATIONS: '175',
  MARKETING_PR_ADVERTISING: '176',
  MARKETING_ONLINE_ADVERTISING: '177',
  MARKETING_MERCHANDISE: '178',
  MARKETING_DIGITAL_NEWS_LETTER: '179',
  MARKETING_SUBSCRIPTIONS: '187',
  MARKETING_CATERING_HOSPITALITY: '146',
  TICKET_AGENTS_COMMISSION: '144',
  ARTISTS_VISAS_WORK_PERMITS: '121',
  PRODUCTION_STAFF_TRAVEL: '137',
  PRODUCTION_STAFF_SUBSISTENCE_PER_DIEMS: '138',
  PRODUCTION_STAFF_ACCOMMODATION: '139',
  PRODUCTION_COURIER_POSTAGE: '140',
  IMRO_PUBLIC_LIABILITY_INSURANCE: '145',
  OTHER_EXPENSES_NATIONAL_CAMPAIGN_FOR_THE_ARTS: '181',
  OTHER_EXPENSES_RESEARCH: '182',
  OTHER_EXPENSES_SPONSORSHIP_FUNDRAISING_EXPENSES: '183',
  OTHER_EXPENSES_INSTRUMENT_BACKLINE_MAINTENANCE: '184',
  EVENT_COLLABORATION_REIMBURSEMENTS: '125',
  OTHER_EXPENSES_IN_KIND_CONTRA: '180'
};

export const getIMCCodeLabel = (code: string): string => {
  const allCodes = { ...IMC_INCOME_CODES, ...IMC_EXPENSE_CODES };
  return Object.entries(allCodes).find(([, value]) => value === code)?.[0] || 'Unknown';
};

export const getIMCCodeOptions = (): { value: string; label: string }[] => {
  const allCodes = { ...IMC_INCOME_CODES, ...IMC_EXPENSE_CODES };
  return Object.entries(allCodes).map(([key, value]) => ({
    value,
    label: `${value} - ${key.replace(/_/g, ' ').toLowerCase()}`
  }));
};