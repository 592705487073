// src/pages/PersonnelDetailPage.tsx
import React from 'react';
import { useParams } from 'react-router-dom';
import PersonnelDetail from '../components/PersonnelDetail';

const PersonnelDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  return (
    <div>
      <h1>Personnel Details</h1>
      <PersonnelDetail id={id!} />
    </div>
  );
};
export default PersonnelDetailPage;
