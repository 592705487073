import React, { useEffect, useState } from 'react';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  SelectChangeEvent,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';

import { BandRecommendationFormData, EuropeanCountry, IrishCounty } from '../../types/BandRecommendations';

interface RecommenderInfoProps {
  formData: BandRecommendationFormData;
  setFormData: React.Dispatch<React.SetStateAction<BandRecommendationFormData>>;
}

interface Strand {
  id: string;
  name: string;
  slug: string;
}

const RecommenderInfo: React.FC<RecommenderInfoProps> = ({ formData, setFormData }) => {
  const [strands, setStrands] = useState<Strand[]>([]);
  const [loadingStrands, setLoadingStrands] = useState<boolean>(true);
  const [strandsError, setStrandsError] = useState<string | null>(null);
  const { user } = useAuth();

  useEffect(() => {
    const fetchStrands = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/strands`);
        setStrands(response.data);
        setLoadingStrands(false);
      } catch (error) {
        console.error('Error fetching strands:', error);
        setStrandsError('Failed to load strands.');
        setLoadingStrands(false);
      }
    };

    fetchStrands();
  }, []);

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: value,
      };

      if (updatedData.isRecommenderApplicant) {
        if (name === 'recommenderName') {
          updatedData.contactName = value;
        } else if (name === 'recommenderEmail') {
          updatedData.contactEmail = value;
        }
      }

      return updatedData;
    });
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      ...(name === 'recommenderCountry' && value !== 'Ireland' && { county: '' }),
    }));
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      isRecommenderApplicant: checked,
      ...(checked && {
        contactName: prevData.recommenderName,
        contactEmail: prevData.recommenderEmail,
      }),
    }));
  };

  // Determine if the strand dropdown should be shown
  const showStrandDropdown = !user || user.role !== 'artist';

  return (
    <Grid container spacing={2}>
      {showStrandDropdown && (
        <Grid item xs={12}>
          {loadingStrands ? (
            <Typography>Loading strands...</Typography>
          ) : strandsError ? (
            <Typography color="error">{strandsError}</Typography>
          ) : (
            <FormControl required fullWidth variant="outlined">
              <InputLabel id="strand-label">Select Strand</InputLabel>
              <Select
                labelId="strand-label"
                name="selectedStrand"
                value={formData.selectedStrand || ''}
                onChange={handleSelectChange}
                label="Select Strand"
              >
                {strands.map((strand) => (
                  <MenuItem key={strand.id} value={strand.slug}>
                    {strand.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <Tooltip title="Please enter your full name.">
          <TextField
            required
            name="recommenderName"
            label="Your Name"
            value={formData.recommenderName || ''}
            onChange={handleTextFieldChange}
            variant="outlined"
            fullWidth
          />
        </Tooltip>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Tooltip title="We'll use this email to contact you if needed.">
          <TextField
            required
            name="recommenderEmail"
            label="Your Email"
            type="email"
            value={formData.recommenderEmail || ''}
            onChange={handleTextFieldChange}
            variant="outlined"
            fullWidth
          />
        </Tooltip>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Tooltip title="Select your country from the list.">
          <FormControl required fullWidth variant="outlined">
            <InputLabel id="country-label">Country</InputLabel>
            <Select
              labelId="country-label"
              name="recommenderCountry"
              value={formData.recommenderCountry || ''}
              onChange={handleSelectChange}
              label="Country"
            >
              {Object.values(EuropeanCountry).map((country) => (
                <MenuItem key={country} value={country}>
                  {country}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Tooltip>
      </Grid>
      {formData.recommenderCountry === 'Ireland' && (
        <Grid item xs={12} sm={6}>
          <Tooltip title="Select your county.">
            <FormControl required fullWidth variant="outlined">
              <InputLabel id="county-label">County</InputLabel>
              <Select
                labelId="county-label"
                name="county"
                value={formData.county || ''}
                onChange={handleSelectChange}
                label="County"
              >
                {Object.values(IrishCounty).map((county) => (
                  <MenuItem key={county} value={county}>
                    {county}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Tooltip>
        </Grid>
      )}
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.isRecommenderApplicant || false}
              onChange={handleCheckboxChange}
              name="isRecommenderApplicant"
            />
          }
          label="I am also the applicant"
        />
      </Grid>
    </Grid>
  );
};

export default RecommenderInfo;