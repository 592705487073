import React from 'react';
import { Box } from '@mui/material';
import StageDesigner from '../components/artists/StageDesigner';
import CollapsibleNav from '../components/CollapsibleNav';

const StageDesignerPage: React.FC = () => {
  return (
    <Box sx={{ 
      position: 'relative', // Ensure proper stacking context
      width: '100vw',
      height: '100vh',
    }}>
      {/* Place CollapsibleNav before StageDesigner */}
      <CollapsibleNav />
      <Box sx={{ 
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}>
        <StageDesigner />
      </Box>
    </Box>
  );
};

export default StageDesignerPage;