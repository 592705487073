import React, { useState, useCallback, useRef, useEffect } from 'react';
import { 
  DndContext, 
  useDraggable, 
  useDroppable, 
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
  DragStartEvent,
  DragOverlay,
} from '@dnd-kit/core';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Drawer,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  AppBar,
  Toolbar,
  Tooltip,
  Theme,
  useTheme,
  ListSubheader
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Mic as MicIcon,
  MusicNote as MusicNoteIcon,
  Speaker as SpeakerIcon,
  RadioButtonChecked as MonitorIcon,
  GridOn as GridIcon,
  Save as SaveIcon,
  Upload as LoadIcon,
  Album as DrumIcon,
  PianoOutlined as PianoIcon,
  RotateLeft as RotateIcon,
  Delete as DeleteIcon,
  PowerInput as PowerIcon,
  TableRestaurantRounded as TableIcon,
  Power as Power1Icon,
  Expand as ExpandIcon,
  Laptop as LaptopIcon,
  Memory as MemoryIcon,
  MenuBook as MenuBookIcon,
  Height as HeightIcon,
  Tune as TuneIcon,
  DeveloperBoard as DeveloperBoardIcon,
  ViewAgenda as ViewAgendaIcon,
  Weekend as WeekendIcon,
  Chair as ChairIcon,
  LinearScale as LinearScaleIcon,
  AllInbox as AllInboxIcon,
  Article as CarpetIcon,
  Usb as UsbIcon,
  Headphones as HeadphonesIcon,
  TrendingUp as TrendingUpIcon,
  Router as RouterIcon,
  Tablet as TabletIcon,
  Link as LinkIcon,
} from '@mui/icons-material';
import SizedStageElement, { 
    sizeCategoryMap, 
    getSizeCategory, 
  } from './SizedStageElement';

// Types
interface StageElement {
  id: string;
  name: string;
  icon: JSX.Element;
  type: string;
}

interface PlacedElement extends StageElement {
  x: number;
  y: number;
  rotation: number;
  instanceId: string;
}

// First, define the updated stageElements constant with subcategories
const stageElements: {
  [category: string]: {
    [subcategory: string]: StageElement[];
  };
} = {
  microphones: {
    '': [
      { id: 'vocal-mic', name: 'Vocal Mic', icon: <MicIcon />, type: 'microphone' },
      { id: 'instrument-mic', name: 'Instrument Mic', icon: <MicIcon />, type: 'microphone' },
      { id: 'di-box', name: 'DI Box', icon: <MicIcon />, type: 'microphone' },
      // ... other microphones
    ],
  },
  instruments: {
    'Rhythm Section': [
      { id: 'acoustic-piano', name: 'Acoustic Piano', icon: <PianoIcon />, type: 'instrument' },
      { id: 'electric-piano', name: 'Electric Piano', icon: <PianoIcon />, type: 'instrument' },
      { id: 'organ', name: 'Organ', icon: <PianoIcon />, type: 'instrument' },
      { id: 'rhodes', name: 'Rhodes', icon: <PianoIcon />, type: 'instrument' },
      { id: 'acoustic-guitar', name: 'Acoustic Guitar', icon: <MusicNoteIcon />, type: 'instrument' },
      { id: 'electric-guitar', name: 'Electric Guitar', icon: <MusicNoteIcon />, type: 'instrument' },
      { id: 'acoustic-bass', name: 'Acoustic Bass', icon: <MusicNoteIcon />, type: 'instrument' },
      { id: 'electric-bass', name: 'Electric Bass', icon: <MusicNoteIcon />, type: 'instrument' },
      { id: 'drum-kit', name: 'Drum Kit', icon: <DrumIcon />, type: 'instrument' },
    ],
    'Brass Section': [
      { id: 'trumpet', name: 'Trumpet', icon: <MusicNoteIcon />, type: 'instrument' },
      { id: 'flugelhorn', name: 'Flugelhorn', icon: <MusicNoteIcon />, type: 'instrument' },
      { id: 'cornet', name: 'Cornet', icon: <MusicNoteIcon />, type: 'instrument' },
      { id: 'trombone', name: 'Trombone', icon: <MusicNoteIcon />, type: 'instrument' },
      { id: 'bass-trombone', name: 'Bass Trombone', icon: <MusicNoteIcon />, type: 'instrument' },
      { id: 'french-horn', name: 'French Horn', icon: <MusicNoteIcon />, type: 'instrument' },
      { id: 'tuba', name: 'Tuba', icon: <MusicNoteIcon />, type: 'instrument' },
    ],
    'Reed Section': [
      { id: 'alto-sax', name: 'Alto Saxophone', icon: <MusicNoteIcon />, type: 'instrument' },
      { id: 'tenor-sax', name: 'Tenor Saxophone', icon: <MusicNoteIcon />, type: 'instrument' },
      { id: 'baritone-sax', name: 'Baritone Saxophone', icon: <MusicNoteIcon />, type: 'instrument' },
      { id: 'soprano-sax', name: 'Soprano Saxophone', icon: <MusicNoteIcon />, type: 'instrument' },
      { id: 'clarinet', name: 'Clarinet', icon: <MusicNoteIcon />, type: 'instrument' },
      { id: 'bass-clarinet', name: 'Bass Clarinet', icon: <MusicNoteIcon />, type: 'instrument' },
      { id: 'flute', name: 'Flute', icon: <MusicNoteIcon />, type: 'instrument' },
    ],
    Strings: [
      { id: 'violin', name: 'Violin', icon: <MusicNoteIcon />, type: 'instrument' },
      { id: 'viola', name: 'Viola', icon: <MusicNoteIcon />, type: 'instrument' },
      { id: 'cello', name: 'Cello', icon: <MusicNoteIcon />, type: 'instrument' },
      { id: 'double-bass', name: 'Double Bass', icon: <MusicNoteIcon />, type: 'instrument' },
    ],
    'Percussion/Mallet': [
      { id: 'vibraphone', name: 'Vibraphone', icon: <MusicNoteIcon />, type: 'instrument' },
      { id: 'marimba', name: 'Marimba', icon: <MusicNoteIcon />, type: 'instrument' },
      { id: 'xylophone', name: 'Xylophone', icon: <MusicNoteIcon />, type: 'instrument' },
      { id: 'congas', name: 'Congas', icon: <DrumIcon />, type: 'instrument' },
      { id: 'bongos', name: 'Bongos', icon: <DrumIcon />, type: 'instrument' },
      { id: 'timbales', name: 'Timbales', icon: <DrumIcon />, type: 'instrument' },
      { id: 'percussion', name: 'Percussion', icon: <DrumIcon />, type: 'instrument' },
    ],
    'Modern/Electronic': [
      { id: 'synthesizer', name: 'Synthesizer', icon: <PianoIcon />, type: 'instrument' },
      { id: 'sampler', name: 'Sampler', icon: <PianoIcon />, type: 'instrument' },
      { id: 'electronic-wind', name: 'EWI', icon: <MusicNoteIcon />, type: 'instrument' },
    ],
    Vocal: [
      { id: 'vocals', name: 'Vocals', icon: <MusicNoteIcon />, type: 'instrument' },
    ],
  },
  equipment: {
    Amplification: [
      { id: 'bass-amp', name: 'Bass Amplifier', icon: <SpeakerIcon />, type: 'equipment' },
      { id: 'guitar-amp', name: 'Guitar Amplifier', icon: <SpeakerIcon />, type: 'equipment' },
      { id: 'keyboard-amp', name: 'Keyboard Amplifier', icon: <SpeakerIcon />, type: 'equipment' },
      { id: 'pa-speaker-l', name: 'PA Speaker Left', icon: <SpeakerIcon />, type: 'equipment' },
      { id: 'pa-speaker-r', name: 'PA Speaker Right', icon: <SpeakerIcon />, type: 'equipment' },
      { id: 'subwoofer', name: 'Subwoofer', icon: <SpeakerIcon />, type: 'equipment' },
    ],
    Monitoring: [
      { id: 'floor-monitor', name: 'Floor Monitor', icon: <MonitorIcon />, type: 'equipment' },
      { id: 'side-fill-l', name: 'Side Fill Left', icon: <MonitorIcon />, type: 'equipment' },
      { id: 'side-fill-r', name: 'Side Fill Right', icon: <MonitorIcon />, type: 'equipment' },
      { id: 'drum-monitor', name: 'Drum Monitor', icon: <MonitorIcon />, type: 'equipment' },
      { id: 'in-ear-system', name: 'In-Ear System', icon: <HeadphonesIcon />, type: 'equipment' },
    ],
    Stands: [
      { id: 'music-stand', name: 'Music Stand', icon: <MenuBookIcon />, type: 'equipment' },
      { id: 'keyboard-stand-x', name: 'Keyboard Stand (X)', icon: <ExpandIcon />, type: 'equipment' },
      { id: 'keyboard-stand-z', name: 'Keyboard Stand (Z)', icon: <ExpandIcon />, type: 'equipment' },
      { id: 'guitar-stand', name: 'Guitar Stand', icon: <ExpandIcon />, type: 'equipment' },
      { id: 'mic-stand-straight', name: 'Mic Stand (Straight)', icon: <HeightIcon />, type: 'equipment' },
      { id: 'mic-stand-boom', name: 'Mic Stand (Boom)', icon: <TrendingUpIcon />, type: 'equipment' },
      { id: 'mic-stand-short', name: 'Mic Stand (Short)', icon: <HeightIcon />, type: 'equipment' },
      { id: 'amp-stand', name: 'Amp Stand', icon: <ExpandIcon />, type: 'equipment' },
    ],
    Power: [
      { id: 'power-drop', name: 'Power Drop', icon: <PowerIcon />, type: 'equipment' },
      { id: 'power-strip', name: 'Power Strip', icon: <PowerIcon />, type: 'equipment' },
      { id: 'power-distro', name: 'Power Distribution', icon: <PowerIcon />, type: 'equipment' },
      { id: 'transformer', name: 'Transformer', icon: <PowerIcon />, type: 'equipment' },
    ],
    Mixing: [
      { id: 'mixer', name: 'Mixing Console', icon: <TuneIcon />, type: 'equipment' },
      { id: 'digital-stage-box', name: 'Digital Stage Box', icon: <RouterIcon />, type: 'equipment' },
      { id: 'monitor-mixer', name: 'Monitor Mixer', icon: <TuneIcon />, type: 'equipment' },
    ],
    Effects: [
      { id: 'effects-rack', name: 'Effects Rack', icon: <DeveloperBoardIcon />, type: 'equipment' },
      { id: 'di-box-active', name: 'DI Box (Active)', icon: <MemoryIcon />, type: 'equipment' },
      { id: 'di-box-passive', name: 'DI Box (Passive)', icon: <MemoryIcon />, type: 'equipment' },
    ],
    Furniture: [
      { id: 'table-small', name: 'Small Table', icon: <TableIcon />, type: 'equipment' },
      { id: 'table-large', name: 'Large Table', icon: <TableIcon />, type: 'equipment' },
      { id: 'drum-riser', name: 'Drum Riser', icon: <ViewAgendaIcon />, type: 'equipment' },
      { id: 'piano-bench', name: 'Piano Bench', icon: <WeekendIcon />, type: 'equipment' },
      { id: 'drum-throne', name: 'Drum Throne', icon: <WeekendIcon />, type: 'equipment' },
      { id: 'chair', name: 'Chair', icon: <ChairIcon />, type: 'equipment' },
      { id: 'bar-stool', name: 'Bar Stool', icon: <ChairIcon />, type: 'equipment' },
    ],
    Cables: [
      { id: 'xlr-cable', name: 'XLR Cable', icon: <LinearScaleIcon />, type: 'equipment' },
      { id: 'instrument-cable', name: 'Instrument Cable', icon: <LinearScaleIcon />, type: 'equipment' },
      { id: 'speaker-cable', name: 'Speaker Cable', icon: <LinearScaleIcon />, type: 'equipment' },
      { id: 'power-cable', name: 'Power Cable', icon: <LinearScaleIcon />, type: 'equipment' },
      { id: 'multi-core', name: 'Multi-core Cable', icon: <LinearScaleIcon />, type: 'equipment' },
    ],
    Technology: [
      { id: 'laptop', name: 'Laptop', icon: <LaptopIcon />, type: 'equipment' },
      { id: 'tablet', name: 'Tablet', icon: <TabletIcon />, type: 'equipment' },
      { id: 'interface', name: 'Audio Interface', icon: <UsbIcon />, type: 'equipment' },
      { id: 'midi-controller', name: 'MIDI Controller', icon: <PianoIcon />, type: 'equipment' },
    ],
    Accessories: [
      { id: 'drum-mat', name: 'Drum Mat', icon: <CarpetIcon />, type: 'equipment' },
      { id: 'cable-ramp', name: 'Cable Ramp', icon: <LinearScaleIcon />, type: 'equipment' },
      { id: 'cable-ties', name: 'Cable Ties', icon: <LinkIcon />, type: 'equipment' },
      { id: 'gaffer-tape', name: 'Gaffer Tape', icon: <AllInboxIcon />, type: 'equipment' },
    ],
  },
};

// Then, define the updated StageElementsDrawer component
const StageElementsDrawer: React.FC<{
  drawerWidth: number;
}> = ({ drawerWidth }) => {
  const [expandedCategory, setExpandedCategory] = useState<string | false>('instruments');

  const handleAccordionChange = (category: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpandedCategory(isExpanded ? category : false);
  };

  const getCategoryColor = (category: string) => {
    switch (category) {
      case 'microphones':
        return '#e91e63'; // pink
      case 'instruments':
        return '#2196f3'; // blue
      case 'equipment':
        return '#ff9800'; // orange
      default:
        return 'text.primary';
    }
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: 'background.default',
        },
      }}
    >
      <Toolbar />
      <Box
        sx={{
          overflow: 'auto',
          '& .MuiAccordion-root': {
            '&:before': {
              display: 'none',
            },
            boxShadow: 'none',
          },
        }}
      >
        {Object.entries(stageElements).map(([category, subcategories]) => (
          <Accordion
            key={category}
            expanded={expandedCategory === category}
            onChange={handleAccordionChange(category)}
            sx={{
              backgroundColor: 'transparent',
              '&.Mui-expanded': {
                margin: 0,
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                borderLeft: 3,
                borderColor: getCategoryColor(category),
                '&.Mui-expanded': {
                  minHeight: 48,
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
                '& .MuiAccordionSummary-content.Mui-expanded': {
                  margin: '12px 0',
                },
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 500,
                  color: getCategoryColor(category),
                  textTransform: 'capitalize',
                }}
              >
                {category}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 1 }}>
              {Object.entries(subcategories).map(([subcategory, elements]) => (
                <React.Fragment key={subcategory}>
                  {subcategory && (
                    <ListSubheader
                      sx={{
                        backgroundColor: 'inherit',
                        color: 'text.secondary',
                        textTransform: 'capitalize',
                        lineHeight: '24px',
                        paddingLeft: 2,
                        paddingRight: 2,
                      }}
                    >
                      {subcategory}
                    </ListSubheader>
                  )}
                  <List disablePadding>
                    {elements.map((element) => (
                      <DraggableElement key={element.id} element={element} />
                    ))}
                  </List>
                </React.Fragment>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Drawer>
  );
};

// Draggable Element Component
const DraggableElement: React.FC<{ element: StageElement }> = ({ element }) => {
    const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
      id: element.id,
      data: { ...element, type: 'stageElement' },
    });
  
    return (
      <ListItem
        ref={setNodeRef}
        {...listeners}
        {...attributes}
        sx={{
          cursor: 'grab',
          opacity: isDragging ? 0.5 : 1,
          '&:hover': {
            backgroundColor: 'action.hover',
          },
          padding: '4px 8px',
          height: 40,
          borderRadius: 1
        }}
      >
        <ListItemIcon sx={{ minWidth: 36 }}>
          {React.cloneElement(element.icon, {
            fontSize: 'small',
            color: element.type === 'microphone' ? 'error' :
                   element.type === 'instrument' ? 'primary' :
                   'warning'
          })}
        </ListItemIcon>
        <ListItemText 
          primary={element.name}
          primaryTypographyProps={{
            variant: 'body2',
            sx: { fontWeight: 500 }
          }}
        />
      </ListItem>
    );
  };

  const PlacedElement: React.FC<{
    element: PlacedElement;
    onRotate: () => void;
    onDelete: () => void;
  }> = ({ element, onRotate, onDelete }) => {
    const [isSelected, setIsSelected] = useState(false);
    
    const { attributes, listeners, setNodeRef, isDragging, transform } = useDraggable({
      id: element.instanceId,
      data: { ...element, type: 'placedElement', instanceId: element.instanceId },
    });
  
    const style = {
      position: 'absolute',
      left: transform ? element.x + transform.x : element.x,
      top: transform ? element.y + transform.y : element.y,
      transform: `rotate(${element.rotation}deg)`,
      cursor: 'grab',
      touchAction: 'none',
      border: isSelected ? '2px solid #1976d2' : 'none',
      borderRadius: 1,
      boxShadow: isSelected ? 4 : isDragging ? 3 : 1,
      '&:hover': {
        '& .element-controls': {
          opacity: 1
        }
      }
    };
  
    return (
      <Box
        ref={setNodeRef}
        {...listeners}
        {...attributes}
        onClick={() => setIsSelected(!isSelected)}
        sx={style}
      >
        <Box 
          className="element-controls"
          sx={{
            display: 'flex',
            gap: 0.5,
            position: 'absolute',
            top: -20,
            left: '50%',
            transform: 'translateX(-50%)',
            opacity: isSelected ? 1 : 0,
            transition: 'opacity 0.2s',
            backgroundColor: 'background.paper',
            borderRadius: '4px',
            padding: '2px',
            boxShadow: 1,
            zIndex: 1
          }}
        >
          <IconButton size="small" onClick={(e) => { e.stopPropagation(); onRotate(); }}>
            <RotateIcon fontSize="small" />
          </IconButton>
          <IconButton size="small" onClick={(e) => { e.stopPropagation(); onDelete(); }}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
        <SizedStageElement element={element} isDragging={isDragging} />
      </Box>
    );
  };

  // Define the audience pattern as a data URI
const audiencePattern = `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(`
  <svg width="120" height="60" viewBox="0 0 10 25" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="30" r="20" fill="%23212121" />
  </svg>
  `)}`;
  

  const StageArea: React.FC<{
    showGrid: boolean;
    placedElements: PlacedElement[];
    onRotate: (id: string) => void;
    onDelete: (id: string) => void;
    gridSize: number;
  }> = ({ showGrid, placedElements, onRotate, onDelete, gridSize }) => {
    const { setNodeRef, isOver } = useDroppable({
      id: 'stage-area',
    });
  
    return (
      <Paper
        ref={setNodeRef}
        sx={{
          mt: 2,
          height: 'calc(100vh - 148px)',
          position: 'relative',
          backgroundColor: '#fff',
          backgroundImage: showGrid
            ? `linear-gradient(to right, #f0f0f0 1px, transparent 1px), 
               linear-gradient(to bottom, #f0f0f0 1px, transparent 1px)`
            : 'none',
          backgroundSize: `${gridSize}px ${gridSize}px`,
          overflow: 'hidden',
          border: '1px solid rgba(0, 0, 0, 0.12)',
          borderColor: isOver ? 'primary.main' : 'rgba(0, 0, 0, 0.12)',
        }}
      >
        {placedElements.map((element) => (
          <PlacedElement
            key={element.instanceId}
            element={element}
            onRotate={() => onRotate(element.instanceId)}
            onDelete={() => onDelete(element.instanceId)}
          />
        ))}
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '80px', // Adjust the height to your preference
            backgroundImage: `url(${audiencePattern})`,
            backgroundRepeat: 'repeat-x',
            backgroundPosition: 'bottom',
            backgroundSize: 'auto 100%',
            zIndex: 2,
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'center',
            pb: 1, // Padding bottom for text positioning
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontWeight: 1000,
              letterSpacing: 0.9,
              textTransform: 'uppercase',
              fontSize: '1.2rem',
              color: 'yellow',
              textShadow: '1px 1px 2px rgba(0,0,0,0.9)',
              userSelect: 'none',
            }}
          >
            Front of Stage (Audience)
          </Typography>
        </Box>
      </Paper>
    );
  };

  const StageDesigner: React.FC = () => {
    const [showGrid, setShowGrid] = useState(true);
    const [placedElements, setPlacedElements] = useState<PlacedElement[]>([]);
    const [dragOffset, setDragOffset] = useState<{ x: number; y: number } | null>(null);
    const [activeDragItem, setActiveDragItem] = useState<StageElement | null>(null);
    const [gridSize, setGridSize] = useState(15); // Grid size in pixels
    const drawerWidth = 240;
    const stageRef = useRef<HTMLDivElement>(null);
    const theme = useTheme();

  
    const sensors = useSensors(
      useSensor(PointerSensor, {
        activationConstraint: {
          distance: 5,
        },
      })
    );


  const { setNodeRef: dropRef } = useDroppable({
    id: 'stage-area',
  });

  const snapToGrid = useCallback((value: number): number => {
    return Math.floor(value / gridSize) * gridSize;
  }, [gridSize]);

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    const draggedItem = active.data.current as StageElement;
    setActiveDragItem(draggedItem);
    
    if (active.rect.current?.initial) {
      const rect = active.rect.current.initial;
      const offset = {
        x: rect.left,  // Use left instead of x
        y: rect.top,   // Use top instead of y
      };
      setDragOffset(offset);
    }
  };

  const handleDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event;
    setActiveDragItem(null);
  
    if (!over || over.id !== 'stage-area' || !stageRef.current) return;
  
    const stageRect = stageRef.current.getBoundingClientRect();
    const activeRect = active.rect.current.translated;
  
    if (!activeRect) return;
  
    // Calculate raw position relative to stage
    const rawX = (activeRect.left - stageRect.left) +70;
    const rawY = (activeRect.top - stageRect.top) - 70; // Compensate for the offset
  
    // Snap to grid
    const x = Math.max(0, Math.min(snapToGrid(rawX), stageRect.width - activeRect.width));
    const y = Math.max(0, Math.min(snapToGrid(rawY), stageRect.height - activeRect.height));
  
    if (active.data.current?.type === 'stageElement') {
      const element = active.data.current;
      const newElement: PlacedElement = {
        id: element.id,
        name: element.name,
        icon: element.icon,
        type: element.type,
        x,
        y,
        rotation: 0,
        instanceId: `${element.id}-${Date.now()}`,
      };
      setPlacedElements(prev => [...prev, newElement]);
    } else if (active.data.current?.type === 'placedElement') {
      const instanceId = active.data.current.instanceId;
      setPlacedElements(prev =>
        prev.map(element =>
          element.instanceId === instanceId ? { ...element, x, y } : element
        )
      );
    }
  }, [gridSize]);


  // Add keyboard movement support
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!document.activeElement || document.activeElement.tagName === 'INPUT') return;

      const selectedElement = document.querySelector('[data-selected="true"]');
      if (!selectedElement) return;

      const instanceId = selectedElement.getAttribute('data-instance-id');
      if (!instanceId) return;

      const moveAmount = e.shiftKey ? 1 : gridSize;

      setPlacedElements(prev => prev.map(element => {
        if (element.instanceId !== instanceId) return element;

        let newX = element.x;
        let newY = element.y;

        switch (e.key) {
          case 'ArrowLeft':
            newX = Math.max(0, element.x - moveAmount);
            break;
          case 'ArrowRight':
            newX = Math.min(stageRef.current?.clientWidth ?? 0, element.x + moveAmount);
            break;
          case 'ArrowUp':
            newY = Math.max(0, element.y - moveAmount);
            break;
          case 'ArrowDown':
            newY = Math.min(stageRef.current?.clientHeight ?? 0, element.y + moveAmount);
            break;
        }

        return { ...element, x: newX, y: newY };
      }));
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [gridSize]);


  const handleRotate = useCallback((instanceId: string) => {
    setPlacedElements(prev =>
      prev.map(element =>
        element.instanceId === instanceId
          ? { ...element, rotation: (element.rotation + 45) % 360 }
          : element
      )
    );
  }, []);

  const handleDelete = useCallback((instanceId: string) => {
    setPlacedElements(prev =>
      prev.filter(element => element.instanceId !== instanceId)
    );
  }, []);

  const handleSave = useCallback(() => {
    localStorage.setItem('stagePlot', JSON.stringify(placedElements));
  }, [placedElements]);

  const handleLoad = useCallback(() => {
    const data = localStorage.getItem('stagePlot');
    if (data) {
      setPlacedElements(JSON.parse(data));
    }
  }, []);

  return (
    <DndContext 
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <Box sx={{ display: 'flex', height: '100vh' }}>
          <StageElementsDrawer drawerWidth={drawerWidth} />
  
        <Box component="main" sx={{ flexGrow: 1, p: 3 }} ref={stageRef}>
          <AppBar
            position="fixed"
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          >
            <Toolbar>
            <Typography 
  variant="h6" 
  noWrap 
  component="div" 
  sx={{ 
    flexGrow: 1,
    ml: 8, // Adds margin-left equivalent to 64px (8 * 8px = 64px)
    // Or if you want to be more precise:
    marginLeft: '64px', // Exactly 64px margin
    // If you need more space:
    // ml: 10, // for 80px
    // Or use padding instead:
    // pl: 8, // padding-left
  }}
>
  Stage Plot Designer
</Typography>
              <Tooltip title="Toggle Grid">
                <IconButton color="inherit" onClick={() => setShowGrid(!showGrid)}>
                  <GridIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Save">
                <IconButton color="inherit" onClick={handleSave}>
                  <SaveIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Load">
                <IconButton color="inherit" onClick={handleLoad}>
                  <LoadIcon />
                </IconButton>
              </Tooltip>
            </Toolbar>
          </AppBar>
          <Toolbar />
  
          <StageArea
            showGrid={showGrid}
            placedElements={placedElements}
            onRotate={handleRotate}
            onDelete={handleDelete}
            gridSize={gridSize}
          />
        </Box>
      </Box>
  
      <DragOverlay>
        {activeDragItem && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: 'white',
              padding: 1,
              borderRadius: 1,
              boxShadow: 3,
            }}
          >
            {activeDragItem.icon}
            <Typography variant="caption">{activeDragItem.name}</Typography>
          </Box>
        )}
      </DragOverlay>
    </DndContext>
  );
};

export default StageDesigner;