import React, { useState, useMemo } from 'react';
import { 
  Box, Typography, Card, CardContent, Grid,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  TableSortLabel, Alert
} from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip as RechartsTooltip, Legend, ResponsiveContainer } from 'recharts';

interface TicketType {
  name: string;
  quantity: number;
  quantity_sold: number;
  price: number;
  revenue: number;
}

interface PastEvent {
  id: string;
  title: string;
  start_date: string;
  totalSold: number;
  totalAvailable: number;
  totalRevenue: number;
  ticketTypes: TicketType[];
}

interface SortConfig {
  key: keyof PastEvent | 'earlyBirdPercentage' | 'performerPromoPercentage';
  direction: 'asc' | 'desc';
}

const PastEventsAnalysis: React.FC<{ pastEvents: PastEvent[] }> = ({ pastEvents }) => {
  const [sortConfig, setSortConfig] = useState<SortConfig>({ key: 'start_date', direction: 'desc' });
  const [error, setError] = useState<string | null>(null);

  // Data Validation
  const validPastEvents = useMemo(() => {
    return pastEvents.filter(event => {
      if (
        !event.id ||
        !event.title ||
        !event.start_date || 
        typeof event.totalSold !== 'number' || 
        typeof event.totalAvailable !== 'number' || 
        typeof event.totalRevenue !== 'number' ||
        !Array.isArray(event.ticketTypes)
      ) {
        console.error('Invalid event data:', event);
        return false;
      }
      return true;
    });
  }, [pastEvents]);

  // Error Handling
  useMemo(() => {
    if (validPastEvents.length === 0 && pastEvents.length > 0) {
      setError('No valid event data available. Please check the data format.');
    } else {
      setError(null);
    }
  }, [validPastEvents, pastEvents]);

  // Date Formatting
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return isNaN(date.getTime()) 
      ? 'Invalid Date' 
      : date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  const sortedEvents = useMemo(() => {
    return [...validPastEvents].sort((a, b) => {
      if (sortConfig.key === 'earlyBirdPercentage') {
        const aPercentage = getTicketTypePercentage(a, 'Early Bird');
        const bPercentage = getTicketTypePercentage(b, 'Early Bird');
        return sortConfig.direction === 'asc' ? aPercentage - bPercentage : bPercentage - aPercentage;
      }
      if (sortConfig.key === 'performerPromoPercentage') {
        const aPercentage = getTicketTypePercentage(a, 'Performer Promo');
        const bPercentage = getTicketTypePercentage(b, 'Performer Promo');
        return sortConfig.direction === 'asc' ? aPercentage - bPercentage : bPercentage - aPercentage;
      }
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }, [validPastEvents, sortConfig]);

  const handleSort = (key: SortConfig['key']) => {
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  const getTicketTypePercentage = (event: PastEvent, typeName: string): number => {
    const ticketType = event.ticketTypes.find(t => t.name === typeName);
    if (!ticketType || event.totalSold === 0) return 0;
    return (ticketType.quantity_sold / event.totalSold) * 100;
  };

  const bestEvent = useMemo(() => {
    return validPastEvents.reduce((max, event) => event.totalRevenue > max.totalRevenue ? event : max, validPastEvents[0]);
  }, [validPastEvents]);

  const worstEvent = useMemo(() => {
    return validPastEvents.reduce((min, event) => event.totalRevenue < min.totalRevenue ? event : min, validPastEvents[0]);
  }, [validPastEvents]);

  const overallStats = useMemo(() => {
    const totalRevenue = validPastEvents.reduce((sum, event) => sum + event.totalRevenue, 0);
    const totalTicketsSold = validPastEvents.reduce((sum, event) => sum + event.totalSold, 0);
    const averageRevenuePerEvent = validPastEvents.length > 0 ? totalRevenue / validPastEvents.length : 0;
    const averageTicketsPerEvent = validPastEvents.length > 0 ? totalTicketsSold / validPastEvents.length : 0;

    return { totalRevenue, totalTicketsSold, averageRevenuePerEvent, averageTicketsPerEvent };
  }, [validPastEvents]);

  const chartData = useMemo(() => {
    return validPastEvents.map(event => ({
      name: event.title,
      'Total Revenue': event.totalRevenue,
      'Early Bird %': getTicketTypePercentage(event, 'Early Bird'),
      'Performer Promo %': getTicketTypePercentage(event, 'Performer Promo'),
      'General Admission %': getTicketTypePercentage(event, 'General Admission')
    }));
  }, [validPastEvents]);

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <Box sx={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', p: 1, borderRadius: 1 }}>
          <Typography variant="body2">{label}</Typography>
          {payload.map((pld: any) => (
            <Typography key={pld.name} variant="body2" color={pld.color}>
              {`${pld.name}: ${isNaN(pld.value) ? 'N/A' : pld.value.toFixed(2)}${pld.name.includes('%') ? '%' : ''}`}
            </Typography>
          ))}
        </Box>
      );
    }
    return null;
  };

  if (error) {
    return (
      <Box sx={{ maxWidth: 1200, margin: 'auto', mt: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  if (validPastEvents.length === 0) {
    return (
      <Box sx={{ maxWidth: 1200, margin: 'auto', mt: 4 }}>
        <Alert severity="warning" sx={{ mb: 2 }}>
          No valid event data available. Total events received: {pastEvents.length}. 
          Please check the data format and ensure all required fields are present.
        </Alert>
        <Typography variant="h6">Debug Information:</Typography>
        <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
          {JSON.stringify(pastEvents, null, 2)}
        </pre>
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 1200, margin: 'auto', mt: 4 }}>
      <Typography variant="h4" gutterBottom>Past Events Analysis</Typography>
      
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>Overall Statistics</Typography>
              <Typography>Total Revenue: €{overallStats.totalRevenue.toFixed(2)}</Typography>
              <Typography>Total Tickets Sold: {overallStats.totalTicketsSold}</Typography>
              <Typography>Average Revenue per Event: €{overallStats.averageRevenuePerEvent.toFixed(2)}</Typography>
              <Typography>Average Tickets Sold per Event: {overallStats.averageTicketsPerEvent.toFixed(2)}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>Events Comparison</Typography>
              <ResponsiveContainer width="100%" height={400}>
                <BarChart data={chartData}>
                  <XAxis dataKey="name" angle={-45} textAnchor="end" height={100} />
                  <YAxis yAxisId="left" />
                  <YAxis yAxisId="right" orientation="right" />
                  <RechartsTooltip content={<CustomTooltip />} />
                  <Legend />
                  <Bar yAxisId="left" dataKey="Total Revenue" fill="#8884d8" />
                  <Bar yAxisId="right" dataKey="Early Bird %" fill="#82ca9d" />
                  <Bar yAxisId="right" dataKey="Performer Promo %" fill="#ffc658" />
                  <Bar yAxisId="right" dataKey="General Admission %" fill="#ff8042" />
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" gutterBottom>Event Performance Summary</Typography>
        <Typography>
          Best Performing Event: {bestEvent.title} (Revenue: €{bestEvent.totalRevenue.toFixed(2)})
        </Typography>
        <Typography>
          Worst Performing Event: {worstEvent.title} (Revenue: €{worstEvent.totalRevenue.toFixed(2)})
        </Typography>
      </Box>

      <TableContainer component={Paper} sx={{ mt: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.key === 'title'}
                  direction={sortConfig.key === 'title' ? sortConfig.direction : 'asc'}
                  onClick={() => handleSort('title')}
                >
                  Event Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.key === 'start_date'}
                  direction={sortConfig.key === 'start_date' ? sortConfig.direction : 'asc'}
                  onClick={() => handleSort('start_date')}
                >
                  Date
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={sortConfig.key === 'totalSold'}
                  direction={sortConfig.key === 'totalSold' ? sortConfig.direction : 'asc'}
                  onClick={() => handleSort('totalSold')}
                >
                  Tickets Sold
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={sortConfig.key === 'totalRevenue'}
                  direction={sortConfig.key === 'totalRevenue' ? sortConfig.direction : 'asc'}
                  onClick={() => handleSort('totalRevenue')}
                >
                  Revenue
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={sortConfig.key === 'earlyBirdPercentage'}
                  direction={sortConfig.key === 'earlyBirdPercentage' ? sortConfig.direction : 'asc'}
                  onClick={() => handleSort('earlyBirdPercentage')}
                >
                  Early Bird %
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={sortConfig.key === 'performerPromoPercentage'}
                  direction={sortConfig.key === 'performerPromoPercentage' ? sortConfig.direction : 'asc'}
                  onClick={() => handleSort('performerPromoPercentage')}
                >
                  Performer Promo %
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedEvents.map((event) => (
              <TableRow key={event.id}>
                <TableCell>{event.title}</TableCell>
                <TableCell>{formatDate(event.start_date)}</TableCell>
                <TableCell align="right">{event.totalSold}</TableCell>
                <TableCell align="right">€{event.totalRevenue.toFixed(2)}</TableCell>
                <TableCell align="right">{getTicketTypePercentage(event, 'Early Bird').toFixed(2)}%</TableCell>
                <TableCell align="right">{getTicketTypePercentage(event, 'Performer Promo').toFixed(2)}%</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" gutterBottom>Marketing Insights</Typography>
        <Typography>
          Average Early Bird Sales: {
            (validPastEvents.reduce((sum, event) => sum + getTicketTypePercentage(event, 'Early Bird'), 0) / validPastEvents.length).toFixed(2)
          }%
        </Typography>
        <Typography>
          Average Performer Promo Sales: {
            (validPastEvents.reduce((sum, event) => sum + getTicketTypePercentage(event, 'Performer Promo'), 0) / validPastEvents.length).toFixed(2)
          }%
        </Typography>
        <Typography>
          Average General Admission Sales: {
            (validPastEvents.reduce((sum, event) => sum + getTicketTypePercentage(event, 'General Admission'), 0) / validPastEvents.length).toFixed(2)
          }%
        </Typography>
      </Box>
    </Box>
  );
};

export default PastEventsAnalysis;