import React from 'react';
import { Card, CardContent, Typography, List, ListItem, ListItemText } from '@mui/material';
import { Performance, Event, Venue, BandRecommendation, Staff } from '../../types/mongodb';

interface BandEventInfoProps {
  performance: Performance;
}

const BandEventInfo: React.FC<BandEventInfoProps> = ({ performance }) => {
  const eventCountdown = () => {
    if (!performance.startTime) return 'Date not set';
    const eventDate = new Date(performance.startTime).getTime();
    const currentDate = new Date().getTime();
    const daysLeft = Math.ceil((eventDate - currentDate) / (1000 * 60 * 60 * 24));
    return daysLeft < 0 ? 'Event has passed' : `${daysLeft} days left until event`;
  };

  const getArtistName = () => {
    const band = performance.band as BandRecommendation;
    return typeof band === 'string' ? 'Unknown Band' : band.artistName;
  };

  const getVenueName = (venue: string | Venue | undefined) => {
    if (!venue) return 'Venue information not available';
    if (typeof venue === 'string') return 'Venue information not available';
    return venue.name;
  };
  
  const getEventInfo = () => {
    // First check for strandId
    if (performance.strandId) {
      if (typeof performance.strandId === 'string') {
        return (
          <Typography variant="subtitle1" color="text.secondary">
            This performance is part of strand ID: {performance.strandId}
          </Typography>
        );
      }
  
      return (
        <>
          <Typography variant="subtitle1" color="text.secondary">
            Strand: {performance.strandId.name}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            Period: {new Date(performance.strandId.startDate).toLocaleDateString()} - 
                   {new Date(performance.strandId.endDate).toLocaleDateString()}
          </Typography>
        </>
      );
    }
  
    // Handle event case or no assignment case
    const event = performance.event as Event;
    if (!event || typeof event === 'string') {
      return (
        <Typography variant="subtitle1" color="text.secondary">
          No event or strand information available
        </Typography>
      );
    }
    
    return (
      <>
        <Typography variant="subtitle1" color="text.secondary">
          Event: {event.name}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          Date: {performance.startTime ? new Date(performance.startTime).toLocaleDateString() : 'N/A'}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          Venue: {getVenueName(event.venue)}
        </Typography>
        <Typography variant="h6" color="primary">
          {eventCountdown()}
        </Typography>
      </>
    );
  };

  const formatTime = (time: Date | string | undefined) => {
    if (!time) return 'N/A';
    const date = time instanceof Date ? time : new Date(time);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const renderStaff = () => {
    if (!performance.staff || performance.staff.length === 0) {
      return <Typography>No staff assigned</Typography>;
    }

    return (
      <List>
        {performance.staff.map((staffMember, index) => {
          const isString = typeof staffMember === 'string';
          const key = isString ? staffMember : (staffMember as Staff)._id || index;
          const name = isString ? `Staff ID: ${staffMember}` : (staffMember as Staff).name;
          const role = isString ? 'Role not available' : (staffMember as Staff).role;
          return (
            <ListItem key={key}>
              <ListItemText 
                primary={name}
                secondary={role}
              />
            </ListItem>
          );
        })}
      </List>
    );
  };

  return (
    <Card sx={{ mb: 4 }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          {getArtistName()}
        </Typography>
        {getEventInfo()}
        <Typography variant="subtitle1" color="text.secondary">
          Start Time: {formatTime(performance.startTime)}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          End Time: {formatTime(performance.endTime)}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          Status: {performance.status}
        </Typography>
        
        <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
          Assigned Staff
        </Typography>
        {renderStaff()}
      </CardContent>
    </Card>
  );
};

export default BandEventInfo;