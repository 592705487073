const jazzSubgenres = [
  // Traditional and Classic Styles
  "Swing",
  "Bebop",
  "Cool Jazz",
  "Hard Bop",
  "Modal Jazz",
  "Free Jazz",
  "Post-Bop",
  "Avant-Garde Jazz",
  "Contemporary Jazz",

  // European Jazz Styles
  "European Free Jazz",
  "ECM Style",
  "Nordic Jazz",
  "Gypsy Jazz",
  "British Jazz",
  "Continental Jazz",
  "Other",

  // Fusion and Modern Styles
  "Jazz Fusion",
  "Jazz-Funk",
  "Jazz-Rock",
  "Smooth Jazz",
  "Acid Jazz",
  "Nu Jazz",
  "Electro Swing",
  "Jazztronica",
  "Future Jazz",

  // World Music Fusion
  "Latin Jazz",
  "Afro-Cuban Jazz",
  "Bossa Nova",
  "Indo-Jazz",
  "Ethno Jazz",
  "Mediterranean Jazz",
  "Balkan Jazz",

  // Contemporary and Experimental
  "M-Base",
  "Punk Jazz",
  "Jazz Metal",
  "Math Jazz",
  "Crossover Jazz",
  "Prog Jazz",
  "Micro-Jazz",
  "Post-Jazz",
  "Post-Fusion",
  "Post-Modern Jazz",
  "Skronk Jazz",
  "No Wave",
  "Noise Jazz",
  "Free Improvisation",

  // Electronic and Dance Music Influenced
  "Nu-Jazz",
  "Broken Beat",
  "Acid House Jazz",
  "Jazz House",
  "Drum 'n' Bass Jazz",
  "Techno Jazz",

  // Hip-Hop Influenced
  "Jazz Rap",
  "Hip-Hop Jazz",
  "Trip Hop Jazz",

  // Pop and R&B Influenced
  "Pop Jazz",
  "R&B Jazz",
  "Neo-Soul Jazz",
  "Urban Jazz",
  "Soul Jazz",
  "Future Soul",
  "Quiet Storm Jazz",

  // Vocal Jazz Styles
  "Vocalese",
  "Scat",
  "Contemporary Vocal Jazz",

  // Minimalist and Ambient Influenced
  "Minimal Jazz",
  "Ambient Jazz",
  "Drone Jazz",

  // Orchestral and Big Band
  "Third Stream",
  "Orchestral Jazz",
  "Contemporary Big Band",
  "Modern Creative",
  "Chamber Jazz",
  "Symphonic Jazz",
  "Brass Band Jazz",
  "Big Band Swing",
  "Big Band",

  // Specific European Innovations
  "Scandinavian Jazz",
  "French Touch Jazz",
  "Berlin Nu Jazz",
  "London Broken Beat Jazz",
  "Dutch Improv",
  "Italian Jazz Prog",

  // Modern Fusion Styles
  "Neo-Classical Jazz",
  "Jazz-Tronica",
  "Folktronica Jazz",
  "Post-Rock Jazz",
  "Jazz Noir",

  // Culturally Specific European Styles
  "Flamenco Jazz",
  "Celtic Jazz",
  "Klezmer Jazz",
  "Manouche Jazz",

  // Emerging and Niche Styles
  "Afrofuturist Jazz",
  "Eco Jazz",
  "Quantum Jazz",
  "Algorithmic Jazz",
  "VR Jazz",
  "Blockchain Jazz",

  // Contemporary Classical Influenced
  "New Complexity Jazz",
  "Spectral Jazz",
  "Polystylistic Jazz",

  // Visual Arts Inspired
  "Cubist Jazz",
  "Surrealist Jazz",
  "Abstract Expressionist Jazz",

  // Literary Influenced
  "Beat Jazz",
  "Dada Jazz",
  "Oulipo Jazz",

  // Technology Inspired
  "Glitch Jazz",
  "AI Jazz",
  "Biometric Jazz",
  "Nanotech Jazz",

// General
"Noise",
"Glitch",
"Ambient",
"Drone",
"Microtonal",
"Just Intonation",
"Atonal",
"Serialism",
"Aleatoric",
"Minimalism",
"Post-Minimalism",
"Spectralism",
"Musique Concrete",
"Electroacoustic",
"Acousmatic",
"Sound Art",
"Sound Sculpture",
"Sound Installation",
"Sound Walk",
"Sound Map",
"Sound Ecology",
"Sound Archaeology",
"Sound Therapy",
"Field Recording",
"Phonography",
"Circuit Bending",
"Process Music",
"Spatial Music",
"Soundscape Composition",
"Lowercase",
"Electro-Improvisation",
"Generative Music",
"Noise Wall",
"Ecoacoustics",
"Live Coding",
"Transidiomatic Improvisation",
"Fluxus",
"Industrial",
"Art Punk",
"Post-Punk",
"Noisecore",
"Power Electronics",
"Harsh Noise",
"Dark Ambient",

// Additional Jazz and Improvisational Styles
"Spiritual Jazz",
"Ambient Improvisation",
"Afrobeat Jazz",
"Lo-Fi Jazz",
"Eco-Acoustic Jazz",
"Post-Genre Jazz",
"Spoken Word Jazz",

// Additional Experimental/Interdisciplinary Categories
"Data-Driven Jazz",
"Algorithmic Improv",
"Augmented Reality Jazz",
"Somatic Jazz",
"Sound Poetry",
"Biofeedback Music",

// Additional Ambient and Sound Art-Inspired Categories
"Industrial Jazz",
"Ambient Folk Jazz",
"Transcultural Improvisation",
"Text-Sound Composition",
"Micro-Improvisation",
"Sonic Cartography",

// Emerging Technology and Science-Inspired
"Neural Network Jazz",
"Bioacoustic Jazz",
"Cybernetic Jazz",
"Blockchain Improv",
"Cyborg Jazz",

// Experimental Aesthetic Movements in Jazz
"Afrosurrealism Jazz",
"Gothic Jazz",
"Digital Minimalism",
"Existential Jazz",
"Modular Jazz",
];


export default jazzSubgenres;