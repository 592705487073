import React from 'react';

const GlobalFontStyles = () => {
    return (
      <style>{`
        @font-face {
          font-family: 'RocGrotesk';
          src: url('/fonts/RocGrotesk-Medium.otf') format('opentype');
          font-weight: 400;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'RocGrotesk';
          src: url('/fonts/RocGrotesk-Bold.otf') format('opentype');
          font-weight: 700;
          font-style: normal;
          font-display: swap;
        }

      
      body {
        font-family: 'RocGrotesk', -apple-system, BlinkMacSystemFont, 'Segoe UI',
          Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      }
      /* Optional: Create utility classes */
      .custom-font {
        font-family: 'CustomFont', sans-serif;
      }
    `}</style>
  );
};


export default GlobalFontStyles;