import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Paper, Typography, ThemeProvider, createTheme, TextField, Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';

const theme = createTheme({
    palette: {
      primary: {
        main: '#102E44',
      },
      secondary: {
        main: '#c2e5d5',
      },
      error: {
        main: '#ff6b6b',
      },
      success: {
        main: '#7bc043',
      },
      info: {
        main: '#3498db',
      },
      warning: {
        main: '#f39c12',
      },
    },
  });
  
  const CalcButton = styled(Button)(({ theme }) => ({
    width: '100%',
    height: '60px',
    fontSize: '1.5rem',
    fontWeight: 'bold',
  }));
  
  const OperationButton = styled(CalcButton)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  }));
  
  const ClearButton = styled(CalcButton)(({ theme }) => ({
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  }));
  
  const EqualsButton = styled(CalcButton)(({ theme }) => ({
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.white,
    height: '120px',
    '&:hover': {
      backgroundColor: theme.palette.info.dark,
    },
  }));
  
  const NumberButton = styled(CalcButton)(({ theme }) => ({
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
    },
  }));
  
  const SpecialButton = styled(CalcButton)(({ theme }) => ({
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  }));
  
  const Display = styled(Paper)(({ theme }) => ({
    width: '100%',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'right',
    fontSize: '2.5rem',
    fontWeight: 'bold',
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.primary.main,
  }));
  

interface PromoCalcProps {
    initialValue: number;
    onSubmit: (value: number) => void;
    onClose: () => void;
  }

  const PromoCalc: React.FC<PromoCalcProps> = ({ initialValue, onSubmit, onClose }) => {
    const [display, setDisplay] = useState(initialValue.toString());
    const [waitingForOperand, setWaitingForOperand] = useState(true);
    const [pendingOperator, setPendingOperator] = useState<string | null>(null);
    const [storedValue, setStoredValue] = useState<number | null>(null);
    const [currentOperator, setCurrentOperator] = useState<string | null>(null);
    const [showCapacityDialog, setShowCapacityDialog] = useState(false);
    const [venueCapacity, setVenueCapacity] = useState(100);
    const [occupancyRate, setOccupancyRate] = useState(70);

  useEffect(() => {
    setDisplay(initialValue.toString());
  }, [initialValue]);

  const inputDigit = (digit: string) => {
    if (waitingForOperand) {
      setDisplay(digit);
      setWaitingForOperand(false);
    } else {
      setDisplay(display === '0' ? digit : display + digit);
    }
  };

  const inputDot = () => {
    if (waitingForOperand) {
      setDisplay('0.');
      setWaitingForOperand(false);
    } else if (display.indexOf('.') === -1) {
      setDisplay(display + '.');
    }
  };

  const clearDisplay = () => {
    setDisplay('0');
    setWaitingForOperand(true);
    setPendingOperator(null);
    setStoredValue(null);
  };

  const performOperation = (operator: string) => {
    const inputValue = parseFloat(display);

    if (storedValue === null) {
        setStoredValue(inputValue);
    } else if (pendingOperator) {
        const newValue = calculate(storedValue, inputValue, pendingOperator);
        setStoredValue(newValue);
        setDisplay(newValue.toString());
    }

    setWaitingForOperand(true);
    setPendingOperator(operator);
    setCurrentOperator(operator); // Update the operator state
};

const calculate = (x: number, y: number, operator: string): number => {
  switch (operator) {
      case '+':
          return x + y;
      case '-':
          return x - y;
      case '*':
          return x * y;
      case '/':
          return x / y;
      default:
          return y;
  }
};

  const percentage = () => {
    const value = parseFloat(display);
    setDisplay((value / 100).toString());
  };

  const tip = (percentage: number) => {
    const value = parseFloat(display);
    setDisplay((value * (1 + percentage / 100)).toFixed(2));
  };

  const handleSubmit = () => {
    onSubmit(parseFloat(display));
  };

  const calculateRevenue = () => {
    const ticketPrice = parseFloat(display);
    const actualAttendance = Math.floor(venueCapacity * (occupancyRate / 100));
    const revenue = ticketPrice * actualAttendance;
    setDisplay(revenue.toFixed(2));
    setWaitingForOperand(true);
  };

  const calculateBandCost = () => {
    setShowCapacityDialog(true);
  };

  const handleBandCostCalculation = (memberCount: number, costPerMember: number) => {
    const totalCost = memberCount * costPerMember;
    setDisplay(totalCost.toFixed(2));
    setShowCapacityDialog(false);
    setWaitingForOperand(true);
  };


  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: 300, p: 2, backgroundColor: theme.palette.primary.main }}>
        <Typography variant="h4" color="secondary" sx={{ textAlign: 'center', marginBottom: 2, fontFamily: 'CalculatorFont' }}>
          PromoCalc
        </Typography>
        <Display sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ color: 'grey', marginRight: 'auto' }}>{currentOperator}</Box>
          <Box sx={{ marginLeft: 'auto' }}>{display}</Box>
        </Display>
        <Grid container spacing={1}>
          {/* First row */}
          <Grid item xs={3}><ClearButton onClick={clearDisplay}>C</ClearButton></Grid>
          <Grid item xs={3}><SpecialButton onClick={calculateRevenue}>Rev</SpecialButton></Grid>
          <Grid item xs={3}><SpecialButton onClick={calculateBandCost}>Cost</SpecialButton></Grid>
          <Grid item xs={3}><OperationButton onClick={() => performOperation('/')}>/</OperationButton></Grid>


          {/* Second row */}
          <Grid item xs={3}><NumberButton onClick={() => inputDigit('7')}>7</NumberButton></Grid>
          <Grid item xs={3}><NumberButton onClick={() => inputDigit('8')}>8</NumberButton></Grid>
          <Grid item xs={3}><NumberButton onClick={() => inputDigit('9')}>9</NumberButton></Grid>
          <Grid item xs={3}><OperationButton onClick={() => performOperation('*')}>×</OperationButton></Grid>

          {/* Third row */}
          <Grid item xs={3}><NumberButton onClick={() => inputDigit('4')}>4</NumberButton></Grid>
          <Grid item xs={3}><NumberButton onClick={() => inputDigit('5')}>5</NumberButton></Grid>
          <Grid item xs={3}><NumberButton onClick={() => inputDigit('6')}>6</NumberButton></Grid>
          <Grid item xs={3}><OperationButton onClick={() => performOperation('-')}>-</OperationButton></Grid>

          {/* Fourth row */}
          <Grid item xs={3}><NumberButton onClick={() => inputDigit('1')}>1</NumberButton></Grid>
          <Grid item xs={3}><NumberButton onClick={() => inputDigit('2')}>2</NumberButton></Grid>
          <Grid item xs={3}><NumberButton onClick={() => inputDigit('3')}>3</NumberButton></Grid>
          <Grid item xs={3}><OperationButton onClick={() => performOperation('+')}>+</OperationButton></Grid>

          {/* Fifth row */}
          <Grid item xs={3}><NumberButton onClick={() => inputDigit('0')}>0</NumberButton></Grid>
          <Grid item xs={3}><NumberButton onClick={inputDot}>.</NumberButton></Grid>
          <Grid item xs={3}><OperationButton onClick={percentage}>%</OperationButton></Grid>
          <Grid item xs={3}><EqualsButton onClick={() => performOperation('=')}>=</EqualsButton></Grid>

          {/* Last row */}
          <Grid item xs={6}><Button fullWidth variant="outlined" color="secondary" onClick={onClose}>Cancel</Button></Grid>
          <Grid item xs={6}><Button fullWidth variant="contained" color="secondary" onClick={() => onSubmit(parseFloat(display))}>Submit</Button></Grid>
        </Grid>
      </Box>

      {/* Revenue Calculation Dialog */}
      <Dialog open={showCapacityDialog} onClose={() => setShowCapacityDialog(false)}>
        <Box sx={{ p: 2 }}>
          <Typography variant="h6">Calculate Revenue</Typography>
          <TextField
            label="Venue Capacity"
            type="number"
            value={venueCapacity}
            onChange={(e) => setVenueCapacity(parseInt(e.target.value))}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Occupancy Rate (%)"
            type="number"
            value={occupancyRate}
            onChange={(e) => setOccupancyRate(parseInt(e.target.value))}
            fullWidth
            margin="normal"
          />
          <Button onClick={calculateRevenue} variant="contained" color="secondary" fullWidth>
            Calculate
          </Button>
        </Box>
      </Dialog>

      {/* Band Cost Calculation Dialog */}
      <Dialog open={showCapacityDialog} onClose={() => setShowCapacityDialog(false)}>
        <Box sx={{ p: 2 }}>
          <Typography variant="h6">Calculate Band Cost</Typography>
          <TextField
            label="Number of Band Members"
            type="number"
            fullWidth
            margin="normal"
          />
          <TextField
            label="Cost per Member"
            type="number"
            fullWidth
            margin="normal"
          />
          <Button onClick={() => handleBandCostCalculation(5, 100)} variant="contained" color="primary" fullWidth>
            Calculate
          </Button>
        </Box>
      </Dialog>
    </ThemeProvider>
  );
};

export default PromoCalc;