import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import {
  Container, Typography, Box, Grid, Card, CardContent, CircularProgress,
  Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Autocomplete,
  Snackbar,
  Alert
} from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip as RechartsTooltip } from 'recharts';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PublishIcon from '@mui/icons-material/Publish';
import ProgrammingProspects from '../components/ProgrammingProspects';
import StrandDateManager from '../components/strands/StrandDateManager';

import axios from 'axios';

// Import types
import { Venue, Performance, Personnel, BandRecommendation } from '../types/mongodb';

interface Strand {
  _id: string;
  name: string;
  slug: string;
  description: string;
  type: 'domestic' | 'international';
  startDate: string;
  endDate: string;
  eventType: string;
  status: 'planned' | 'confirmed' | 'cancelled';
  dates: string;
  published: boolean;
  mainImage: string;
  artsCouncilActivityType: string;
  artsCouncilActivityTarget: string;
  artsCouncilStatus: string;
  overallFinance: {
    totalIncome: number;
    totalExpenditure: number;
  };
  associations: {
    venues: string[];
    performances: string[];
    strands: string[];
    personnel: string[];
    bands: string[];
    applications: string[];
  };
  employmentFigures: {
    artists: number;
    others: number;
    associatedEmploy: number;
    volunteers: number;
  };
  audiences: {
    venuesCaps: number;
    paying: number;
    free: number;
    total: number;
    ratio: number;
  };
  numberOfProposedEvents: number;
}

const StrandDetailPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [strand, setStrand] = useState<Strand | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openPublishDialog, setOpenPublishDialog] = useState(false);
    const [snackbar, setSnackbar] = useState<{ open: boolean; message: string; severity: 'success' | 'error' }>({
      open: false,
      message: '',
      severity: 'success'
    });
  

  // State for available associations
  const [availableVenues, setAvailableVenues] = useState<Venue[]>([]);
  const [availablePerformances, setAvailablePerformances] = useState<Performance[]>([]);
  const [availablePersonnel, setAvailablePersonnel] = useState<Personnel[]>([]);
  const [availableBands, setAvailableBands] = useState<BandRecommendation[]>([]);

  // State for selected associations
  const [selectedVenues, setSelectedVenues] = useState<Venue[]>([]);
  const [selectedPerformances, setSelectedPerformances] = useState<Performance[]>([]);
  const [selectedPersonnel, setSelectedPersonnel] = useState<Personnel[]>([]);
  const [selectedBands, setSelectedBands] = useState<BandRecommendation[]>([]);

 
  const fetchData = useCallback(async () => {
    if (!id) return;
    try {
      setLoading(true);

        // Fetch strand details
        const strandResponse = await axios.get<Strand>(`${process.env.REACT_APP_API_URL}/api/strands/${id}`);
        const strandData = strandResponse.data;
        setStrand(strandData);

        // Fetch available associations
        const [venuesRes, performancesRes, personnelRes, bandsRes] = await Promise.all([
          axios.get<Venue[]>(`${process.env.REACT_APP_API_URL}/api/venues`),
          axios.get<Performance[]>(`${process.env.REACT_APP_API_URL}/api/performances`),
          axios.get<Personnel[]>(`${process.env.REACT_APP_API_URL}/api/personnel`),
          axios.get<BandRecommendation[]>(`${process.env.REACT_APP_API_URL}/api/bandRecommendations`),
        ]);

        setAvailableVenues(venuesRes.data);
        setAvailablePerformances(performancesRes.data);
        setAvailablePersonnel(personnelRes.data);
        setAvailableBands(bandsRes.data);

        // Map IDs to objects for selected associations
        const selectedVenues = venuesRes.data.filter(venue => strandData.associations.venues.includes(venue._id));
        const selectedPerformances = performancesRes.data.filter(performance => strandData.associations.performances.includes(performance._id));
        const selectedPersonnel = personnelRes.data.filter(person => strandData.associations.personnel.includes(person._id));
        const selectedBands = bandsRes.data.filter(band => band._id && strandData.associations.bands.includes(band._id));

        setSelectedVenues(selectedVenues);
        setSelectedPerformances(selectedPerformances);
        setSelectedPersonnel(selectedPersonnel);
        setSelectedBands(selectedBands);

        setError(null);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('An unexpected error occurred while fetching data.');
      setSnackbar({
        open: true,
        message: 'Failed to fetch strand data',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleEdit = () => {
    navigate(`/strands/edit/${id}`);
  };

  const handleDelete = () => {
    setOpenDeleteDialog(true);
  };

  const confirmDelete = async () => {
    if (strand) {
      try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/api/strands/${strand._id}`);
        setOpenDeleteDialog(false);
        navigate('/strands');
      } catch (error) {
        console.error('Error deleting strand:', error);
        // Handle error (e.g., show error message)
      }
    }
  };

  const handlePublish = () => {
    setOpenPublishDialog(true);
  };

  const confirmPublish = async () => {
    if (strand) {
      try {
        await axios.patch(`${process.env.REACT_APP_API_URL}/api/strands/${strand._id}`, { published: !strand.published });
        setStrand({ ...strand, published: !strand.published });
        setOpenPublishDialog(false);
      } catch (error) {
        console.error('Error publishing strand:', error);
        // Handle error (e.g., show error message)
      }
    }
  };

  const handleSaveAssociations = async () => {
    if (strand) {
      try {
        setLoading(true);

        // Prepare the data
        const updatedAssociations = {
          venues: selectedVenues.map((venue) => venue._id),
          performances: selectedPerformances.map((performance) => performance._id),
          personnel: selectedPersonnel.map((person) => person._id),
          bands: selectedBands
          .map((band) => band._id)
          .filter((id): id is string => id !== undefined),
         applications: strand.associations.applications,
          strands: strand.associations.strands,
        };

        // Send the update request to the API
        await axios.patch(`${process.env.REACT_APP_API_URL}/api/strands/${strand._id}`, {
          associations: updatedAssociations,
        });

        // Update the strand state with new associations
        setStrand({
          ...strand,
          associations: updatedAssociations,
        });

        // Optionally, display a success message
        alert('Associations updated successfully.');
      } catch (error) {
        console.error('Error updating associations:', error);
        // Handle error (e.g., show error message)
        alert('Failed to update associations. Please try again.');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleProspectsChange = () => {
    fetchData();
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!strand) return <Typography>Strand not found.</Typography>;

  const employmentData = [
    { name: 'Artists', value: strand.employmentFigures.artists, color: '#8884d8' },
    { name: 'Others', value: strand.employmentFigures.others, color: '#82ca9d' },
    { name: 'Associated', value: strand.employmentFigures.associatedEmploy, color: '#ffc658' },
    { name: 'Volunteers', value: strand.employmentFigures.volunteers, color: '#ff8042' },
  ];

  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <Typography variant="h3" component="h1" gutterBottom>{strand.name}</Typography>
        <Grid container spacing={4}>
          {/* Left Column */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>Basic Information</Typography>
                <Typography><strong>Type:</strong> {strand.type}</Typography>
                <Typography><strong>Event Type:</strong> {strand.eventType}</Typography>
                <Typography><strong>Number of Proposed Events:</strong> {strand.numberOfProposedEvents}</Typography>
                <Typography><strong>Status:</strong> {strand.status}</Typography>
                <Typography><strong>Published:</strong> {strand.published ? 'Yes' : 'No'}</Typography>
                <Typography><strong>Start Date:</strong> {new Date(strand.startDate).toLocaleDateString()}</Typography>
                <Typography><strong>End Date:</strong> {new Date(strand.endDate).toLocaleDateString()}</Typography>
              </CardContent>
            </Card>

            <Box mt={4}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>Description</Typography>
                  <Typography>{strand.description}</Typography>
                </CardContent>
              </Card>
            </Box>

            <Box mt={4}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>Arts Council Information</Typography>
                  <Typography><strong>Activity Type:</strong> {strand.artsCouncilActivityType}</Typography>
                  <Typography><strong>Activity Target:</strong> {strand.artsCouncilActivityTarget}</Typography>
                  <Typography><strong>Status:</strong> {strand.artsCouncilStatus}</Typography>
                </CardContent>
              </Card>
            </Box>
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={6}>
          {/* Actions */}
<Card sx={{ mb: 2 }}>
  <CardContent>
    <Typography variant="h6" gutterBottom>Actions</Typography>
    <Button variant="contained" color="primary" onClick={handleEdit} fullWidth sx={{ mb: 1 }}>
      <EditIcon sx={{ mr: 1 }} />
      Edit
    </Button>
    <Button variant="contained" color="secondary" onClick={handlePublish} fullWidth sx={{ mb: 1 }}>
      <PublishIcon sx={{ mr: 1 }} />
      {strand.published ? 'Unpublish' : 'Publish'}
    </Button>
    <Button 
      variant="contained" 
      component={Link} 
      to="/strands/guidelines/new" 
      fullWidth 
      sx={{ mb: 1 }}
    >
      Create Guidelines
    </Button>
    <Button 
      variant="contained" 
      color="info" 
      onClick={() => alert('Application creation coming soon!')} 
      fullWidth 
      sx={{ mb: 1 }}
    >
      Create Application
    </Button>
    <Button variant="contained" color="error" onClick={handleDelete} fullWidth>
      <DeleteIcon sx={{ mr: 1 }} />
      Delete
    </Button>
  </CardContent>
</Card>

            {/* Financial Information */}
            <Card sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>Financial Information</Typography>
                <Typography><strong>Total Income:</strong> €{strand.overallFinance.totalIncome}</Typography>
                <Typography><strong>Total Expenditure:</strong> €{strand.overallFinance.totalExpenditure}</Typography>
              </CardContent>
            </Card>

            {/* Audience Information */}
            <Card sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>Audience Information</Typography>
                <Typography><strong>Venue Capacity:</strong> {strand.audiences.venuesCaps}</Typography>
                <Typography><strong>Paying Audience:</strong> {strand.audiences.paying}</Typography>
                <Typography><strong>Free Audience:</strong> {strand.audiences.free}</Typography>
                <Typography><strong>Total Audience:</strong> {strand.audiences.total}</Typography>
                <Typography><strong>Audience Ratio:</strong> {strand.audiences.ratio}%</Typography>
              </CardContent>
            </Card>

            {/* Employment Figures */}
            <Card sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>Employment Figures</Typography>
                <Box height={200}>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={employmentData}
                        cx="50%"
                        cy="50%"
                        innerRadius={50}
                        outerRadius={70}
                        paddingAngle={5}
                        dataKey="value"
                      >
                        {employmentData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                      </Pie>
                      <RechartsTooltip />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Associations */}
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>Associations</Typography>
                <Grid container spacing={2}>
                  {/* Venues Multi-Select */}
                  <Grid item xs={12} sm={6} md={4}>
                    <Autocomplete
                      multiple
                      options={availableVenues}
                      getOptionLabel={(option) => option.name}
                      value={selectedVenues}
                      onChange={(event, newValue) => {
                        setSelectedVenues(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Venues" placeholder="Select Venues" />
                      )}
                    />
                  </Grid>

                  {/* Performances Multi-Select */}
                  <Grid item xs={12} sm={6} md={4}>
                    <Autocomplete
                      multiple
                      options={availablePerformances}
                      getOptionLabel={(option) => {
                        // Create a label for the performance
                        const bandName =
                          typeof option.band === 'string'
                            ? option.band
                            : (option.band as BandRecommendation)?.artistName || 'Unknown Band';
                        const eventName =
                          typeof option.event === 'string'
                            ? option.event
                            : (option.event as any)?.name || 'Unknown Event';
                        return `${bandName} at ${eventName}`;
                      }}
                      value={selectedPerformances}
                      onChange={(event, newValue) => {
                        setSelectedPerformances(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Performances" placeholder="Select Performances" />
                      )}
                    />
                  </Grid>

                  {/* Personnel Multi-Select */}
                  <Grid item xs={12} sm={6} md={4}>
                    <Autocomplete
                      multiple
                      options={availablePersonnel}
                      getOptionLabel={(option) => option.name}
                      value={selectedPersonnel}
                      onChange={(event, newValue) => {
                        setSelectedPersonnel(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Personnel" placeholder="Select Personnel" />
                      )}
                    />
                  </Grid>

                  {/* Bands Multi-Select */}
                  <Grid item xs={12} sm={6} md={4}>
                    <Autocomplete
                      multiple
                      options={availableBands}
                      getOptionLabel={(option) => option.artistName}
                      value={selectedBands}
                      onChange={(event, newValue) => {
                        setSelectedBands(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Bands" placeholder="Select Bands" />
                      )}
                    />
                  </Grid>
                </Grid>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveAssociations}
                  sx={{ mt: 2 }}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : 'Save Associations'}
                </Button>
              </CardContent>
            </Card>
          </Grid>

        </Grid>
      </Box>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete <strong>{strand.name}</strong>?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
          <Button onClick={confirmDelete} variant="contained" color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

          {/* Programming Prospects */}
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <ProgrammingProspects 
                  strandId={strand._id}
                  strandStartDate={strand.startDate}
                  strandEndDate={strand.endDate}
                  onProspectsChange={handleProspectsChange}
                />
    <StrandDateManager
      strandId={strand._id}
      strandStartDate={strand.startDate}
      strandEndDate={strand.endDate}
      initialDates={strand.dates}
      onDatesChange={(newDates) => {
        // Handle the updated dates, e.g., update the strand state
        setStrand({ ...strand, dates: newDates });
      }}
    />
              </CardContent>
            </Card>
</Grid>

      {/* Publish Confirmation Dialog */}
      <Dialog open={openPublishDialog} onClose={() => setOpenPublishDialog(false)}>
        <DialogTitle>{strand.published ? 'Unpublish' : 'Publish'} Strand</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to {strand.published ? 'unpublish' : 'publish'} <strong>{strand.name}</strong>?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPublishDialog(false)}>Cancel</Button>
          <Button onClick={confirmPublish} variant="contained" color="primary">
            {strand.published ? 'Unpublish' : 'Publish'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default StrandDetailPage;