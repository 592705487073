// src/components/layout/GuestLayout.tsx

import React from 'react';
import { Outlet } from 'react-router-dom';
import GuestNav from '../navigation/GuestNav';
import Footer from '../Footer';  // Assuming you have a Footer component

const GuestLayout: React.FC = () => {
  return (
    <div className="guest-layout">
      <GuestNav />
      <main className="guest-main-content">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default GuestLayout;