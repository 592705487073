import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  Chip,
  Avatar,
  Button,
  ImageList,
  ImageListItem,
  Divider
} from '@mui/material';
import { BandRecommendationFormData, InstrumentWithPerformer } from '../../types/BandRecommendations';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LinkIcon from '@mui/icons-material/Link';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import ImageIcon from '@mui/icons-material/Image';
import BuildIcon from '@mui/icons-material/Build';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

interface ReviewSubmitProps {
  formData: BandRecommendationFormData;
  onSubmit: () => void;
  isEditMode?: boolean;
}

const ReviewSubmit: React.FC<ReviewSubmitProps> = ({ formData, onSubmit, isEditMode = false }) => {
  const Section: React.FC<{ title: string; children: React.ReactNode }> = ({ title, children }) => (
    <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
      <Typography variant="h6" gutterBottom color="primary">
        {title}
      </Typography>
      {children}
    </Paper>
  );

  const ListItemWithIcon: React.FC<{ 
    icon: React.ReactElement; 
    primary: string | React.ReactNode; 
    secondary?: string;
    divider?: boolean;
  }> = ({
    icon,
    primary,
    secondary,
    divider = false
  }) => (
    <ListItem divider={divider}>
      <ListItemText
        primary={
          <Box display="flex" alignItems="center">
            {React.cloneElement(icon, { sx: { mr: 1 } })}
            <Typography variant="body1">{primary}</Typography>
          </Box>
        }
        secondary={secondary}
      />
    </ListItem>
  );

  const InstrumentationSection = () => (
    <Section title="Instrumentation">
      <Box sx={{ mt: 1 }}>
        <Box display="flex" flexWrap="wrap" gap={1}>
          {formData.instrumentation.map((instrument: InstrumentWithPerformer) => (
            <Chip
              key={instrument.instrumentId}
              label={
                instrument.performer
                  ? `${instrument.instrumentName}${instrument.withEffects ? ' (with effects)' : ''} - ${instrument.performer.name}`
                  : `${instrument.instrumentName}${instrument.withEffects ? ' (with effects)' : ''}`
              }
              color={instrument.withEffects ? "primary" : "default"}
              variant="outlined"
              icon={<MusicNoteIcon />}
              avatar={instrument.performer ? <Avatar><PersonIcon /></Avatar> : undefined}
            />
          ))}
        </Box>
      </Box>
    </Section>
  );

  const ImageSection = () => (
    <Section title="Images">
      <Typography variant="subtitle1" gutterBottom>Main Image:</Typography>
      {formData.mainImage ? (
        <Box sx={{ mb: 2 }}>
          <img 
            src={formData.mainImage} 
            alt="Main band/artist" 
            style={{ 
              width: '100%', 
              maxHeight: '300px', 
              objectFit: 'cover',
              borderRadius: '8px'
            }} 
          />
        </Box>
      ) : (
        <Typography color="text.secondary">No main image provided</Typography>
      )}

      {formData.additionalImages && formData.additionalImages.length > 0 && (
        <>
          <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>Additional Images:</Typography>
          <ImageList cols={3} gap={8}>
            {formData.additionalImages.map((image, index) => (
              <ImageListItem key={index}>
                <img
                  src={image}
                  alt={`Additional ${index + 1}`}
                  style={{ borderRadius: '4px' }}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </>
      )}
    </Section>
  );

  const TechnicalSection = () => (
    <Section title="Technical Requirements">
      <List>
        {formData.technicalRider?.stageSize && (
          <ListItemWithIcon 
            icon={<BuildIcon />} 
            primary={formData.technicalRider.stageSize} 
            secondary="Stage Size"
            divider 
          />
        )}
        {formData.technicalRider?.audioRequirements && (
          <ListItemWithIcon 
            icon={<AudiotrackIcon />} 
            primary={formData.technicalRider.audioRequirements} 
            secondary="Audio Requirements"
            divider
          />
        )}
        {formData.technicalRider?.lightingRequirements && (
          <ListItemWithIcon 
            icon={<BuildIcon />} 
            primary={formData.technicalRider.lightingRequirements} 
            secondary="Lighting Requirements"
            divider
          />
        )}
        {formData.technicalRider?.additionalEquipment && 
         formData.technicalRider.additionalEquipment.length > 0 && (
          <ListItem>
            <ListItemText
              primary={
                <Box>
                  <Typography variant="subtitle2" gutterBottom>Additional Equipment:</Typography>
                  <Box display="flex" flexWrap="wrap" gap={1}>
                    {formData.technicalRider.additionalEquipment.map((item, index) => (
                      <Chip key={index} label={item} size="small" />
                    ))}
                  </Box>
                </Box>
              }
            />
          </ListItem>
        )}
      </List>
    </Section>
  );

  const FinancialSection = () => (
    <Section title="Financial Details">
      <List>
        {formData.financialDetails?.minimumFee && (
          <ListItemWithIcon 
            icon={<AttachMoneyIcon />} 
            primary={`${formData.financialDetails.minimumFee} ${formData.financialDetails.preferredCurrency || ''}`}
            secondary="Minimum Fee"
            divider
          />
        )}
        <ListItem>
          <ListItemText
            primary={
              <Box>
                <Typography variant="subtitle2" gutterBottom>Requirements:</Typography>
                <Box display="flex" gap={1}>
                  <Chip 
                    label="Accommodation Required"
                    color={formData.financialDetails?.accommodationNeeded ? "primary" : "default"}
                    variant={formData.financialDetails?.accommodationNeeded ? "filled" : "outlined"}
                  />
                  <Chip 
                    label="Transportation Required"
                    color={formData.financialDetails?.transportationNeeded ? "primary" : "default"}
                    variant={formData.financialDetails?.transportationNeeded ? "filled" : "outlined"}
                  />
                </Box>
              </Box>
            }
          />
        </ListItem>
      </List>
    </Section>
  );

  const MediaLinksSection = () => (
    <Section title="Social Media & Links">
      {(['youtube', 'spotify', 'soundcloud', 'bandcamp'] as const).map((platform) => {
        const embeds = formData[`${platform}Embeds`];
        if (!Array.isArray(embeds) || embeds.length === 0) return null;

        return (
          <Box key={platform} sx={{ mb: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              {platform.charAt(0).toUpperCase() + platform.slice(1)} Links:
            </Typography>
            <Box display="flex" flexWrap="wrap" gap={1}>
              {embeds.map((link, index) => (
                <Chip
                  key={index}
                  label={`Link ${index + 1}`}
                  onClick={() => window.open(link, '_blank')}
                  color="primary"
                  variant="outlined"
                />
              ))}
            </Box>
            <Divider sx={{ my: 2 }} />
          </Box>
        );
      })}
      
      {/* Social Media Handles */}
      <Box sx={{ mt: 2 }}>
        {formData.instagramHandle && (
          <Chip 
            label={`@${formData.instagramHandle}`} 
            color="default" 
            sx={{ m: 0.5 }} 
            onClick={() => window.open(`https://instagram.com/${formData.instagramHandle}`, '_blank')}
          />
        )}
        {formData.twitterHandle && (
          <Chip 
            label={`@${formData.twitterHandle}`} 
            color="default" 
            sx={{ m: 0.5 }}
            onClick={() => window.open(`https://twitter.com/${formData.twitterHandle}`, '_blank')}
          />
        )}
        {formData.facebookPage && (
          <Chip 
            label="Facebook" 
            color="default" 
            sx={{ m: 0.5 }}
            onClick={() => window.open(formData.facebookPage, '_blank')}
          />
        )}
        {formData.tikTokHandle && (
          <Chip 
            label={`@${formData.tikTokHandle}`} 
            color="default" 
            sx={{ m: 0.5 }}
            onClick={() => window.open(`https://tiktok.com/@${formData.tikTokHandle}`, '_blank')}
          />
        )}
      </Box>
    </Section>
  );

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h4" gutterBottom align="center" color="primary">
        {isEditMode ? 'Review Your Changes' : 'Review Your Submission'}
      </Typography>

      <Grid container spacing={3}>
        {/* Left Column */}
        <Grid item xs={12} md={6}>
          <Section title="Recommender Information">
            <List>
              <ListItemWithIcon icon={<PersonIcon />} primary={formData.recommenderName} secondary="Your Name" divider />
              <ListItemWithIcon icon={<EmailIcon />} primary={formData.recommenderEmail} secondary="Your Email" divider />
              <ListItemWithIcon icon={<LocationOnIcon />} primary={formData.recommenderCountry} secondary="Your Country" />
            </List>
          </Section>

          <ImageSection />

          <Section title="Band/Artist Information">
            <List>
              <ListItemWithIcon icon={<MusicNoteIcon />} primary={formData.artistName} secondary="Artist/Band Name" divider />
              <ListItem divider>
                <ListItemText
                  primary={
                    <Box display="flex" gap={2}>
                      <Chip label={`${formData.maleMembers} Male`} color="primary" />
                      <Chip label={`${formData.femaleMembers} Female`} color="secondary" />
                    </Box>
                  }
                  secondary="Band Members"
                />
              </ListItem>
              <ListItemWithIcon icon={<LinkIcon />} primary={formData.performanceLink} secondary="Performance Link" />
            </List>
          </Section>

          <InstrumentationSection />
        </Grid>

        {/* Right Column */}
        <Grid item xs={12} md={6}>
          <MediaLinksSection />
          <TechnicalSection />
          <FinancialSection />
        </Grid>

        {/* Full Width Sections */}
        <Grid item xs={12}>
          <Section title="Genres & Similar Artists">
            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle1" gutterBottom>Genres:</Typography>
              <Box display="flex" flexWrap="wrap" gap={1}>
                {formData.genres.map((genre: string, index: number) => (
                  <Chip 
                    key={index} 
                    label={genre} 
                    color="primary" 
                    variant="outlined"
                  />
                ))}
              </Box>
            </Box>
            
            <Box>
              <Typography variant="subtitle1" gutterBottom>Similar Artists:</Typography>
              <Box display="flex" flexWrap="wrap" gap={1}>
                {(formData.similarArtists || []).map((artist: string, index: number) => (
                  <Chip
                    key={index}
                    label={artist}
                    avatar={<Avatar><PersonIcon /></Avatar>}
                    variant="outlined"
                  />
                ))}
              </Box>
            </Box>
          </Section>

          <Section title="Availability">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box sx={{ textAlign: 'center', p: 2, bgcolor: 'background.default', borderRadius: 1 }}>
                  <Typography variant="overline">Available From</Typography>
                  <Typography variant="h6">
                    {new Date(formData.availability.from).toLocaleDateString()}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{ textAlign: 'center', p: 2, bgcolor: 'background.default', borderRadius: 1 }}>
                  <Typography variant="overline">Available Until</Typography>
                  <Typography variant="h6">
                    {new Date(formData.availability.to).toLocaleDateString()}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            {formData.availability.excludedDates && formData.availability.excludedDates.length > 0 && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1" gutterBottom>Excluded Dates:</Typography>
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {formData.availability.excludedDates.map((date: Date, index: number) => (
                    <Chip
                      key={index}
                      label={new Date(date).toLocaleDateString()}
                      color="error"
                      variant="outlined"
                    />
                  ))}
                </Box>
              </Box>
            )}
          </Section>

          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={onSubmit}
              sx={{ 
                minWidth: 200,
                py: 1.5,
                fontSize: '1.1rem'
              }}
            >
              {isEditMode ? 'Save Changes' : 'Submit Recommendation'}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReviewSubmit;