// BanBamApplicantDetailPage.jsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; // If you're using react-router v5
// For react-router v6, use 'useParams' from 'react-router-dom'
import BanBamApplicantDetail from '../components/form/BanBamApplicantDetail';
import { BanBamApplication } from '../types/BanBam';
import { Typography } from '@mui/material';

const BanBamApplicantDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [application, setApplication] = useState<BanBamApplication | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // Fetch the application data based on the ID from the URL
    const fetchApplication = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/banbam/${id}`);
        if (!response.ok) {
          throw new Error('Failed to fetch application data.');
        }
        const data = await response.json();
        setApplication(data);
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchApplication();
  }, [id]);

  if (loading) {
    return <Typography>Loading application data...</Typography>;
  }

  if (error) {
    return <Typography>Error: {error}</Typography>;
  }

  if (!application) {
    return <Typography>No application data found.</Typography>;
  }

  return (
    <BanBamApplicantDetail application={application} />
  );
};

export default BanBamApplicantDetailPage;