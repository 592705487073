// src/pages/VenueDetailPage.tsx

import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Container, Typography, Paper, Button, Grid, CircularProgress } from '@mui/material';
import axios from 'axios';

interface Venue {
  _id: string;
  name: string;
  capacity: number;
  location: string;
  technicalSpecs: string;
  contactInfo?: { // Made optional
    name: string;
    email: string;
    phone: string;
  };
}

const VenueDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [venue, setVenue] = useState<Venue | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const fetchVenue = async () => {
      try {
        const response = await axios.get<Venue>(`${process.env.REACT_APP_API_URL}/api/venues/${id}`);
        console.log('Fetched Venue:', response.data); // Log fetched data for debugging
        setVenue(response.data);
        setLoading(false);
      } catch (error: any) { // Type as any to access error properties
        console.error('Error fetching venue:', error);
        setError(error.response?.data?.message || 'An error occurred while fetching the venue.');
        setLoading(false);
      }
    };

    fetchVenue();
  }, [id]);

  if (loading) {
    return (
      <Container style={{ textAlign: 'center', marginTop: '50px' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container style={{ textAlign: 'center', marginTop: '50px' }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Container>
    );
  }

  if (!venue) {
    return (
      <Container style={{ textAlign: 'center', marginTop: '50px' }}>
        <Typography variant="h6" color="text.secondary">
          Venue not found.
        </Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          {venue.name}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">Capacity: {venue.capacity}</Typography>
            <Typography variant="body1">Location: {venue.location}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Contact Information</Typography>
            <Typography variant="body1">Name: {venue.contactInfo?.name || 'N/A'}</Typography>
            <Typography variant="body1">Email: {venue.contactInfo?.email || 'N/A'}</Typography>
            <Typography variant="body1">Phone: {venue.contactInfo?.phone || 'N/A'}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Technical Specifications</Typography>
            <Typography variant="body1">{venue.technicalSpecs}</Typography>
          </Grid>
        </Grid>
        <Button
  component={Link}
  to={`/venues/${venue._id}/edit`}
  variant="contained"
  color="primary"
  style={{ marginTop: '20px', marginRight: '10px' }}
>
  Edit Venue
</Button>
        <Button
          component={Link}
          to="/admin/venues"
          variant="outlined"
          color="secondary"
          style={{ marginTop: '20px' }}
        >
          Back to Venues
        </Button>
      </Paper>
    </Container>
  );
};

export default VenueDetail;