// Updated instruments.tsx with effects identifier

export const instruments = [
  {
    label: 'Rhythm Section',
    options: [
      { id: 'acoustic-piano', name: 'Acoustic Piano'},
      { id: 'electric-piano', name: 'Electric Piano' },
      { id: 'organ', name: 'Organ' },
      { id: 'keyboard', name: 'Keyboard' },
      { id: 'electric-organ', name: 'Electric Organ' },
      { id: 'rhodes', name: 'Rhodes' },
      { id: 'acoustic-guitar', name: 'Acoustic Guitar' },
      { id: 'electric-guitar', name: 'Electric Guitar' },
      { id: 'acoustic-bass', name: 'Acoustic Bass' },
      { id: 'electric-bass', name: 'Electric Bass' },
      { id: 'drum-kit', name: 'Acoustic Drum Kit' },
      { id: 'electronic-drum-kit', name: 'Electronic Drum Kit' },
    ],
  },
  {
    label: 'Brass Section',
    options: [
      { id: 'trumpet', name: 'Trumpet' },
      { id: 'flugelhorn', name: 'Flugelhorn' },
      { id: 'cornet', name: 'Cornet' },
      { id: 'trombone', name: 'Trombone' },
      { id: 'bass-trombone', name: 'Bass Trombone' },
      { id: 'french-horn', name: 'French Horn' },
      { id: 'tuba', name: 'Tuba' },
      { id: 'euphonium', name: 'Euphonium' },
      { id: 'sousaphone', name: 'Sousaphone' },
      { id: 'bugle', name: 'Bugle' },
      { id: 'baritone-horn', name: 'Baritone Horn' },
      { id: 'tenor-horn', name: 'Tenor Horn' },
      { id: 'alto-horn', name: 'Alto Horn' },
      { id: 'mellophone', name: 'Mellophone' },
    ],
  },
  {
    label: 'Reed Section',
    options: [
      { id: 'alto-sax', name: 'Alto Saxophone' },
      { id: 'tenor-sax', name: 'Tenor Saxophone' },
      { id: 'baritone-sax', name: 'Baritone Saxophone' },
      { id: 'soprano-sax', name: 'Soprano Saxophone' },
      { id: 'clarinet', name: 'Clarinet' },
      { id: 'bass-clarinet', name: 'Bass Clarinet' },
      { id: 'flute', name: 'Acoustic Flute' },
      { id: 'electric-flute', name: 'Electric Flute' },
      { id: 'piccolo', name: 'Piccolo' },
      { id: 'oboe', name: 'Oboe' },
      { id: 'english-horn', name: 'English Horn' },
      { id: 'bassoon', name: 'Bassoon' },
      { id: 'contrabassoon', name: 'Contrabassoon' },
      { id: 'bagpipes', name: 'Bagpipes' },
      { id: 'didgeridoo', name: 'Didgeridoo' },
      { id: 'harmonica', name: 'Harmonica' },
      { id: 'accordion', name: 'Accordion' },
      { id: 'melodica', name: 'Melodica' },
      { id: 'pan-flute', name: 'Pan Flute' },
      { id: 'shakuhachi', name: 'Shakuhachi' },
      { id: 'ocarina', name: 'Ocarina' },
      { id: 'tin-whistle', name: 'Tin Whistle' },
      { id: 'recorder', name: 'Recorder' },
      { id: 'saxophone', name: 'Saxophone' },
    ],
  },
  {
    label: 'Strings',
    options: [
      { id: 'violin', name: 'Acoustic Violin' },
      { id: 'electric-violin', name: 'Electric Violin' },
      { id: 'viola', name: 'Acoustic Viola' },
      { id: 'electric-viola', name: 'Electric Viola' },
      { id: 'cello', name: 'Acoustic Cello' },
      { id: 'electric-cello', name: 'Electric Cello' },
      { id: 'double-bass', name: 'Double Bass' },
      { id: 'electric-upright-bass', name: 'Electric Upright Bass' },
      { id: 'mandolin', name: 'Acoustic Mandolin' },
      { id: 'electric-mandolin', name: 'Electric Mandolin' },
      { id: 'harp', name: 'Harp' },
      { id: 'banjo', name: 'Banjo' },
      { id: 'ukulele', name: 'Ukulele' },
      { id: 'guzheng', name: 'Guzheng' },
      { id: 'koto', name: 'Koto' },
      { id: 'sitar', name: 'Sitar' },
      { id: 'oud', name: 'Oud' },
      { id: 'lap-steel', name: 'Lap Steel Guitar' },
      { id: 'pedal-steel', name: 'Pedal Steel Guitar' },
      { id: 'slide-guitar', name: 'Slide Guitar' },
      { id: 'dobro', name: 'Dobro' },
      { id: 'autoharp', name: 'Autoharp' },
      { id: 'zither', name: 'Zither' },
      { id: 'hurdy-gurdy', name: 'Hurdy Gurdy' },
      { id: 'nyckelharpa', name: 'Nyckelharpa' },
      { id: 'santoor', name: 'Santoor' },
      { id: 'saz', name: 'Saz' },
      { id: 'bouzouki', name: 'Bouzouki' },
      { id: 'balalaika', name: 'Balalaika' },
      { id: 'domra', name: 'Domra' },
      { id: 'pipa', name: 'Pipa' },
      { id: 'erhu', name: 'Erhu' },
      { id: 'gamelan', name: 'Gamelan' },
      { id: 'kora', name: 'Kora' },
      { id: 'mbira', name: 'Mbira' },
      { id: 'santur', name: 'Santur' },
      { id: 'yangqin', name: 'Yangqin' },
      { id: 'qanun', name: 'Qanun' },
      { id: 'dulcimer', name: 'Dulcimer' },
      { id: 'psaltery', name: 'Psaltery' },
      { id: 'harp-guitar', name: 'Harp Guitar' },
      { id: 'harp-lute', name: 'Harp Lute' },
      { id: 'guitar-viol', name: 'Guitar Viol' },
    ],
  },
  {
    label: 'Percussion/Mallet',
    options: [
      { id: 'vibraphone', name: 'Vibraphone' },
      { id: 'marimba', name: 'Marimba' },
      { id: 'xylophone', name: 'Xylophone' },
      { id: 'congas', name: 'Acoustic Congas' },
      { id: 'electronic-congas', name: 'Electronic Congas' },
      { id: 'bongos', name: 'Bongos' },
      { id: 'timbales', name: 'Timbales' },
      { id: 'steel-pan', name: 'Steel Pan' },
      { id: 'tabla', name: 'Tabla' },
      { id: 'djembe', name: 'Djembe' },
      { id: 'cajon', name: 'Cajon' },
      { id: 'bass-drum', name: 'Bass Drum' },
      { id: 'snare-drum', name: 'Snare Drum' },
      { id: 'tambourine', name: 'Tambourine' },
      { id: 'shaker', name: 'Shaker' },
      { id: 'triangle', name: 'Triangle' },
      { id: 'cowbell', name: 'Cowbell' },
      { id: 'woodblock', name: 'Woodblock' },
      { id: 'percussion', name: 'Percussion' },
    ],
  },
  {
    label: 'Modern/Electronic',
    options: [
      { id: 'synthesizer', name: 'Synthesizer' },
      { id: 'sampler', name: 'Sampler' },
      { id: 'electronic-wind', name: 'EWI' },
      { id: 'modular-synth', name: 'Modular Synthesizer' },
      { id: 'theremin', name: 'Theremin' },
      { id: 'keytar', name: 'Keytar' },
      { id: 'drum-machine', name: 'Drum Machine' },
      { id: 'sequencer', name: 'Sequencer' },
      { id: 'turntable', name: 'Turntable' },
    ],
  },
  {
    label: 'Vocal',
    options: [
      { id: 'vocals', name: 'Vocals' },
      { id: 'beatbox', name: 'Beatbox' },
      { id: 'throat-singing', name: 'Throat Singing' },
      { id: 'tuvan', name: 'Tuvan' },
      { id: 'inuit', name: 'Inuit' },
      { id: 'yodeling', name: 'Yodeling' },
      { id: 'scat', name: 'Scat' },
      { id: 'rap', name: 'Rap' },
      { id: 'spoken-word', name: 'Spoken Word' },
      { id: 'vocoder', name: 'Vocoder' },
    ],
  },
  {
    label: 'Experimental',
    options: [
      { id: 'found-objects', name: 'Found Objects' },
      { id: 'metal-percussion', name: 'Metal Percussion' },
      { id: 'glass-harmonica', name: 'Glass Harmonica' },
      { id: 'waterphone', name: 'Waterphone' },
      { id: 'singing-bowl', name: 'Singing Bowl' },
      { id: 'circuit-bending', name: 'Circuit Bending' },
      { id: 'feedback-manipulation', name: 'Feedback Manipulation' },
      { id: 'tape-manipulation', name: 'Tape Manipulation' },
      { id: 'field-recording', name: 'Field Recording' },
      { id: 'granular-synthesis', name: 'Granular Synthesis' },
    ],
  },
  {
    label: 'Prepared',
    options: [
      { id: 'prepared-piano', name: 'Prepared Piano' },
      { id: 'prepared-guitar', name: 'Prepared Guitar' },
      { id: 'prepared-bass', name: 'Prepared Bass' },
      { id: 'prepared-drums', name: 'Prepared Drums' },
      { id: 'prepared-percussion', name: 'Prepared Percussion' },
      { id: 'prepared-strings', name: 'Prepared Strings' },
      { id: 'prepared-wind', name: 'Prepared Wind' },
      { id: 'prepared-electronics', name: 'Prepared Electronics' },
      { id: 'prepared-vocals', name: 'Prepared Vocals' },
      { id: 'prepared-instruments', name: 'Prepared Instruments' },
    ],
    },
];