import React from 'react';
import { Chip, Button } from '@mui/material';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BlockIcon from '@mui/icons-material/Block';
import EmailIcon from '@mui/icons-material/Email';
import { useBandView } from '../../../../contexts/BandViewContext';

interface StatusActionDisplayProps {
  status: 'pending' | 'approved' | 'rejected';
  bandName: string;
}

const StatusActionDisplay: React.FC<StatusActionDisplayProps> = ({ status, bandName }) => {
  const { viewMode } = useBandView();

  const handleBookBand = () => {
    const subject = encodeURIComponent(`Booking Inquiry for ${bandName}`);
    const body = encodeURIComponent(`I'm interested in booking ${bandName}.\n\nBand Details: ${window.location.href}`);
    window.location.href = `mailto:showrunnr@improvisedmusic.ie?subject=${subject}&body=${body}`;
  };

  if (viewMode === 'strand') {
    return (
      <Button
        variant="contained"
        color="primary"
        startIcon={<EmailIcon />}
        onClick={handleBookBand}
        sx={{
          position: 'absolute',
          top: 20,
          right: 20,
          backdropFilter: 'blur(4px)',
          backgroundColor: 'rgba(25, 118, 210, 0.9)',
          '&:hover': {
            backgroundColor: 'rgba(25, 118, 210, 1)',
          }
        }}
      >
        Book This Band
      </Button>
    );
  }

  const getStatusConfig = () => {
    switch (status) {
      case 'approved':
        return {
          icon: <CheckCircleIcon />,
          label: 'Approved',
          color: 'success'
        };
      case 'rejected':
        return {
          icon: <BlockIcon />,
          label: 'Rejected',
          color: 'error'
        };
      default:
        return {
          icon: <PendingIcon />,
          label: 'Pending Review',
          color: 'warning'
        };
    }
  };

  const config = getStatusConfig();

  return (
    <Chip
      icon={config.icon}
      label={config.label}
      color={config.color as any}
      sx={{
        position: 'absolute',
        top: 20,
        right: 20,
        backdropFilter: 'blur(4px)',
        backgroundColor: 'rgba(0,0,0,0.5)',
        '& .MuiChip-icon': {
          color: 'inherit'
        }
      }}
    />
  );
};

export default StatusActionDisplay;