import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  CircularProgress
} from '@mui/material';

interface Artist {
  _id: string;
  artistName: string;
}

interface ProspectWithConversion {
  _id?: string;
  strandId: string;
  date: Date | null;
  eventConcept: string;
  potentialArtists: Array<string | Artist>;
  venueIdeas: string[];
  fees: number;
  boxOffice: number;
  expenses: number;
  sponsorship: number;
  net: number;
  notes: string;
  status: 'idea' | 'negotiation' | 'prospective' | 'confirmed';
  isConverting?: boolean;
  hasPerformance?: boolean;
}

interface ProspectConversionDialogProps {
  open: boolean;
  onClose: () => void;
  convertingProspect: ProspectWithConversion | null;
  conversionLoading: boolean;
  onConfirm: (prospect: ProspectWithConversion) => void;
}

const ProspectConversionDialog: React.FC<ProspectConversionDialogProps> = ({
  open,
  onClose,
  convertingProspect,
  conversionLoading,
  onConfirm
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Convert to Performance</DialogTitle>
      <DialogContent>
        <Typography>
          This will create a new performance from this prospect. The performance will be:
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Typography>• Linked to strand: {convertingProspect?.strandId}</Typography>
          <Typography>• Artist: {
            typeof convertingProspect?.potentialArtists[0] === 'string' 
              ? convertingProspect?.potentialArtists[0] 
              : convertingProspect?.potentialArtists[0]?.artistName
          }</Typography>
          <Typography>• Date: {convertingProspect?.date?.toLocaleDateString()}</Typography>
          <Typography>• Duration: 3 hours</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={onClose} 
          disabled={conversionLoading}
        >
          Cancel
        </Button>
        <Button
          onClick={() => convertingProspect && onConfirm(convertingProspect)}
          variant="contained"
          color="primary"
          disabled={conversionLoading}
        >
          {conversionLoading ? <CircularProgress size={24} /> : 'Create Performance'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProspectConversionDialog;