export const saveToStorage = (key: string, value: any) => {
    try {
      sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error('Error saving to sessionStorage:', error);
    }
  };
  
  export const loadFromStorage = (key: string) => {
    try {
      const value = sessionStorage.getItem(key);
      return value ? JSON.parse(value) : null;
    } catch (error) {
      console.error('Error loading from sessionStorage:', error);
      return null;
    }
  };
  
  export const clearStorage = (key: string) => {
    sessionStorage.removeItem(key);
  };