// src/components/IconRenderer.tsx

import React from 'react';
import {
  Event as EventIcon,
  CalendarToday as CalendarIcon,
  People as PeopleIcon,
  Announcement as AnnouncementIcon,
  MusicNote as MusicNoteIcon,
  Star as StarIcon,
  EditCalendar as EditCalendarIcon,
  Groups as GroupsIcon,
  Celebration as CelebrationIcon,
  EmojiEvents as AwardIcon,
  LocationCity as VenueIcon,
  Campaign as CampaignIcon,
  LibraryMusic as LibraryMusicIcon,
  RecordVoiceOver as VoiceIcon,
  TheaterComedy as TheaterIcon,
} from '@mui/icons-material';

export interface IconOption {
  value: string;
  label: string;
  icon: React.ReactElement;
}

export const iconOptions: IconOption[] = [
  { value: 'Event', label: 'Default Event', icon: <EventIcon /> },
  { value: 'Calendar', label: 'Calendar', icon: <CalendarIcon /> },
  { value: 'People', label: 'People', icon: <PeopleIcon /> },
  { value: 'Announcement', label: 'Announcement', icon: <AnnouncementIcon /> },
  { value: 'Music', label: 'Music', icon: <MusicNoteIcon /> },
  { value: 'Star', label: 'Star', icon: <StarIcon /> },
  { value: 'EditCalendar', label: 'Schedule', icon: <EditCalendarIcon /> },
  { value: 'Groups', label: 'Groups', icon: <GroupsIcon /> },
  { value: 'Celebration', label: 'Celebration', icon: <CelebrationIcon /> },
  { value: 'Award', label: 'Award', icon: <AwardIcon /> },
  { value: 'Venue', label: 'Venue', icon: <VenueIcon /> },
  { value: 'Campaign', label: 'Announcement', icon: <CampaignIcon /> },
  { value: 'LibraryMusic', label: 'Library', icon: <LibraryMusicIcon /> },
  { value: 'Voice', label: 'Performance', icon: <VoiceIcon /> },
  { value: 'Theater', label: 'Theater', icon: <TheaterIcon /> },
];

export const IconRenderer: React.FC<{ iconName: string }> = ({ iconName }) => {
  const iconOption = iconOptions.find((option) => option.value === iconName);
  return iconOption ? iconOption.icon : <EventIcon />;
};

export default IconRenderer;