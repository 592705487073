import React from 'react';

const ShowrunnrLogo = ({ width = 2000, height = 320 }) => {
  // Generate random values for equalizer bars
  const bars = Array.from({ length: 5 }, () => ({
    baseHeight: 40 + Math.random() * 60,
    duration: 1 + Math.random() * 0.5,
    delay: Math.random(),
  }));

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1300 200"
      width={width}
      height={height}
      role="img"
      aria-labelledby="showrunnrLogoTitle"
    >
      <title id="showrunnrLogoTitle">Showrunnr Logo</title>
      <defs>
        <linearGradient id="textGradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor="#d8b5a6" />
          <stop offset="100%" stopColor="#50E3C2" />
        </linearGradient>
        <linearGradient id="barGradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stopColor="#50E3C2" />
          <stop offset="100%" stopColor="#4A90E2" />
        </linearGradient>
      </defs>
      <style>
        {`
.text {
  font-family: 'Impact', 'Haettenschweiler', 'Arial Narrow Bold', sans-serif;
  font-weight: normal; /* Impact is already bold */
  font-size: 210px;
  fill: url(#textGradient);
}
          .icon {
            fill: #4A90E2;
          }
          .equalizer {
            fill: url(#barGradient);
          }
        `}
      </style>

      {/* Enlarged and Integrated Musical Note */}
      <g transform="translate(40, 20) scale(1.6)">
        <path
          d="M60 10 L60 90 C60 100 50 100 50 90 L50 30 Z"
          className="icon"
        />
        <circle cx="50" cy="100" r="15" className="icon" />
      </g>

      {/* Dynamic Equalizer Bars */}
      <g transform="translate(160, 40)">
        {bars.map((bar, index) => {
          const x = index * 25;
          const { baseHeight, duration, delay } = bar;
          const maxHeight = 100;
          const minHeight = 20;
          return (
            <rect
              key={index}
              x={x}
              y={120 - baseHeight}
              width="18"
              height={baseHeight}
              className="equalizer"
            >
              <animate
                attributeName="height"
                values={`${baseHeight};${maxHeight};${minHeight};${baseHeight}`}
                keyTimes="0;0.3;0.6;1"
                dur={`${duration}s`}
                repeatCount="indefinite"
                begin={`${delay}s`}
              />
              <animate
                attributeName="y"
                values={`${120 - baseHeight};${120 - maxHeight};${120 - minHeight};${120 - baseHeight}`}
                keyTimes="0;0.3;0.6;1"
                dur={`${duration}s`}
                repeatCount="indefinite"
                begin={`${delay}s`}
              />
            </rect>
          );
        })}
      </g>

      {/* Text with gradient */}
      <text x="300" y="140" className="text">
        show.runnr
      </text>
    </svg>
  );
};

export default ShowrunnrLogo;