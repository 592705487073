import React from 'react';
import { Grid, TextField, Tooltip, Autocomplete, Chip } from '@mui/material';
import { BandRecommendationFormData } from '../../types/BandRecommendations';
import jazzSubgenres from '../../data/jazzSubgenres';

interface RecommendationDetailsProps {
  formData: BandRecommendationFormData;
  setFormData: React.Dispatch<React.SetStateAction<BandRecommendationFormData>>;
}

const RecommendationDetails: React.FC<RecommendationDetailsProps> = ({ formData, setFormData }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleGenreChange = (event: React.SyntheticEvent, value: string[]) => {
    setFormData(prevData => ({
      ...prevData,
      genres: value.slice(0, 3) // Limit to 3 genres
    }));
  };

  const handleSimilarArtistsChange = (event: React.SyntheticEvent, value: string[]) => {
    setFormData(prevData => ({
      ...prevData,
      similarArtists: value
    }));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Tooltip title="Select up to 3 relevant jazz sub-genres.">
          <Autocomplete
            multiple
            id="genres"
            options={jazzSubgenres}
            value={formData.genres}
            onChange={handleGenreChange}
            renderInput={params => (
              <TextField {...params} variant="outlined" label="Jazz Sub-genres (Select up to 3)" fullWidth />
            )}
            renderTags={(value: string[], getTagProps) =>
              value.map((option: string, index: number) => (
                <Chip variant="outlined" label={option} {...getTagProps({ index })} key={index} />
              ))
            }
            limitTags={3}
            fullWidth
          />
        </Tooltip>
      </Grid>
      <Grid item xs={12}>
        <Tooltip title="List similar artists for reference.">
          <Autocomplete
            multiple
            freeSolo
            id="similarArtists"
            options={[]} // You can add preset options here if desired
            value={formData.similarArtists || []}
            onChange={handleSimilarArtistsChange}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="Similar Artists"
                placeholder="Type and press enter to add artists"
                fullWidth
              />
            )}
            renderTags={(value: string[], getTagProps) =>
              value.map((option: string, index: number) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                  key={index}
                />
              ))
            }
            fullWidth
          />
        </Tooltip>
      </Grid>
      <Grid item xs={12}>
        <Tooltip title="Any additional notes or recommendations.">
          <TextField
            name="recommendationNotes"
            label="Recommendation Notes"
            multiline
            rows={4}
            value={formData.recommendationNotes}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default RecommendationDetails;