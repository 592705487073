import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent, Typography, CardActions, Button, Chip, Box } from '@mui/material';
import { Event, EventType } from '../types/mongodb'; // Adjust the import path as needed

interface EventCardProps {
  event: Event;
}

const EventCard: React.FC<EventCardProps> = ({ event }) => {
  const formatDateRange = (start: string | Date, end: string | Date) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    return `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
  };

  const getDateDisplay = () => {
    if (event.eventType === EventType.Single && event.date) {
      return new Date(event.date).toLocaleDateString();
    } else if (event.startDate && event.endDate) {
      return formatDateRange(event.startDate, event.endDate);
    }
    return 'Dates not specified';
  };

  const getVenueDisplay = () => {
    if (event.isOnline) {
      return 'Online Event';
    } else if (typeof event.venue === 'object' && event.venue?.name) {
      return event.venue.name;
    }
    return 'Venue not specified';
  };

  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          {event.name}
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          {event.eventType === EventType.Single ? 'Date' : 'Dates'}: {getDateDisplay()}
        </Typography>
        <Typography variant="body2" gutterBottom>
          Venue: {getVenueDisplay()}
        </Typography>
        <Typography variant="body2" gutterBottom>
          Type: {event.eventType}
        </Typography>
        <Box sx={{ mt: 1 }}>
          <Chip 
            label={event.status} 
            color={
              event.status === 'confirmed' ? 'success' : 
              event.status === 'cancelled' ? 'error' : 
              'default'
            } 
            size="small" 
            sx={{ mr: 1 }}
          />
          {event.focus && (
            <Chip 
              label={`Focus: ${event.focus}`} 
              size="small" 
              variant="outlined"
            />
          )}
        </Box>
      </CardContent>
      <CardActions>
        <Button size="small" component={Link} to={`/events/${event._id}`}>
          View Details
        </Button>
      </CardActions>
    </Card>
  );
};

export default EventCard;