import React from 'react';
import { Box, Container, Grid, Typography, Link, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Link as RouterLink } from 'react-router-dom';

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: 'primary.main',
        color: 'white',
        py: 6,
        mt: 'auto',
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Show Runnr
            </Typography>
            <Typography variant="body2">
              Innovative event management solutions.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Quick Links
            </Typography>
            <Link component={RouterLink} to="/events" color="inherit" display="block">Events</Link>
            <Link component={RouterLink} to="/bands" color="inherit" display="block">Bands</Link>
            <Link component={RouterLink} to="/venues" color="inherit" display="block">Venues</Link>
            <Link component={RouterLink} to="/publicstrands" color="inherit" display="block">Opportunities</Link>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Connect With Us
            </Typography>
            <IconButton 
              color="inherit" 
              aria-label="Facebook"
              sx={{ 
                color: 'white',
                '&:hover': { color: 'secondary.main' }
              }}
            >
              <FacebookIcon />
            </IconButton>
            <IconButton 
              color="inherit" 
              aria-label="Twitter"
              sx={{ 
                color: 'white',
                '&:hover': { color: 'secondary.main' }
              }}
            >
              <TwitterIcon />
            </IconButton>
            <IconButton 
              color="inherit" 
              aria-label="Instagram"
              sx={{ 
                color: 'white',
                '&:hover': { color: 'secondary.main' }
              }}
            >
              <InstagramIcon />
            </IconButton>
            <IconButton 
              color="inherit" 
              aria-label="YouTube"
              sx={{ 
                color: 'white',
                '&:hover': { color: 'secondary.main' }
              }}
            >
              <YouTubeIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Typography variant="body2" align="center">
            © {new Date().getFullYear()} Show Runnr. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;