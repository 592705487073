import React, { useState, useEffect } from 'react';
import { Typography, Card, CardContent, Button, Chip, Box, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://api.imc.show';

interface Strand {
  _id: string;
  name: string;
  description: string;
  type: string;
  startDate: string;
  endDate: string;
  status: string;
  published: boolean;
}

const strandRoutes: { [key: string]: string } = {
  'Better Live': '/recommend',
  'Navigator': '/navigator',
  'Ban Bam': '/banbam',
};

const AvailableOpportunities: React.FC = () => {
  const navigate = useNavigate();
  const [strands, setStrands] = useState<Strand[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchPublishedStrands();
  }, []);

  const fetchPublishedStrands = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get<Strand[]>(`${API_BASE_URL}/api/strands`);
      const publishedStrands = response.data.filter(strand => strand.published);
      setStrands(publishedStrands);
    } catch (error) {
      console.error('Error fetching published strands:', error);
      setError('Failed to fetch published strands. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleApply = (strand: Strand) => {
    const route = strandRoutes[strand.name] || '/recommend';
    navigate(route);
  };

  const getRemainingDays = (endDate: string): number => {
    const today = new Date();
    const deadline = new Date(endDate);
    const diffTime = deadline.getTime() - today.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const RemainingDaysChip: React.FC<{ days: number }> = ({ days }) => {
    let color: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' = 'default';
    let label = `${days} days left`;

    if (days > 30) {
      color = 'success';
    } else if (days > 14) {
      color = 'warning';
    } else if (days > 0) {
      color = 'error';
    } else {
      label = 'Closed';
      color = 'default';
    }

    return (
      <Chip 
        label={label}
        color={color}
        size="small"
        sx={{ position: 'absolute', top: 16, right: 16 }}
      />
    );
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <>
      {strands.length === 0 ? (
        <Typography>No open opportunities available at the moment.</Typography>
      ) : (
        strands.map((strand) => (
          <Card key={strand._id} sx={{ mb: 3, position: 'relative' }}>
            <RemainingDaysChip days={getRemainingDays(strand.endDate)} />
            <CardContent>
              <Typography variant="h6">{strand.name}</Typography>
              <Typography variant="subtitle2" color="text.secondary">
                {strand.type} | {new Date(strand.startDate).toLocaleDateString()} - {new Date(strand.endDate).toLocaleDateString()}
              </Typography>
              <Typography variant="body2" paragraph>
                {strand.description}
              </Typography>
              <Box sx={{ mb: 2 }}>
                <Chip 
                  label={strand.status === 'confirmed' ? "Open for Submissions" : "Closed"} 
                  color={strand.status === 'confirmed' ? "success" : "error"} 
                  size="small"
                />
                {strand.status === 'confirmed' && (
                  <Chip 
                    label={`Deadline: ${new Date(strand.endDate).toLocaleDateString()}`} 
                    color="primary" 
                    size="small"
                    sx={{ ml: 1 }} 
                  />
                )}
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button 
                  variant="contained" 
                  size="small"
                  color="primary"
                  onClick={() => handleApply(strand)}
                  disabled={strand.status !== 'confirmed' || getRemainingDays(strand.endDate) <= 0}
                >
                  Apply Now
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={() => navigate('/banbamfaq')}
                >
                  Guidelines
                </Button>
              </Box>
            </CardContent>
          </Card>
        ))
      )}
    </>
  );
};

export default AvailableOpportunities;