import React, { useState, useEffect, useCallback } from 'react';
import { 
  Accordion, 
  AccordionSummary, 
  AccordionDetails, 
  Typography, 
  Box,
  Tabs,
  Tab,
  CircularProgress,
  Alert
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import PastEventsAnalysis from '../components/PastEventsAnalysis';

const API_BASE_URL = process.env.REACT_APP_API_URL;

interface TicketType {
  name: string;
  quantity: number;
  quantity_sold: number;
  price: number;
  revenue: number;
}

interface TitoEvent {
  id: string;
  title: string;
  slug: string;
  start_date: string;
  end_date: string;
  totalSold: number;
  totalAvailable: number;
  totalRevenue: number;
  ticketTypes: TicketType[];
}

// This interface matches what's expected in PastEventsAnalysis
interface PastEvent {
  id: string;
  title: string;
  start_date: string;
  totalSold: number;
  totalAvailable: number;
  totalRevenue: number;
  ticketTypes: TicketType[];
}

const TitoApiTestPage: React.FC = () => {
  const [events, setEvents] = useState<TitoEvent[]>([]);
  const [pastEvents, setPastEvents] = useState<TitoEvent[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [tabValue, setTabValue] = useState(0);

  const fetchEventDetails = useCallback(async (eventSlug: string): Promise<TitoEvent | null> => {
    try {
      const [eventResponse, registrationsResponse, releasesResponse] = await Promise.all([
        axios.get(`${API_BASE_URL}/api/tito/events/${eventSlug}`),
        axios.get(`${API_BASE_URL}/api/tito/${eventSlug}/registrations`),
        axios.get(`${API_BASE_URL}/api/tito/${eventSlug}/releases`)
      ]);

      const event = eventResponse.data;
      const registrations = registrationsResponse.data.registrations;
      const releases = releasesResponse.data.releases;

      // Corrected mapping: Use tickets_count instead of quantity_sold
      const ticketTypes: TicketType[] = releases.map((release: any) => ({
        name: release.title,
        quantity: release.quantity,
        quantity_sold: release.tickets_count, // Corrected field
        price: release.price,
        revenue: release.tickets_count * release.price // Corrected calculation
      }));

      const totalSold = ticketTypes.reduce((sum, type) => sum + type.quantity_sold, 0);
      const totalAvailable = ticketTypes.reduce((sum, type) => sum + type.quantity, 0);
      const totalRevenue = ticketTypes.reduce((sum, type) => sum + type.revenue, 0);

      return {
        ...event,
        totalSold,
        totalAvailable,
        totalRevenue,
        ticketTypes
      };
    } catch (error) {
      console.error(`Error fetching details for event ${eventSlug}:`, error);
      return null;
    }
  }, []);

  const fetchEvents = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${API_BASE_URL}/api/tito/events`);
      const fetchedEvents = response.data.events || [];
      
      const currentDate = new Date();
      const currentEvents: TitoEvent[] = [];
      const pastEventsData: TitoEvent[] = [];

      console.log(`Total events fetched: ${fetchedEvents.length}`);
console.log(`Past events: ${pastEventsData.length}`);

      for (const event of fetchedEvents) {
        const eventWithDetails = await fetchEventDetails(event.slug);
        if (eventWithDetails) {
          const eventEndDate = new Date(eventWithDetails.end_date);
          if (eventEndDate >= currentDate) {
            currentEvents.push(eventWithDetails);
          } else {
            pastEventsData.push(eventWithDetails);
          }
        }
      }

      setEvents(currentEvents);
      setPastEvents(pastEventsData);
    } catch (err: any) {
      console.error('Error fetching events:', err.response || err);
      setError(err.message || 'Failed to fetch events');
    } finally {
      setLoading(false);
    }
  }, [fetchEventDetails]);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  const transformTitoData = (event: TitoEvent | undefined): PastEvent | null => {
    if (!event) {
      console.error('Event is undefined');
      return null;
    }
  
    try {
      console.log('Processing event:', event); // Add this line for debugging
  
      const totalSold = event.ticketTypes?.reduce((sum: number, type: TicketType) => sum + (type.quantity_sold || 0), 0) || 0;
      const totalRevenue = event.ticketTypes?.reduce((sum: number, type: TicketType) => sum + ((type.quantity_sold || 0) * (type.price || 0)), 0) || 0;
  
      return {
        id: event.id?.toString() || 'unknown',
        title: event.title || 'Untitled Event',
        start_date: event.start_date || new Date().toISOString(),
        totalSold: event.totalSold || totalSold,
        totalAvailable: event.totalAvailable || 0,
        totalRevenue: event.totalRevenue || totalRevenue,
        ticketTypes: event.ticketTypes?.map((type: TicketType) => ({
          name: type.name || 'Unknown Ticket',
          quantity: type.quantity || 0,
          quantity_sold: type.quantity_sold || 0,
          price: type.price || 0,
          revenue: (type.quantity_sold || 0) * (type.price || 0)
        })) || []
      };
    } catch (error) {
      console.error('Error transforming event data:', error, 'Event:', event);
      return null;
    }
  };

  const validPastEvents: PastEvent[] = pastEvents
    .map(transformTitoData)
    .filter((event): event is PastEvent => event !== null && event.totalSold > 0 && event.totalRevenue > 0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ color: 'error.main', textAlign: 'center', mt: 4 }}>
        <Alert severity="error">Error: {error}</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Tabs value={tabValue} onChange={handleTabChange} sx={{ mb: 3 }}>
        <Tab label="Current Events" />
        <Tab label="Past Events Analysis" />
      </Tabs>

      {tabValue === 0 && (
        <>
          <Typography variant="h4" sx={{ mb: 3 }}>Current Events</Typography>
          {events.length === 0 ? (
            <Typography>No current events available.</Typography>
          ) : (
            events.map((event) => (
              <Accordion key={event.id} sx={{ mb: 2 }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{event.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography><strong>Total Sold:</strong> {event.totalSold}</Typography>
                  <Typography><strong>Total Available:</strong> {event.totalAvailable}</Typography>
                  <Typography><strong>Total Revenue:</strong> €{event.totalRevenue.toFixed(2)}</Typography>
                  {event.ticketTypes.map((type, index) => (
                    <Box key={index} sx={{ mt: 2 }}>
                      <Typography><strong>{type.name}:</strong></Typography>
                      <Typography>Sold: {type.quantity_sold} / {type.quantity}</Typography>
                      <Typography>Revenue: €{type.revenue.toFixed(2)}</Typography>
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))
          )}
        </>
      )}

      {tabValue === 1 && (
        <>
          {validPastEvents.length === 0 ? (
            <Box sx={{ color: 'warning.main', textAlign: 'center', mt: 4 }}>
              <Alert severity="warning">
                No valid past event data available. Total events received: {pastEvents.length}. 
                Please check the data format and ensure all required fields are present.
              </Alert>
              <Typography variant="h6" sx={{ mt: 2 }}>Debug Information:</Typography>
              <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                {JSON.stringify(pastEvents, null, 2)}
              </pre>
            </Box>
          ) : (
            <PastEventsAnalysis pastEvents={validPastEvents} />
          )}
        </>
      )}
    </Box>
  );
};

export default TitoApiTestPage;