import React from 'react';
import { Grid, Card, CardContent, CardActions, Typography, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { DashboardStats } from '../../types/dashboard';

interface DashboardStatsCardsProps {
  stats: DashboardStats;
  onTabChange: (tabIndex: number) => void;
}

const DashboardStatsCards: React.FC<DashboardStatsCardsProps> = ({ stats, onTabChange }) => {
  return (
    <Grid container spacing={4}>
      {/* Applications Card */}
      <Grid item xs={12} md={3}>
        <Card sx={{ 
          backgroundColor: '#4A6F28', 
          color: '#fff',
          height: '100%', // Full height
          display: 'flex',
          flexDirection: 'column'
        }}>
          <CardContent sx={{ flex: 1 }}>
            <Typography variant="h5" component="h2" gutterBottom>
              Applications Received
            </Typography>
            <Typography variant="h2" component="p">
              {stats.banBamApplications + stats.navigatorApplications}
            </Typography>
            <Typography variant="body2" sx={{ mb: 1 }}>
              BAN BAM: {stats.banBamApplications} | Navigator: {stats.navigatorApplications}
            </Typography>
          </CardContent>
          <CardActions sx={{ flexDirection: 'column', alignItems: 'stretch', px: 2, pb: 2 }}>
            <Button 
              fullWidth
              component={Link} 
              to="/admin/banbam-applications" 
              sx={{ color: '#fff', mb: 1 }}
            >
              View BAN BAM ({stats.banBamApplications})
            </Button>
            <Button 
              fullWidth
              component={Link} 
              to="/admin/navigator-applications" 
              sx={{ color: '#fff' }}
            >
              View Navigator ({stats.navigatorApplications})
            </Button>
          </CardActions>
        </Card>
      </Grid>

      {/* Upcoming Events Card */}
      <Grid item xs={12} md={3}>
        <Card sx={{ 
          backgroundColor: '#182d42', 
          color: '#fff',
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}>
          <CardContent sx={{ flex: 1 }}>
            <Typography variant="h5" component="h2" gutterBottom>
              Upcoming Events
            </Typography>
            <Typography variant="h2" component="p">
              {stats.upcomingEvents}
            </Typography>
            <Typography variant="body2">
              Events scheduled from today onwards
            </Typography>
          </CardContent>
          <CardActions sx={{ px: 2, pb: 2 }}>
            <Button 
              fullWidth
              component={Link} 
              to="/admin/events" 
              sx={{ color: '#fff' }}
            >
              View All Events
            </Button>
          </CardActions>
        </Card>
      </Grid>

      {/* Total Bands Card */}
      <Grid item xs={12} md={3}>
        <Card sx={{ 
          backgroundColor: '#9F5127', 
          color: '#fff',
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}>
          <CardContent sx={{ flex: 1 }}>
            <Typography variant="h5" component="h2" gutterBottom>
              Total Bands
            </Typography>
            <Typography variant="h2" component="p">
              {stats.totalBands}
            </Typography>
            <Typography variant="body2">
              Total bands in database
            </Typography>
          </CardContent>
          <CardActions sx={{ px: 2, pb: 2 }}>
            <Button 
              fullWidth
              component={Link} 
              to="/admin/bands" 
              sx={{ color: '#fff' }}
            >
              View All Bands
            </Button>
          </CardActions>
        </Card>
      </Grid>

      {/* Confirmed Performances Card */}
      <Grid item xs={12} md={3}>
        <Card sx={{ 
          backgroundColor: '#115971', 
          color: '#fff',
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}>
          <CardContent sx={{ flex: 1 }}>
            <Typography variant="h5" component="h2" gutterBottom>
              Confirmed Performances
            </Typography>
            <Typography variant="h2" component="p">
              {stats.confirmedPerformances}
            </Typography>
            <Box sx={{ flex: 1 }} /> {/* Spacer */}
          </CardContent>
          <CardActions sx={{ px: 2, pb: 2 }}>
            <Button 
              fullWidth
              onClick={() => onTabChange(2)} 
              sx={{ color: '#fff' }}
            >
              View Confirmed
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};

export default DashboardStatsCards;