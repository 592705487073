import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { CircularProgress, Typography } from '@mui/material';
import axios from 'axios';
import { BandRecommendation } from '../types/BandRecommendations';
import BandRecommendationForm from '../components/BandRecommendationForm';

const BandEditPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [band, setBand] = useState<BandRecommendation | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchBand = async () => {
      try {
        const response = await axios.get<BandRecommendation>(
          `${process.env.REACT_APP_API_URL}/api/bandrecommendations/${id}`
        );
        setBand(response.data);
        setError(null);
      } catch (error) {
        console.error('Error fetching band recommendation:', error);
        setError('Failed to load band recommendation.');
      } finally {
        setLoading(false);
      }
    };

    fetchBand();
  }, [id]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error || !band) {
    return (
      <Typography color="error">
        {error || 'Band recommendation not found.'}
      </Typography>
    );
  }

  return (
    <BandRecommendationForm
      initialFormData={band}
      isEditMode={true}
    />
  );
};

export default BandEditPage;