// src/pages/AdminUserManagement.tsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import { Table, TableBody, TableCell, TableHead, TableRow, Select, MenuItem, Typography, Container } from '@mui/material';

interface User {
  _id: string;
  email: string;
  name: string;
  role: string;
}

const AdminUserManagement: React.FC = () => {
  const { user, setToken } = useAuth();
  const [users, setUsers] = useState<User[]>([]);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/users`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUsers(response.data);
      } catch (err: any) {
        setError('Failed to fetch users');
        console.error(err);
      }
    };

    fetchUsers();
  }, []);

  const handleRoleChange = async (userId: string, newRole: string) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`${process.env.REACT_APP_API_URL}/api/admin/users/${userId}/role`, { role: newRole }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUsers(prevUsers => prevUsers.map(user => user._id === userId ? { ...user, role: newRole } : user));
    } catch (err: any) {
      setError('Failed to update user role');
      console.error(err);
    }
  };

  if (!user || user.role !== 'admin') {
    return <Typography variant="h6">Access Denied</Typography>;
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Admin User Management</Typography>
      {error && <Typography color="error">{error}</Typography>}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Role</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map(u => (
            <TableRow key={u._id}>
              <TableCell>{u.name}</TableCell>
              <TableCell>{u.email}</TableCell>
              <TableCell>
                <Select
                  value={u.role}
                  onChange={(e) => handleRoleChange(u._id, e.target.value as string)}
                >
                  <MenuItem value="admin">Admin</MenuItem>
                  <MenuItem value="domain_user">Domain User</MenuItem>
                  <MenuItem value="guest">Guest</MenuItem>
                  <MenuItem value="artist">Artist</MenuItem>
                </Select>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
};

export default AdminUserManagement;