// src/pages/PersonnelListPage.tsx
import React from 'react';
import PersonnelList from '../components/PersonnelList';

const PersonnelListPage: React.FC = () => (
  <div>
    <h1>Personnel List</h1>
    <PersonnelList />
  </div>
);
export default PersonnelListPage;
