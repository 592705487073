import React, { useState, useEffect } from 'react';
import { Card, CardMedia, SxProps, Box, Skeleton, Theme } from '@mui/material';

export interface AdaptiveImageProps {
  src: string;
  alt: string;
  defaultHeight?: number;
  maxWidth?: number;
  onClick?: () => void;
  sx?: SxProps<Theme>;
}

type ImageOrientation = 'landscape' | 'portrait' | null;

const AdaptiveImage: React.FC<AdaptiveImageProps> = ({ 
  src, 
  alt, 
  defaultHeight = 400,
  maxWidth = 800,
  onClick,
  sx = {}
}) => {
  const [imageOrientation, setImageOrientation] = useState<ImageOrientation>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const img = new Image();
    img.src = src;

    img.onload = () => {
      const aspectRatio = img.width / img.height;
      setImageOrientation(aspectRatio >= 1 ? 'landscape' : 'portrait');
      setLoading(false);
    };

    img.onerror = () => {
      setError('Failed to load image');
      setLoading(false);
    };

    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [src]);

  if (loading) {
    return (
      <Skeleton 
        variant="rectangular" 
        width="100%" 
        height={defaultHeight}
        animation="wave"
      />
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          width: '100%',
          height: defaultHeight,
          maxWidth,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'grey.200',
          color: 'text.secondary',
          borderRadius: 1,
          ...sx
        }}
      >
        Failed to load image
      </Box>
    );
  }

  const containerStyles: SxProps<Theme> = {
    width: '100%',
    maxWidth,
    position: 'relative',
    paddingTop: imageOrientation === 'portrait' ? '133%' : '56.25%',
    overflow: 'hidden',
    borderRadius: 1,
    ...sx
  };

  const imageStyles: SxProps<Theme> = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.02)',
    },
    cursor: onClick ? 'pointer' : 'default'
  };

  return (
    <Box sx={containerStyles}>
      <CardMedia
        component="img"
        image={src}
        alt={alt}
        onClick={onClick}
        sx={imageStyles}
      />
    </Box>
  );
};

export default AdaptiveImage;