// src/components/layout/ArtistLayout.tsx
import React from 'react';
import { Outlet } from 'react-router-dom';
import { Container, Box } from '@mui/material';
import ArtistNav from '../navigation/ArtistNav';
import Footer from '../Footer';

const ArtistLayout: React.FC = () => {
  console.log("ArtistLayout: Rendering");
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <ArtistNav />
      <Container component="main" sx={{ flexGrow: 1, mt: 4, mb: 4 }}>
        <Outlet />
      </Container>
      <Footer />
    </Box>
  );
};

export default ArtistLayout;