import React from 'react';
import { Stack, Chip } from '@mui/material';

interface GenreTagsProps {
  genres: string[];
}

const GenreTags: React.FC<GenreTagsProps> = ({ genres }) => {
  return (
    <Stack direction="row" spacing={1} flexWrap="wrap">
      {genres.map((genre) => (
        <Chip
          key={genre}
          label={genre}
          sx={{
            backgroundColor: 'rgba(255,255,255,0.15)',
            backdropFilter: 'blur(10px)',
            color: 'white',
            '&:hover': {
              backgroundColor: 'rgba(255,255,255,0.25)',
            },
            mb: 1,
          }}
        />
      ))}
    </Stack>
  );
};

export default GenreTags;