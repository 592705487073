import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { MusicNote, Event, School, Edit, Delete, Visibility } from '@mui/icons-material';
import { 
  Container, 
  Typography, 
  Grid, 
  Paper, 
  Button, 
  Box, 
  Card, 
  CardContent, 
  CardMedia,
  CardActions,
  List, 
  ListItem, 
  ListItemText, 
  Chip,
  CircularProgress,
  Alert,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText
} from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import axiosInstance from '../../utils/axiosConfig';
import AvailableOpportunities from '../../components/AvailableOpportunities';

interface UpcomingEvent {
  _id: string;
  name: string;
  date: string;
  focus: string;
}

interface Band {
  _id: string;
  artistName: string;
  mainImage?: string;
}

const ArtistDashboard: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [upcomingEvents, setUpcomingEvents] = useState<UpcomingEvent[]>([]);
  const [bands, setBands] = useState<Band[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [bandToDelete, setBandToDelete] = useState<Band | null>(null);

  const recentActivities = [
    { id: 1, action: 'Updated profile', date: '2023-06-30' },
    { id: 2, action: 'Submitted application', date: '2023-06-28' },
  ];

  // Fetch upcoming events
  useEffect(() => {
    const fetchUpcomingEvents = async () => {
      try {
        const response = await axiosInstance.get<UpcomingEvent[]>('/api/events/upcoming');
        setUpcomingEvents(response.data);
        setError(null);
      } catch (error) {
        console.error('Error fetching upcoming events:', error);
        setError('Failed to load upcoming events');
      }
    };

    fetchUpcomingEvents();
  }, []);

  // Fetch bands
  useEffect(() => {
    const fetchBands = async (userId: string) => {
      try {
        setLoading(true);
        const response = await axiosInstance.get<Band[]>(`/api/users/${userId}/bands`);
        setBands(response.data);
        setError(null);
      } catch (error) {
        console.error('Error fetching bands:', error);
        setError('Failed to load bands');
      } finally {
        setLoading(false);
      }
    };
  
    if (user?._id) {
      fetchBands(user._id);
    }
  }, [user]);

  const handleDeleteClick = (band: Band) => {
    setBandToDelete(band);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!bandToDelete) return;

    try {
      await axiosInstance.delete(`/api/bandrecommendations/${bandToDelete._id}`);
      setBands(bands.filter(band => band._id !== bandToDelete._id));
      setDeleteDialogOpen(false);
      setBandToDelete(null);
    } catch (error) {
      console.error('Error deleting band:', error);
      setError('Failed to delete band');
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" my={4}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" component="h1" gutterBottom>
        Artist Dashboard
      </Typography>
      
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Grid container spacing={3}>
        {/* Profile Section */}
        <Grid item xs={12} md={8}>
          <Paper sx={{ p: 2, mb: 2 }}>
            <Typography variant="h6" gutterBottom>
              Your Profile
            </Typography>
            <Button 
              component={RouterLink} 
              to="/artist/profile" 
              variant="contained" 
              sx={{ mr: 2 }}
            >
              Edit Profile
            </Button>
            <Button 
              component={RouterLink} 
              to="/artist/portfolio" 
              variant="outlined"
            >
              View Portfolio
            </Button>
          </Paper>

          {/* Bands Section */}
          <Paper sx={{ p: 2 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography variant="h6">
                Your Bands
              </Typography>
              <Button 
                component={RouterLink} 
                to="/submit" 
                variant="contained" 
                startIcon={<MusicNote />}
              >
                Create Band
              </Button>
            </Box>
            
            <Grid container spacing={2}>
              {bands.map((band) => (
                <Grid item xs={12} sm={6} md={4} key={band._id}>
                  <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    {band.mainImage && (
                      <CardMedia
                        component="img"
                        height="140"
                        image={band.mainImage}
                        alt={band.artistName}
                        sx={{ objectFit: 'cover' }}
                      />
                    )}
                    <CardContent>
                      <Typography variant="h6" noWrap>
                        {band.artistName}
                      </Typography>
                    </CardContent>
                    <CardActions sx={{ mt: 'auto', justifyContent: 'space-between', px: 2, pb: 2 }}>
                      <IconButton 
                        size="small" 
                        onClick={() => navigate(`/band/${band._id}`)}
                        title="View"
                      >
                        <Visibility />
                      </IconButton>
                      <IconButton 
                        size="small" 
                        onClick={() => navigate(`/submit?edit=${band._id}`)}
                        title="Edit"
                      >
                        <Edit />
                      </IconButton>
                      <IconButton 
                        size="small" 
                        onClick={() => handleDeleteClick(band)}
                        title="Delete"
                      >
                        <Delete />
                      </IconButton>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>

        {/* Upcoming Events Section */}
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Upcoming Events
            </Typography>
            <List>
              {upcomingEvents.map((event) => (
                <ListItem key={event._id}>
                  <ListItemText
                    primary={
                      <Box display="flex" alignItems="center">
                        {event.name}
                        {event.focus === 'Artist' && (
                          <Chip label="For Artists" color="primary" size="small" sx={{ ml: 1 }} />
                        )}
                      </Box>
                    }
                    secondary={new Date(event.date).toLocaleDateString()}
                  />
                </ListItem>
              ))}
            </List>
            <Button 
              component={RouterLink} 
              to="/artist/events" 
              variant="outlined" 
              startIcon={<Event />}
            >
              View All Events
            </Button>
          </Paper>
        </Grid>

        {/* Other sections remain the same */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Available Opportunities
            </Typography>
            <Box sx={{ mt: 2 }}>
              <AvailableOpportunities />
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Your Applications
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Typography variant="body1">
                You haven't submitted any applications yet.
              </Typography>
            </Box>
            <Button 
              component={RouterLink} 
              to="/artist/applications" 
              variant="outlined" 
              sx={{ mt: 2 }}
            >
              View All Applications
            </Button>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Recent Activity
            </Typography>
            <List>
              {recentActivities.map((activity) => (
                <ListItem key={activity.id}>
                  <ListItemText 
                    primary={activity.action} 
                    secondary={activity.date} 
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Learning Resources
              </Typography>
              <Button 
                component={RouterLink} 
                to="/artist/workshops" 
                variant="outlined" 
                startIcon={<School />} 
                sx={{ mr: 2 }}
              >
                Available Workshops
              </Button>
              <Button 
                component={RouterLink} 
                to="/artist/resources" 
                variant="outlined"
              >
                Artist Resources
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>
          Delete Band
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {bandToDelete?.artistName}? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setDeleteDialogOpen(false)}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleDeleteConfirm} 
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ArtistDashboard;