import React, { useEffect } from 'react';
import { 
  Box,
  Stepper, 
  Step, 
  StepButton,
  useTheme,
  useMediaQuery,
  IconButton,
  Paper
} from '@mui/material';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const FormStepper = ({ 
  steps, 
  activeStep, 
  setActiveStep 
}: { 
  steps: string[],
  activeStep: number,
  setActiveStep: (step: number) => void 
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const stepperRef = React.useRef<HTMLDivElement>(null);
  const activeStepRef = React.useRef<HTMLDivElement>(null);

  // Function to scroll active step into view
  const scrollToActiveStep = () => {
    if (stepperRef.current && activeStepRef.current) {
      const container = stepperRef.current;
      const activeElement = activeStepRef.current;
      
      // Calculate the scroll position to center the active step
      const scrollLeft = activeElement.offsetLeft - (container.clientWidth / 2) + (activeElement.clientWidth / 2);
      
      container.scrollTo({
        left: scrollLeft,
        behavior: 'smooth'
      });
    }
  };

  // Scroll to active step when it changes
  useEffect(() => {
    scrollToActiveStep();
  }, [activeStep]);

  const handleScroll = (direction: 'left' | 'right') => {
    if (stepperRef.current) {
      const scrollAmount = stepperRef.current.clientWidth / 2;
      const newScroll = stepperRef.current.scrollLeft + (direction === 'left' ? -scrollAmount : scrollAmount);
      stepperRef.current.scrollTo({
        left: newScroll,
        behavior: 'smooth'
      });
    }
  };

  const canScrollLeft = () => {
    return stepperRef.current ? stepperRef.current.scrollLeft > 0 : false;
  };

  const canScrollRight = () => {
    if (!stepperRef.current) return false;
    return stepperRef.current.scrollLeft < (stepperRef.current.scrollWidth - stepperRef.current.clientWidth);
  };

  return (
    <Paper 
      elevation={2}
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: 1000,
        backgroundColor: 'background.paper',
        borderRadius: 0
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          px: 2,
          py: 1,
          gap: 1,
          borderBottom: 1,
          borderColor: 'divider'
        }}
      >
        {isMobile && (
          <IconButton 
            onClick={() => handleScroll('left')}
            disabled={!canScrollLeft()}
            sx={{ flexShrink: 0 }}
          >
            <ChevronLeft />
          </IconButton>
        )}
        
        <Box
          ref={stepperRef}
          sx={{
            flex: 1,
            overflow: 'hidden',
            '& .MuiStepper-root': {
              overflow: 'auto',
              scrollbarWidth: 'none',
              '&::-webkit-scrollbar': {
                display: 'none'
              }
            }
          }}
        >
          <Stepper 
            nonLinear 
            activeStep={activeStep}
            sx={{
              '& .MuiStepLabel-root .Mui-completed': {
                color: 'secondary.main'
              },
              '& .MuiStepLabel-root .Mui-active': {
                color: 'primary.main',
                fontWeight: 'bold'
              },
              '& .MuiStepLabel-label': {
                mt: 0.5,
                fontSize: isMobile ? '0.75rem' : '0.875rem',
                whiteSpace: 'nowrap'
              },
              '& .MuiStepButton-root': {
                py: 1,
                px: isMobile ? 1 : 2,
                transition: 'all 0.2s'
              }
            }}
          >
            {steps.map((label, index) => (
              <Step 
                key={label}
                ref={index === activeStep ? activeStepRef : null}
              >
                <StepButton 
                  onClick={() => setActiveStep(index)}
                  sx={{
                    transform: index === activeStep ? 'scale(1.05)' : 'none',
                    bgcolor: index === activeStep ? 'action.hover' : 'transparent',
                    borderRadius: 1
                  }}
                >
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Box>

        {isMobile && (
          <IconButton 
            onClick={() => handleScroll('right')}
            disabled={!canScrollRight()}
            sx={{ flexShrink: 0 }}
          >
            <ChevronRight />
          </IconButton>
        )}
      </Box>
    </Paper>
  );
};

export default FormStepper;