// src/constants/paymentMethods.ts

export interface PaymentMethod {
  id: string;
  name: string;
  isPersonal: boolean;
}

export const PAYMENT_METHODS: PaymentMethod[] = [
  { id: 'BUSINESS_CREDIT_DEBIT', name: 'BUSINESS Credit/Debit Card', isPersonal: false },
  { id: 'BUSINESS_SUMUP', name: 'BUSINESS SumUp Card', isPersonal: false },
  { id: 'BUSINESS_PETTY_CASH', name: 'BUSINESS Petty Cash', isPersonal: false },
  { id: 'PERSONAL_CREDIT_DEBIT', name: 'PERSONAL Credit/Debit Card', isPersonal: true },
  { id: 'PERSONAL_CASH', name: 'PERSONAL Cash', isPersonal: true },
  // Add any other payment methods here
];

export const getPaymentMethodById = (id: string): PaymentMethod | undefined => {
  return PAYMENT_METHODS.find(method => method.id === id);
};

export const isPersonalPayment = (id: string): boolean => {
  const method = getPaymentMethodById(id);
  return method ? method.isPersonal : false;
};