import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import theme from './theme';
import GlobalFontStyles from './components/GlobalFontStyles';


// Import layouts
import AdminLayout from './components/layout/AdminLayout';
import ArtistLayout from './components/layout/ArtistLayout';
import GuestLayout from './components/layout/GuestLayout';
import DomainUserLayout from './components/layout/DomainUserLayout';
import PublicLayout from './components/layout/PublicLayout';

// Import route configurations
import { publicRoutes } from './routes/publicRoutes';
import { adminRoutes } from './routes/adminRoutes';
import { artistRoutes } from './routes/artistRoutes';
import { domainUserRoutes } from './routes/domainUserRoutes';
import { guestRoutes } from './routes/guestRoutes';

// Import components for public routes
import LoginPage from './pages/LoginPage';
import AuthCallback from './pages/AuthCallback';

const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <CssBaseline />
            <GlobalFontStyles /> {/* Add GlobalFontStyles here */}
            <Router>
              <AppRoutes />
            </Router>
          </LocalizationProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </AuthProvider>
  );
};

const AppRoutes: React.FC = () => {
  const { user, isAuthenticated } = useAuth();

  console.log("AppRoutes: User:", user);
  console.log("AppRoutes: Is Authenticated:", isAuthenticated);

  if (isAuthenticated && user) {
    console.log("AppRoutes: User role:", user.role);
    switch (user.role) {
      case 'admin':
        console.log("AppRoutes: Rendering admin routes");
        return (
          <Routes>
            <Route element={<AdminLayout />}>
              {adminRoutes && adminRoutes[0] && adminRoutes[0].children 
                ? adminRoutes[0].children.map((route, index) => (
                    <Route key={index} path={route.path} element={route.element} />
                  ))
                : null
              }
            </Route>
            <Route path="*" element={<Navigate to="/admin" replace />} />
          </Routes>
        );
        case 'artist':
          console.log("AppRoutes: Rendering artist routes");
          return (
            <Routes>
              <Route element={<ArtistLayout />}>
                {artistRoutes && artistRoutes[0] && artistRoutes[0].children 
                  ? artistRoutes[0].children.map((route, index) => (
                      <Route key={index} path={route.path} element={route.element} />
                    ))
                  : null
                }
              </Route>
              <Route path="*" element={<Navigate to="/artist/dashboard" replace />} />
            </Routes>
          );
        case 'domain_user':
          console.log("AppRoutes: Rendering domain user routes");
          return (
            <Routes>
              <Route element={<DomainUserLayout />}>
                {domainUserRoutes && domainUserRoutes[0] && domainUserRoutes[0].children 
                  ? domainUserRoutes[0].children.map((route, index) => (
                      <Route key={index} path={route.path} element={route.element} />
                    ))
                  : null
                }
              </Route>
              <Route path="*" element={<Navigate to="/dashboard" replace />} />
            </Routes>
          );
        default:
          console.log("AppRoutes: Rendering guest routes");
          return (
            <Routes>
              <Route element={<GuestLayout />}>
                {guestRoutes && guestRoutes[0] && guestRoutes[0].children 
                  ? guestRoutes[0].children.map((route, index) => (
                      <Route key={index} path={route.path} element={route.element} />
                    ))
                  : null
                }
              </Route>
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          );
      }
    } else {
      console.log("AppRoutes: Rendering public routes");
      return (
        <Routes>
          <Route element={<PublicLayout />}>
            {publicRoutes && publicRoutes[0] && publicRoutes[0].children 
              ? publicRoutes[0].children.map((route, index) => (
                  <Route key={index} path={route.path} element={route.element} />
                ))
              : null
          }
               </Route>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/auth-callback" element={<AuthCallback />} />
        </Routes>
      );
  }
};
export default App;