// src/components/guidelines/ContactInfoDisplay.tsx
import React from 'react';
import { List, ListItem, ListItemText, Link as MuiLink } from '@mui/material';

interface ContactInfo {
  id: string;
  type: string;
  value: string;
  link?: string;
}

interface ContactInfoDisplayProps {
  contactInfo: ContactInfo[];
}

const ContactInfoDisplay: React.FC<ContactInfoDisplayProps> = ({ contactInfo }) => {
  return (
    <List>
      {contactInfo.map((info) => (
        <ListItem key={info.id}>
          <ListItemText
            primary={info.type}
            secondary={
              info.link ? (
                <MuiLink href={info.link} target="_blank" rel="noopener noreferrer">
                  {info.value}
                </MuiLink>
              ) : (
                info.value
              )
            }
          />
        </ListItem>
      ))}
    </List>
  );
};

export default ContactInfoDisplay;