import axios from 'axios';

const titoService = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/tito`,
});

export const getTitoEvents = () => titoService.get('/events');
export const getTitoRegistrations = (eventSlug: string) => titoService.get(`/${eventSlug}/registrations`);
export const getTitoReleases = (eventSlug: string) => titoService.get(`/${eventSlug}/releases`);

export default titoService;