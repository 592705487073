import React from 'react';
import { RouteObject } from 'react-router-dom';
import LoginPage from '../pages/LoginPage';
import AuthCallback from '../pages/AuthCallback';
import PublicStrandsPage from '../pages/PublicStrandsPage';
import PublicLayout from '../components/layout/PublicLayout';
import PublishedBandListPage from '../pages/PublishedBandListPage';
import BandRecommendationPage from '../pages/BandRecommendationPage';
import BanBamPage from '../pages/BanBamPage';
import FormPage from '../pages/FormPage';
import BanBam2025Announcement from '../pages/BanBam2025Announcement';
import SingleTitoEventPage from '../pages/SingleTitoEventPage';
import MusicAdminPage from '../pages/Artists/MusicAdminPage';
import StrandBandView from '../components/bandpage/StrandBandView';
import ArtistSignupPage from '../pages/Artists/ArtistSignupPage';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage';
import InvestorDashboard from '../pages/InvestorDashboard';



export const publicRoutes: RouteObject[] = [
    {
      element: <PublicLayout />,
      children: [
  { path: '/login', element: <LoginPage /> },
  { path: '/auth-callback', element: <AuthCallback /> },
  { path: '/publicstrands', element: <PublicStrandsPage /> },
  { 
    path: '/strands/:strandSlug', 
    element: <PublishedBandListPage /> 
  },
  { 
    path: '/strands/:strandSlug/band/:bandId', 
    element: <StrandBandView /> 
  },
  { path: '/submit', element: <BandRecommendationPage /> },
  { path: '/banbam', element: <BanBamPage /> },
  { path: '/navigator', element: <FormPage /> },
  { path: '/banbamfaq', element: <BanBam2025Announcement /> },
  { path: '/event/:slug', element: <SingleTitoEventPage /> },
  { path: "/musicadmin", element: <MusicAdminPage />},
  { path: '/artist/signup', element: <ArtistSignupPage /> },
  { path: '/privacy-policy', element: <PrivacyPolicyPage /> },
  { path: '/investor/dashboard', element: <InvestorDashboard /> },


],
},
];