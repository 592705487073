import React, { useState, useEffect } from 'react';
import {
  Box, 
  Typography, 
  Button, 
  Select, 
  MenuItem, 
  Grid,
  Snackbar,
  Alert,
  CircularProgress
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import ProspectList from './prospects/ProspectList';
import ProspectFormDialog from './prospects/ProspectFormDialog';
import ProspectConversionDialog from './prospects/ProspectConversionDialog';
import { Artist, Venue, Prospect, ProspectFormData, ProspectWithConversion, Strand } from './prospects/types';
import { calculateNet } from './prospects/utils';

interface Props {
  strandId: string;
  strandStartDate: string;  
  strandEndDate: string;
  onProspectsChange: () => void;
  prospectId?: string;
}

const ProgrammingProspects: React.FC<Props> = ({
  strandId,
  strandStartDate,
  strandEndDate,
  onProspectsChange,
  prospectId
}) => {
  // States
  const [prospects, setProspects] = useState<Prospect[]>([]);
  const [artists, setArtists] = useState<Artist[]>([]);
  const [venues, setVenues] = useState<Venue[]>([]);
  const [strands, setStrands] = useState<Strand[]>([]);
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Dialog states
  const [openDialog, setOpenDialog] = useState(false);
  const [openConversionDialog, setOpenConversionDialog] = useState(false);
  const [currentProspect, setCurrentProspect] = useState<ProspectFormData | null>(null);
  const [convertingProspect, setConvertingProspect] = useState<ProspectWithConversion | null>(null);
  const [conversionLoading, setConversionLoading] = useState(false);
  const [newArtistName, setNewArtistName] = useState('');
  
  // Snackbar state
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: 'success' | 'error';
  }>({
    open: false,
    message: '',
    severity: 'success'
  });

  const emptyProspect: ProspectFormData = {
    strandId,
    date: null,
    eventConcept: '',
    potentialArtists: [],
    venueIdeas: [],
    fees: 0,
    boxOffice: 0,
    expenses: 0,
    sponsorship: 0,
    net: 0,
    notes: '',
    status: 'idea'
  };

  // Fetch functions
  useEffect(() => {
    fetchStrands();
    fetchProspects();
    fetchArtists();
    fetchVenues();
  }, [strandId, selectedYear]);

  useEffect(() => {
    if (prospectId) {
      fetchSpecificProspect(prospectId);
    }
  }, [prospectId]);

  const fetchStrands = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/strands`);
      if (!response.ok) throw new Error('Failed to fetch strands');
      const data = await response.json();
      setStrands(data);
    } catch (error) {
      console.error('Error fetching strands:', error);
      showSnackbar('Failed to fetch strands', 'error');
    }
  };

  const fetchProspects = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/strands/${strandId}/prospects?year=${selectedYear}`
      );
      if (!response.ok) throw new Error('Failed to fetch prospects');
      const data = await response.json();
      
      const parsedProspects = data.map((prospect: any) => ({
        ...prospect,
        date: prospect.date ? new Date(prospect.date) : null
      }));
      
      setProspects(parsedProspects);
      setError(null);
    } catch (error) {
      console.error('Error fetching prospects:', error);
      setError('Failed to fetch prospects');
    } finally {
      setLoading(false);
    }
  };

  const fetchArtists = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/bandrecommendations`);
      if (!response.ok) throw new Error('Failed to fetch artists');
      const data = await response.json();
      setArtists(data);
    } catch (error) {
      console.error('Error fetching artists:', error);
      showSnackbar('Failed to fetch artists', 'error');
    }
  };

  const fetchVenues = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/venues`);
      if (!response.ok) throw new Error('Failed to fetch venues');
      const data = await response.json();
      setVenues(data);
    } catch (error) {
      console.error('Error fetching venues:', error);
      showSnackbar('Failed to fetch venues', 'error');
    }
  };

  const fetchSpecificProspect = async (id: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/strands/${strandId}/prospects/${id}`
      );
      if (!response.ok) throw new Error('Failed to fetch specific prospect');
      const data = await response.json();
      
      const parsedProspect = {
        ...data,
        date: data.date ? new Date(data.date) : null
      };
      
      handleOpenDialog(parsedProspect);
    } catch (error) {
      console.error('Error fetching specific prospect:', error);
      showSnackbar('Failed to fetch specific prospect', 'error');
    }
  };

  // Handler functions
  const handleSaveProspect = async () => {
    if (!currentProspect) return;
  
    try {
      const prospectToSave = {
        ...currentProspect,
        net: calculateNet(currentProspect)
      };

      // Check if this is a strand transfer
      const isStrandTransfer = currentProspect._id && currentProspect.strandId !== strandId;
      
      const url = currentProspect._id
        ? `${process.env.REACT_APP_API_URL}/api/strands/${currentProspect.strandId}/prospects/${currentProspect._id}`
        : `${process.env.REACT_APP_API_URL}/api/strands/${currentProspect.strandId}/prospects`;

      const response = await fetch(url, {
        method: currentProspect._id ? 'PUT' : 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ...prospectToSave,
          date: prospectToSave.date ? format(prospectToSave.date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null
        })
      });

      if (!response.ok) throw new Error('Failed to save prospect');

      if (isStrandTransfer) {
        onProspectsChange();
      }

      showSnackbar('Prospect saved successfully', 'success');
      handleCloseDialog();
      fetchProspects();
    } catch (error) {
      console.error('Error saving prospect:', error);
      showSnackbar('Failed to save prospect', 'error');
    }
  };

  const handleDeleteProspect = async (id: string) => {
    if (!window.confirm('Are you sure you want to delete this prospect?')) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/strands/${strandId}/prospects/${id}`,
        { method: 'DELETE' }
      );
      
      if (!response.ok) throw new Error('Failed to delete prospect');
      
      showSnackbar('Prospect deleted successfully', 'success');
      fetchProspects();
      onProspectsChange();
    } catch (error) {
      console.error('Error deleting prospect:', error);
      showSnackbar('Failed to delete prospect', 'error');
    }
  };

  const handleConvertToPerformance = async (prospect: ProspectWithConversion) => {
    setConversionLoading(true);
    try {
      const token = localStorage.getItem('token'); // or however you store your auth token
      const bandId = await resolveBandReference(prospect.potentialArtists[0]);
      
      const startTime = prospect.date;
      const endTime = startTime ? new Date(startTime.getTime() + (3 * 60 * 60 * 1000)) : null;

      const performanceData = {
        strandId: prospect.strandId,
        band: bandId,
        startTime: startTime?.toISOString(),
        endTime: endTime?.toISOString(),
        status: 'confirmed',
        costs: {
          bandFee: prospect.fees || 0,
          travel: { flights: 0, groundTransport: 0, other: 0 },
          accommodation: { 
            singleRooms: 0,
            twinRooms: 0,
            costPerRoom: 0,
            totalNights: 1
          },
          perDiem: { dailyRate: 0, numberOfDays: 1 },
          equipment: { rental: 0, shipping: 0 },
          personnel: {},
          marketing: 0,
          insurance: 0,
          visas: 0,
          other: 0
        },
        revenue: {
          ticketPrice: 0,
          expectedOccupancy: 0,
          sponsorship: prospect.sponsorship || 0,
          merchandiseSales: 0,
          otherRevenue: 0
        },
        notes: prospect.notes || '',
        contingencyRate: 10
      };

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/performances`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Add the auth header
        },
        body: JSON.stringify(performanceData)
      });
  
      if (!response.ok) throw new Error('Failed to create performance');
  
      // Update prospect status if needed
      if (prospect._id && prospect.status !== 'confirmed') {
        await fetch(
          `${process.env.REACT_APP_API_URL}/api/strands/${strandId}/prospects/${prospect._id}`,
          {
            method: 'PATCH',
            headers: { 
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}` // Add the auth header
            },
            body: JSON.stringify({ status: 'confirmed' })
          }
        );
      }
  
      showSnackbar('Successfully created performance', 'success');
      fetchProspects();
      onProspectsChange();
    } catch (error) {
      console.error('Error converting to performance:', error);
      showSnackbar('Failed to create performance', 'error');
    } finally {
      setConversionLoading(false);
      setOpenConversionDialog(false);
      setConvertingProspect(null);
    }
  };

  const resolveBandReference = async (artist: string | { _id: string }) => {
    try {
      if (typeof artist === 'string' && !artist.match(/^[0-9a-fA-F]{24}$/)) {
        const token = localStorage.getItem('token');
  
        // Send minimal required data for a "draft" band
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/bandrecommendations`, {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            artistName: artist,
            status: 'pending',
            // Add minimal required fields with placeholder values
            recommenderName: 'From Prospect Conversion',
            recommenderEmail: 'system@imc.show',
            recommenderCountry: 'Ireland',
            contactName: 'Pending',
            contactEmail: 'pending@imc.show',
            about: 'Band created from prospect conversion - details pending',
          })
        });
        
        if (!response.ok) throw new Error('Failed to create band');
        const newBand = await response.json();
        return newBand._id;
      }
      return typeof artist === 'string' ? artist : artist._id;
    } catch (error) {
      console.error('Error resolving band reference:', error);
      throw error;
    }
  };

  // UI helper functions
  const showSnackbar = (message: string, severity: 'success' | 'error') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleOpenDialog = (prospect: Prospect | null = null) => {
    setCurrentProspect(prospect ? {
      ...prospect,
      date: prospect.date ? new Date(prospect.date) : null
    } : emptyProspect);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentProspect(null);
    setNewArtistName('');
  };

  const handleConvertClick = (prospect: ProspectWithConversion) => {
    setConvertingProspect(prospect);
    setOpenConversionDialog(true);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ p: 4 }}>
        <Typography variant="h6" sx={{ mb: 4 }}>
          Programming Prospects
        </Typography>

        <Grid container spacing={2} alignItems="center" sx={{ mb: 4 }}>
          <Grid item>
            <Select
              value={selectedYear}
              onChange={(e) => setSelectedYear(Number(e.target.value))}
            >
              {Array.from({ length: 5 }, (_, i) => new Date().getFullYear() + i).map(year => (
                <MenuItem key={year} value={year}>{year}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => handleOpenDialog()}
            >
              Add Prospect
            </Button>
          </Grid>
        </Grid>

        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : prospects.length === 0 ? (
          <Typography color="textSecondary">
            No prospects found for this year. Add some using the button above.
          </Typography>
        ) : (
          <ProspectList
            prospects={prospects}
            venues={venues}
            onEdit={(prospect) => handleOpenDialog(prospect)}
            onDelete={handleDeleteProspect}
            onConvert={handleConvertClick}
          />
        )}

        <ProspectFormDialog
          open={openDialog}
          onClose={handleCloseDialog}
          currentProspect={currentProspect}
          setCurrentProspect={setCurrentProspect}
          artists={artists}
          venues={venues}
          strands={strands}
          newArtistName={newArtistName}
          setNewArtistName={setNewArtistName}
          onSave={handleSaveProspect}
        />

        {/* Conversion Dialog */}
        <ProspectConversionDialog
          open={openConversionDialog}
          onClose={() => setOpenConversionDialog(false)}
          convertingProspect={convertingProspect}
          conversionLoading={conversionLoading}
          onConfirm={handleConvertToPerformance}
        />

        {/* Snackbar for notifications */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert 
            onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} 
            severity={snackbar.severity}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </LocalizationProvider>
  );
};

export default ProgrammingProspects;