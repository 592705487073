import React from 'react';
import { Container, Typography } from '@mui/material';
import NavigatorForm from '../components/form/NavigatorForm';

const FormPage: React.FC = () => {
  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Navigator Application Form
      </Typography>
      <NavigatorForm />
    </Container>
  );
};

export default FormPage;