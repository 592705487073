import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext'; // Import our custom auth hook
import { 
  Box, Typography, TextField, Button, List, ListItem, 
  ListItemText, ListItemSecondaryAction, IconButton 
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

interface DomainUser {
  _id: string;
  name: string;
  email: string;
}

const AdminDomainManagement: React.FC = () => {
  const { user } = useAuth(); 
  const [domainUsers, setDomainUsers] = useState<DomainUser[]>([]);
  const [inviteEmail, setInviteEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    if (user?.id) {
      fetchDomainUsers();
    }
  }, [user]);

  const fetchDomainUsers = async () => {
    if (!user?.id) return;
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users?domain=${user.id}`);
      setDomainUsers(response.data);
    } catch (error) {
      setError('Failed to fetch domain users');
    }
  };

  const handleInvite = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user?.id) return;
    setError('');
    setSuccess('');
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/users/invite`, { 
        email: inviteEmail, 
        domainId: user.id
      });
      setSuccess('Invitation sent successfully');
      setInviteEmail('');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setError(error.response?.data?.message || 'Failed to send invitation');
      } else {
        setError('An unexpected error occurred');
      }
    }
  };

  const handleRemoveUser = async (userId: string) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/users/${userId}`);
      fetchDomainUsers();
    } catch (error) {
      setError('Failed to remove user');
    }
  };

  if (!user) {
    return <Typography>Please sign in to manage domain users.</Typography>;
  }

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Domain User Management
      </Typography>
      <Box component="form" onSubmit={handleInvite} sx={{ mb: 4 }}>
        <TextField
          label="Email to invite"
          value={inviteEmail}
          onChange={(e) => setInviteEmail(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary">
          Send Invitation
        </Button>
      </Box>
      {error && <Typography color="error">{error}</Typography>}
      {success && <Typography color="success">{success}</Typography>}
      <Typography variant="h5" gutterBottom>
        Domain Users
      </Typography>
      <List>
        {domainUsers.map((user) => (
          <ListItem key={user._id}>
            <ListItemText primary={user.name} secondary={user.email} />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveUser(user._id)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default AdminDomainManagement;