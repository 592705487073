import React, { useState, useEffect } from 'react';
import titoApi from '../services/titoApi';

interface TitoAuthResponse {
  authenticated: boolean;
  access_token: string;
  lookup_mode: string;
  accounts: string[];
}

const TitoApiConnectionTest: React.FC = () => {
  const [authInfo, setAuthInfo] = useState<TitoAuthResponse | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const checkConnection = async () => {
      try {
        const response = await titoApi.get<TitoAuthResponse>('/hello');
        console.log('Tito API Response:', response.data);
        setAuthInfo(response.data);
        setLoading(false);
      } catch (err: any) {
        console.error('Error connecting to Tito API:', err.response || err);
        setError(err.response?.data?.message || 'Failed to connect to Tito API');
        setLoading(false);
      }
    };

    checkConnection();
  }, []);

  if (loading) return <div>Checking Tito API connection...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <h1>Tito API Connection Test</h1>
      {authInfo && (
        <div>
          <p>Authentication Status: {authInfo.authenticated ? 'Successful' : 'Failed'}</p>
          <p>Access Token (last 4 characters): {authInfo.access_token}</p>
          <p>Lookup Mode: {authInfo.lookup_mode}</p>
          <h2>Accessible Accounts:</h2>
          <ul>
            {authInfo.accounts.map((account, index) => (
              <li key={index}>{account}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default TitoApiConnectionTest;