// src/components/guidelines/TimelineDisplay.tsx

import React from 'react';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
} from '@mui/lab';
import { Typography } from '@mui/material';
import { IconRenderer } from './IconRenderer'; // Adjust the path accordingly

interface TimelineEvent {
  id: string;
  title: string;
  date: string;
  color: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  icon: string;
}

interface TimelineDisplayProps {
  events: TimelineEvent[];
}

const TimelineDisplay: React.FC<TimelineDisplayProps> = ({ events }) => {
  return (
    <Timeline position="alternate">
      {events.map((event) => (
        <TimelineItem key={event.id}>
          <TimelineOppositeContent color="textSecondary">
            <Typography>{event.date}</Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot color={event.color}>
              <IconRenderer iconName={event.icon} />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="h6">{event.title}</Typography>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default TimelineDisplay;