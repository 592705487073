import React from 'react';
import { RouteObject } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import withAuth from '../hoc/withAuth';
import AdminLayout from '../components/layout/AdminLayout';


// Import all admin-related components
import Dashboard from '../pages/Dashboard';
import BandListPage from '../pages/BandListPage';
import BandDetailPage from '../pages/BandDetailPage';
import BandEditPage from '../pages/BandEditPage';
import EventListPage from '../pages/EventListPage';
import EventDetailPage from '../pages/EventDetailPage';
import EventFormPage from '../pages/EventFormPage';
import VenueListPage from '../pages/VenueListPage';
import VenueDetailPage from '../pages/VenueDetailPage';
import VenueFormPage from '../pages/VenueFormPage';
import PerformancesPage from '../pages/PerformanceListPage';
import PerformanceDetailPage from '../pages/PerformanceDetailPage';
import AddEditPerformancePage from '../pages/AddEditPerformancePage';
import PersonnelListPage from '../pages/PersonnelListPage';
import PersonnelDetailPage from '../pages/PersonnelDetailPage';
import PersonnelFormPage from '../pages/PersonnelFormPage';
import TitoApiTestPage from '../pages/TitoApiTestPage';
import TitoApiConnectionTest from '../pages/TitoConnectionTest';
import StrandListPage from '../pages/StrandListPage';
import StrandFormPage from '../pages/StrandFormPage';
import StrandDetailPage from '../pages/StrandDetailPage';
import NavigatorApplicationsList from '../pages/NavigatorApplicationsList';
import BanBamApplicationList from '../pages/BanBamApplicationList';
import BanBamApplicantDetailPage from '../pages/BanBamApplicantDetailPage';
import NavigatorApplicantDetailPage from '../pages/NavigatorApplicantDetailPage';
import UnifiedApplicationsView from '../components/UnifiedApplicationsView';
import AdminUserManagement from '../pages/AdminUserManagement';
import AdminDomainManagementPage from '../pages/AdminDomainManagementPage';
import AllProspectsPage from '../pages/AllProspectsPage';
import ExpenseListPage from '../pages/ExpenseListPage';
import ExpenseDetailPage from '../pages/ExpenseDetailPage';
import ExpenseForm from '../components/ExpenseForm';
import GuidelinesManagementPage from '../components/guidelines/GuidelinesManagementPage';
import GuidelineEditor from '../components/guidelines/GuidelineEditor';
import TicketConfigPage from '../components/TicketConfigPage';
import BandRecommendationPage from '../pages/BandRecommendationPage';
import CalendarComponent from '../components/CalendarComponent';
import PublicStrandsPage from '../pages/PublicStrandsPage';
import MondayApiTest from '../components/MondayApiTest';
import PublishedBandListPage from '../pages/PublishedBandListPage';
import StageDesignerPage from '../pages/StageDesignerPage';
import StrandBandView from '../components/bandpage/StrandBandView';
import MusicAdminPage from '../pages/Artists/MusicAdminPage';
import ProgrammingProspects from '../components/ProgrammingProspects';
import BanBamPage from '../pages/BanBamPage';
import FormPage from '../pages/FormPage';
import BanBam2025Announcement from '../pages/BanBam2025Announcement';
import DynamicForm from '../components/form/formbuilder/DynamicForm';
import { banBamFormSchema } from '../forms/schemas/banBamFormSchema';
import FormBuilder from '../pages/FormBuilder';
import BandContainer from '../components/bandpage/BandContainer';
import GuidelineViewPage from '../pages/GuidelineViewPage';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage';
import ArtistDashboard from '../pages/Artists/ArtistDashboard';
import ArtistProfileEditPage from '../pages/Artists/ArtistProfileEditPage';
import ArtistSignupPage from '../pages/Artists/ArtistSignupPage';
import InvestorDashboard from '../pages/InvestorDashboard';





// Wrap all components with the withAuth HOC
const ProtectedDashboard = withAuth(Dashboard, { allowedRoles: ['admin'] });
const ProtectedBandListPage = withAuth(BandListPage, { allowedRoles: ['admin'] });
const ProtectedBandDetailPage = withAuth(BandDetailPage, { allowedRoles: ['admin'] });
const ProtectedBandEditPage = withAuth(BandEditPage, { allowedRoles: ['admin'] });
const ProtectedEventListPage = withAuth(EventListPage, { allowedRoles: ['admin'] });
const ProtectedEventDetailPage = withAuth(EventDetailPage, { allowedRoles: ['admin'] });
const ProtectedEventFormPage = withAuth(EventFormPage, { allowedRoles: ['admin'] });
const ProtectedVenueListPage = withAuth(VenueListPage, { allowedRoles: ['admin'] });
const ProtectedVenueDetailPage = withAuth(VenueDetailPage, { allowedRoles: ['admin'] });
const ProtectedVenueFormPage = withAuth(VenueFormPage, { allowedRoles: ['admin'] });
const ProtectedPerformancesPage = withAuth(PerformancesPage, { allowedRoles: ['admin'] });
const ProtectedPerformanceDetailPage = withAuth(PerformanceDetailPage, { allowedRoles: ['admin'] });
const ProtectedAddEditPerformancePage = withAuth(AddEditPerformancePage, { allowedRoles: ['admin'] });
const ProtectedPersonnelListPage = withAuth(PersonnelListPage, { allowedRoles: ['admin'] });
const ProtectedPersonnelDetailPage = withAuth(PersonnelDetailPage, { allowedRoles: ['admin'] });
const ProtectedPersonnelFormPage = withAuth(PersonnelFormPage, { allowedRoles: ['admin'] });
const ProtectedTitoApiTestPage = withAuth(TitoApiTestPage, { allowedRoles: ['admin'] });
const ProtectedTitoApiConnectionTest = withAuth(TitoApiConnectionTest, { allowedRoles: ['admin'] });
const ProtectedStrandListPage = withAuth(StrandListPage, { allowedRoles: ['admin'] });
const ProtectedStrandFormPage = withAuth(StrandFormPage, { allowedRoles: ['admin'] });
const ProtectedStrandDetailPage = withAuth(StrandDetailPage, { allowedRoles: ['admin'] });
const ProtectedNavigatorApplicationsList = withAuth(NavigatorApplicationsList, { allowedRoles: ['admin'] });
const ProtectedBanBamApplicationList = withAuth(BanBamApplicationList, { allowedRoles: ['admin'] });
const ProtectedBanBamApplicantDetailPage = withAuth(BanBamApplicantDetailPage, { allowedRoles: ['admin'] });
const ProtectedNavigatorApplicantDetailPage = withAuth(NavigatorApplicantDetailPage, { allowedRoles: ['admin'] });
const ProtectedUnifiedApplicationsView = withAuth(UnifiedApplicationsView, { allowedRoles: ['admin'] });
const ProtectedAdminUserManagement = withAuth(AdminUserManagement, { allowedRoles: ['admin'] });
const ProtectedAdminDomainManagementPage = withAuth(AdminDomainManagementPage, { allowedRoles: ['admin'] });
const ProtectedAllProspectsPage = withAuth(AllProspectsPage, { allowedRoles: ['admin'] });
const ProtectedExpenseListPage = withAuth(ExpenseListPage, { allowedRoles: ['admin'] });
const ProtectedExpenseDetailPage = withAuth(ExpenseDetailPage, { allowedRoles: ['admin'] });
const ProtectedExpenseForm = withAuth(ExpenseForm, { allowedRoles: ['admin'] });
const ProtectedGuidelinesManagementPage = withAuth(GuidelinesManagementPage, { allowedRoles: ['admin'] });
const ProtectedGuidelineEditor = withAuth(GuidelineEditor, { allowedRoles: ['admin'] });
const ProtectedTicketConfigPage = withAuth(TicketConfigPage, { allowedRoles: ['admin'] });
const ProtectedBandRecommendationPage = withAuth(BandRecommendationPage, { allowedRoles: ['admin'] });
const ProtectedCalendarComponent = withAuth(CalendarComponent, { allowedRoles: ['admin'] });
const ProtectedPublicStrandsPage = withAuth(PublicStrandsPage, { allowedRoles: ['admin'] });
const ProtectedMondayApiTest = withAuth(MondayApiTest, { allowedRoles: ['admin'] });
const ProtectedPublishedBandListPage = withAuth(PublishedBandListPage, { allowedRoles: ['admin'] });
const ProtectedStageDesigner = withAuth(StageDesignerPage, { allowedRoles: ['admin'] });
const ProgrammingProspectsWrapper = () => {
    const { strandId, prospectId } = useParams();
    
    if (!strandId) {
      return <div>Strand ID is required</div>;
    }
  
    return (
      <ProgrammingProspects
        strandId={strandId}
        strandStartDate={new Date().toISOString()} // Convert to ISO string
        strandEndDate={new Date().toISOString()} // Convert to ISO string
        onProspectsChange={() => {}}
        prospectId={prospectId}
      />
    );
  };
  const ProtectedProgrammingProspects = withAuth(ProgrammingProspectsWrapper, { allowedRoles: ['admin'] });
  const ProtectedBandContainer = withAuth(BandContainer, { allowedRoles: ['admin'] });



export const adminRoutes: RouteObject[] = [
    {
      element: <AdminLayout />,
      children: [
        { path: '/admin', element: <ProtectedDashboard /> },
        { path: '/admin/bands', element: <ProtectedBandListPage /> },
        { path: '/band/:id', element: <ProtectedBandContainer /> },
        { path: '/bandrecommendations/:id/edit', element: <ProtectedBandEditPage /> },
        { path: '/admin/events', element: <ProtectedEventListPage /> },
        { path: '/events/:id', element: <ProtectedEventDetailPage /> },
        { path: '/events/edit/:id', element: <ProtectedEventDetailPage /> },
        { path: '/events/new', element: <ProtectedEventFormPage /> },
        { path: '/admin/venues', element: <ProtectedVenueListPage /> },
        { path: '/venues/:id', element: <ProtectedVenueDetailPage /> },
        { path: '/venues/:id/edit', element: <ProtectedVenueFormPage /> },
        { path: '/venues/new', element: <ProtectedVenueFormPage /> },
        { path: '/admin/performances', element: <ProtectedPerformancesPage /> },
        { path: '/performances/edit/:id', element: <ProtectedAddEditPerformancePage /> },
        { path: '/performances/:id', element: <ProtectedPerformanceDetailPage /> },
        { path: '/performances/new', element: <ProtectedAddEditPerformancePage /> },
        { path: '/admin/personnel', element: <ProtectedPersonnelListPage /> },
        { path: '/admin/personnel/:id', element: <ProtectedPersonnelDetailPage /> },
        { path: '/admin/personnel/new', element: <ProtectedPersonnelFormPage /> },
        { path: "/submit", element: <ProtectedBandRecommendationPage />},
        { path: '/admin/sales', element: <ProtectedTitoApiTestPage /> },
        { path: '/admin/tito-connection', element: <ProtectedTitoApiConnectionTest /> },
        { path: '/admin/strands', element: <ProtectedStrandListPage /> },
        { path: '/strands/new', element: <ProtectedStrandFormPage /> },
        { path: '/strands/edit/:id', element: <ProtectedStrandFormPage /> },
        { path: '/admin/strands/:id', element: <ProtectedStrandDetailPage /> },
        { path: '/banbam', element: <BanBamPage /> },
        { path: '/navigator', element: <FormPage /> },
        { path: '/banbamfaq', element: <BanBam2025Announcement /> },
        { path: '/admin/navigator-applications', element: <ProtectedNavigatorApplicationsList /> },
        { path: '/admin/banbam-applications', element: <ProtectedBanBamApplicationList /> },
        { path: '/banbam-applications/:id', element: <ProtectedBanBamApplicantDetailPage /> },
        { path: '/navigator-applications/:id', element: <ProtectedNavigatorApplicantDetailPage /> },
        { path: '/admin/applications', element: <ProtectedUnifiedApplicationsView /> },
        { path: '/admin/users', element: <ProtectedAdminUserManagement /> },
        { path: '/admin/domains', element: <ProtectedAdminDomainManagementPage /> },
        { path: '/admin/prospects', element: <ProtectedAllProspectsPage /> },
        { path: '/admin/prospects/:strandId/new', element: <ProtectedProgrammingProspects /> },
        { path: '/admin/prospects/:strandId/edit/:prospectId', element: <ProtectedProgrammingProspects /> },
        { path: '/admin/expenses', element: <ProtectedExpenseListPage /> },
        { path: '/admin/expenses/:id', element: <ProtectedExpenseDetailPage /> },
        { path: '/admin/expenses/new', element: <ProtectedExpenseForm /> },
        { path: '/guidelines', element: <ProtectedGuidelinesManagementPage /> },
        { path: '/guidelines/:id', element: <ProtectedGuidelineEditor /> },
        { path: '/guidelines/:id/edit', element: <ProtectedGuidelineEditor /> },
        { path: '/strands/guidelines/new', element: <ProtectedGuidelineEditor /> },
        { path: '/tix', element: <ProtectedTicketConfigPage /> },
        { path: '/calendar', element: <ProtectedCalendarComponent /> },
        { path: '/publicstrands', element: <ProtectedPublicStrandsPage /> },
        { path: '/monday', element: <ProtectedMondayApiTest /> },
        { path: '/strands/:strandSlug', element: <ProtectedPublishedBandListPage />},
        { 
            path: '/strands/:strandSlug/band/:bandId', 
            element: <StrandBandView />  // NEW
          },        
          { path: '/stage-designer', element: <StageDesignerPage />},
          { path: '/guidelines/strand/:strandSlug', element: <GuidelineViewPage /> },
          { path: "/musicadmin", element: <MusicAdminPage />},
        { path: "/dynamicform", element: <DynamicForm formSchema={banBamFormSchema}/>},
        { path: '/formbuilder', element: <FormBuilder /> },
        { path: '/privacy-policy', element: <PrivacyPolicyPage /> },
        { path: '/artist/signup', element: <ArtistSignupPage /> },
        { path: '/artist/dashboard', element: <ArtistDashboard />},
        { path: '/artist/profile', element: <ArtistProfileEditPage />},
        { path: '/investor/dashboard', element: <InvestorDashboard /> },

      ],
    },
  ];