import React from 'react';
import { Grid, TextField, Tooltip } from '@mui/material';
import { BandRecommendationFormData } from '../../types/BandRecommendations';
import { NavigatorFormData } from '../../types/NavigatorTypes';

type FormDataType = BandRecommendationFormData | NavigatorFormData;

interface ContactInfoProps<T extends FormDataType> {
  formData: T;
  setFormData: React.Dispatch<React.SetStateAction<T>>;
}

const ContactInfo = <T extends FormDataType>({ formData, setFormData }: ContactInfoProps<T>) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  // Check if the formData is of type NavigatorFormData
  const isNavigatorForm = (data: FormDataType): data is NavigatorFormData => {
    return 'name' in data && 'email' in data && 'phone' in data;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Tooltip title="Contact person name.">
          <TextField
            name={isNavigatorForm(formData) ? "name" : "contactName"}
            label="Name"
            value={isNavigatorForm(formData) ? formData.name : formData.contactName}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
        </Tooltip>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Tooltip title="Contact person email.">
          <TextField
            name={isNavigatorForm(formData) ? "email" : "contactEmail"}
            label="Email"
            type="email"
            value={isNavigatorForm(formData) ? formData.email : formData.contactEmail}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
        </Tooltip>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Tooltip title="Contact person phone number.">
          <TextField
            name={isNavigatorForm(formData) ? "phone" : "contactPhone"}
            label="Phone"
            value={isNavigatorForm(formData) ? formData.phone : formData.contactPhone}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default ContactInfo;