// src/pages/BanBam2025Announcement.tsx

import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Link,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link as MuiLink } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TimelineAccordion, { TimelineEvent } from '../components/TimelineAccordion';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import EventIcon from '@mui/icons-material/Event';

const BanBam2025Announcement: React.FC = () => {
  // Define the timeline events
  const events: TimelineEvent[] = [
    {
      title: 'Application Deadline',
      date: '17:00hrs (GMT) on Monday 25th November 2024',
      color: 'primary',
      icon: <AnnouncementIcon />,
    },
    {
      title: 'International Jury Selection',
      date: '20th December 2024',
      color: 'primary',
      icon: <EventIcon />,
    },
    {
      title: 'Announcement of Awardees',
      date: 'January 2025',
      color: 'secondary',
      icon: <AnnouncementIcon />,
    },
    {
      title: 'Composition Period',
      date: 'January - March 2025',
      color: 'secondary',
      icon: <EventIcon />,
    },
    {
      title: 'Rehearsals',
      date: 'March 2025',
      color: 'secondary',
      icon: <EventIcon />,
    },
    {
      title: 'Premiere in Dublin ',
      date: '3rd April 2025',
      color: 'primary',
      icon: <AnnouncementIcon />,
    },
    {
      title: 'Further Performances in Belfast',
      date: 'Later in 2025',
      color: 'secondary',
      icon: <EventIcon />,
    },
  ];

  return (
    <div className="ban-bam-announcement">
      <Typography variant="h4" gutterBottom>
        BAN BAM 2025 Guidelines
      </Typography>
      <Typography variant="h5" gutterBottom>
        BAN BAM: Supporting women in jazz and creative improvised music
      </Typography>
      <Typography paragraph>
        Applications can be made{' '}
        <MuiLink component={RouterLink} to="/banbam">HERE</MuiLink>.<br />
      </Typography>
      <Box>
        <Typography variant="h6" gutterBottom>
          Need Assistance?
        </Typography>
        <Typography paragraph>
          If you have additional access needs, need to submit your information in an alternate way, or require support in applying, please reach out to Aoife via one of the following methods:
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <EmailIcon sx={{ mr: 1 }} color="action" />
          <Link 
            href="mailto:pr@improvisedmusic.ie" 
            underline="hover" 
            color="primary"
            aria-label="Email us at pr@improvisedmusic.ie"
          >
            pr@improvisedmusic.ie
          </Link>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <WhatsAppIcon sx={{ mr: 1 }} color="action" />
          <Link 
            href="https://wa.me/35387833391" 
            target="_blank" 
            rel="noopener noreferrer" 
            underline="hover" 
            color="primary"
            aria-label="Send a WhatsApp message to +353879495365"
          >
            Send a WhatsApp Message
          </Link>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <PhoneIcon sx={{ mr: 1 }} color="action" />
          <Link 
            href="tel:+35387833391" 
            underline="hover" 
            color="primary"
            aria-label="Call Aoife at +353879495365"
          >
           +353879495365
          </Link>
        </Box>
      </Box>

      {/* Details of the Award Accordion */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Details of the Award</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography paragraph>
            The BAN BAM award includes the commission of new work from the selected composers, and creative and career development support for a 12-month period.
          </Typography>

          <Typography variant="subtitle1">Music Commission:</Typography>
          <ul>
            <li>
              This commission is open to female and gender minority music makers who compose in the style of jazz, and/or all creative improvised music.
            </li>
            <li>Applicants must be mainly resident on the island of Ireland.</li>
            <li>
              Two composers will be selected from the applicants, assessed by a panel of music professionals on criteria outlined below.
            </li>
            <li>
              Each awardee will receive a €2,500 commission fee, as well as 12 months of bespoke creative and career development support and consultation from IMC and selected industry professionals during 2025.
            </li>
          </ul>

          <Typography variant="subtitle1">Compositional Guidelines:</Typography>
          <ul>
            <li>
              The 2 awardees will each compose music for either solo, duo, trio, or quartet.
            </li>
            <li>
              The composed piece can be intended for a named ensemble (i.e., within the duo, trio, or quartet), which may include the composer.
            </li>
            <li>
              Alternatively, instrumentation of the parts alone can be named, with personnel selected at a later date for performance.
            </li>
            <li>
              The composed work should be approximately 10 minutes in length, with the possibility of extending the work via improvisation in performance.
            </li>
            <li>
              The piece will be included in a 45-minute performance with your own other original work.
            </li>
          </ul>

          <Typography variant="subtitle1">Creative & Career Development</Typography>
          <Typography paragraph>
            IMC view this commission to compose new work as a catalyst to further development and are committed to working closely with the awardees over a 12-month period, developing potential avenues for the work, as well as offering mentorship, career guidance, and creative consultation. This aspect of the award will run from January to December 2025 and will be developed to suit the individual needs and ambitions of each awardee.
          </Typography>

          <Typography variant="subtitle1">Performances</Typography>
          <ul>
            <li>
              The award includes a guaranteed premiere performance in Dublin on <strong>3rd April 2025</strong>, as well as a performance in Belfast during summer 2025.
            </li>
            <li>
              Separate performance fees will be allocated for all performers as per industry standard.
            </li>
            <li>
              The award includes provision of rehearsal space at IMC’s The Cooler, as well as fees for all musicians to cover 2x rehearsal sessions per ensemble during March 2025.
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>

      {/* Insert the TimelineAccordion Component */}
      <TimelineAccordion
        title="Timeline"
        introText="Here's a visual overview of the key dates and milestones for the BAN BAM Award (scroll right to reveal more!):"
        events={events}
        additionalNotes={
          <span>
            <strong>Please Note:</strong> If the composer intends to perform their new composition, they and any named ensemble{' '}
            <strong>
              must be available to do so in Dublin on 3rd April 2025. No exceptions, so please check before applying.
            </strong>
          </span>
        }
        contactInfo={{
          emailLink: 'mailto:hello@improvisedmusic.ie',
          emailText: 'hello@improvisedmusic.ie',
        }}
        applicationLink={{
          to: '/banbam',
          text: 'HERE',
        }}
      />

      {/* Diversity Policy Accordion */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Diversity Policy</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography paragraph>
            The purpose of the award is to assist in increasing the presence of female and gender minority composers and instrumentalists within the Irish jazz and improvised music scene, and to support further collaboration and development.
          </Typography>
          <Typography paragraph>
            We welcome applications from members of the LGBTQ+ communities, all POC and people of any and all ethnic backgrounds, and members of the Irish Traveller community.
          </Typography>
          <Typography paragraph>
            If you have additional access needs or require support in applying, please contact us at{' '}
            <Link href="mailto:pr@improvisedmusic.ie">pr@improvisedmusic.ie</Link>
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Application Guidelines Accordion */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Application Guidelines</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography paragraph>
            Please note applications will only be accepted via an online Application Form.
          </Typography>
          <Typography paragraph>Applicants must submit the following:</Typography>
          <ul>
            <li>
              Provide 2 or 3 samples of your existing original work.
              <ul>
                <li>Please note, we will only accept your own original compositions.</li>
              </ul>
            </li>
            <li>
              At least one sample of existing original works as a score, or a detailed description of your scoring process with examples. PDF format is preferable. The works must be reflective of the applicant’s current work.
            </li>
            <li>
              Evidence of prior original work (studio or live recording). A URL link to an online location such as YouTube, Bandcamp, Soundcloud (public or private) is preferable, but we can also accept MP3/VIDEO/WAV/AIFF files up to 500MB.
            </li>
            <li>
              A 1-page CV outlining career highlights to date, education, and background.
            </li>
          </ul>
          <Typography paragraph>
            Complete the Application Form in full, with details of your plans for the composition, potential future development, and your own creative ambitions.
            <br />
            These 3 sections have a maximum of 200 words.
            <br />
            We recommend that you prepare this in a Word document or similar and paste into the Application Form.
          </Typography>
          <Typography paragraph>
            This award is for the development of new work. Existing compositions will not be considered.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Criteria for Assessment Accordion */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Criteria for the Assessment of Applications</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li>Applicants must be born in, or currently resident in Ireland (Rep. and NI).</li>
            <li>Applicants must be over the age of 18 at the time of application.</li>
            <li>These awards are for female or gender minority composers only.</li>
            <li>
              Applications are assessed in a competitive context and with consideration of the available resources.
            </li>
            <li>
              Applications will be assessed by a panel of industry professionals and composers, including representatives from IMC.
            </li>
          </ul>
          <Typography paragraph>All applications are assessed on the following criteria:</Typography>
          <ul>
            <li>
              Artistic quality of supporting material (videos, recorded music, scores, etc. Please note: providing a standard score, while desirable, is not a requirement).
            </li>
            <li>
              Stylistic suitability of artist and proposal (BAN BAM is an award for composers working in jazz and improvised music).
            </li>
            <li>Compositional outline and vision.</li>
            <li>Project feasibility (future performance feasibility).</li>
            <li>
              Potential impact of this award on the applicant's career and ambitions.
            </li>
          </ul>
          <Typography paragraph>
            If you have any queries about whether you are eligible for this award, please see the FAQs or contact us directly via{' '}
            <Link href="mailto:hello@improvisedmusic.ie">hello@improvisedmusic.ie</Link>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default BanBam2025Announcement;