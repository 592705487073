import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, CircularProgress, Paper } from '@mui/material';

const API_BASE_URL = process.env.REACT_APP_API_URL;

interface TitoEvent {
  [key: string]: any;  // This allows for any fields to be present
}

const SingleTitoEventPage: React.FC = () => {
  const [event, setEvent] = useState<TitoEvent | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchSingleEvent = async () => {
      setLoading(true);
      setError(null);
      try {
        // Replace 'your-event-slug' with an actual event slug from your Tito account
        const eventSlug = 'aerie-cooler-2024';
        const [eventResponse, registrationsResponse, releasesResponse] = await Promise.all([
          axios.get(`${API_BASE_URL}/api/tito/events/${eventSlug}`),
          axios.get(`${API_BASE_URL}/api/tito/${eventSlug}/registrations`),
          axios.get(`${API_BASE_URL}/api/tito/${eventSlug}/releases`)
        ]);

        const eventData = {
          ...eventResponse.data,
          registrations: registrationsResponse.data.registrations,
          releases: releasesResponse.data.releases
        };

        console.log('Full event data:', eventData);
        setEvent(eventData);
      } catch (err: any) {
        console.error('Error fetching event:', err.response || err);
        setError(err.message || 'Failed to fetch event');
      } finally {
        setLoading(false);
      }
    };

    fetchSingleEvent();
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ color: 'error.main', textAlign: 'center', mt: 4 }}>
        Error: {error}
      </Box>
    );
  }

  if (!event) {
    return (
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        No event data found.
      </Box>
    );
  }

  const renderEventField = (key: string, value: any) => {
    if (typeof value === 'object' && value !== null) {
      return (
        <Box key={key} sx={{ mt: 2 }}>
          <Typography variant="h6">{key}</Typography>
          <Paper sx={{ p: 2, maxHeight: '200px', overflow: 'auto' }}>
            <pre>{JSON.stringify(value, null, 2)}</pre>
          </Paper>
        </Box>
      );
    }
    return (
      <Typography key={key}>
        <strong>{key}:</strong> {String(value)}
      </Typography>
    );
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" sx={{ mb: 3 }}>Tito Event Details</Typography>
      {Object.entries(event).map(([key, value]) => renderEventField(key, value))}
    </Box>
  );
};

export default SingleTitoEventPage;