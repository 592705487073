import React from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { keyframes } from '@emotion/react';

const pulseAnimation = keyframes`
  0% {
    background-color: rgb(25, 118, 210);
  }
  50% {
    background-color: rgb(76, 175, 80);
  }
  100% {
    background-color: rgb(25, 118, 210);
  }
`;

interface AttentionAddButtonProps {
  hasInput: boolean;
  onClick: () => void;
  fullWidth?: boolean;
}

const AttentionAddButton: React.FC<AttentionAddButtonProps> = ({ 
  hasInput, 
  onClick, 
  fullWidth = false 
}) => {
  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<AddIcon />}
      onClick={onClick}
      fullWidth={fullWidth}
      className={hasInput ? 'pulse' : ''}
      sx={{
        '&.pulse': {
          animation: `${pulseAnimation} 2s infinite`,
          '&:hover': {
            animation: 'none',
            backgroundColor: 'primary.dark'
          }
        }
      }}
    >
      Add
    </Button>
  );
};

export default AttentionAddButton;