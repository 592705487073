// VenueFormPage.tsx
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Typography } from '@mui/material';
import VenueForm from '../components/VenueForm';
import axios from 'axios';

interface VenueData {
  name: string;
  venueId: string;
  capacity: number;
  location: string;
  technicalSpecs: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
}

const VenueFormPage: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const isEditing = Boolean(id);

  const handleSubmit = async (venueData: VenueData) => {
    try {
      if (isEditing) {
        await axios.put(`${process.env.REACT_APP_API_URL}/api/venues/${id}`, venueData);
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/venues`, venueData);
      }
      navigate('/venues');
    } catch (error) {
      console.error('Error saving venue:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        {isEditing ? 'Edit Venue' : 'Create New Venue'}
      </Typography>
      <VenueForm />
    </Container>
  );
};

export default VenueFormPage;