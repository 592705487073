// src/constants/expenseCategories.ts

import { IMC_EXPENSE_CODES } from './imcCodes';

export interface ExpenseCategory {
  name: string;
  relatedIMCCodes: string[];
}

export const EXPENSE_CATEGORIES: ExpenseCategory[] = [
  {
    name: "Meal",
    relatedIMCCodes: [
      IMC_EXPENSE_CODES.ARTISTS_CATERING_HOSPITALITY,
      IMC_EXPENSE_CODES.ARTISTS_PER_DIEMS,
      IMC_EXPENSE_CODES.PRODUCTION_STAFF_SUBSISTENCE_PER_DIEMS,
      IMC_EXPENSE_CODES.MARKETING_CATERING_HOSPITALITY
    ]
  },
  {
    name: "Travel - Train",
    relatedIMCCodes: [
      IMC_EXPENSE_CODES.ARTISTS_INTERNATIONAL_TRAVEL,
      IMC_EXPENSE_CODES.ARTISTS_LOCAL_TRANSPORT,
      IMC_EXPENSE_CODES.PRODUCTION_STAFF_TRAVEL
    ]
  },
  {
    name: "Travel - Taxi",
    relatedIMCCodes: [
      IMC_EXPENSE_CODES.ARTISTS_LOCAL_TRANSPORT,
      IMC_EXPENSE_CODES.PRODUCTION_STAFF_TRAVEL
    ]
  },
  {
    name: "Travel - Car Rental",
    relatedIMCCodes: [
      IMC_EXPENSE_CODES.ARTISTS_LOCAL_TRANSPORT,
      IMC_EXPENSE_CODES.PRODUCTION_STAFF_TRAVEL
    ]
  },
  {
    name: "Travel - Flight",
    relatedIMCCodes: [
      IMC_EXPENSE_CODES.ARTISTS_INTERNATIONAL_TRAVEL,
      IMC_EXPENSE_CODES.PRODUCTION_STAFF_TRAVEL
    ]
  },
  {
    name: "Entertainment",
    relatedIMCCodes: [
      IMC_EXPENSE_CODES.ARTISTS_CATERING_HOSPITALITY,
      IMC_EXPENSE_CODES.MARKETING_CATERING_HOSPITALITY
    ]
  },
  {
    name: "Accommodation - Hotel",
    relatedIMCCodes: [
      IMC_EXPENSE_CODES.ARTISTS_ACCOMMODATION,
      IMC_EXPENSE_CODES.PRODUCTION_STAFF_ACCOMMODATION
    ]
  },
  {
    name: "Tech",
    relatedIMCCodes: [
      IMC_EXPENSE_CODES.PRODUCTION_EQUIPMENT_PURCHASES,
      IMC_EXPENSE_CODES.ADVERTISING_WEBSITE_DESIGN_EXPENSES,
      IMC_EXPENSE_CODES.ADVERTISING_WEBSITE_MAINTENANCE,
      IMC_EXPENSE_CODES.ADVERTISING_WEBSITE_HOSTING_DOMAINS
    ]
  },
  {
    name: "Production Sundry",
    relatedIMCCodes: [
      IMC_EXPENSE_CODES.SUNDRY_PRODUCTION_EXPENSES_CONTINGENCIES,
      IMC_EXPENSE_CODES.PRODUCTION_PROPS_STAGING,
      IMC_EXPENSE_CODES.PRODUCTION_EQUIPMENT_HIRE,
      IMC_EXPENSE_CODES.PRODUCTION_LIGHTING_HIRE
    ]
  },
  {
    name: "Other",
    relatedIMCCodes: [
      IMC_EXPENSE_CODES.OTHER_EXPENSES_RESEARCH,
      IMC_EXPENSE_CODES.OTHER_EXPENSES_INSTRUMENT_BACKLINE_MAINTENANCE,
      IMC_EXPENSE_CODES.OTHER_EXPENSES_IN_KIND_CONTRA
    ]
  },
  {
    name: "TBC",
    relatedIMCCodes: [] // This category allows for expenses that don't fit elsewhere
  }
];

export const getCategoryByName = (name: string): ExpenseCategory | undefined => {
  return EXPENSE_CATEGORIES.find(category => category.name === name);
};

export const getRelatedIMCCodes = (categoryName: string): string[] => {
  const category = getCategoryByName(categoryName);
  return category ? category.relatedIMCCodes : [];
};