import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  SelectChangeEvent,
  Box,
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
  TextFieldVariants
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import axios from 'axios';
import EventCalculator from './EventCalculator'; // Import the EventCalculator component
import { JSX } from 'react/jsx-runtime';

interface PerformanceFormProps {
  eventId?: string;  // Make eventId optional
  strandId?: string; // Add strandId
  onSubmit: (performanceData: any) => void;
  initialData?: any;
  isEdit?: boolean; // Add isEdit prop
}
interface Band {
  _id: string;
  artistName: string;
}

interface CostCategory {
  [key: string]: string;
}

interface Costs {
  travel: {
    flights: string;
    groundTransport: string;
    other: string;
  };
  accommodation: {
    hotelCost: string;
    numberOfNights: string;
  };
  perDiem: {
    dailyRate: string;
    numberOfDays: string;
  };
  equipment: {
    rental: string;
    shipping: string;
  };
  marketing: string;
  insurance: string;
  visas: string;
  other: string;
}

interface FormData {
  band: string;
  startTime: Date | null;
  endTime: Date | null;
  fee: string;
  status: string;
  costs: Costs;
  contingencyRate: string;
  notes: string;
  strandId?: string; // Add strandId to FormData
}

interface CalculatedCosts {
  flightsTotal: number;
  accommodationTotal: number;
  perDiemTotal: number;
  equipmentTotal: number;
  otherCostsTotal: number;
  grandTotal: number;
}

const PerformanceForm: React.FC<PerformanceFormProps> = ({ 
  eventId, 
  strandId,  // Add this prop
  onSubmit, 
  initialData, 
  isEdit = false, // Add this prop to determine if we're editing
}) => {
      const [formData, setFormData] = useState<FormData>({
        band: '',
        startTime: new Date(),
        endTime: new Date(),
        fee: '',
        status: 'pending',
        strandId: strandId || '',  // Initialize strandId
        costs: {
      travel: {
        flights: '',
        groundTransport: '',
        other: ''
      },
      accommodation: {
        hotelCost: '',
        numberOfNights: '1'
      },
      perDiem: {
        dailyRate: '',
        numberOfDays: '1'
      },
      equipment: {
        rental: '',
        shipping: ''
      },
      marketing: '',
      insurance: '',
      visas: '',
      other: ''
    },
    contingencyRate: '10',
    notes: ''
  });
  const [bands, setBands] = useState<Band[]>([]);

  const [calculatorOpen, setCalculatorOpen] = useState(false);

  useEffect(() => {
    if (initialData) {
      setFormData(prevData => ({
        ...prevData,
        ...initialData,
        startTime: new Date(initialData.startTime),
        endTime: new Date(initialData.endTime)
      }));
    }
  }, [initialData]);

  useEffect(() => {
    const fetchBands = async () => {
      try {
        const response = await axios.get<Band[]>(`${process.env.REACT_APP_API_URL}/api/bandRecommendations`);
        setBands(response.data);
      } catch (error) {
        console.error('Error fetching bands:', error);
      }
    };

    fetchBands();
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>
  ) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleNestedChange = (
    category: keyof Costs,
    field: string
  ) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFormData(prev => ({
      ...prev,
      costs: {
        ...prev.costs,
        [category]:
          typeof prev.costs[category] === 'object'
            ? {
                ...(prev.costs[category] as CostCategory),
                [field]: value
              }
            : value
      }
    }));
  };

  const handleDateChange = (field: 'startTime' | 'endTime') => (newValue: Date | null) => {
    setFormData(prev => ({
      ...prev,
      [field]: newValue
    }));
  };

  const parseNumber = (value: string) => (value === '' ? 0 : parseFloat(value));

  const calculateTotalCosts = () => {
    const { costs } = formData;

    const travelCosts =
      parseNumber(costs.travel.flights) +
      parseNumber(costs.travel.groundTransport) +
      parseNumber(costs.travel.other);

    const accommodationCosts =
      parseNumber(costs.accommodation.hotelCost) * parseNumber(costs.accommodation.numberOfNights);

    const perDiemCosts =
      parseNumber(costs.perDiem.dailyRate) * parseNumber(costs.perDiem.numberOfDays);

    const equipmentCosts =
      parseNumber(costs.equipment.rental) + parseNumber(costs.equipment.shipping);

    const otherCosts =
      parseNumber(costs.marketing) +
      parseNumber(costs.insurance) +
      parseNumber(costs.visas) +
      parseNumber(costs.other);

    const totalCosts = travelCosts + accommodationCosts + perDiemCosts + equipmentCosts + otherCosts;

    return totalCosts;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit({
      ...formData,
      event: eventId,
      strandId: formData.strandId || strandId  // Include strandId in submission
    });
  };

  const totalCosts = calculateTotalCosts();
  const contingency =
    totalCosts * (parseNumber(formData.contingencyRate) / 100);
  const totalWithContingency = totalCosts + contingency;

  const handleCalculatorSubmit = (calculatedCosts: CalculatedCosts) => {
    // Update the formData with the calculated costs
    setFormData(prev => ({
      ...prev,
      costs: {
        ...prev.costs,
        travel: {
          ...prev.costs.travel,
          flights: calculatedCosts.flightsTotal.toString(),
        },
        accommodation: {
          ...prev.costs.accommodation,
          hotelCost: calculatedCosts.accommodationTotal.toString(),
        },
        perDiem: {
          ...prev.costs.perDiem,
          dailyRate: calculatedCosts.perDiemTotal.toString(),
        },
        equipment: {
          ...prev.costs.equipment,
          rental: calculatedCosts.equipmentTotal.toString(),
        },
        other: calculatedCosts.otherCostsTotal.toString(),
      },
    }));
    setCalculatorOpen(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          p: 4, // Add padding around the entire form
          '& .MuiGrid-item': {
            mb: 2 // Add margin bottom to all grid items
          },
          '& .MuiFormControl-root': {
            mb: 2 // Add margin bottom to all form controls
          },
          '& .MuiTypography-h6': {
            mb: 2, // Add margin below section headings
            mt: 2  // Add margin above section headings
          }
        }}
      >
        <Grid container spacing={3}>
          {/* Band Selection */}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Band</InputLabel>
              <Select
                name="band"
                value={formData.band}
                onChange={handleChange}
                required
              >
                {bands.map((band) => (
                  <MenuItem key={band._id} value={band._id}>
                    {band.artistName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Date/Time Selection */}
          <Grid item xs={12} sm={6}>
            <DateTimePicker
              label="Start Time"
              value={formData.startTime}
              onChange={handleDateChange('startTime')}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateTimePicker
              label="End Time"
              value={formData.endTime}
              onChange={handleDateChange('endTime')}
              sx={{ width: '100%' }}
            />
          </Grid>

          {/* Fee */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="fee"
              label="Performance Fee"
              type="text"
              inputMode="numeric"
              value={formData.fee}
              onChange={handleChange}
              required
            />
          </Grid>

          {/* Status */}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                name="status"
                value={formData.status}
                onChange={handleChange}
                required
              >
                <MenuItem value="confirmed">Confirmed</MenuItem>
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="cancelled">Cancelled</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Calculator Button */}
          <Grid item xs={12}>
            <Box sx={{ mb: 3 }}>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={() => setCalculatorOpen(true)}
                fullWidth
              >
                Open Event Calculator
              </Button>
            </Box>
          </Grid>

          {/* Costs Section */}
          <Grid item xs={12}>
            <Accordion sx={{ mb: 3 }}>
              <AccordionSummary 
                expandIcon={<ExpandMoreIcon />}
                sx={{ 
                  '&.MuiAccordionSummary-root': {
                    minHeight: 64
                  }
                }}
              >
                <Typography variant="h6" sx={{ m: 0 }}>Costs</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  {/* Cost sections with improved spacing */}
                  {/* Travel Section */}
                  <Grid item xs={12}>
                    <Typography variant="h6">Travel</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          label="Flights"
                          type="text"
                          inputMode="numeric"
                          value={formData.costs.travel.flights}
                          onChange={handleNestedChange('travel', 'flights')}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          label="Ground Transport"
                          type="text"
                          inputMode="numeric"
                          value={formData.costs.travel.groundTransport}
                          onChange={handleNestedChange('travel', 'groundTransport')}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          label="Other Travel Costs"
                          type="text"
                          inputMode="numeric"
                          value={formData.costs.travel.other}
                          onChange={handleNestedChange('travel', 'other')}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Similar grid structure for other cost sections... */}
                  {/* Cost Summary Section */}
                  <Grid item xs={12}>
                    <Box sx={{ 
                      bgcolor: 'background.paper', 
                      p: 3, 
                      borderRadius: 1,
                      mt: 3
                    }}>
                      <Typography variant="h6" gutterBottom>Cost Summary</Typography>
                      <Typography>Total Costs: €{totalCosts.toFixed(2)}</Typography>
                      <Typography>
                        Contingency ({formData.contingencyRate}%): €{contingency.toFixed(2)}
                      </Typography>
                      <Typography variant="h6" sx={{ mt: 2 }}>
                        Total Including Contingency: €{totalWithContingency.toFixed(2)}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>

          {/* Contingency Rate */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="contingencyRate"
              label="Contingency Rate (%)"
              type="text"
              inputMode="numeric"
              value={formData.contingencyRate}
              onChange={handleChange}
            />
          </Grid>

          {/* Notes */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="notes"
              label="Notes"
              multiline
              rows={4}
              value={formData.notes}
              onChange={handleChange}
            />
          </Grid>

          {/* Submit Button */}
          <Grid item xs={12}>
            <Button 
              type="submit" 
              variant="contained" 
              color="primary"
              size="large"
              fullWidth
              sx={{ mt: 2 }}
            >
              {isEdit ? 'Update Performance' : 'Add Performance'}
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* Event Calculator Dialog */}
      {calculatorOpen && (
        <EventCalculator
          onClose={() => setCalculatorOpen(false)}
          onSubmit={handleCalculatorSubmit}
          defaultCurrency="EUR"
        />
      )}
    </LocalizationProvider>
  );
};

export default PerformanceForm;