import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';

// Export types
export interface StageElement {
  id: string;
  name: string;
  icon: JSX.Element;
  type: string;
}

export interface PlacedElement extends StageElement {
  x: number;
  y: number;
  rotation: number;
  instanceId: string;
}

// Export size categories and their scaling factors
export const sizeCategoryMap = {
  xs: {
    scale: 0.5,
    items: ['di-box', 'di-box-active', 'di-box-passive', 'cable-ties', 'gaffer-tape', 'vocal-mic', 'instrument-mic', 'clip-on-mic', 'headset-mic', 
        'lavalier-mic', 'music-stand', 'guitar-stand',
        'power-strip', ]
  },
  sm: {
    scale: 0.75,
    items: [
      'mic-stand', 'interface', 'tablet'
    ]
  },
  md: {
    scale: 1,
    items: [
      'electric-guitar', 'electric-bass', 'trumpet', 'saxophone',
      'violin', 'viola', 'floor-monitor', 'keyboard-stand-x',
      'chair', 'bar-stool', 'laptop'
    ]
  },
  lg: {
    scale: 1.5,
    items: [
      'acoustic-guitar','electric-piano', 'bass-amp',
      'guitar-amp', 'keyboard-amp', 'table-small', 'mixer'
    ]
  },
  xl: {
    scale: 2,
    items: [
      'acoustic-piano', 'acoustic-bass', 'drum-riser', 'table-large', 'pa-speaker-l',
      'pa-speaker-r', 'subwoofer','drum-kit'
    ]
  }
};

// Update size configuration to ensure text remains readable in all cases
const sizeConfig = {
    xs: { 
      base: 45,    // Minimum size to accommodate text
      icon: 20, 
      fontSize: 10,
      labelPlacement: 'right' as const  // Small items show label to the right
    },
    sm: { 
      base: 50, 
      icon: 22, 
      fontSize: 11,
      labelPlacement: 'bottom' as const
    },
    md: { 
      base: 60, 
      icon: 24, 
      fontSize: 11,
      labelPlacement: 'bottom' as const
    },
    lg: { 
      base: 80, 
      icon: 32, 
      fontSize: 12,
      labelPlacement: 'bottom' as const
    },
    xl: { 
      base: 100, 
      icon: 40, 
      fontSize: 12,
      labelPlacement: 'bottom' as const
    }
  };

// Export helper function
export const getSizeCategory = (elementId: string) => {
  for (const [category, data] of Object.entries(sizeCategoryMap)) {
    if (data.items.includes(elementId)) {
      return category;
    }
  }
  return 'md'; // Default to medium if not found
};

interface SizedStageElementProps {
  element: PlacedElement | StageElement;
  isDragging?: boolean;
}

const SizedStageElement: React.FC<SizedStageElementProps> = ({ 
    element, 
    isDragging = false 
  }) => {
    const sizeCategory = getSizeCategory(element.id);
    const size = sizeConfig[sizeCategory as keyof typeof sizeConfig];
  
    const isHorizontalLabel = size.labelPlacement === 'right';
  
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: isHorizontalLabel ? 'row' : 'column',
          alignItems: 'center',
          justifyContent: 'center',
          opacity: isDragging ? 0.5 : 1,
          backgroundColor: 'background.paper',
          borderRadius: 1,
          padding: 1,
          boxSizing: 'border-box',
          width: isHorizontalLabel ? 'auto' : size.base,
          height: isHorizontalLabel ? size.base : 'auto',
          minWidth: size.base,
          border: '1px solid rgba(0,0,0,0.12)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: size.icon * 1.5,
            height: size.icon * 1.5,
          }}
        >
          {React.cloneElement(element.icon, {
            sx: { fontSize: size.icon }
          })}
        </Box>
        <Typography 
          variant="caption" 
          sx={{ 
            fontSize: size.fontSize,
            textAlign: 'center',
            lineHeight: 1.2,
            ml: isHorizontalLabel ? 1 : 0,
            mt: isHorizontalLabel ? 0 : 0.5,
            fontWeight: 500,
            color: 'text.primary',
            whiteSpace: isHorizontalLabel ? 'nowrap' : 'normal',
            maxWidth: isHorizontalLabel ? '100px' : size.base - 8,
            wordBreak: 'break-word'
          }}
        >
          {element.name}
        </Typography>
      </Box>
    );
  };
  
  export default SizedStageElement;