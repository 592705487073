import React from 'react';
import { Container, Grid, Typography, Box, Paper, Button } from '@mui/material';
import PomodoroTimer from '../../components/artists/PomodoroTimer';
import GoalList from '../../components/artists/GoalList';
import { clearStorage } from '../../components/artists/storageUtils';

const MusicAdminPage: React.FC = () => {
  const handleClearAllData = () => {
    clearStorage('pomodoroState');
    clearStorage('goals');
    window.location.reload(); // Reload the page to reset components
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      {/* Top Card - Full Width */}
      <Paper elevation={3} sx={{ padding: 4, mb: 4 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Music Admin Mondays
        </Typography>
        <Typography variant="body1" align="center" paragraph>
          Are you feeling overwhelmed or behind in admin tasks like booking gigs, social media, or that funding application? Join our Pomodoro sessions to focus and get things done!
        </Typography>
        <Typography variant="body2" align="center" gutterBottom>
          <strong>Date:</strong> Mondays, Oct 25th - Nov 25th inclusive
          <br />
          <strong>Time:</strong> 10:00 AM - 12:30 PM
          <br />
          <strong>Where:</strong> Online
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Button variant="outlined" color="secondary" onClick={handleClearAllData}>
            Clear All Data
          </Button>
        </Box>
      </Paper>
      {/* Pomodoro Timer and Goals Side by Side */}
      <Grid container spacing={4}>
        {/* Pomodoro Timer - Larger on the Left */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ padding: 4, height: '100%' }}>
            <PomodoroTimer />
          </Paper>
        </Grid>
        
        {/* Goals - Remaining Space on the Right */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ padding: 4, height: '100%' }}>
            <GoalList />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default MusicAdminPage;