// src/components/navigation/ArtistNav.tsx
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, Menu, MenuItem, Box, IconButton, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuIcon from '@mui/icons-material/Menu';
import ShowrunnrLogo from '../ShowrunnrLogo';
import { useAuth } from '../../contexts/AuthContext';

const ArtistNav: React.FC = () => {
  const { user, logout } = useAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" color="primary">
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
            <MenuIcon />
          </IconButton>
          <ShowrunnrLogo width={150} height={50} />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button color="inherit" component={Link} to="/artist/dashboard">My Dashboard</Button>
          <Button color="inherit" component={Link} to="/artist/profile">My Profile</Button>
          <Button color="inherit" component={Link} to="/submit">Submit A New Band</Button>
          
          {/* Add more artist-specific navigation items here */}
          <Button color="inherit" onClick={handleMenuOpen} endIcon={<ArrowDropDownIcon />}>
            More
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            {/* Add more menu items as needed */}
            <MenuItem onClick={handleMenuClose} component={Link} to="/artist/performances">My Performances</MenuItem>
            <MenuItem onClick={handleMenuClose} component={Link} to="/artist/calendar">Calendar</MenuItem>
            <MenuItem onClick={handleMenuClose} component={Link} to="/musicadmin">My Pomodoro</MenuItem>
            <MenuItem onClick={handleMenuClose} component={Link} to="/stage-designer">Stage Plot Creator</MenuItem>
          </Menu>

          <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
            <Typography variant="body2" sx={{ mr: 2 }}>
              {user?.email} ({user?.role})
            </Typography>
            <Button color="inherit" onClick={logout}>Logout</Button>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default ArtistNav;