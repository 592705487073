import React, { useState, useEffect } from 'react';
import { Box, Typography, Alert, Button, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { 
  BandRecommendationFormData as OriginalFormData,
  InstrumentWithPerformer, BandActivityStatus 
} from '../types/BandRecommendations';
import { useAuth } from '../contexts/AuthContext';
import FormSaveHandler from '../components/form/FormSaveHandler';


// Import components
import RecommenderInfo from './form/RecommenderInfo';
import BandInfo from './form/BandInfo';
import MediaLinks from './form/MediaLinks';
import ContactInfo from './form/ContactInfo';
import RecommendationDetails from './form/RecommendationDetails';
import Availability from './form/Availability';
import ReviewSubmit from './form/ReviewSubmit';
import AdditionalMedia from './form/AdditionalMedia';
import TechnicalRequirements from './form/TechnicalRequirements';
import FinancialInfo from './form/FinancialInfo';
import FormStepper from './FormStepper';

interface BandRecommendationFormData extends OriginalFormData {
  selectedStrand?: string;
  county?: string;
  _id?: string;
}

interface BandRecommendationFormProps {
  initialFormData?: BandRecommendationFormData;
  isEditMode?: boolean;
}

const BandRecommendationForm: React.FC<BandRecommendationFormProps> = ({
  initialFormData,
  isEditMode = false,
}) => {
  const navigate = useNavigate();
  const { user, getToken } = useAuth();
  const [submitting, setSubmitting] = useState(false);

  const defaultFormData: BandRecommendationFormData = {
    selectedStrand: '',
    county: '',
    recommenderName: '',
    recommenderEmail: '',
    recommenderCountry: '',
    isRecommenderApplicant: false,
    artistName: '',
    maleMembers: 0,
    femaleMembers: 0,
    instrumentation: [] as InstrumentWithPerformer[], // explicitly type the empty array
    about: '',
    performances: [],
    performanceLink: '',
    youtubeEmbeds: [],
    spotifyEmbeds: [],
    soundcloudEmbeds: [],
    bandcampEmbeds: [],
    instagramHandle: '',
    twitterHandle: '',
    facebookPage: '',
    tikTokHandle: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    recommendationNotes: '',
    activityStatus: BandActivityStatus.ACTIVE,  // Use the enum value instead of string
    activityStatusNote: '',
    activityStatusDate: new Date(),
    genres: [],
    similarArtists: [],
    availability: {
      from: new Date(),
      to: new Date(),
      excludedDates: [],
    },
    mainImage: '',
    additionalImages: [],
    technicalRider: {
      stageSize: '',
      audioRequirements: '',
      lightingRequirements: '',
      additionalEquipment: [],
    },
    financialDetails: {
      minimumFee: 0,
      preferredCurrency: '',
      accommodationNeeded: false,
      transportationNeeded: false,
    },
  };

  const [formContext, setFormContext] = useState<'initial' | 'artistDetails'>('initial');
  const [activeStep, setActiveStep] = useState<number>(0);
  const [formData, setFormData] = useState<BandRecommendationFormData>(() => {
    if (initialFormData) {
      return { ...defaultFormData, ...initialFormData };
    }
    if (!isEditMode) {
      const savedData = localStorage.getItem('bandRecommendationFormData');
      if (savedData) return JSON.parse(savedData);
    }
    return defaultFormData;
  });
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);


  const steps: string[] = [
    'Recommender Information',
    'Band/Artist Information',
    'Media Links',
    'Contact Information',
    'Recommendation Details',
    'Availability',
    ...(formContext === 'artistDetails' || isEditMode || user?.role === 'admin'
      ? ['Additional Media', 'Technical Requirements', 'Financial Information']
      : []),
    'Review & Submit',
  ];
  const handleSubmit = async () => {
    setSubmitting(true);
    setSuccessMessage(null);
    setErrorMessage(null);

    try {
      const token = await getToken();
      if (!token) {
        setErrorMessage('You must be logged in to submit a recommendation.');
        return;
      }

      const dataToSubmit = {
        ...formData,
        createdBy: user?.role || 'unknown',
        userId: user?._id || null,
        userRole: user?.role || null,
      };
      console.log('Instrumentation data being sent:', JSON.stringify(dataToSubmit.instrumentation, null, 2));

      if (!dataToSubmit.selectedStrand) {
        delete dataToSubmit.selectedStrand;
      }

      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true
      };

      if (isEditMode && formData._id) {
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/api/bandrecommendations/${formData._id}`,
          dataToSubmit,
          config
        );
        setSuccessMessage('Recommendation updated successfully!');
        navigate(`/band/${formData._id}`);
      } else {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/bandrecommendations`,
          dataToSubmit,
          config
        );
        setSuccessMessage('Recommendation submitted successfully!');
        localStorage.removeItem('bandRecommendationFormData');
        navigate(`/band/${response.data._id}`);
      }
    } catch (error: any) {
      console.error('Error submitting recommendation:', error);
      let errorMsg = 'Failed to submit recommendation. Please try again.';
      if (error.response) {
        switch (error.response.status) {
          case 401:
            errorMsg = 'Your session has expired. Please log in again.';
            break;
          case 403:
            errorMsg = 'You do not have permission to perform this action.';
            break;
          case 400:
            errorMsg = error.response.data.message || 'Invalid form data. Please check your inputs.';
            break;
          default:
            errorMsg = error.response.data.message || errorMsg;
        }
      }
      setErrorMessage(errorMsg);
    } finally {
      setSubmitting(false);
    }
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      handleSubmit();
    } else {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const getStepContent = (stepIndex: number) => {
    const commonProps = {
      formData,
      setFormData,
    };
  
  
    switch (stepIndex) {
      case 0:
        return <RecommenderInfo {...commonProps} />;
      case 1:
        return <BandInfo {...commonProps} />;
      case 2:
        return <MediaLinks {...commonProps} />;
      case 3:
        return <ContactInfo {...commonProps} />;
      case 4:
        return <RecommendationDetails {...commonProps} />;
      case 5:
        return <Availability {...commonProps} />;
      case 6:
        if (formContext === 'artistDetails' || isEditMode || user?.role === 'admin') {
          return <AdditionalMedia {...commonProps} />;
        }
        return (
          <ReviewSubmit
            formData={formData}
            onSubmit={handleSubmit}
            isEditMode={isEditMode}
          />
        );
      case 7:
        if (formContext === 'artistDetails' || isEditMode || user?.role === 'admin') {
          return <TechnicalRequirements {...commonProps} />;
        }
        return (
          <ReviewSubmit
            formData={formData}
            onSubmit={handleSubmit}
            isEditMode={isEditMode}
          />
        );
      case 8:
        if (formContext === 'artistDetails' || isEditMode || user?.role === 'admin') {
          return <FinancialInfo {...commonProps} />;
        }
        return (
          <ReviewSubmit
            formData={formData}
            onSubmit={handleSubmit}
            isEditMode={isEditMode}
          />
        );
      case 9:
        return (
          <ReviewSubmit
            formData={formData}
            onSubmit={handleSubmit}
            isEditMode={isEditMode}
          />
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <Container maxWidth="md">
       {/* Move FormSaveHandler here */}
       <FormSaveHandler
        formData={formData}
        formId={formData._id}
        isEditMode={isEditMode}
        enabled={!submitting} // Disable during final submission
      />
      <Box component="form" sx={{ mt: 4, mb: 8 }}>
        <Typography variant="h4" gutterBottom color="primary.main" align="center" sx={{ mb: 4 }}>
          {isEditMode ? 'Edit Artist/Band' : 'Artist/Band Creation'}
        </Typography>
        
        {successMessage && (
          <Alert severity="success" sx={{ mb: 3 }}>
            {successMessage}
          </Alert>
        )}
        {errorMessage && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {errorMessage}
          </Alert>
        )}
        
        <FormStepper 
          steps={steps} 
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
        
        <Box sx={{ p: 3 }}>
          {getStepContent(activeStep)}
        </Box>
        
        <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 3 }}>
          <Button
            variant="outlined"
            onClick={handleBack}
            disabled={activeStep === 0 || submitting}
          >
            Back
          </Button>
          {activeStep === steps.length - 1 ? (
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={submitting}
            >
              {submitting ? 'Submitting...' : isEditMode ? 'Save Changes' : 'Submit'}
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={handleNext}
              disabled={submitting}
            >
              Next
            </Button>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default BandRecommendationForm;