import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Typography, Box } from '@mui/material';
import axios from 'axios';
import PerformanceForm from '../components/PerformanceForm';
import LoadingSpinner from '../components/LoadingSpinner';
import ErrorMessage from '../components/ErrorMessage';

interface Event {
  _id: string;
  name: string;
}

interface Performance {
  _id: string;
  band: string;
  startTime: string;
  endTime: string;
  fee: string;
  status: string;
  costs: any;
  contingencyRate: string;
  notes: string;
  event: Event;
  eventId: string;  // New field for the separate event ID
  strandId?: string;  // Add strandId

}

const AddEditPerformancePage: React.FC = () => {
  const { eventId, id, strandId } = useParams<{ eventId?: string; id?: string; strandId?: string }>();
  const navigate = useNavigate();
  const [event, setEvent] = useState<Event | null>(null);
  const [performance, setPerformance] = useState<Performance | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id) {
          // Fetching performance data
          const performanceResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/performances/${id}`);
          setPerformance(performanceResponse.data);
          
          // Handle both event and strand cases
          if (performanceResponse.data.eventId) {
            const eventResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/events/${performanceResponse.data.eventId}`);
            setEvent(eventResponse.data);
          } else if (performanceResponse.data.strandId) {
            // Optionally fetch strand data if you need it
            // const strandResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/strands/${performanceResponse.data.strandId}`);
            // setStrand(strandResponse.data);
          } else {
            setError('No valid event or strand association found.');
          }
        } else if (eventId) {
          const eventResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/events/${eventId}`);
          setEvent(eventResponse.data);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to fetch data. Please try again.');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id, eventId]);

  const handleSubmit = async (performanceData: any) => {
    try {
      if (id) {
        // Update existing performance
        await axios.put(`${process.env.REACT_APP_API_URL}/api/performances/${id}`, performanceData);
      } else if (eventId) {
        // Add new performance
        await axios.post(`${process.env.REACT_APP_API_URL}/api/events/${eventId}/performances`, performanceData);
      } else {
        throw new Error('Missing event ID for new performance');
      }
      navigate(`/events/${eventId || performance?.eventId}`);
    } catch (err) {
      console.error('Error submitting performance:', err);
      setError('Failed to submit performance. Please try again.');
    }
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage message={error} />;
  if (!event && !performance) return <ErrorMessage message="No data found." />;

  return (
    <Container>
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          {id ? 'Edit' : 'Add'} Performance {event?.name ? `for ${event.name}` : ''}
        </Typography>
        <PerformanceForm
          eventId={eventId}
          strandId={strandId || performance?.strandId}
          onSubmit={handleSubmit}
          initialData={performance}
        />
      </Box>
    </Container>
  );
};

export default AddEditPerformancePage;