// src/pages/GuidelineViewPage.tsx
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Box,
  Button,
  Divider,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import axiosInstance from '../utils/axiosConfig';
import { useAuth } from '../contexts/AuthContext';

// Import the display components
import AccordionDisplay from '../components/guidelines/AccordionDisplay';
import TimelineDisplay from '../components/guidelines/TimelineDisplay';
import ContactInfoDisplay from '../components/guidelines/ContactInfoDisplay';

// Type Definitions
interface ContentSection {
  id: string;
  title: string;
  content: string;
}

interface TimelineEvent {
  id: string;
  title: string;
  date: string;
  color: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  icon: string;
}

interface ContactInfo {
  id: string;
  type: string;
  value: string;
  link?: string;
}

interface GuidelineVersion {
  title: string;
  subtitle?: string;
  contentSections: ContentSection[];
  timeline: TimelineEvent[];
  contactInfo: ContactInfo[];
  createdAt?: Date;
  createdBy?: string;
}

interface Strand {
  _id: string;
  name: string;
  slug: string;
}

interface Guideline {
  _id?: string;
  strandId: string | Strand;
  currentVersion: number;
  versions: GuidelineVersion[];
  isPublished: boolean;
  publishedVersion?: number;
  publishedAt?: Date;
  applicationLink?: string;
  deadline?: Date;
  createdAt?: Date;
  updatedAt?: Date;
}

const GuidelineViewPage: React.FC = () => {
  const { strandSlug } = useParams<{ strandSlug: string }>();
  const [guideline, setGuideline] = useState<Guideline | null>(null);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { user } = useAuth();

  // Fetch the guideline based on the strand slug
  useEffect(() => {
    const fetchGuideline = async () => {
      try {
        const response = await axiosInstance.get(`/api/guidelines/strand/${strandSlug}`);
        setGuideline(response.data);
      } catch (err: any) {
        console.error('Error fetching guideline:', err);
        setError(err.response?.data?.message || 'An error occurred');
      }
    };

    fetchGuideline();
  }, [strandSlug]);

  const handleEdit = () => {
    if (guideline?._id) {
      navigate(`/guidelines/${guideline._id}/edit`);
    }
  };

  if (error) {
    return (
      <Container>
        <Typography variant="h4" color="error">
          {error}
        </Typography>
      </Container>
    );
  }

  if (!guideline) {
    return (
      <Container>
        <Typography variant="h4">Loading...</Typography>
      </Container>
    );
  }

  // Get the published version or the latest version
  const versionIndex = guideline.publishedVersion
    ? guideline.publishedVersion - 1
    : guideline.currentVersion - 1;

  const currentVersion = guideline.versions[versionIndex];

  // Handle the case where strandId could be a string or an object
  const strandName = typeof guideline.strandId === 'object' && 'name' in guideline.strandId
    ? guideline.strandId.name
    : 'Unknown Strand';

  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h3">
            {strandName} {new Date().getFullYear()} Guidelines
          </Typography>
          {user?.role === 'admin' && guideline?._id && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<EditIcon />}
              onClick={handleEdit}
            >
              Edit Guidelines
            </Button>
          )}
        </Box>

        <Typography variant="h4" gutterBottom>
          {currentVersion.title}
        </Typography>
        {currentVersion.subtitle && (
          <Typography variant="h5" gutterBottom>
            {currentVersion.subtitle}
          </Typography>
        )}
        {guideline.applicationLink && (
          <Box my={2}>
            <Button
              variant="contained"
              color="primary"
              component="a"
              href={guideline.applicationLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Apply Now
            </Button>
          </Box>
        )}
        {currentVersion.contentSections && currentVersion.contentSections.length > 0 && (
          <Box my={4}>
            <Typography variant="h5" gutterBottom>
              Information
            </Typography>
            <Divider />
            <AccordionDisplay sections={currentVersion.contentSections} />
          </Box>
        )}
        {currentVersion.timeline && currentVersion.timeline.length > 0 && (
          <Box my={4}>
            <Typography variant="h5" gutterBottom>
              Timeline
            </Typography>
            <Divider />
            <TimelineDisplay events={currentVersion.timeline} />
          </Box>
        )}
        {currentVersion.contactInfo && currentVersion.contactInfo.length > 0 && (
          <Box my={4}>
            <Typography variant="h5" gutterBottom>
              Contact Information
            </Typography>
            <Divider />
            <ContactInfoDisplay contactInfo={currentVersion.contactInfo} />
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default GuidelineViewPage;