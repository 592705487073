import React, { useState, useEffect } from 'react';
import {
  Box, Button, TextField, Typography, List, ListItem, ListItemText,
  Checkbox, IconButton, Paper, Chip, Fade, Grow, Divider
} from '@mui/material';
import { Delete, EmojiEvents } from '@mui/icons-material';
import { saveToStorage, loadFromStorage } from './storageUtils';

interface Goal {
  id: number;
  text: string;
  completed: boolean;
}

const GoalList: React.FC = () => {
  const [goals, setGoals] = useState<Goal[]>([]);
  const [newGoal, setNewGoal] = useState<string>('');
  const [completedCount, setCompletedCount] = useState<number>(0);
  const [showCelebration, setShowCelebration] = useState<boolean>(false);

  useEffect(() => {
    const savedGoals = loadFromStorage('goals');
    console.log('Loaded goals:', savedGoals);
    if (savedGoals && Array.isArray(savedGoals)) {
      setGoals(savedGoals);
      setCompletedCount(savedGoals.filter(goal => goal.completed).length);
    }
  }, []);

  useEffect(() => {
    console.log('Saving goals:', goals);
    saveToStorage('goals', goals);
    const completed = goals.filter(goal => goal.completed).length;
    setCompletedCount(completed);
    if (completed > 0 && completed % 3 === 0) {
      setShowCelebration(true);
      setTimeout(() => setShowCelebration(false), 3000);
    }
  }, [goals]);

  const handleAddGoal = () => {
    if (newGoal.trim() === '') return;
    const newGoalItem: Goal = {
      id: Date.now(),
      text: newGoal.trim(),
      completed: false,
    };
    setGoals(prevGoals => [...prevGoals, newGoalItem]);
    setNewGoal('');
  };

  const handleToggleComplete = (id: number) => {
    setGoals(prevGoals => 
      prevGoals.map(goal =>
        goal.id === id ? { ...goal, completed: !goal.completed } : goal
      )
    );
  };

  const handleDeleteGoal = (id: number) => {
    setGoals(prevGoals => prevGoals.filter(goal => goal.id !== id));
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleAddGoal();
    }
  };

  return (
    <Paper elevation={3} sx={{ padding: 4, width: '100%', maxWidth: 500, position: 'relative' }}>
      <Typography variant="h5" align="center" gutterBottom>
        Your Goals
      </Typography>
      
      <Chip
        label={`${completedCount}/${goals.length}`}
        color="primary"
        sx={{
          position: 'absolute',
          top: 16,
          right: 16,
          fontSize: '1.2rem',
          fontWeight: 'bold',
        }}
      />

      <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
        <TextField
          label="Add a new goal"
          variant="outlined"
          value={newGoal}
          onChange={(e) => setNewGoal(e.target.value)}
          onKeyPress={handleKeyPress}
          fullWidth
        />
        <Button variant="contained" color="primary" onClick={handleAddGoal}>
          Add
        </Button>
      </Box>

      <List>
        {goals.filter(goal => !goal.completed).map(goal => (
          <Grow key={goal.id} in={true}>
            <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteGoal(goal.id)}>
                  <Delete />
                </IconButton>
              }
            >
              <Checkbox
                edge="start"
                checked={goal.completed}
                onChange={() => handleToggleComplete(goal.id)}
                tabIndex={-1}
                disableRipple
              />
              <ListItemText primary={goal.text} />
            </ListItem>
          </Grow>
        ))}
      </List>

      {goals.filter(goal => goal.completed).length > 0 && (
        <>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6" gutterBottom>
            Completed Goals
          </Typography>
          <List>
            {goals.filter(goal => goal.completed).map(goal => (
              <Fade key={goal.id} in={true}>
                <ListItem
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteGoal(goal.id)}>
                      <Delete />
                    </IconButton>
                  }
                >
                  <Checkbox
                    edge="start"
                    checked={goal.completed}
                    onChange={() => handleToggleComplete(goal.id)}
                    tabIndex={-1}
                    disableRipple
                  />
                  <ListItemText
                    primary={goal.text}
                    style={{
                      textDecoration: 'line-through',
                      color: 'gray',
                    }}
                  />
                </ListItem>
              </Fade>
            ))}
          </List>
        </>
      )}

      {goals.length === 0 && (
        <Typography variant="body2" align="center" color="textSecondary">
          No goals added yet. Add a goal to get started!
        </Typography>
      )}

      <Fade in={showCelebration}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            zIndex: 1000,
          }}
        >
          <EmojiEvents sx={{ fontSize: 60, color: 'gold' }} />
          <Typography variant="h5" color="primary">
            Great job! Keep it up!
          </Typography>
        </Box>
      </Fade>
    </Paper>
  );
};

export default GoalList;