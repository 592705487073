// genericFields.ts

import { FieldType, FormField, FieldBlock } from '../../../types/FormSchema';

export const genericInfoBlock: FieldBlock = {
  id: 'generic-info',
  label: 'Generic Information',
  fields: [
    {
      id: 'firstName',
      type: FieldType.Text,
      name: 'firstName',
      label: 'First Name',
      validation: { required: true },
      grid: { sm: 6 },
    },
    {
      id: 'lastName',
      type: FieldType.Text,
      name: 'lastName',
      label: 'Last Name',
      validation: { required: true },
      grid: { sm: 6 },
    },
    {
      id: 'email',
      type: FieldType.Email,
      name: 'email',
      label: 'Email Address',
      validation: { required: true },
      grid: { sm: 12 },
    },
    {
      id: 'streetAddress1',
      type: FieldType.Text,
      name: 'streetAddress1',
      label: 'Street Address 1',
      validation: { required: true },
      grid: { sm: 12 },
    },
    {
      id: 'streetAddress2',
      type: FieldType.Text,
      name: 'streetAddress2',
      label: 'Street Address 2',
      validation: { required: false },
      grid: { sm: 12 },
    },
    {
      id: 'city',
      type: FieldType.Text,
      name: 'city',
      label: 'City',
      validation: { required: true },
      grid: { sm: 6 },
    },
    {
      id: 'county',
      type: FieldType.Text,
      name: 'county',
      label: 'County',
      validation: { required: true },
      grid: { sm: 6 },
    },
    {
      id: 'postcode',
      type: FieldType.Text,
      name: 'postcode',
      label: 'Postcode',
      validation: { required: true },
      grid: { sm: 6 },
    },
    {
      id: 'phone',
      type: FieldType.Text,
      name: 'phone',
      label: 'Phone',
      validation: { required: true },
      grid: { sm: 6 },
    },
    {
      id: 'websiteOrSocial1',
      type: FieldType.Url,
      name: 'websiteOrSocial1',
      label: 'Website/Social 1',
      validation: { required: false },
      grid: { sm: 12 },
    },
    {
      id: 'websiteOrSocial2',
      type: FieldType.Url,
      name: 'websiteOrSocial2',
      label: 'Website/Social 2',
      validation: { required: false },
      grid: { sm: 12 },
    },
  ],
};