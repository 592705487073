// src/components/bandpage/StrandBandView.tsx
import React from 'react';
import { useParams } from 'react-router-dom';
import BandContainer from './BandContainer';
import { BandViewProvider } from '../../contexts/BandViewContext';

const StrandBandView: React.FC = () => {
  const { bandId, strandSlug } = useParams();

  return (
    <BandViewProvider 
      initialViewMode="strand"
      currentUser={null} // or pass user if needed
    >
      <BandContainer />
    </BandViewProvider>
  );
};

export default StrandBandView;