import React from 'react';
import { Container, Typography, Paper, Box } from '@mui/material';
import BanBamForm from '../components/form/BanBamForm';

const BanBamPage: React.FC = () => {
  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h2" component="h1" gutterBottom align="center">
          BAN BAM Application
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom align="center">
          Commission & Development Award 2025
        </Typography>
        <Typography variant="subtitle1" gutterBottom align="center">
          Supporting women in jazz, improvised and experimental music
        </Typography>
      </Box>
      <Paper elevation={3}>
        <Box p={3}>
          <Typography variant="body1" paragraph>
            Welcome to the BAN BAM Commission & Development Award application process. 
            This programme is designed to support and promote women in jazz, improvised, 
            and experimental music. Please fill out the form below with care and attention 
            to detail.
          </Typography>
          <Typography variant="body1" paragraph>
            Before you begin, please ensure you have the following ready:
            <ul>
              <li>Your CV in PDF format (required)</li>
              <li>Audio or video files of your work in MP3 format, no WAV files please! (max 2 files, suggest contrasting works to highlight range etc) and/or links to your original live or recorded music online</li>
              <li>A score of your work, also in PDF format. This does not have to conform to standard notation but it does have to show evidence of how you approach scoring work.(required)</li>
              <li>Links to your online presence (website, social media, music platforms)</li>
              <li>Ensure you only use PDFs and mp3s in uploads</li>
            </ul>
          </Typography>
          <BanBamForm />
        </Box>
      </Paper>
    </Container>
  );
};

export default BanBamPage;