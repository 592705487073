export const SALUTATIONS = [
  "Welcome back, {name}. Ready for a great day?",
  "Hello {name}, let's make today count.",
  "Hi {name}, it's good to see you.",
  "Glad you're here, {name}. Let's get started.",
  "Hey {name}, hope you're doing well today.",
  "Welcome, {name}. Let's dive in.",
  "Hi {name}, ready for today's journey?",
  "Hello {name}, let's accomplish something amazing today.",
  "Good to see you, {name}. Let's begin.",
  "Hi {name}, let's make today a good one.",
  "Welcome, {name}. Let's make some progress.",
  "Hello {name}, hope you're feeling ready for today.",
  "Nice to see you, {name}. Let's get going.",
  "Hi {name}, time to get started!",
  "Welcome back, {name}. Let's do this together."
];

export const getRandomSalutation = (name: string): string => {
  const randomIndex = Math.floor(Math.random() * SALUTATIONS.length);
  return SALUTATIONS[randomIndex].replace("{name}", name);
};