import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface ContentSection {
  id: string;
  title: string;
  content: string;
}

interface AccordionDisplayProps {
  sections: ContentSection[];
}

const AccordionDisplay: React.FC<AccordionDisplayProps> = ({ sections }) => {
  return (
    <>
      {sections.map((section) => (
        <Accordion key={section.id}>
          <AccordionSummary 
            expandIcon={<ExpandMoreIcon />}
            sx={{
              '& .MuiAccordionSummary-content': {
                margin: '12px 0',
              }
            }}
          >
            <Typography variant="h6">{section.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                '& p': { mb: 2 },
                '& strong': { fontWeight: 'bold' },
                '& em': { fontStyle: 'italic' },
                '& ul, & ol': { pl: 3, mb: 2 },
                '& li': { mb: 1 },
                '& a': { color: 'primary.main' },
              }}
              dangerouslySetInnerHTML={{ __html: section.content }}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default AccordionDisplay;