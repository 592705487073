import { IrishCounty } from './BandRecommendations';


export enum Instrument {
    // Keyboard Instruments
    Piano = "Piano",
    Organ = "Organ",
    Synthesizer = "Synthesizer",
    Rhodes = "Rhodes",
    Wurlitzer = "Wurlitzer",
    Harpsichord = "Harpsichord",
    Clavinet = "Clavinet",
  
    // String Instruments
    Guitar = "Guitar",
    AcousticGuitar = "Acoustic Guitar",
    ElectricGuitar = "Electric Guitar",
    Bass = "Bass",
    DoubleBass = "Double Bass",
    Violin = "Violin",
    Viola = "Viola",
    Cello = "Cello",
    Banjo = "Banjo",
    Mandolin = "Mandolin",
    Ukulele = "Ukulele",
    Harp = "Harp",
    LapSteelGuitar = "Lap Steel Guitar",
    PedalSteelGuitar = "Pedal Steel Guitar",
  
    // Wind Instruments (Brass and Woodwinds)
    Trumpet = "Trumpet",
    Trombone = "Trombone",
    FrenchHorn = "French Horn",
    Tuba = "Tuba",
    Flugelhorn = "Flugelhorn",
    Cornet = "Cornet",
    Saxophone = "Saxophone",
    AltoSaxophone = "Alto Saxophone",
    TenorSaxophone = "Tenor Saxophone",
    BaritoneSaxophone = "Baritone Saxophone",
    SopranoSaxophone = "Soprano Saxophone",
    Clarinet = "Clarinet",
    BassClarinet = "Bass Clarinet",
    Flute = "Flute",
    Piccolo = "Piccolo",
    Oboe = "Oboe",
    EnglishHorn = "English Horn",
    Bassoon = "Bassoon",
    Harmonica = "Harmonica",
  
    // Percussion Instruments
    Drums = "Drums",
    SnareDrum = "Snare Drum",
    BassDrum = "Bass Drum",
    Congas = "Congas",
    Bongos = "Bongos",
    Timbales = "Timbales",
    Cajon = "Cajon",
    Vibraphone = "Vibraphone",
    Marimba = "Marimba",
    Xylophone = "Xylophone",
    Glockenspiel = "Glockenspiel",
    Timpani = "Timpani",
    Triangle = "Triangle",
    Cowbell = "Cowbell",
    Cymbals = "Cymbals",
    HiHat = "Hi-Hat",
    Tambourine = "Tambourine",
    Djembe = "Djembe",
  
    // Vocal Styles
    Vocals = "Vocals",
    Soprano = "Soprano",
    Alto = "Alto",
    Tenor = "Tenor",
    Baritone = "Baritone",
    BassVoice = "Bass Voice",
    ScatSinging = "Scat Singing",
    
    // Other Instruments
    Accordion = "Accordion",
    Bagpipes = "Bagpipes",
    Didgeridoo = "Didgeridoo",
    Melodica = "Melodica",
    Theremin = "Theremin",
    Kalimba = "Kalimba",
    Oud = "Oud",
    Sitar = "Sitar",
    Zither = "Zither",
    Shamisen = "Shamisen",
    Dulcimer = "Dulcimer",
    Bouzouki = "Bouzouki",
    PanFlute = "Pan Flute",
    Shakuhachi = "Shakuhachi",
    Koto = "Koto",
    Guzheng = "Guzheng"
  }

  export enum Pronouns {
    HeHim = "He/Him",
    SheHer = "She/Her",
    TheyThem = "They/Them",
    ZeZir = "Ze/Zir",
    XeXem = "Xe/Xem",
    PreferNotToSay = "Prefer not to say",
  }

export interface LocationDate {
    location: string;
    dates: string;
  }
  
  export interface NavigatorFormData {
    name: string;
    email: string;
    phone: string;
    instrument: Instrument [];
    location: IrishCounty;
    pronouns: Pronouns;
    linkToWork: string;
    webLink: string;
    residencyGoals: string;
    locationDates: string[];
    parentalDuties: string;
  }