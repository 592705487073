import React from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import { Event, Performance } from '../../types/dashboard';
import EventList from './EventList';
import PerformanceList from './PerformanceList';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`dashboard-tabpanel-${index}`}
      aria-labelledby={`dashboard-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

interface DashboardTabsProps {
  upcomingEvents: Event[];
  pendingPerformances: Performance[];
  confirmedPerformances: Performance[];
  tabValue: number;
  onTabChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const DashboardTabs: React.FC<DashboardTabsProps> = ({
  upcomingEvents,
  pendingPerformances,
  confirmedPerformances,
  tabValue,
  onTabChange
}) => {
  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs 
          value={tabValue} 
          onChange={onTabChange} 
          aria-label="dashboard tabs"
          variant="fullWidth"
        >
          <Tab label="Upcoming Events" />
          <Tab label="Pending Performances" />
          <Tab label="Confirmed Performances" />
        </Tabs>
      </Box>

      <TabPanel value={tabValue} index={0}>
        <EventList 
          events={upcomingEvents} 
          emptyMessage="No upcoming events."
        />
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <PerformanceList 
          performances={pendingPerformances}
          type="pending"
          emptyMessage="No pending performances."
        />
      </TabPanel>

      <TabPanel value={tabValue} index={2}>
        <PerformanceList 
          performances={confirmedPerformances}
          type="confirmed"
          emptyMessage="No confirmed performances."
        />
      </TabPanel>
    </Box>
  );
};

export default DashboardTabs;