import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { CircularProgress, Typography, Container } from '@mui/material';
import { BandRecommendation } from '../../types/BandRecommendations';
import { useAuth } from '../../contexts/AuthContext';
import { User, UserRole } from '../../types/User';
import axiosInstance from '../../utils/axiosConfig';
import { createYouTubeEmbed } from '../../utils/youtubeEmbedUtil';
import { useBandView } from '../../contexts/BandViewContext';

// Components
import { BandViewProvider } from '../../contexts/BandViewContext';
import AdminViewSwitcher from './AdminViewSwitcher';
import BandLayout from './BandLayout';
import AddToEventDialog from './dialogs/AddToEventDialog';
import DeleteConfirmDialog from './dialogs/DeleteConfirmDialog';
import StrandPublishingManager from './StrandPublishingManager';

interface Event {
  _id: string;
  name: string;
  date: string;
}

const BandContainerContent: React.FC = () => {
  const { id, bandId, strandSlug } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { viewMode, viewConfig } = useBandView();

  const currentBandId = id || bandId;
  
  const [band, setBand] = useState<BandRecommendation | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [events, setEvents] = useState<Event[]>([]);
  const [showPublishManager, setShowPublishManager] = useState(false);

  // Dialog states
  const [openAddToEventDialog, setOpenAddToEventDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<string>('');
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const fetchBandDetails = async () => {
      if (!currentBandId) return;

      try {
        setLoading(true);
        const endpoint = `/api/bandrecommendations/${currentBandId}${
          strandSlug ? `?strandSlug=${strandSlug}` : ''
        }`;

        const response = await axiosInstance.get<BandRecommendation>(endpoint);
        
        const updatedBand = {
          ...response.data,
          youtubeEmbeds: response.data.youtubeEmbeds.map(embed =>
            embed.startsWith('<iframe') ? embed : createYouTubeEmbed(embed)
          )
        };
        
        setBand(updatedBand);
        setError(null);
      } catch (error) {
        console.error('Error fetching band details:', error);
        setError('An unexpected error occurred while fetching band details.');
      } finally {
        setLoading(false);
      }
    };

    // Only fetch events if admin actions are enabled
    const fetchEvents = async () => {
      if (!viewConfig.showAdminActions || strandSlug) return;
      
      try {
        const response = await axiosInstance.get<Event[]>('/api/events');
        setEvents(response.data);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchBandDetails();
    fetchEvents();
  }, [currentBandId, strandSlug, viewConfig.showAdminActions]);

  const handleAddToExistingEvent = async () => {
    if (!viewConfig.showAdminActions || !selectedEvent || !band) return;
    
    try {
      await axiosInstance.post(`/api/events/${selectedEvent}/performances`, {
        band: band._id,
      });
      setOpenAddToEventDialog(false);
    } catch (error) {
      console.error('Error adding band to event:', error);
    }
  };

  const handleConfirmDelete = async () => {
    if (!band) return;
    
    try {
      setIsDeleting(true);
      await axiosInstance.delete(`/api/bandrecommendations/${band._id}`);
      setOpenDeleteDialog(false);
      navigate(viewMode === 'admin' ? '/admin/bands' : '/artist/dashboard');
    } catch (error) {
      console.error('Error deleting band recommendation:', error);
    } finally {
      setIsDeleting(false);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!band) return <Typography>Band not found.</Typography>;
  if (!band._id) return <Typography>Invalid band data</Typography>;

  return (
    <Container maxWidth="lg">
      {viewConfig.showAdminActions && !strandSlug && <AdminViewSwitcher />}
      
      <BandLayout 
        band={band}
        onAddToEvent={() => setOpenAddToEventDialog(true)}
        events={events}
        onDelete={() => setOpenDeleteDialog(true)}
        onPublish={async () => {
          setShowPublishManager(true);
          return Promise.resolve();
        }}      />

      {viewConfig.showAdminActions && !strandSlug && (
        <>
          <AddToEventDialog
            open={openAddToEventDialog}
            onClose={() => setOpenAddToEventDialog(false)}
            bandName={band.artistName}
            events={events}
            selectedEvent={selectedEvent}
            onEventSelect={setSelectedEvent}
            onCreateNew={() => navigate('/events/new', { state: { bandId: currentBandId } })}
            onConfirm={handleAddToExistingEvent}
          />

          <StrandPublishingManager
            bandId={band._id}
            open={showPublishManager}
            onClose={() => setShowPublishManager(false)}
          />
        </>
      )}

      <DeleteConfirmDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onConfirm={handleConfirmDelete}
        bandName={band.artistName}
        loading={isDeleting}
      />
    </Container>
  );
};

const BandContainer: React.FC = () => {
  const { user } = useAuth();
  const typedUser: User | null = user ? {
    ...user,
    role: user.role as UserRole,
    createdAt: new Date(),
    updatedAt: new Date()
  } : null;

  // Don't force initial view mode based on role
  return (
    <BandViewProvider currentUser={typedUser}>
      <BandContainerContent />
    </BandViewProvider>
  );
};

export default BandContainer;