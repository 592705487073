import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UserRole, User, UserPermissions, getUserPermissions } from '../types/User';

export type ViewMode = 'admin' | 'artist' | 'public' | 'strand' | 'judge';

export interface ViewConfig {
  showAdminActions: boolean;
  showFinancialDetails: boolean;
  showTechnicalRequirements: boolean;
  showContactInfo: boolean;
  showDemographics: boolean;
  showRecommenderInfo: boolean;
  showAvailability: boolean;
  showSocialMedia: boolean;
  showFullMedia: boolean;
  showinstrumentation: boolean;
  showPublishedStrandCard: boolean;
}

export const VIEW_CONFIGS: Record<ViewMode, ViewConfig> = {
  public: {
    showAdminActions: false,
    showFinancialDetails: false,
    showTechnicalRequirements: false,
    showContactInfo: false,
    showDemographics: true,
    showRecommenderInfo: false,
    showAvailability: false,
    showSocialMedia: true,
    showFullMedia: true,
    showinstrumentation: true,
    showPublishedStrandCard: false
  },
  artist: {
    showAdminActions: false,
    showFinancialDetails: true,
    showTechnicalRequirements: true,
    showContactInfo: true,
    showDemographics: true,
    showRecommenderInfo: false,
    showAvailability: true,
    showSocialMedia: true,
    showFullMedia: true,
    showinstrumentation: true,
    showPublishedStrandCard: true
  },
  admin: {
    showAdminActions: true,
    showFinancialDetails: true,
    showTechnicalRequirements: true,
    showContactInfo: true,
    showDemographics: true,
    showRecommenderInfo: true,
    showAvailability: true,
    showSocialMedia: true,
    showFullMedia: true,
    showinstrumentation: true,
    showPublishedStrandCard: true
  },
  strand: {
    showAdminActions: false,
    showFinancialDetails: false,
    showTechnicalRequirements: true,
    showContactInfo: true,
    showDemographics: true,
    showRecommenderInfo: true,
    showAvailability: true,
    showSocialMedia: true,
    showFullMedia: true,
    showinstrumentation: true,
    showPublishedStrandCard: false
  },
  judge: {
    showAdminActions: false,
    showFinancialDetails: false,
    showTechnicalRequirements: true,
    showContactInfo: false,
    showDemographics: true,
    showRecommenderInfo: true,
    showAvailability: true,
    showSocialMedia: true,
    showFullMedia: true,
    showinstrumentation: true,
    showPublishedStrandCard: false
  }
};

interface StrandContext {
  strandSlug?: string;
  strandId?: string;
}

interface BandViewContextProps {
  viewMode: ViewMode;
  viewConfig: ViewConfig;
  currentUser: User | null;
  permissions: UserPermissions;
  strandContext?: StrandContext;
  actions: {
    handlePublish: () => void;
    handleEdit: () => void;
    handleDelete: () => void;
    handleAddToEvent: () => void;
  };
  setViewMode: (mode: ViewMode) => void;
}

const BandViewContext = createContext<BandViewContextProps | undefined>(undefined);

export const BandViewProvider: React.FC<{ 
  children: ReactNode;
  currentUser: User | null;
  initialViewMode?: ViewMode;
}> = ({ children, currentUser, initialViewMode = 'admin' }) => {
  const [viewMode, setViewMode] = useState<ViewMode>(initialViewMode);
  
  // Get both standard and strand-specific URL parameters
  const { strandSlug, bandId } = useParams<{ 
    strandSlug?: string;
    bandId?: string;
  }>();

  // Set up strand context if we're in a strand view
  const strandContext: StrandContext | undefined = strandSlug ? {
    strandSlug,
    strandId: bandId
  } : undefined;

  // Effect to handle view mode changes based on URL context
  useEffect(() => {
    if (strandSlug) {
      setViewMode('strand');
    } else if (initialViewMode) {
      setViewMode(initialViewMode);
    }
  }, [strandSlug, initialViewMode]);

  // Get permissions based on user role
  const permissions = currentUser 
    ? getUserPermissions(currentUser.role)
    : getUserPermissions(UserRole.Guest);

  // Get view configuration based on current view mode
  const viewConfig = VIEW_CONFIGS[viewMode];
  
  const actions = {
    handlePublish: () => {
      if (!viewConfig.showAdminActions) return;
      console.log('Publish action');
    },
    handleEdit: () => {
      if (!viewConfig.showAdminActions) return;
      console.log('Edit action');
    },
    handleDelete: () => {
      if (!viewConfig.showAdminActions) return;
      console.log('Delete action');
    },
    handleAddToEvent: () => {
      if (!viewConfig.showAdminActions) return;
      console.log('Add to event action');
    }
  };

  // Extended context value including strand context
  const contextValue: BandViewContextProps = {
    viewMode,
    viewConfig,
    currentUser,
    permissions,
    strandContext,
    actions,
    setViewMode
  };

  return (
    <BandViewContext.Provider value={contextValue}>
      {children}
    </BandViewContext.Provider>
  );
};

export const useBandView = () => {
  const context = useContext(BandViewContext);
  if (context === undefined) {
    throw new Error('useBandView must be used within a BandViewProvider');
  }
  return context;
};

export default BandViewContext;