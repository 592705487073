import React from 'react';
import { useDroppable } from '@dnd-kit/core';
import { FormField } from '../../../types/FormSchema';
import CanvasField from './CanvasField';
import { Grid } from '@mui/material';

interface FormCanvasProps {
  fields: FormField[];
  onUpdateField: (field: FormField) => void;
  onRemoveField: (fieldId: string) => void;
}

const FormCanvas: React.FC<FormCanvasProps> = ({ 
  fields, 
  onUpdateField, 
  onRemoveField 
}) => {
  const { setNodeRef, isOver } = useDroppable({
    id: 'canvas',
  });

  return (
    <div
      ref={setNodeRef}
      style={{
        minHeight: '400px',
        border: `2px dashed ${isOver ? '#6366f1' : 'rgba(255, 255, 255, 0.2)'}`,
        padding: '16px',
        backgroundColor: isOver ? 'rgba(99, 102, 241, 0.1)' : 'transparent',
        transition: 'all 0.2s ease',
        borderRadius: '8px',
        color: '#fff',
      }}
    >
      {fields.length === 0 && (
        <div style={{ 
          textAlign: 'center', 
          color: 'rgba(255, 255, 255, 0.6)',
          padding: '2rem',
        }}>
          <p>Drag fields here to build your form.</p>
        </div>
      )}
      <Grid container spacing={2}>
        {fields.map((field) => (
          <Grid
            item
            xs={field.grid?.xs || 12}
            sm={field.grid?.sm || 12}
            md={field.grid?.md || 12}
            lg={field.grid?.lg || 12}
            key={field.id}
          >
            <CanvasField
              field={field}
              onUpdate={onUpdateField}
              onRemove={onRemoveField}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default FormCanvas;