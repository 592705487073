import React, { useState } from 'react';
import { 
  Box, 
  Button, 
  Grid, 
  Typography, 
  TextField, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';

interface EventCalculatorProps {
  onClose: () => void;
  onSubmit: (calculatedCosts: CalculatedCosts) => void;
  defaultCurrency?: string;
}

interface CalculatedCosts {
  flightsTotal: number;
  accommodationTotal: number;
  perDiemTotal: number;
  equipmentTotal: number;
  otherCostsTotal: number;
  grandTotal: number;
  currency: string;
}

const currencies = {
  EUR: '€',
  GBP: '£',
  USD: '$'
};

const EventCalculator: React.FC<EventCalculatorProps> = ({ 
  onClose, 
  onSubmit, 
  defaultCurrency = 'EUR'  // Default to EUR for Ireland
}) => {
  const [currency, setCurrency] = useState(defaultCurrency);
  const [flights, setFlights] = useState({ costPerPerson: '', numberOfPeople: '' });
  const [accommodation, setAccommodation] = useState({ 
    costPerRoomPerNight: '', 
    numberOfRooms: '', 
    numberOfNights: '' 
  });
  const [perDiem, setPerDiem] = useState({ 
    dailyRatePerPerson: '', 
    numberOfPeople: '', 
    numberOfDays: '' 
  });
  const [equipment, setEquipment] = useState({ 
    costPerItem: '', 
    numberOfItems: '', 
    numberOfDays: '' 
  });
  const [otherCosts, setOtherCosts] = useState([{ description: '', amount: '' }]);

  const parseNumber = (value: string) => (value === '' ? 0 : parseFloat(value));

  const calculateFlightsTotal = () => 
    parseNumber(flights.costPerPerson) * parseNumber(flights.numberOfPeople);

  const calculateAccommodationTotal = () =>
    parseNumber(accommodation.costPerRoomPerNight) *
    parseNumber(accommodation.numberOfRooms) *
    parseNumber(accommodation.numberOfNights);

  const calculatePerDiemTotal = () =>
    parseNumber(perDiem.dailyRatePerPerson) *
    parseNumber(perDiem.numberOfPeople) *
    parseNumber(perDiem.numberOfDays);

  const calculateEquipmentTotal = () =>
    parseNumber(equipment.costPerItem) * 
    parseNumber(equipment.numberOfItems) * 
    parseNumber(equipment.numberOfDays);

  const calculateOtherCostsTotal = () =>
    otherCosts.reduce((sum, cost) => sum + parseNumber(cost.amount), 0);

  const grandTotal =
    calculateFlightsTotal() +
    calculateAccommodationTotal() +
    calculatePerDiemTotal() +
    calculateEquipmentTotal() +
    calculateOtherCostsTotal();

  const handleAddOtherCost = () => {
    setOtherCosts([...otherCosts, { description: '', amount: '' }]);
  };

  const handleOtherCostChange = (index: number, field: string, value: string) => {
    const updatedOtherCosts = otherCosts.map((cost, i) =>
      i === index ? { ...cost, [field]: value } : cost
    );
    setOtherCosts(updatedOtherCosts);
  };

  const formatCurrency = (amount: number) => {
    const currencySymbol = currencies[currency as keyof typeof currencies];
    return `${currencySymbol}${amount.toFixed(2)}`;
  };

  const handleSubmit = () => {
    const calculatedCosts: CalculatedCosts = {
      flightsTotal: calculateFlightsTotal(),
      accommodationTotal: calculateAccommodationTotal(),
      perDiemTotal: calculatePerDiemTotal(),
      equipmentTotal: calculateEquipmentTotal(),
      otherCostsTotal: calculateOtherCostsTotal(),
      grandTotal,
      currency
    };
    onSubmit(calculatedCosts);
  };

  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography>Event Calculator</Typography>
          <FormControl sx={{ minWidth: 120 }} size="small">
            <InputLabel>Currency</InputLabel>
            <Select
              value={currency}
              label="Currency"
              onChange={(e) => setCurrency(e.target.value)}
            >
              <MenuItem value="EUR">EUR (€)</MenuItem>
              <MenuItem value="GBP">GBP (£)</MenuItem>
              <MenuItem value="USD">USD ($)</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ p: 2 }}>
          <Grid container spacing={2}>
            {/* Flights */}
            <Grid item xs={12}>
              <Typography variant="h6">Flights</Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Cost per Person"
                value={flights.costPerPerson}
                onChange={(e) => setFlights({ ...flights, costPerPerson: e.target.value })}
                type="number"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Number of People"
                value={flights.numberOfPeople}
                onChange={(e) => setFlights({ ...flights, numberOfPeople: e.target.value })}
                type="number"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Total: {formatCurrency(calculateFlightsTotal())}
              </Typography>
            </Grid>

            {/* Accommodation */}
            <Grid item xs={12}>
              <Typography variant="h6">Accommodation</Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Cost per Room per Night"
                value={accommodation.costPerRoomPerNight}
                onChange={(e) => setAccommodation({ ...accommodation, costPerRoomPerNight: e.target.value })}
                type="number"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Number of Rooms"
                value={accommodation.numberOfRooms}
                onChange={(e) => setAccommodation({ ...accommodation, numberOfRooms: e.target.value })}
                type="number"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Number of Nights"
                value={accommodation.numberOfNights}
                onChange={(e) => setAccommodation({ ...accommodation, numberOfNights: e.target.value })}
                type="number"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                Total: {formatCurrency(calculateAccommodationTotal())}
              </Typography>
            </Grid>

            {/* Per Diems */}
            <Grid item xs={12}>
              <Typography variant="h6">Per Diems</Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Daily Rate per Person"
                value={perDiem.dailyRatePerPerson}
                onChange={(e) => setPerDiem({ ...perDiem, dailyRatePerPerson: e.target.value })}
                type="number"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Number of People"
                value={perDiem.numberOfPeople}
                onChange={(e) => setPerDiem({ ...perDiem, numberOfPeople: e.target.value })}
                type="number"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Number of Days"
                value={perDiem.numberOfDays}
                onChange={(e) => setPerDiem({ ...perDiem, numberOfDays: e.target.value })}
                type="number"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                Total: {formatCurrency(calculatePerDiemTotal())}
              </Typography>
            </Grid>

            {/* Equipment Rental */}
            <Grid item xs={12}>
              <Typography variant="h6">Equipment Rental</Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Cost per Item"
                value={equipment.costPerItem}
                onChange={(e) => setEquipment({ ...equipment, costPerItem: e.target.value })}
                type="number"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Number of Items"
                value={equipment.numberOfItems}
                onChange={(e) => setEquipment({ ...equipment, numberOfItems: e.target.value })}
                type="number"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Number of Days"
                value={equipment.numberOfDays}
                onChange={(e) => setEquipment({ ...equipment, numberOfDays: e.target.value })}
                type="number"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                Total: {formatCurrency(calculateEquipmentTotal())}
              </Typography>
            </Grid>

            {/* Other Costs */}
            <Grid item xs={12}>
              <Typography variant="h6">Other Costs</Typography>
            </Grid>
            {otherCosts.map((cost, index) => (
              <React.Fragment key={index}>
                <Grid item xs={6}>
                  <TextField
                    label="Description"
                    value={cost.description}
                    onChange={(e) => handleOtherCostChange(index, 'description', e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Amount"
                    value={cost.amount}
                    onChange={(e) => handleOtherCostChange(index, 'amount', e.target.value)}
                    type="number"
                    fullWidth
                  />
                </Grid>
                {index === 0 && (
                  <Grid item xs={2}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleAddOtherCost}
                      sx={{ mt: 1 }}
                    >
                      Add
                    </Button>
                  </Grid>
                )}
              </React.Fragment>
            ))}
            <Grid item xs={12}>
              <Typography variant="body1">
                Total Other Costs: {formatCurrency(calculateOtherCostsTotal())}
              </Typography>
            </Grid>

            {/* Grand Total */}
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ mt: 2 }}>
                Grand Total: {formatCurrency(grandTotal)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EventCalculator;