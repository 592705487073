// src/components/navigation/GuestNav.tsx

import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, Typography, Box } from '@mui/material';
import ShowrunnrLogo from '../ShowrunnrLogo';  // Adjust the import path as necessary

const GuestNav: React.FC = () => {
  return (
    <AppBar position="static" color="default">
      <Toolbar>
        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
          <ShowrunnrLogo width={150} height={50} />
          <Typography variant="h6" sx={{ ml: 2 }}>
            Guest Portal
          </Typography>
        </Box>
        <Button color="inherit" component={Link} to="/dashboard">
          Dashboard
        </Button>
        <Button color="inherit" component={Link} to="/events">
          Events
        </Button>
        <Button color="inherit" component={Link} to="/bands">
          Bands
        </Button>
        <Button color="inherit" component={Link} to="/venues">
          Venues
        </Button>
        <Button color="inherit" component={Link} to="/publicstrands">
          Public Strands
        </Button>
        <Button color="inherit" component={Link} to="/login">
          Login
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default GuestNav;