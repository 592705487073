import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container, Typography, Box, Grid, Chip, Card, CardContent, CardMedia, CircularProgress,
  Button, Dialog, DialogTitle, DialogContent, DialogActions, Select, MenuItem, FormControl, InputLabel
} from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip as RechartsTooltip } from 'recharts';
import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import axios from 'axios';
import { AxiosError } from 'axios';
import { BandRecommendation } from '../types/BandRecommendations';
import { createYouTubeEmbed } from '../utils/youtubeEmbedUtil';
import EditIcon from '@mui/icons-material/Edit';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import TikTokIcon from '@mui/icons-material/MusicVideo';
import PublishBandModal from '../components/PublishBandModal';
import PublishedStrandsCard from '../components/PublishedStrandsCard';
import AdaptiveImage from '../components/artists/AdaptiveBandImage';


interface Event {
  _id: string;
  name: string;
  date: string;
}

  // Create an axios instance with default config
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true, // Important for cookies
    headers: {
      'Content-Type': 'application/json'
    }
  });
  
  // Add auth token to requests if it exists
  axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token'); // or however you store your JWT
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

const BandDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [band, setBand] = useState<BandRecommendation | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [events, setEvents] = useState<Event[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<string>('');
  const [openAddToEventDialog, setOpenAddToEventDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false); // New state variable for delete confirmation
  const [openPublishModal, setOpenPublishModal] = useState(false);

  const handlePublish = () => {
    setOpenPublishModal(true);
  };


  useEffect(() => {
    const fetchBandDetails = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get<BandRecommendation>(
          `/api/bandrecommendations/${id}`
        );
        const updatedBand = {
          ...response.data,
          youtubeEmbeds: response.data.youtubeEmbeds.map(embed =>
            embed.startsWith('<iframe') ? embed : createYouTubeEmbed(embed)
          )
        };
        setBand(updatedBand);
        setError(null);
      } catch (error) {
        console.error('Error fetching band details:', error);
        setError('An unexpected error occurred while fetching band details.');
      } finally {
        setLoading(false);
      }
    };

    const fetchEvents = async () => {
      try {
        const response = await axiosInstance.get<Event[]>(`/api/events`);
        setEvents(response.data);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchBandDetails();
    fetchEvents();
  }, [id]);

  const handleAddToEvent = () => {
    setOpenAddToEventDialog(true);
  };

  const handleCreateNewEvent = () => {
    navigate('/events/new', { state: { bandId: id } });
  };

  const handleAddToExistingEvent = async () => {
    if (selectedEvent && band) {
      try {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/events/${selectedEvent}/performances`, {
          band: band._id,
          // Add other necessary performance details
        });
        setOpenAddToEventDialog(false);
        // Optionally, show a success message or update the UI
      } catch (error) {
        console.error('Error adding band to event:', error);
        // Handle error (e.g., show error message)
      }
    }
  };
  const handlePublishBand = async (strandId: string, shortDescription: string) => {
    try {
      console.log('Publishing band with token:', localStorage.getItem('token'));
  
      // Prepare the payload
      const payload = {
        strandId: strandId, // Send strandId as a single value
        shortDescription,
      };
  
      // Make the API call
      const response = await axiosInstance.post(
        `/api/bandrecommendations/${band?._id}/publish`,
        payload
      );
  
      console.log('Publish response:', response);
      setOpenPublishModal(false); // Close the modal after publishing
    } catch (error: unknown) {
      console.error('Error publishing band:', error);
  
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 403) {
          alert('You do not have permission to publish bands. Please contact an administrator.');
        } else {
          alert(`An error occurred while publishing the band: ${error.message}`);
        }
      } else {
        alert('An unexpected error occurred while publishing the band.');
      }
    }
  };

  const handlePending = () => {
    // Future implementation
    alert('Pending functionality to be implemented.');
  };

  // New function to handle delete action
  const handleDelete = () => {
    setOpenDeleteDialog(true);
  };

  const handleEdit = () => {
    navigate(`/bandrecommendations/${id}/edit`);
  };

  // Function to confirm deletion
  const confirmDelete = async () => {
    if (band) {
      try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/api/bandrecommendations/${band._id}`);
        setOpenDeleteDialog(false);
        // Navigate back to the band recommendations list after deletion
        navigate('/bandrecommendations');
      } catch (error) {
        console.error('Error deleting band recommendation:', error);
        // Handle error (e.g., show error message)
      }
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!band) return <Typography>Band not found.</Typography>;

  const genderData = [
    { name: 'Male', value: band.maleMembers, color: '#2196f3' },
    { name: 'Female', value: band.femaleMembers, color: '#f50057' },
  ];

  const CustomizedLegend = () => (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box display="flex" alignItems="center" mr={2}>
        <MaleIcon style={{ color: '#2196f3' }} />
        <Typography variant="body2" ml={1}>Male</Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <FemaleIcon style={{ color: '#f50057' }} />
        <Typography variant="body2" ml={1}>Female</Typography>
      </Box>
    </Box>
  );

  const formatDate = (date: Date) => {
    return {
      month: date.toLocaleString('default', { month: 'short' }),
      day: date.getDate(),
      year: date.getFullYear(),
    };
  };

  const availabilityFrom = formatDate(new Date(band.availability.from));
  const availabilityTo = formatDate(new Date(band.availability.to));

  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <Typography variant="h1" component="h1" gutterBottom>{band.artistName}</Typography>
        <Grid container spacing={4}>
          {/* Left Column */}
          <Grid item xs={12} md={6}>
            <Card>
              <AdaptiveImage
                src={band.mainImage || '/placeholder-image.jpg'}
                alt={band.artistName}
                defaultHeight={400}
                maxWidth={800}
              />
              <CardContent>
                <Typography variant="h6" gutterBottom>Band Information</Typography>
                <Box height={200}>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={genderData}
                        cx="50%"
                        cy="50%"
                        innerRadius={50}
                        outerRadius={70}
                        paddingAngle={5}
                        dataKey="value"
                        nameKey="name"
                      >
                        {genderData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                      </Pie>
                      <RechartsTooltip
                        formatter={(value: number, name: string) => [`${value}`, `${name} Members`]}
                      />
                      <Legend content={<CustomizedLegend />} />
                    </PieChart>
                  </ResponsiveContainer>
                </Box>
                <Typography align="center" variant="body1" mt={2}>
                  Total Members: {band.maleMembers + band.femaleMembers}
                </Typography>
                <Box mt={2}>
                  <Typography variant="subtitle1">Genres:</Typography>
                  {band.genres.map((genre) => (
                    <Chip key={genre} label={genre} style={{ margin: '4px' }} />
                  ))}
                </Box>
              </CardContent>
            </Card>

            <Box mt={4}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>Recommendation Notes</Typography>
                  <Typography>{band.recommendationNotes}</Typography>
                </CardContent>
              </Card>
            </Box>
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={6}>
            {/* Actions */}
            <Card sx={{ mb: 2 }}>
  <CardContent>
    <Typography variant="h6" gutterBottom>Admin Actions</Typography>
    <Button 
      variant="contained" 
      color="success" 
      onClick={handleAddToEvent} 
      fullWidth 
      sx={{ mb: 1 }}
    >
      Add to Event
    </Button>
    <Button 
  variant="contained" 
  color={(band.publishedTo && band.publishedTo.length > 0) ? "info" : "primary"}
  onClick={handlePublish} 
  fullWidth 
  sx={{ mb: 1 }}
>
  {(band.publishedTo && band.publishedTo.length > 0)
    ? `Manage Publications (${band.publishedTo.length})` 
    : "Publish"}
</Button>
    <Button 
      variant="contained" 
      color="warning" 
      onClick={handlePending} 
      fullWidth 
      sx={{ mb: 1 }}
    >
      Pending
    </Button>
    <Button 
      variant="contained" 
      color="secondary" 
      onClick={handleEdit} 
      fullWidth 
      sx={{ mb: 1 }}
    >
      <EditIcon sx={{ mr: 1 }} />
      Edit
    </Button>
    <Button 
      variant="contained" 
      color="error" 
      onClick={handleDelete} 
      fullWidth
    >
      Delete
    </Button>
  </CardContent>
</Card>
            <PublishedStrandsCard bandId={band._id || ''} />

            {/* Recommender Information */}
            <Card sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>Recommender Information</Typography>
                <Typography><strong>Name:</strong> {band.recommenderName}</Typography>
                <Typography><strong>Email:</strong> {band.recommenderEmail}</Typography>
                <Typography><strong>Country:</strong> {band.recommenderCountry}</Typography>
              </CardContent>
            </Card>

            {/* Contact Information */}
            <Card sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>Contact Information</Typography>
                <Typography><strong>Name:</strong> {band.contactName}</Typography>
                <Typography><strong>Email:</strong> {band.contactEmail}</Typography>
              </CardContent>
            </Card>

            {/* Availability */}
            <Card sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>Availability</Typography>
                <Grid container alignItems="center" justifyContent="center">
                  {/* From Date */}
                  <Grid item xs={5} textAlign="center">
                    <Typography variant="h4">{availabilityFrom.month}</Typography>
                    <Typography variant="h5">{availabilityFrom.day}</Typography>
                    <Typography variant="h6">{availabilityFrom.year}</Typography>
                  </Grid>
                  {/* Arrow */}
                  <Grid item xs={2} textAlign="center">
                    <ArrowForwardIcon fontSize="large" />
                  </Grid>
                  {/* To Date */}
                  <Grid item xs={5} textAlign="center">
                    <Typography variant="h4">{availabilityTo.month}</Typography>
                    <Typography variant="h5">{availabilityTo.day}</Typography>
                    <Typography variant="h6">{availabilityTo.year}</Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
                      {/* Additional Media */}
                      {(band.instagramHandle || band.twitterHandle || band.facebookPage || band.tikTokHandle) && (
              <Card sx={{ mb: 2 }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>Social Media</Typography>
                  {band.instagramHandle && (
                    <Typography>
                      <InstagramIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                      <strong>Instagram:</strong> 
                      <a href={`https://instagram.com/${band.instagramHandle}`} target="_blank" rel="noopener noreferrer">
                        @{band.instagramHandle}
                      </a>
                    </Typography>
                  )}
                  {band.twitterHandle && (
                    <Typography>
                      <TwitterIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                      <strong>Twitter:</strong> 
                      <a href={`https://twitter.com/${band.twitterHandle}`} target="_blank" rel="noopener noreferrer">
                        @{band.twitterHandle}
                      </a>
                    </Typography>
                  )}
                  {band.facebookPage && (
                    <Typography>
                      <FacebookIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                      <strong>Facebook:</strong> 
                      <a href={band.facebookPage} target="_blank" rel="noopener noreferrer">
                        {band.facebookPage}
                      </a>
                    </Typography>
                  )}
                  {band.tikTokHandle && (
                    <Typography>
                      <TikTokIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                      <strong>TikTok:</strong> 
                      <a href={`https://www.tiktok.com/@${band.tikTokHandle}`} target="_blank" rel="noopener noreferrer">
                        @{band.tikTokHandle}
                      </a>
                    </Typography>
                  )}
                </CardContent>
              </Card>
            )}

            {/* Technical Requirements */}
            {band.technicalRider && (
              <Card sx={{ mb: 2 }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>Technical Requirements</Typography>
                  {band.technicalRider.stageSize && (
                    <Typography>
                      <strong>Stage Size:</strong> {band.technicalRider.stageSize}
                    </Typography>
                  )}
                  {band.technicalRider.audioRequirements && (
                    <Typography>
                      <strong>Audio Requirements:</strong> {band.technicalRider.audioRequirements}
                    </Typography>
                  )}
                  {band.technicalRider.lightingRequirements && (
                    <Typography>
                      <strong>Lighting Requirements:</strong> {band.technicalRider.lightingRequirements}
                    </Typography>
                  )}
                  {band.technicalRider.additionalEquipment && band.technicalRider.additionalEquipment.length > 0 && (
                    <Box>
                      <Typography><strong>Additional Equipment:</strong></Typography>
                      <ul>
                        {band.technicalRider.additionalEquipment.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </Box>
                  )}
                </CardContent>
              </Card>
            )}

            {/* Financial Information */}
            {band.financialDetails && (
              <Card sx={{ mb: 2 }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>Financial Information</Typography>
                  {band.financialDetails.minimumFee && (
                    <Typography>
                      <strong>Minimum Fee:</strong> {band.financialDetails.minimumFee} {band.financialDetails.preferredCurrency || ''}
                    </Typography>
                  )}
                  {band.financialDetails.accommodationNeeded !== undefined && (
                    <Typography>
                      <strong>Accommodation Needed:</strong> {band.financialDetails.accommodationNeeded ? 'Yes' : 'No'}
                    </Typography>
                  )}
                  {band.financialDetails.transportationNeeded !== undefined && (
                    <Typography>
                      <strong>Transportation Needed:</strong> {band.financialDetails.transportationNeeded ? 'Yes' : 'No'}
                    </Typography>
                  )}
                </CardContent>
              </Card>
            )}
          </Grid>
          </Box>

          {/* Media Section */}
          <Grid item xs={12}>
            {/* ... (existing code for Media Section) */}

            {/* Additional Images */}
            {band.additionalImages && band.additionalImages.length > 0 && (
              <Box mt={4}>
                <Typography variant="h6" gutterBottom>Additional Images</Typography>
                <Grid container spacing={2}>
                  {band.additionalImages.map((imageUrl, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <CardMedia
                        component="img"
                        height="200"
                        image={imageUrl}
                        alt={`Additional Image ${index + 1}`}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
          </Grid>

          {/* Media Section */}
          <Grid item xs={12}>
            <Box mt={4}>
              <Typography variant="h6" gutterBottom>Media</Typography>
              {band.performanceLink && (
                <Box mb={2}>
                  <Typography variant="subtitle1">Performance Link:</Typography>
                  <a href={band.performanceLink} target="_blank" rel="noopener noreferrer">{band.performanceLink}</a>
                </Box>
              )}
              {band.youtubeEmbeds.map((embed, index) => (
                <Box
                  key={index}
                  mb={2}
                  sx={{
                    '& iframe': {
                      width: '100%',
                      height: '450px', // Increased height
                    },
                  }}
                  dangerouslySetInnerHTML={{ __html: embed }}
                />
              ))}
              {band.spotifyEmbeds.map((embed, index) => (
                <Box key={index} mb={2} dangerouslySetInnerHTML={{ __html: embed }} />
              ))}
              {band.soundcloudEmbeds.map((embed, index) => (
                <Box key={index} mb={2} dangerouslySetInnerHTML={{ __html: embed }} />
              ))}
            </Box>
          </Grid>

      {/* Add to Event Dialog */}
      <Dialog open={openAddToEventDialog} onClose={() => setOpenAddToEventDialog(false)}>
        <DialogTitle>Add {band.artistName} to Event</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Select Event</InputLabel>
            <Select
              value={selectedEvent}
              onChange={(e) => setSelectedEvent(e.target.value as string)}
            >
              {events.map((event) => (
                <MenuItem key={event._id} value={event._id}>
                  {event.name} - {new Date(event.date).toLocaleDateString()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleCreateNewEvent}
            fullWidth
            sx={{ mt: 2 }}
          >
            Create New Event
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAddToEventDialog(false)}>Cancel</Button>
          <Button onClick={handleAddToExistingEvent} variant="contained" color="primary">
            Add to Event
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete <strong>{band.artistName}</strong>?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
          <Button onClick={confirmDelete} variant="contained" color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <PublishBandModal
        open={openPublishModal}
        onClose={() => setOpenPublishModal(false)}
        bandId={band?._id || ''}
        bandName={band?.artistName || ''}
        onPublish={handlePublishBand}
      />
    </Container>
  );
};

export default BandDetailPage;