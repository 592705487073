import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Box,
  Divider
} from '@mui/material';
import { BandRecommendation } from '../../../../types/BandRecommendations';
import { useBandView } from '../../../../contexts/BandViewContext';

interface ContactSectionProps {
  band: BandRecommendation;
}

const ContactSection: React.FC<ContactSectionProps> = ({ band }) => {
  const { viewConfig, viewMode } = useBandView();

  if (!viewConfig.showContactInfo) {
    return null;
  }

  return (
    <>
      {/* Recommender Information - Only visible to admin */}
      {viewMode === 'admin' && (
        <Card sx={{ mb: 3 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>Recommender Information</Typography>
            <List>
              <ListItem>
                <ListItemText primary="Name" secondary={band.recommenderName} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Email" secondary={band.recommenderEmail} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Country" secondary={band.recommenderCountry} />
              </ListItem>
              {band.county && (
                <ListItem>
                  <ListItemText primary="County" secondary={band.county} />
                </ListItem>
              )}
            </List>

            {band.recommendationNotes && (
              <Box sx={{ mt: 2 }}>
                <Divider sx={{ mb: 2 }} />
                <Typography variant="subtitle1" color="primary" gutterBottom>
                  Recommendation Notes
                </Typography>
                <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                  {band.recommendationNotes}
                </Typography>
              </Box>
            )}
          </CardContent>
        </Card>
      )}

      {/* Contact Information */}
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>Contact Information</Typography>
          <List>
            <ListItem>
              <ListItemText primary="Name" secondary={band.contactName} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Email" secondary={band.contactEmail} />
            </ListItem>
            {band.contactPhone && (
              <ListItem>
                <ListItemText primary="Phone" secondary={band.contactPhone} />
              </ListItem>
            )}
          </List>
        </CardContent>
      </Card>
    </>
  );
};

export default ContactSection;