import React, { useState, useEffect, useCallback, useRef } from 'react';
import { BandRecommendation } from '../types/BandRecommendations';
import { getPlaylistFromBands } from '../utils/youtubeUtils';

interface PlayerState {
  isReady: boolean;
  isPlaying: boolean;
  currentVideoIndex: number;
  currentVideoId: string | null;
  playlist: Array<{
    bandId: string;
    videoId: string;
    bandName: string;
  }>;
  currentVideo: {
    bandId: string;
    videoId: string;
    bandName: string;
  } | null;
}

interface YouTubePlayerProps {
  bands: BandRecommendation[];
  onVideoChange?: (bandId: string, videoId: string) => void;
}

const YouTubePlaylistPlayer: React.FC<YouTubePlayerProps> = ({ bands, onVideoChange }) => {
  const playerRef = useRef<YT.Player | null>(null);
  const [playerState, setPlayerState] = useState<PlayerState>({
    isReady: false,
    isPlaying: false,
    currentVideoIndex: 0,
    currentVideoId: null,
    playlist: [],
    currentVideo: null
  });

  useEffect(() => {
    // Initialize playlist first
    const newPlaylist = getPlaylistFromBands(bands);
    console.log('Generated playlist:', newPlaylist);
    
    setPlayerState(prev => ({
      ...prev,
      playlist: newPlaylist,
      currentVideoId: newPlaylist[0]?.videoId || null,
      currentVideo: newPlaylist[0] || null
    }));
  }, [bands]);

  useEffect(() => {
    if (!window.YT) {
      // Load the IFrame Player API code asynchronously
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag);

      // Setup the callback for when API is ready
      window.onYouTubeIframeAPIReady = initializePlayer;
    } else {
      initializePlayer();
    }

    return () => {
      if (playerRef.current) {
        try {
          playerRef.current.destroy();
        } catch (error) {
          console.error('Error destroying player:', error);
        }
      }
    };
  }, []); // Empty dependency array as this should only run once

  const initializePlayer = useCallback(() => {
    if (!playerState.currentVideoId) return;

    try {
      playerRef.current = new window.YT.Player('youtube-player', {
        videoId: playerState.currentVideoId,
        playerVars: {
          playsinline: 1,
          autoplay: 0,
          controls: 1,
          rel: 0
        },
        events: {
          onReady: (event: YT.PlayerEvent) => {
            console.log('Player ready:', event);
            setPlayerState(prev => ({ ...prev, isReady: true }));
          },
          onStateChange: (event) => {
            console.log('Player state changed:', event.data);
            setPlayerState(prev => ({
              ...prev,
              isPlaying: event.data === window.YT.PlayerState.PLAYING
            }));

            if (event.data === window.YT.PlayerState.ENDED) {
              handleNextVideo();
            }
          },
          onError: (event) => {
            console.error('Player error:', event);
            handleNextVideo();
          }
        }
      });
    } catch (error) {
      console.error('Error initializing player:', error);
    }
  }, [playerState.currentVideoId]);

  const changeVideo = useCallback((videoId: string) => {
    if (!playerRef.current || !videoId) return;
    
    try {
      // Load and play the new video
      playerRef.current.loadVideoById({
        videoId: videoId,
        startSeconds: 0
      });
    } catch (error) {
      console.error('Error changing video:', error);
      // If loadVideoById fails, try to reinitialize the player
      initializePlayer();
    }
  }, [initializePlayer]);

  const handleNextVideo = useCallback(() => {
    if (!playerState.playlist.length) return;

    const nextIndex = (playerState.currentVideoIndex + 1) % playerState.playlist.length;
    const nextVideo = playerState.playlist[nextIndex];

    if (nextVideo) {
      if (onVideoChange) {
        onVideoChange(nextVideo.bandId, nextVideo.videoId);
      }

      setPlayerState(prev => ({
        ...prev,
        currentVideoIndex: nextIndex,
        currentVideoId: nextVideo.videoId,
        currentVideo: nextVideo
      }));

      changeVideo(nextVideo.videoId);
    }
  }, [playerState.playlist, playerState.currentVideoIndex, onVideoChange, changeVideo]);

  const handlePreviousVideo = useCallback(() => {
    if (!playerState.playlist.length) return;

    const prevIndex = playerState.currentVideoIndex === 0 
      ? playerState.playlist.length - 1 
      : playerState.currentVideoIndex - 1;
    const prevVideo = playerState.playlist[prevIndex];

    if (prevVideo) {
      if (onVideoChange) {
        onVideoChange(prevVideo.bandId, prevVideo.videoId);
      }

      setPlayerState(prev => ({
        ...prev,
        currentVideoIndex: prevIndex,
        currentVideoId: prevVideo.videoId,
        currentVideo: prevVideo
      }));

      changeVideo(prevVideo.videoId);
    }
  }, [playerState.playlist, playerState.currentVideoIndex, onVideoChange, changeVideo]);

  const togglePlay = useCallback(() => {
    if (!playerRef.current) return;

    try {
      if (playerState.isPlaying) {
        playerRef.current.pauseVideo();
      } else {
        playerRef.current.playVideo();
      }
    } catch (error) {
      console.error('Error toggling playback:', error);
    }
  }, [playerState.isPlaying]);
  

  return (
    <div className="w-full max-w-4xl mx-auto bg-white rounded-lg shadow-lg">
      {/* Video Container */}
      <div className="relative h-[480px]">
        <div 
          id="youtube-player"
          className="absolute inset-0 w-full h-full"
        />
      </div>
      
      {/* Controls */}
      <div className="p-4">
        <div className="mb-2">
          <div className="text-lg font-semibold">
            {playerState.currentVideo ? `Now Playing: ${playerState.currentVideo.bandName}` : 'No video selected'}
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div className="flex gap-2">
            <button
              onClick={togglePlay}
              className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 disabled:opacity-50"
              disabled={!playerState.isReady}
            >
              {playerState.isPlaying ? 'Pause' : 'Play'}
            </button>
            
            <button
              onClick={handlePreviousVideo}
              className="px-3 py-2 border rounded hover:bg-gray-100 disabled:opacity-50"
              disabled={!playerState.isReady || playerState.playlist.length <= 1}
            >
              Previous
            </button>
            
            <button
              onClick={handleNextVideo}
              className="px-3 py-2 border rounded hover:bg-gray-100 disabled:opacity-50"
              disabled={!playerState.isReady || playerState.playlist.length <= 1}
            >
              Next
            </button>
          </div>
          
          <div className="text-sm text-gray-600">
            Video {playerState.currentVideoIndex + 1} of {playerState.playlist.length}
          </div>
        </div>
      </div>
    </div>
  );
};

export default YouTubePlaylistPlayer;
