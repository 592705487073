// User roles enum for type safety and autocompletion
export enum UserRole {
    Admin = 'admin',
    Guest = 'guest',
    Judge = 'judge',
    Artist = 'artist',
    DomainUser = 'domain_user'
  }

// Base user interface - matches your MongoDB schema
export interface User {
    _id: string;
    email: string;
    name: string;
    role: UserRole;
    googleId?: string;
    domainId?: string;
    lastLogin?: Date;
    createdAt: Date;
    updatedAt: Date;
  }

// User state interface - for managing user context/state
export interface UserState {
  isAuthenticated: boolean;
  user: User | null;
  loading: boolean;
  error: string | null;
}

// Authentication response interface
export interface AuthResponse {
  user: User;
  token: string;
}

// User permissions interface - for role-based access control
export interface UserPermissions {
  canEdit: boolean;
  canPublish: boolean;
  canDelete: boolean;
  canAddToEvent: boolean;
  canViewFinancials: boolean;
  canViewTechnical: boolean;
  canViewContacts: boolean;
  canViewAdminNotes: boolean;
  canManagePublications: boolean;
}

// Helper function to get permissions based on role
export const getUserPermissions = (role: UserRole): UserPermissions => {
  const basePermissions: UserPermissions = {
    canEdit: false,
    canPublish: false,
    canDelete: false,
    canAddToEvent: false,
    canViewFinancials: false,
    canViewTechnical: false,
    canViewContacts: false,
    canViewAdminNotes: false,
    canManagePublications: false
  };

  switch (role) {
    case UserRole.Admin:
      return {
        ...basePermissions,
        canEdit: true,
        canPublish: true,
        canDelete: true,
        canAddToEvent: true,
        canViewFinancials: true,
        canViewTechnical: true,
        canViewContacts: true,
        canViewAdminNotes: true,
        canManagePublications: true
      };

    case UserRole.Artist:
      return {
        ...basePermissions,
        canViewFinancials: true,
        canViewTechnical: true,
        canViewContacts: true
      };

    case UserRole.Judge:
      return {
        ...basePermissions,
        canViewTechnical: true
      };

    case UserRole.DomainUser:
      return {
        ...basePermissions,
        canViewContacts: true,
        canViewTechnical: true
      };

    case UserRole.Guest:
    default:
      return basePermissions;
  }
};

// Type guard to check if user has specific role
export const hasRole = (user: User, role: UserRole): boolean => {
  return user.role === role;
};

// Type guard to check if user is admin
export const isAdmin = (user: User | null): boolean => {
  return user?.role === UserRole.Admin;
};

// Type guard to check if user is artist
export const isArtist = (user: User | null): boolean => {
  return user?.role === UserRole.Artist;
};

// Type guard to check if user has permission
export const hasPermission = (
  user: User | null,
  permission: keyof UserPermissions
): boolean => {
  if (!user) return false;
  const permissions = getUserPermissions(user.role);
  return permissions[permission];
};