import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Stack,
  Chip,
  Button,
  Box,
  Link
} from '@mui/material';
import { BandRecommendation } from '../../../../types/BandRecommendations';
import { useBandView } from '../../../../contexts/BandViewContext';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';

interface TechnicalSectionProps {
  band: BandRecommendation;
}

const TechnicalSection: React.FC<TechnicalSectionProps> = ({ band }) => {
  const { viewConfig } = useBandView();

  if (!viewConfig.showTechnicalRequirements || !band.technicalRider) {
    return null;
  }

  const renderFileLink = (url: string | undefined, label: string, isPdf: boolean = true) => {
    if (!url) return null;

    return (
      <Grid item xs={12} sm={6} md={3}>
        <Box sx={{ 
          border: '1px solid #e0e0e0', 
          borderRadius: 1, 
          p: 2,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 1
        }}>
          {isPdf ? (
            <PictureAsPdfIcon color="error" sx={{ fontSize: 40 }} />
          ) : (
            <ImageIcon color="primary" sx={{ fontSize: 40 }} />
          )}
          <Typography variant="subtitle2" align="center">
            {label}
          </Typography>
          <Button
            variant="outlined"
            size="small"
            component={Link}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            View {isPdf ? 'PDF' : 'Image'}
          </Button>
        </Box>
      </Grid>
    );
  };

  return (
    <Card sx={{ mb: 3 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>Technical Requirements</Typography>
        
        {/* Uploaded Files Section */}
        <Grid container spacing={2} sx={{ mb: 3 }}>
          {renderFileLink(band.technicalRider.techSpecs, 'Tech Specs')}
          {renderFileLink(band.technicalRider.stagePlot, 'Stage Plot')}
          {renderFileLink(band.technicalRider.lightingSpec, 'Lighting Spec')}
          {renderFileLink(band.technicalRider.stageImage, 'Stage Image', false)}
        </Grid>

        {/* Text Requirements Section */}
        <Grid container spacing={2}>
          {band.technicalRider.stageSize && (
            <Grid item xs={12}>
              <Typography variant="subtitle2">Stage Size:</Typography>
              <Typography>{band.technicalRider.stageSize}</Typography>
            </Grid>
          )}

          {band.technicalRider.audioRequirements && (
            <Grid item xs={12}>
              <Typography variant="subtitle2">Audio Requirements:</Typography>
              <Typography>{band.technicalRider.audioRequirements}</Typography>
            </Grid>
          )}

          {band.technicalRider.lightingRequirements && (
            <Grid item xs={12}>
              <Typography variant="subtitle2">Lighting Requirements:</Typography>
              <Typography>{band.technicalRider.lightingRequirements}</Typography>
            </Grid>
          )}

          {band.technicalRider.additionalEquipment && 
           band.technicalRider.additionalEquipment.length > 0 && (
            <Grid item xs={12}>
              <Typography variant="subtitle2">Additional Equipment:</Typography>
              <Stack direction="row" spacing={1} flexWrap="wrap" sx={{ mt: 1 }}>
                {band.technicalRider.additionalEquipment.map((item, index) => (
                  <Chip 
                    key={index} 
                    label={item} 
                    variant="outlined" 
                    size="small"
                    sx={{ mb: 1 }}
                  />
                ))}
              </Stack>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TechnicalSection;