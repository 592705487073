import React, { useState, useEffect } from 'react';
import axios from 'axios';

interface ColumnValue {
  text: string;
  value: string;
}

interface Column {
  title: string;
  type: string;
}

interface Item {
  name: string;
  column_values: ColumnValue[];
}

interface Board {
  name: string;
  columns: Column[];
  items: Item[];
}

const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://api.imc.show';

const MondayApiTest: React.FC = () => {
  const [boardData, setBoardData] = useState<Board | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchBoardData = async () => {
      try {
        const response = await axios.get<Board>(`${API_BASE_URL}/api/monday/board`);
        setBoardData(response.data);
      } catch (err) {
        setError('Failed to fetch data from Monday.com API');
        console.error('Error fetching data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchBoardData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!boardData) return <div>No data available</div>;

  return (
    <div>
      <h1>{boardData.name}</h1>
      <h2>Columns:</h2>
      <ul>
        {boardData.columns.map((column: Column, index: number) => (
          <li key={index}>{column.title} ({column.type})</li>
        ))}
      </ul>
      <h2>Items:</h2>
      {boardData.items.map((item: Item, itemIndex: number) => (
        <div key={itemIndex}>
          <h3>{item.name}</h3>
          <ul>
            {item.column_values.map((value: ColumnValue, valueIndex: number) => (
              <li key={valueIndex}>
                {boardData.columns[valueIndex].title}: {value.text || value.value}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default MondayApiTest;