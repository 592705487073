import React from 'react';
import {
  Container,
  Typography,
  Paper,
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Breadcrumbs,
  Link,
} from '@mui/material';
import {
  Public as PublicIcon,
  Security as SecurityIcon,
  Storage as StorageIcon,
  Gavel as GavelIcon,
  ContactMail as ContactMailIcon,
  Home as HomeIcon,
} from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';

const PrivacyPolicyPage = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {/* Breadcrumbs Navigation */}
      <Breadcrumbs sx={{ mb: 3 }}>
        <Link 
          component={RouterLink} 
          to="/"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Home
        </Link>
        <Typography color="text.primary">Privacy Policy</Typography>
      </Breadcrumbs>

      {/* Header */}
      <Typography variant="h3" component="h1" gutterBottom>
        Privacy Policy
      </Typography>
      <Typography variant="subtitle1" color="text.secondary" paragraph>
        Last updated: {new Date().toLocaleDateString()}
      </Typography>

      {/* Introduction */}
      <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h5" gutterBottom>
          Introduction
        </Typography>
        <Typography paragraph>
          At Improvised Music Company, we take your privacy seriously. This policy describes how we collect, 
          use, and protect both your public and private information when you use our platform 
          as an artist or band.
        </Typography>
      </Paper>

      {/* Public Information Section */}
      <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <PublicIcon color="primary" sx={{ mr: 2 }} />
          <Typography variant="h5">Public Information We Collect</Typography>
        </Box>
        <Typography paragraph>
          The following information will be publicly visible on our platform:
        </Typography>
        <List>
          <ListItem>
            <ListItemText 
              primary="Artist/Band Profile"
              secondary="Your professional name, biography, and performance history (if applicable)"
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Media Content"
              secondary="Photos, videos, and audio samples you choose to share"
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Genre and Style Information"
              secondary="Musical genres, styles, and artistic focus"
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Social Media Links"
              secondary="Professional social media handles and website links"
            />
          </ListItem>
        </List>
      </Paper>

      {/* Private Information Section */}
      <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <SecurityIcon color="primary" sx={{ mr: 2 }} />
          <Typography variant="h5">Private Information We Collect</Typography>
        </Box>
        <Typography paragraph>
          This information is kept private and used only for essential communications and verifications:
        </Typography>
        <List>
          <ListItem>
            <ListItemText 
              primary="Contact Information"
              secondary="Email address, phone number, and postal address"
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Identity Details"
              secondary="Legal name and verification documents if required"
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Payment Information"
              secondary="Banking details for processing payments (where applicable)"
            />
          </ListItem>
        </List>
      </Paper>

      {/* Data Usage Section */}
      <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <StorageIcon color="primary" sx={{ mr: 2 }} />
          <Typography variant="h5">How We Use Your Information</Typography>
        </Box>
        <Typography paragraph>We use your information to:</Typography>
        <List>
          <ListItem>
            <ListItemText 
              primary="Connect You with Opportunities"
              secondary="Matching you with venues, events, and collaborators, where appropriate"
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Improve Our Services"
              secondary="Understanding the music scene and enhancing our platform"
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Communication"
              secondary="Sending you relevant opportunities and important updates"
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Legal Compliance"
              secondary="Meeting our legal obligations and protecting our services"
            />
          </ListItem>
        </List>
      </Paper>

      {/* Your Rights Section */}
      <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <GavelIcon color="primary" sx={{ mr: 2 }} />
          <Typography variant="h5">Your Rights</Typography>
        </Box>
        <Typography paragraph>You have the right to:</Typography>
        <List>
          <ListItem>
            <ListItemText 
              primary="Access Your Data"
              secondary="Request a copy of all personal data we hold about you"
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Update Your Information"
              secondary="Correct or update any of your personal information"
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Delete Your Data"
              secondary="Request deletion of your personal data (subject to legal requirements)"
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Data Portability"
              secondary="Receive your data in a structured, commonly used format"
            />
          </ListItem>
        </List>
      </Paper>

      {/* Contact Section */}
      <Paper elevation={1} sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <ContactMailIcon color="primary" sx={{ mr: 2 }} />
          <Typography variant="h5">Contact Us</Typography>
        </Box>
        <Typography paragraph>
          If you have any questions about this privacy policy or our handling of your data, 
          please contact us at:
        </Typography>
        <List>
          <ListItem>
            <ListItemText 
              primary="Email"
              secondary="privacy@improvisedmusic.ie"
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Address"
              secondary="IMC, Unit B3 Canal Bank
Hume Avenue
Park West Industrial Park
Dublin 12, Ireland"
            />
          </ListItem>
        </List>
      </Paper>

      {/* Footer Note */}
      <Box sx={{ mt: 4, textAlign: 'center' }}>
        <Typography variant="body2" color="text.secondary">
          This privacy policy was last updated on {new Date().toLocaleDateString()}. 
          We will notify you of any significant changes to this policy.
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicyPage;