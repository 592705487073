import React, { useState, useEffect } from 'react';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, 
  Typography, Chip, Button, TextField, MenuItem, Alert 
} from '@mui/material';
import axios from 'axios';

type ApplicationType = 'Artist' | 'Ban Bam' | 'Navigator';
type ApplicationStatus = 'pending' | 'under review' | 'published' | 'approved' | 'rejected';

interface BaseApplication {
  _id: string;
  type: ApplicationType;
  createdAt: string;
  status: ApplicationStatus;
}

interface ArtistApplication extends BaseApplication {
  type: 'Artist';
  artistName: string;
}

interface BanBamApplication extends BaseApplication {
  type: 'Ban Bam';
  firstName: string;
  lastName: string;
}

interface NavigatorApplication extends BaseApplication {
  type: 'Navigator';
  name: string;
}

type Application = ArtistApplication | BanBamApplication | NavigatorApplication;

const UnifiedApplicationsView: React.FC = () => {
  const [applications, setApplications] = useState<Application[]>([]);
  const [filteredApplications, setFilteredApplications] = useState<Application[]>([]);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<Record<ApplicationType, string | null>>({
    Artist: null,
    'Ban Bam': null,
    Navigator: null
  });
  const [filter, setFilter] = useState<ApplicationType | 'all'>('all');

  useEffect(() => {
    const fetchApplications = async () => {
      setLoading(true);
      const newApplications: Application[] = [];
      const newErrors = { ...errors };

      const fetchData = async (url: string, type: ApplicationType) => {
        try {
          const response = await axios.get(url);
          const apps = response.data.map((app: any) => ({ ...app, type }));
          newApplications.push(...apps);
          newErrors[type] = null;
        } catch (err) {
          console.error(`Failed to fetch ${type} applications:`, err);
          newErrors[type] = `Failed to fetch ${type} applications`;
        }
      };

      await Promise.all([
        fetchData(`${process.env.REACT_APP_API_URL}/api/bandRecommendations`, 'Artist'),
        fetchData(`${process.env.REACT_APP_API_URL}/api/banbam`, 'Ban Bam'),
        fetchData(`${process.env.REACT_APP_API_URL}/api/navigator`, 'Navigator')
      ]);

      setApplications(newApplications);
      setFilteredApplications(newApplications);
      setErrors(newErrors);
      setLoading(false);
    };

    fetchApplications();
  }, []);

  useEffect(() => {
    if (filter === 'all') {
      setFilteredApplications(applications);
    } else {
      setFilteredApplications(applications.filter(app => app.type === filter));
    }
  }, [filter, applications]);

  const getDisplayName = (app: Application) => {
    switch (app.type) {
      case 'Artist':
        return app.artistName;
      case 'Ban Bam':
        return `${app.firstName} ${app.lastName}`;
      case 'Navigator':
        return app.name;
      default:
        return 'Unknown';
    }
  };

  const handleViewDetails = (app: Application) => {
    // Implement view details logic here
    console.log('Viewing details for:', app);
  };

  if (loading) return <Typography>Loading...</Typography>;

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h4" gutterBottom>All Applications</Typography>
      
      {Object.entries(errors).map(([type, error]) => 
        error && <Alert severity="error" key={type} style={{ marginBottom: '10px' }}>{error}</Alert>
      )}

      {applications.length === 0 ? (
        <Typography>No applications could be fetched. Please try again later.</Typography>
      ) : (
        <>
          <TextField
            select
            label="Filter by Type"
            value={filter}
            onChange={(e) => setFilter(e.target.value as ApplicationType | 'all')}
            style={{ marginBottom: '20px' }}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="Artist">Artist</MenuItem>
            <MenuItem value="Ban Bam">Ban Bam</MenuItem>
            <MenuItem value="Navigator">Navigator</MenuItem>
          </TextField>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Submission Date</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredApplications.map((app) => (
                  <TableRow key={app._id}>
                    <TableCell>{app.type}</TableCell>
                    <TableCell>{getDisplayName(app)}</TableCell>
                    <TableCell>{new Date(app.createdAt).toLocaleDateString()}</TableCell>
                    <TableCell>
                      <Chip 
                        label={app.status} 
                        color={app.status === 'approved' ? 'success' : app.status === 'rejected' ? 'error' : 'default'} 
                      />
                    </TableCell>
                    <TableCell>
                      <Button variant="contained" size="small" onClick={() => handleViewDetails(app)}>View</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
};

export default UnifiedApplicationsView;