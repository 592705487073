import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Navigate } from 'react-router-dom';
import { 
  Container, 
  Typography, 
  Box 
} from '@mui/material';
import AdminDomainManagement from '../components/AdminDomainManagement';
import { User } from '../types';


const AdminDomainManagementPage: React.FC = () => {
  const { user, isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  if ((user as User)?.role !== 'admin') {
    return <Typography>You do not have permission to view this page.</Typography>;
  }


  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h2" component="h1" gutterBottom>
          Domain Management
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Manage users and invitations for your domain.
        </Typography>
      </Box>
      <AdminDomainManagement />
    </Container>
  );
};

export default AdminDomainManagementPage;