import React, { useState } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  TextField, 
  Button, 
  Chip,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Snackbar,
  Alert
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import type { Guideline } from '../../types/guidelines';
import axiosInstance from '../../utils/axiosConfig';

// API functions
const fetchGuidelines = async (): Promise<Guideline[]> => {
    const response = await axiosInstance.get<Guideline[]>('/api/guidelines');
    const guidelines = response.data;
  
    // Add strandSlug to each guideline
    return guidelines.map((guideline) => ({
      ...guideline,
      strandSlug: guideline.strandSlug || guideline.strandName?.toLowerCase().replace(/\s+/g, '-'),
    }));
  };

const deleteGuideline = async (id: string): Promise<void> => {
  await axiosInstance.delete(`/api/guidelines/${id}`);
};

const GuidelinesManagementPage: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [guidelineToDelete, setGuidelineToDelete] = useState<string | null>(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error'
  });

  const queryClient = useQueryClient();
  
  const { data: guidelines, isLoading, error } = useQuery<Guideline[], Error>(
    'guidelines',
    fetchGuidelines
  );

  const deleteMutation = useMutation<void, Error, string>(
    (guidelineId: string) => deleteGuideline(guidelineId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('guidelines');
        setSnackbar({
          open: true,
          message: 'Guideline deleted successfully',
          severity: 'success'
        });
      },
      onError: (error) => {
        setSnackbar({
          open: true,
          message: `Error deleting guideline: ${error.message}`,
          severity: 'error'
        });
      }
    }
  );

  const getGuidelineTitle = (guideline: Guideline): string => {
    return guideline.title || 'Untitled';
  };

  const handleDeleteClick = (guidelineId: string) => {
    setGuidelineToDelete(guidelineId);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (guidelineToDelete) {
      await deleteMutation.mutateAsync(guidelineToDelete);
      setDeleteDialogOpen(false);
      setGuidelineToDelete(null);
    }
  };

  const filteredGuidelines = guidelines?.filter((guideline: Guideline) => {
    const title = getGuidelineTitle(guideline);
    const strandName = guideline.strandName || '';
    return title.toLowerCase().includes(searchTerm.toLowerCase()) ||
           strandName.toLowerCase().includes(searchTerm.toLowerCase());
  });

  if (isLoading) return <Typography>Loading...</Typography>;
  if (error) return <Typography>An error occurred: {error.message}</Typography>;

  return (
    <Box>
      <Typography variant="h4" gutterBottom>Guidelines Management</Typography>
      
      <TextField
        label="Search guidelines"
        variant="outlined"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        fullWidth
        margin="normal"
      />
      
      <Button 
        variant="contained" 
        color="primary" 
        component={Link} 
        to="/strands/guidelines/new"
        sx={{ mb: 2 }}
      >
        Create New Guideline
      </Button>
      
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Strand</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Last Updated</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Version</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredGuidelines?.map((guideline: Guideline) => (
              <TableRow key={guideline._id}>
                <TableCell>{getGuidelineTitle(guideline)}</TableCell>
                <TableCell>
                  {guideline.strandName || guideline.strandId}
                </TableCell>
                <TableCell>
                  <Chip 
                    label={guideline.isPublished ? 'Published' : 'Draft'} 
                    color={guideline.isPublished ? 'success' : 'default'}
                  />
                </TableCell>
                <TableCell>
                  {guideline.updatedAt ? new Date(guideline.updatedAt).toLocaleDateString() : 'N/A'}
                </TableCell>
                <TableCell>
                  {guideline.createdAt ? new Date(guideline.createdAt).toLocaleDateString() : 'N/A'}
                </TableCell>
                <TableCell>
                  {guideline.currentVersion}
                  {guideline.isPublished && guideline.publishedVersion && 
                    ` (Published: ${guideline.publishedVersion})`
                  }
                </TableCell>
                <TableCell>
                  <Button 
                    component={Link} 
                    to={`/guidelines/${guideline._id}/edit`}
                    sx={{ mr: 1 }}
                  >
                    Edit
                  </Button>
                  <Button
  component={Link}
  to={`/guidelines/strand/${guideline.strandSlug}`}
  sx={{ mr: 1 }}
>
  View
</Button>
                  <Button 
                    color="error"
                    onClick={() => handleDeleteClick(guideline._id)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this guideline? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
      >
        <Alert
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
          severity={snackbar.severity}
          elevation={6}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default GuidelinesManagementPage;