import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, IconButton, TextField, Tooltip } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';

interface CustomNumberInputProps {
  value: number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  disabled?: boolean;
  label?: string;
  step?: number;
}

const CustomNumberInput: React.FC<CustomNumberInputProps> = ({
  value,
  onChange,
  min = 0,
  max,
  disabled = false,
  label = 'Quantity',
  step = 1,
}) => {
  const [isIncrementing, setIsIncrementing] = useState(false);
  const [isDecrementing, setIsDecrementing] = useState(false);
  const incrementInterval = useRef<NodeJS.Timeout | null>(null);
  const decrementInterval = useRef<NodeJS.Timeout | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const initialDelay = 500;
  const intervalDelay = 50;
  const valueRef = useRef(value);

  // Update the ref whenever value changes
  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  const clampValue = useCallback(
    (newValue: number) => {
      return Math.min(max ?? Infinity, Math.max(min, newValue));
    },
    [max, min]
  );

  const updateValue = useCallback(
    (newValue: number) => {
      const clampedValue = clampValue(newValue);
      if (clampedValue !== valueRef.current) {
        onChange(clampedValue);
      }
    },
    [clampValue, onChange]
  );

  const handleIncrement = useCallback(() => {
    updateValue(valueRef.current + step);
  }, [step, updateValue]);

  const handleDecrement = useCallback(() => {
    updateValue(valueRef.current - step);
  }, [step, updateValue]);

  const startIncrementing = useCallback(() => {
    if (disabled || (max !== undefined && valueRef.current >= max)) return;
    setIsIncrementing(true);
    handleIncrement();

    timeoutRef.current = setTimeout(() => {
      incrementInterval.current = setInterval(() => {
        handleIncrement();
      }, intervalDelay);
    }, initialDelay);
  }, [disabled, handleIncrement, initialDelay, intervalDelay, max]);

  const startDecrementing = useCallback(() => {
    if (disabled || valueRef.current <= min) return;
    setIsDecrementing(true);
    handleDecrement();

    timeoutRef.current = setTimeout(() => {
      decrementInterval.current = setInterval(() => {
        handleDecrement();
      }, intervalDelay);
    }, initialDelay);
  }, [disabled, handleDecrement, initialDelay, intervalDelay, min]);

  const stopIncrementing = useCallback(() => {
    setIsIncrementing(false);
    if (incrementInterval.current) {
      clearInterval(incrementInterval.current);
      incrementInterval.current = null;
    }
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, []);

  const stopDecrementing = useCallback(() => {
    setIsDecrementing(false);
    if (decrementInterval.current) {
      clearInterval(decrementInterval.current);
      decrementInterval.current = null;
    }
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, []);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (incrementInterval.current) clearInterval(incrementInterval.current);
      if (decrementInterval.current) clearInterval(decrementInterval.current);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (disabled) return;

    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault();
        handleIncrement();
        break;
      case 'ArrowDown':
        e.preventDefault();
        handleDecrement();
        break;
      case 'PageUp':
        e.preventDefault();
        updateValue(value + step * 10);
        break;
      case 'PageDown':
        e.preventDefault();
        updateValue(value - step * 10);
        break;
      case 'Home':
        e.preventDefault();
        updateValue(min);
        break;
      case 'End':
        e.preventDefault();
        updateValue(max ?? value);
        break;
      default:
        break;
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(e.target.value);
    if (!isNaN(newValue)) {
      updateValue(newValue);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
      }}
      role="spinbutton"
      aria-valuenow={value}
      aria-valuemin={min}
      aria-valuemax={max ?? undefined}
      aria-label={label}
    >
      <Tooltip title="Decrease">
        <IconButton
          onMouseDown={startDecrementing}
          onMouseUp={stopDecrementing}
          onMouseLeave={stopDecrementing}
          onTouchStart={startDecrementing}
          onTouchEnd={stopDecrementing}
          disabled={disabled || value <= min}
          size="large"
          sx={{
            width: 40,
            height: 40,
            bgcolor: isDecrementing ? 'action.selected' : 'transparent',
            transition: 'background-color 0.2s',
          }}
        >
          <Remove />
        </IconButton>
      </Tooltip>

      <TextField
        type="number"
        value={value}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        inputProps={{
          min,
          max,
          step,
          style: {
            textAlign: 'center',
            width: '60px',
            fontSize: '1.2rem',
            padding: '8px',
          },
        }}
        sx={{
          mx: 1,
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            display: 'none',
          },
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
        }}
        disabled={disabled}
      />

      <Tooltip title="Increase">
        <IconButton
          onMouseDown={startIncrementing}
          onMouseUp={stopIncrementing}
          onMouseLeave={stopIncrementing}
          onTouchStart={startIncrementing}
          onTouchEnd={stopIncrementing}
          disabled={disabled || (max !== undefined && value >= max)}
          size="large"
          sx={{
            width: 40,
            height: 40,
            bgcolor: isIncrementing ? 'action.selected' : 'transparent',
            transition: 'background-color 0.2s',
          }}
        >
          <Add />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default CustomNumberInput;