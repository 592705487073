import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Stack,
  Chip
} from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { BandRecommendation } from '../../../../types/BandRecommendations';
import { useBandView } from '../../../../contexts/BandViewContext';

interface AvailabilitySectionProps {
  band: BandRecommendation;
}

const AvailabilitySection: React.FC<AvailabilitySectionProps> = ({ band }) => {
  const { viewConfig } = useBandView();

  if (!viewConfig.showAvailability) {
    return null;
  }

  const formatDate = (date: Date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  return (
    <Card sx={{ mb: 3 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Availability
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <CalendarTodayIcon sx={{ mr: 1 }} />
          <Typography>
            {formatDate(new Date(band.availability.from))} - {formatDate(new Date(band.availability.to))}
          </Typography>
        </Box>
        
        {band.availability.excludedDates && band.availability.excludedDates.length > 0 && (
          <Box>
            <Typography variant="subtitle2" gutterBottom>
              Excluded Dates:
            </Typography>
            <Stack direction="row" spacing={1} flexWrap="wrap">
              {band.availability.excludedDates.map((date, index) => (
                <Chip
                  key={index}
                  label={formatDate(new Date(date))}
                  size="small"
                  variant="outlined"
                  sx={{ mb: 1 }}
                />
              ))}
            </Stack>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default AvailabilitySection;