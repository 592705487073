import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Container, Typography, Grid, Card, CardContent, CardMedia,
  TextField, Select, MenuItem, FormControl, InputLabel,
  Box, Chip, Button, Accordion, AccordionSummary, AccordionDetails,
  SelectChangeEvent, Checkbox, ListItemText
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { BandRecommendation, EuropeanCountry } from '../types/BandRecommendations';
import axios from 'axios';
import jazzSubgenres from '../data/jazzSubgenres';
import { Event } from '../types/mongodb'; 
import YouTubePlaylistPlayer from '../components/YouTubePlaylistPlayer';

declare global {
  interface Window {
    onYouTubeIframeAPIReady: () => void;
  }
}


interface Strand {
  slug: string;
  name: string;
}

const BandList: React.FC = () => {
  // State Variables
  const [recommendations, setRecommendations] = useState<BandRecommendation[]>([]);
  const [filteredRecommendations, setFilteredRecommendations] = useState<BandRecommendation[]>([]);
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [strands, setStrands] = useState<Strand[]>([]);

  const fetchStrands = async () => {
    try {
      const response = await axios.get<Strand[]>(`${process.env.REACT_APP_API_URL}/api/strands`);
      setStrands(response.data);
    } catch (error) {
      console.error('Error fetching strands:', error);
      // Handle error as needed
    }
  };

  useEffect(() => {
    fetchRecommendations();
    fetchEvents();
    fetchStrands();
  }, []);


  // Filters State
  interface Filters {
    country: string;
    genre: string[];
    minMaleMembers: number;
    minFemaleMembers: number;
    lastViewedDate: string;
    searchTerm: string;
    event: string;
    bandSize: string;
    strand: string;
  }

  const [filters, setFilters] = useState<Filters>({
    country: '',
    genre: [],
    minMaleMembers: 0,
    minFemaleMembers: 0,
    lastViewedDate: '',
    searchTerm: '',
    event: '',
    bandSize: '',
    strand: '',
  });

  // Band Size Options
  const bandSizeOptions = [
    { label: 'Soloist', min: 1, max: 1 },
    { label: 'Duo', min: 2, max: 2 },
    { label: 'Trio', min: 3, max: 3 },
    { label: 'Quartet', min: 4, max: 4 },
    { label: 'Quintet', min: 5, max: 5 },
    { label: 'Sextet', min: 6, max: 6 },
    { label: 'Septet', min: 7, max: 7 },
    { label: 'Octet', min: 8, max: 8 },
    { label: 'Nonet', min: 9, max: 9 },
    { label: 'Big Band', min: 10, max: Infinity },
  ];

  useEffect(() => {
    fetchRecommendations();
    fetchEvents();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [recommendations, filters]);

  const fetchRecommendations = async () => {
    try {
      setLoading(true);
      const response = await axios.get<BandRecommendation[]>(`${process.env.REACT_APP_API_URL}/api/bandrecommendations`);
      setRecommendations(response.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching recommendations:', error);
      setError('Failed to fetch recommendations. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const fetchEvents = async () => {
    try {
      const response = await axios.get<Event[]>(`${process.env.REACT_APP_API_URL}/api/events`);
      setEvents(response.data);
    } catch (error) {
      console.error('Error fetching events:', error);
      // Handle error as needed
    }
  };

  const applyFilters = () => {
    const filtered = recommendations.filter((rec) => {
      const totalMembers = rec.maleMembers + rec.femaleMembers;
      return (
        (filters.country === '' || rec.recommenderCountry === filters.country) &&
        (filters.genre.length === 0 || filters.genre.some(genre => rec.genres.includes(genre))) &&
        rec.maleMembers >= filters.minMaleMembers &&
        rec.femaleMembers >= filters.minFemaleMembers &&
        (filters.lastViewedDate === '' || new Date(rec.createdAt) > new Date(filters.lastViewedDate)) &&
        (filters.searchTerm === '' ||
          rec.artistName.toLowerCase().includes(filters.searchTerm.toLowerCase()) ||
          rec.recommendationNotes.toLowerCase().includes(filters.searchTerm.toLowerCase())) &&
        (filters.strand === '' || rec.selectedStrand === filters.strand) && // Changed from event to strand
        (filters.bandSize === '' || (() => {
          const option = bandSizeOptions.find(opt => opt.label === filters.bandSize);
          if (!option) return true;
          return totalMembers >= option.min && totalMembers <= option.max;
        })())
      );
    });
    setFilteredRecommendations(filtered);
  };

  const handleFilterChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<any>
  ) => {
    const { name, value } = event.target;
    setFilters(prevFilters => {
      let newValue: any = value;
      if (name === 'minMaleMembers' || name === 'minFemaleMembers') {
        newValue = Number(value);
      }
      return { ...prevFilters, [name]: newValue };
    });
  };

  const resetFilters = () => {
    setFilters({
      country: '',
      genre: [],
      minMaleMembers: 0,
      minFemaleMembers: 0,
      lastViewedDate: '',
      searchTerm: '',
      event: '',
      bandSize: '',
      strand: '',
    });
  };

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Container maxWidth="lg">
      <Typography variant="h2" component="h1" gutterBottom>Bands List</Typography>
      
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Filters</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {/* Country Filter */}
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <InputLabel>Country</InputLabel>
                <Select
                  name="country"
                  value={filters.country}
                  onChange={handleFilterChange}
                  label="Country"
                >
                  <MenuItem value="">All Countries</MenuItem>
                  {Object.values(EuropeanCountry).map((country) => (
                    <MenuItem key={country} value={country}>{country}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* Genre Filter */}
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <InputLabel>Genre</InputLabel>
                <Select
                  name="genre"
                  multiple
                  value={filters.genre}
                  onChange={handleFilterChange}
                  label="Genre"
                  renderValue={(selected) => (selected as string[]).join(', ')}
                >
                  {jazzSubgenres.map((genre) => (
                    <MenuItem
                      key={genre}
                      value={genre}
                      disabled={filters.genre.length >= 3 && filters.genre.indexOf(genre) === -1}
                    >
                      <Checkbox checked={filters.genre.indexOf(genre) > -1} />
                      <ListItemText primary={genre} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* Band Size Filter */}
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <InputLabel>Band Size</InputLabel>
                <Select
                  name="bandSize"
                  value={filters.bandSize}
                  onChange={handleFilterChange}
                  label="Band Size"
                >
                  <MenuItem value="">All Sizes</MenuItem>
                  {bandSizeOptions.map((option) => (
                    <MenuItem key={option.label} value={option.label}>{option.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
{/* Strand Filter */}
<Grid item xs={12} sm={6} md={3}>
  <FormControl fullWidth>
    <InputLabel>Strand</InputLabel>
    <Select
      name="strand"
      value={filters.strand}
      onChange={handleFilterChange}
      label="Strand"
    >
      <MenuItem value="">All Strands</MenuItem>
      {strands.map((strand) => (
        <MenuItem key={strand.slug} value={strand.slug}>{strand.name}</MenuItem>
      ))}
    </Select>
  </FormControl>
</Grid>
            {/* Min Male Members */}
            <Grid item xs={6} sm={3} md={2}>
              <TextField
                fullWidth
                type="number"
                name="minMaleMembers"
                label="Min Male Members"
                value={filters.minMaleMembers}
                onChange={handleFilterChange}
              />
            </Grid>
            {/* Min Female Members */}
            <Grid item xs={6} sm={3} md={2}>
              <TextField
                fullWidth
                type="number"
                name="minFemaleMembers"
                label="Min Female Members"
                value={filters.minFemaleMembers}
                onChange={handleFilterChange}
              />
            </Grid>
            {/* Last Viewed Date */}
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                type="date"
                name="lastViewedDate"
                label="New Since"
                value={filters.lastViewedDate}
                onChange={handleFilterChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            {/* Search Term */}
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                name="searchTerm"
                label="Search"
                value={filters.searchTerm}
                onChange={handleFilterChange}
              />
            </Grid>
            {/* Reset Filters Button */}
            <Grid item xs={12} md={4}>
              <Button variant="outlined" onClick={resetFilters} fullWidth>
                Reset Filters
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
{filteredRecommendations.length > 0 && (
  <Box mb={4}>
    <YouTubePlaylistPlayer 
      bands={filteredRecommendations}
      onVideoChange={(bandId, videoId) => {
        console.log(`Now playing: Band ${bandId}, Video ${videoId}`);
      }}
    />
  </Box>
)}
  <Box mt={4}>
  <Grid container spacing={3}>
    {filteredRecommendations.length > 0 ? (
      filteredRecommendations.map((rec) => {
        const totalMembers = rec.maleMembers + rec.femaleMembers;
        const bandSizeLabel = bandSizeOptions.find(option => totalMembers >= option.min && totalMembers <= option.max)?.label || `${totalMembers} members`;
        const strandName = rec.selectedStrand ? strands.find(strand => strand.slug === rec.selectedStrand)?.name : '';


        return (
                <Grid item xs={12} sm={6} md={4} key={rec._id}>
                  <Card>
                    <CardMedia
                      component="img"
                      height="180"
                      image={rec.mainImage || '/placeholder-image.jpg'}
                      alt={rec.artistName}
                    />
                    <CardContent>
                      <Typography variant="h5" component="div">
                        <Link to={`/band/${rec._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                          {rec.artistName}
                        </Link>
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {rec.recommenderCountry}
                      </Typography>
                      <Box mt={1}>
                        {rec.genres.map((genre) => (
                          <Chip key={genre} label={genre} size="small" style={{ margin: '0 2px 2px 0' }} />
                        ))}
                      </Box>
                      <Typography variant="body2" mt={1}>
                        Members: {rec.maleMembers} male, {rec.femaleMembers} female
                      </Typography>
                      <Typography variant="body2" mt={1}>
                        Band Size: {bandSizeLabel}
                      </Typography>
                      {strandName && (
                <Typography variant="body2" mt={1}>
                  Strand: {strandName}
                </Typography>
              )}
                      <Typography variant="body2" mt={1}>
                      {(rec.recommendationNotes?.substring(0, 100) || rec.about?.substring(0, 100) || 'No description available.')}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })
          ) : (
            <Typography>No recommendations found.</Typography>
          )}
        </Grid>
      </Box>
    </Container>
  );
};

export default BandList;