import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

interface QuoteCardProps {
  quote: {
    quote: string;
    author: string;
  };
}

const QuoteCard: React.FC<QuoteCardProps> = ({ quote }) => {
  return (
    <Card 
      sx={{ 
        mb: 2, // Reduced from 3
        backgroundColor: '#336076', 
        color: '#fff',
        padding: 1, // Reduced from 2
        boxShadow: '0 3px 6px rgba(0, 0, 0, 0.15)',
        borderRadius: '8px', // Reduced from 10px
        width: '100%'
      }}
    >
      <CardContent sx={{ 
        position: 'relative',
        py: 1, // Added to reduce vertical padding
        '&:last-child': { pb: 1 } // Override Material-UI's default padding
      }}>
        <Typography 
          variant="h4" 
          sx={{ 
            fontFamily: 'serif',
            position: 'absolute',
            top: -15, // Reduced from -20
            left: 12, // Reduced from 16
            color: '#ffeb3b',
            fontSize: '3rem', // Reduced from 4rem
            opacity: 0.7
          }}
        >
          "
        </Typography>
        
        <Typography 
          variant="body1" 
          sx={{ 
            fontStyle: 'italic',
            fontSize: '1rem', // Reduced from 1.1rem
            lineHeight: 1.4, // Reduced from 1.5
            paddingLeft: '2.5rem', // Reduced from 3rem
            pr: 2.5, // Reduced from 3
            my: 0.5 // Added to reduce vertical spacing
          }}
        >
          {quote.quote}
        </Typography>
        
        <Typography 
          variant="h4" 
          sx={{ 
            fontFamily: 'serif',
            position: 'absolute',
            bottom: -5, // Adjusted from 0
            right: 12, // Reduced from 16
            color: '#ffeb3b',
            fontSize: '3rem', // Reduced from 4rem
            opacity: 0.7,
            transform: 'translateY(50%)'
          }}
        >
          "
        </Typography>
        
        <Typography 
          variant="subtitle2" 
          sx={{ 
            textAlign: 'right',
            mt: 1, // Reduced from 2
            fontSize: '0.8rem', // Reduced from 0.9rem
            opacity: 0.8,
            pr: 5 // Reduced from 6
          }}
        >
          - {quote.author}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default QuoteCard;