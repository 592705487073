// pages/BandRecommendationPage.tsx

import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import BandRecommendationForm from '../components/BandRecommendationForm';

const BandRecommendationPage: React.FC = () => {
  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <BandRecommendationForm />
      </Box>
    </Container>
  );
};

export default BandRecommendationPage;