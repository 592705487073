// src/types/EventType.ts (for frontend use)
export enum EventType {
  Concert = 'Concert',
  Series = 'Series',
  Commission = 'Commission',
  Showcase = 'Showcase',
  Festival = 'Festival',
  Residency = 'Residency',
  Clinic = 'Clinic',
  Talk = 'Talk',
  Tour = 'Tour',
  Conference = 'Conference',
  Workshop = 'Workshop',
  MasterClass = 'Master Class',
  AlbumLaunch = 'Album Launch',
  Competition = 'Competition',
  OpenMic = 'Open Mic',
  JamSession = 'Jam Session',
  Benefit = 'Benefit',
  AwardsCeremony = 'Awards Ceremony',
  Podcast = 'Podcast',
  LiveRecording = 'Live Recording',
  StreamingEvent = 'Streaming Event',
  Demo = 'Demo',
  Other = 'Other',
  CollaborationEvent = 'Collaboration Event',
  TradeShow = 'Trade Show',
  Single = "Single"
}