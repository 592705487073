// utils.ts
import { parseISO, format, isValid } from 'date-fns';
import { Prospect, ProspectFormData } from './types';

export const calculateNet = (prospect: ProspectFormData | Partial<Prospect>) => {
  return (
    (Number(prospect.boxOffice) || 0) - 
    (Number(prospect.fees) || 0) - 
    (Number(prospect.expenses) || 0) + 
    (Number(prospect.sponsorship) || 0)
  );
};

export const formatDate = (date: Date | null | string): string => {
  if (!date) return 'No date set';
  try {
    const dateObj = typeof date === 'string' ? parseISO(date) : date;
    return isValid(dateObj) ? format(dateObj, 'dd/MM/yyyy') : 'Invalid date';
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Invalid date';
  }
};