import React, { useState } from 'react';
import { 
  Card, 
  CardContent,
  Button,
  Stack,
  Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EventIcon from '@mui/icons-material/Event';
import PublishIcon from '@mui/icons-material/Publish';
import PendingIcon from '@mui/icons-material/Pending';
import { BandRecommendation } from '../../../types/BandRecommendations';
import { useBandView } from '../../../contexts/BandViewContext';
import AddToEventDialog from '../dialogs/AddToEventDialog';
import DeleteConfirmDialog from '../dialogs/DeleteConfirmDialog';
import StrandPublishingManager from '../StrandPublishingManager';

interface Event {
  _id: string;
  name: string;
  date: string;
}

interface BandActionsProps {
  band: BandRecommendation;
  onEdit: () => void;
  onDelete: () => void;
  onAddToEvent: () => void;
  onPublish: (strandId: string, shortDescription: string) => Promise<void>;
  events: Event[];
}

const BandActions: React.FC<BandActionsProps> = ({
  band,
  onEdit,
  onDelete,
  onAddToEvent,
  onPublish,
  events
}) => {
  const { viewConfig, permissions } = useBandView();
  
  // Dialog states
  const [openStrandManager, setOpenStrandManager] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openAddToEventDialog, setOpenAddToEventDialog] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<string>('');
  const [isDeleting, setIsDeleting] = useState(false);

  // If not showing admin actions, don't render anything
  if (!viewConfig.showAdminActions) {
    return null;
  }

  const handleDelete = () => {
    setOpenDeleteDialog(true);
  };

  const handleAddToEvent = () => {
    setOpenAddToEventDialog(true);
  };

  const handleCreateNewEvent = () => {
    // Navigate to new event page or handle creation
    console.log("Create new event");
  };

  const handleEventSelect = (eventId: string) => {
    setSelectedEvent(eventId);
  };

  const handleOpenPublishManager = () => {
    setOpenStrandManager(true);
  };

  return (
    <>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Admin Actions
          </Typography>
          <Stack spacing={2}>
            {permissions.canEdit && (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                startIcon={<EditIcon />}
                onClick={onEdit}
              >
                Edit Details
              </Button>
            )}

            {permissions.canAddToEvent && (
              <Button
                fullWidth
                variant="contained"
                color="success"
                startIcon={<EventIcon />}
                onClick={handleAddToEvent}
              >
                Add to Event
              </Button>
            )}

            {permissions.canPublish && (
              <Button
                fullWidth
                variant="contained"
                color={(band.publishedTo?.length ?? 0) > 0 ? "info" : "primary"}
                startIcon={<PublishIcon />}
                onClick={handleOpenPublishManager}
              >
                {(band.publishedTo?.length ?? 0) > 0
                  ? `Manage Publications (${band.publishedTo?.length ?? 0})`
                  : "Publish"}
              </Button>
            )}

            <Button
              fullWidth
              variant="contained"
              color="warning"
              startIcon={<PendingIcon />}
              disabled={!permissions.canEdit}
            >
              Pending Review
            </Button>

            {permissions.canDelete && (
              <Button
                fullWidth
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={handleDelete}
              >
                Delete
              </Button>
            )}
          </Stack>
        </CardContent>
      </Card>

      <AddToEventDialog
        open={openAddToEventDialog}
        onClose={() => setOpenAddToEventDialog(false)}
        bandName={band.artistName}
        events={events}
        selectedEvent={selectedEvent}
        onEventSelect={handleEventSelect}
        onCreateNew={handleCreateNewEvent}
        onConfirm={onAddToEvent}
      />

      <DeleteConfirmDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onConfirm={onDelete}
        bandName={band.artistName}
        loading={isDeleting}
      />

      {band._id && (
        <StrandPublishingManager
          bandId={band._id}
          open={openStrandManager}
          onClose={() => setOpenStrandManager(false)}
        />
      )}
    </>
  );
};

export default BandActions;