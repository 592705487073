import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Alert,
  SelectChangeEvent,
} from '@mui/material';
import { X as DeleteIcon } from 'lucide-react';
import axiosInstance from '../utils/axiosConfig';

interface Strand {
  _id: string;
  name: string;
}

interface PublishBandModalProps {
  open: boolean;
  onClose: () => void;
  bandId: string;
  bandName: string;
  onPublish: (strandId: string, shortDescription: string) => void;
}

const PublishBandModal: React.FC<PublishBandModalProps> = ({
  open,
  onClose,
  bandId,
  bandName,
  onPublish,
}) => {
  const [strands, setStrands] = useState<Strand[]>([]);
  const [selectedStrand, setSelectedStrand] = useState<string>('');
  const [publishedStrands, setPublishedStrands] = useState<string[]>([]);
  const [shortDescription, setShortDescription] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setError(null);
        const [strandsResponse, bandResponse] = await Promise.all([
          axiosInstance.get<Strand[]>('/api/strands'),
          axiosInstance.get(`/api/bandrecommendations/${bandId}`),
        ]);
        setStrands(strandsResponse.data);
        setPublishedStrands(bandResponse.data.publishedTo || []);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to fetch data. Please try again.');
      }
    };
    if (open) {
      fetchData();
    }
  }, [bandId, open]);

  const handleStrandChange = (event: SelectChangeEvent<string>) => {
    setSelectedStrand(event.target.value as string);
  };

  const handlePublish = () => {
    onPublish(selectedStrand, shortDescription);
    onClose(); // Close the modal after publishing
  };

  const handleUnpublish = async (strandId: string) => {
    setLoading(true);
    setError(null);
    try {
      await axiosInstance.delete(
        `/api/bandrecommendations/${bandId}/unpublish/${strandId}`
      );
      setPublishedStrands((prevStrands) =>
        prevStrands.filter((id) => id !== strandId)
      );
    } catch (error: any) {
      console.error('Error unpublishing band:', error);
      setError(
        error.response?.data?.message || 'Failed to unpublish. Please try again.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Manage Publication Status - {bandName}</DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {/* Currently Published Section */}
        <Box mb={3}>
          <Typography variant="h6" gutterBottom>
            Currently Published To
          </Typography>
          <List>
            {publishedStrands.length > 0 ? (
              publishedStrands.map((strandId) => {
                const strand = strands.find((s) => s._id === strandId);
                return (
                  <ListItem
                    key={strandId}
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="unpublish"
                        onClick={() => handleUnpublish(strandId)}
                        disabled={loading}
                      >
                        <DeleteIcon className="h-4 w-4" />
                      </IconButton>
                    }
                  >
                    <ListItemText primary={strand?.name || 'Unknown Strand'} />
                  </ListItem>
                );
              })
            ) : (
              <ListItem>
                <ListItemText primary="Not published to any strands" />
              </ListItem>
            )}
          </List>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Publish to New Strand Section */}
        <Typography variant="h6" gutterBottom>
          Publish to New Strand
        </Typography>

        <FormControl fullWidth margin="normal">
          <InputLabel>Select Strand</InputLabel>
          <Select
            value={selectedStrand}
            onChange={handleStrandChange}
            label="Select Strand"
          >
            {strands.map((strand) => (
              <MenuItem
                key={strand._id}
                value={strand._id}
                disabled={publishedStrands.includes(strand._id)}
              >
                {strand.name}
                {publishedStrands.includes(strand._id) && ' (Already published)'}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          fullWidth
          margin="normal"
          label="Short Description"
          multiline
          rows={4}
          value={shortDescription}
          onChange={(e) => setShortDescription(e.target.value)}
          helperText="Optional: Provide a short description useful for programmers. This will be public."
          placeholder="Enter a short description..."
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button
          onClick={handlePublish}
          variant="contained"
          color="primary"
          disabled={!selectedStrand || loading}
        >
          Publish to Selected Strand
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PublishBandModal;