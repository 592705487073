// src/components/form/NavigatorForm.tsx

import React, { useState } from 'react';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Button,
  FormHelperText,
  Checkbox,
  ListItemText,
  Select,
  MenuItem,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { NavigatorFormData, Instrument, Pronouns } from '../../types/NavigatorTypes';
import { IrishCounty } from '../../types/BandRecommendations';
import axios from 'axios';
import { SelectChangeEvent } from '@mui/material/Select';

const dateRanges = [
  'Ballina Arts Centre - Add Dates Here',
  'The Source Arts Centre, Thurles - Add Dates Here',
  'An Grianán Theatre, Letterkenny - Add Dates Here',
  'Courthouse Arts, Tinahely - Add Dates Here',
  'Garter Lane, Waterford - Add Dates Here',
  'Crescent Arts Centre, Belfast - Add Dates Here',
  'Riverbank Arts Centre, Kildare. - Add Dates Here',
  'I would like to be consider for all locations - Add Dates Here',
];

const MAX_INSTRUMENTS = 3; // Maximum number of instruments a user can select

const NavigatorForm: React.FC = () => {
  const [formData, setFormData] = useState<NavigatorFormData>({
    name: '',
    email: '',
    phone: '',
    instrument: [], // Initialize as an empty array for multiple selections
    location: IrishCounty.Dublin,
    pronouns: Pronouns.PreferNotToSay,
    linkToWork: '',
    webLink: '',
    residencyGoals: '',
    locationDates: [],
    parentalDuties: '',
  });

  const [instrumentError, setInstrumentError] = useState<string>(''); // State to manage instrument selection errors
  const [successMessage, setSuccessMessage] = useState<string>(''); // State to manage success messages
  const [submissionError, setSubmissionError] = useState<string>(''); // State to manage submission errors
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false); // State to manage form submission

  // Handler for TextField changes
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const name = event.target.name as keyof NavigatorFormData;
    const value = event.target.value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handler for Select changes
  const handleSelectChange = (event: SelectChangeEvent<string | string[]>) => {
    const name = event.target.name as keyof NavigatorFormData;
    const value = event.target.value;

    if (name === 'locationDates') {
      // Ensure locationDates is always a string array
      setFormData((prevData) => ({
        ...prevData,
        locationDates: typeof value === 'string' ? value.split(',') : (value as string[]),
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value as typeof prevData[keyof NavigatorFormData],
      }));
    }
  };

  // Handler for Instrument selection using Autocomplete
  const handleInstrumentChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: Instrument[]
  ) => {
    if (newValue.length > MAX_INSTRUMENTS) {
      setInstrumentError(`You can select up to ${MAX_INSTRUMENTS} instruments.`);
    } else {
      setInstrumentError('');
      setFormData((prevData) => ({
        ...prevData,
        instrument: newValue,
      }));
    }
  };

  // Handler for form submission
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Reset messages
    setSuccessMessage('');
    setSubmissionError('');

    // Validation for instrument selection
    if (formData.instrument.length === 0) {
      setInstrumentError('Please select at least one instrument.');
      return;
    }
    if (formData.instrument.length > MAX_INSTRUMENTS) {
      setInstrumentError(`You can select up to ${MAX_INSTRUMENTS} instruments.`);
      return;
    }

    console.log('Form submitted:', formData);

    setIsSubmitting(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/navigator`,
        formData
      );
      console.log('API Response:', response.data);
      setSuccessMessage('Application submitted successfully!');
      // Clear form on success
      setFormData({
        name: '',
        email: '',
        phone: '',
        instrument: [],
        location: IrishCounty.Dublin,
        pronouns: Pronouns.PreferNotToSay,
        linkToWork: '',
        webLink: '',
        residencyGoals: '',
        locationDates: [],
        parentalDuties: '',
      });
    } catch (error: any) {
      console.error('API Error:', error);
      const errorMessage = error.response?.data?.error || 'Error submitting the form. Please try again.';
      setSubmissionError(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} aria-labelledby="navigator-form-title">
      <Grid container spacing={3} role="region" aria-live="polite">
        <Grid item xs={12}>
          <h2 id="navigator-form-title">Navigator Application Form</h2>
        </Grid>

        {/* Musician's Name */}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              required
              fullWidth
              name="name"
              label="Musician's Name"
              aria-label="Musician's Name"
              value={formData.name}
              onChange={handleChange}
              helperText="Your full name"
              aria-describedby="name-helper"
            />
            <FormHelperText id="name-helper">Your full name</FormHelperText>
          </FormControl>
        </Grid>

        {/* Phone */}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              required
              fullWidth
              name="phone"
              label="Phone"
              aria-label="Phone number"
              value={formData.phone}
              onChange={handleChange}
              helperText="Your contact phone number"
              aria-describedby="phone-helper"
            />
            <FormHelperText id="phone-helper">Your contact phone number</FormHelperText>
          </FormControl>
        </Grid>

        {/* Email */}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              required
              fullWidth
              name="email"
              label="Email"
              type="email"
              aria-label="Email address"
              value={formData.email}
              onChange={handleChange}
              helperText="Your contact email address"
              aria-describedby="email-helper"
            />
            <FormHelperText id="email-helper">Your contact email address</FormHelperText>
          </FormControl>
        </Grid>

        {/* Instrument Selection */}
        <Grid item xs={12}>
          <FormControl
            fullWidth
            error={Boolean(instrumentError)}
            aria-labelledby="instrument-label"
            aria-describedby="instrument-helper"
          >
            <Autocomplete
              multiple
              id="instrument"
              options={Object.values(Instrument)}
              value={formData.instrument}
              onChange={handleInstrumentChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Instrument"
                  placeholder="Search and select instruments"
                  aria-label="Select your instruments"
                />
              )}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    style={{ marginRight: 8 }}
                    checked={selected}
                    aria-label={`Select ${option}`}
                  />
                  {option}
                </li>
              )}
              clearOnBlur
              selectOnFocus
              handleHomeEndKeys
            />
            <FormHelperText id="instrument-helper">
              Select up to {MAX_INSTRUMENTS} primary instrument(s)
            </FormHelperText>
            {instrumentError && (
              <FormHelperText error>{instrumentError}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        {/* Location */}
        <Grid item xs={12}>
          <FormControl fullWidth aria-labelledby="location-label" aria-describedby="location-helper">
            <InputLabel id="location-label">Location</InputLabel>
            <Select
              labelId="location-label"
              name="location"
              value={formData.location}
              onChange={handleSelectChange}
              label="Location"
              aria-label="Select your primary location"
            >
              {Object.values(IrishCounty).map((county) => (
                <MenuItem key={county} value={county}>
                  {county}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText id="location-helper">County where you are predominantly based</FormHelperText>
          </FormControl>
        </Grid>

        {/* Pronouns */}
        <Grid item xs={12}>
          <FormControl fullWidth aria-labelledby="pronouns-label" aria-describedby="pronouns-helper">
            <InputLabel id="pronouns-label">Pronouns</InputLabel>
            <Select
              labelId="pronouns-label"
              name="pronouns"
              value={formData.pronouns}
              onChange={handleSelectChange}
              label="Pronouns"
              aria-label="Select your pronouns"
            >
              {Object.values(Pronouns).map((pronoun) => (
                <MenuItem key={pronoun} value={pronoun}>
                  {pronoun}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText id="pronouns-helper">Your pronouns, if you are comfortable sharing with us</FormHelperText>
          </FormControl>
        </Grid>

        {/* Link to Work */}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              required
              fullWidth
              name="linkToWork"
              label="Link to work"
              aria-label="Link to your work"
              value={formData.linkToWork}
              onChange={handleChange}
              helperText="A link to an example of your work - video, audio etc. YouTube or Soundcloud links if possible"
              aria-describedby="linkToWork-helper"
            />
            <FormHelperText id="linkToWork-helper">
              A link to an example of your work - video, audio etc. YouTube or Soundcloud links if possible
            </FormHelperText>
          </FormControl>
        </Grid>

        {/* Web Link */}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              required
              fullWidth
              name="webLink"
              label="Web link"
              aria-label="Link to your website or social media"
              value={formData.webLink}
              onChange={handleChange}
              helperText="A link to your website and/or social media platforms"
              aria-describedby="webLink-helper"
            />
            <FormHelperText id="webLink-helper">
              A link to your website and/or social media platforms
            </FormHelperText>
          </FormControl>
        </Grid>

        {/* Residency Goals */}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              required
              fullWidth
              multiline
              rows={4}
              name="residencyGoals"
              label="Residency goals"
              aria-label="Your residency goals"
              value={formData.residencyGoals}
              onChange={handleChange}
              helperText="Could you please briefly outline what your focus would be over a ten-day residency and how you are interested in interacting with place or community? This can be general or specific to an area. Please note that artists are selected by arts centres with respect to their goals and needs."
              aria-describedby="residencyGoals-helper"
            />
            <FormHelperText id="residencyGoals-helper">
              Could you please briefly outline what your focus would be over a ten-day residency and how you are interested in interacting with place or community? This can be general or specific to an area. Please note that artists are selected by arts centres with respect to their goals and needs.
            </FormHelperText>
          </FormControl>
        </Grid>

        {/* Location / Dates */}
        <Grid item xs={12}>
          <FormControl
            fullWidth
            aria-labelledby="locationDates-label"
            aria-describedby="locationDates-helper"
          >
            <InputLabel id="locationDates-label">Location / Dates</InputLabel>
            <Select
              labelId="locationDates-label"
              name="locationDates"
              multiple
              value={formData.locationDates}
              onChange={handleSelectChange}
              renderValue={(selected) => (selected as string[]).join(', ')}
              label="Location / Dates"
              aria-label="Select your available date ranges"
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 48 * 4.5 + 8, // Adjust based on the number of items you want to show
                  },
                },
              }}
            >
              {dateRanges.map((dateRange) => (
                <MenuItem key={dateRange} value={dateRange}>
                  <Checkbox checked={formData.locationDates.includes(dateRange)} />
                  <ListItemText primary={dateRange} />
                </MenuItem>
              ))}
            </Select>
            <FormHelperText id="locationDates-helper">
              Select the date ranges you are available for. Choose 'ALL' if you are available for all periods.
              Navigator aims to increase national mobility by connecting artists with arts centres outside their locality.
            </FormHelperText>
          </FormControl>
        </Grid>

        {/* Parental Duties Support */}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              fullWidth
              multiline
              rows={4}
              name="parentalDuties"
              label="Parental Duties Support"
              aria-label="Parental duties support"
              value={formData.parentalDuties}
              onChange={handleChange}
              helperText="Do you have any parental duties which would require support for the residency? If so, could you please outline the supports which would be helpful?"
              aria-describedby="parentalDuties-helper"
            />
            <FormHelperText id="parentalDuties-helper">
              Do you have any parental duties which would require support for the residency? If so, could you please outline the supports which would be helpful?
            </FormHelperText>
          </FormControl>
        </Grid>

        {/* Success/Error Messages */}
        <Grid item xs={12}>
          <div role="alert" aria-live="assertive">
            {instrumentError && <p className="error">{instrumentError}</p>}
          </div>
          <div role="alert" aria-live="polite">
            {successMessage && <p className="success">{successMessage}</p>}
            {submissionError && <p className="error">{submissionError}</p>}
          </div>
        </Grid>

        {/* Submit Button */}
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            aria-label="Submit Navigator Application"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Submitting...' : 'Submit Application'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default NavigatorForm;