// components/form/BanBamForm.tsx

import React, { useState, ChangeEvent, FormEvent } from 'react';
import {
  Grid,
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Snackbar,
  Alert,
  Box,
  LinearProgress,
  FormHelperText,
} from '@mui/material';
import axios from 'axios';
import { BanBamFormData } from '../../types/BanBam';
import { IrishCounty } from '../../types/BandRecommendations';
import PrePopulateCheckbox from './PrePopulateCheckbox'; // Import the new component
import BanBamMediaLinks from './BanBamMediaLinks'; // Adjust the path as necessary

interface FileUploadComponentProps {
  onFileUploaded: (file: File) => void;
  onProgressUpdate: (progress: number) => void;
  fileType: 'cv' | 'audio' | 'score';
  accept: string;
  label: string;
  multiple?: boolean;
  onError?: (message: string) => void;
}

const FileUploadComponent: React.FC<FileUploadComponentProps> = ({
  onFileUploaded,
  onProgressUpdate,
  fileType,
  accept,
  label,
  multiple,
  onError
}) => {
  const validateAudioFile = (file: File): boolean => {
    // Check if it's an audio file upload
    if (fileType === 'audio') {
      // Check file type
      if (!file.type.startsWith('audio/mpeg')) {
        onError?.('Please upload MP3 files only. WAV, MP4, and other formats are not accepted.');
        return false;
      }

      // Check file extension explicitly
      const extension = file.name.split('.').pop()?.toLowerCase();
      if (extension !== 'mp3') {
        onError?.('Please upload MP3 files only. The file must have a .mp3 extension.');
        return false;
      }

      // Optional: Check file size (e.g., max 10MB)
      const maxSize = 20 * 1024 * 1024; // 20MB in bytes
      if (file.size > maxSize) {
        onError?.('Audio file size must be less than 20MB.');
        return false;
      }
    }
    return true;
  };
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files || files.length === 0) return;

    if (multiple) {
      const validFiles = Array.from(files).filter(file => validateAudioFile(file));
      validFiles.forEach(file => onFileUploaded(file));
    } else {
      if (validateAudioFile(files[0])) {
        onFileUploaded(files[0]);
      }
    }
  };


  return (
    <>
      <Button variant="contained" component="label">
        {label}
        <input 
          type="file" 
          hidden 
          onChange={handleFileChange} 
          accept={fileType === 'audio' ? '.mp3,audio/mpeg' : accept}
          multiple={multiple} 
        />
      </Button>
      {fileType === 'audio' && (
        <Typography variant="caption" color="textSecondary" display="block" sx={{ mt: 1 }}>
          Accepted format: MP3 only. Maximum file size: 20MB
        </Typography>
      )}
    </>
  );
};

const BanBamForm: React.FC = () => {
  const [formData, setFormData] = useState<BanBamFormData>({
    firstName: '',
    lastName: '',
    email: '',
    streetAddress1: '',
    streetAddress2: '',
    city: '',
    county: IrishCounty.Dublin,
    postalCode: '',
    phonePrefix: '',
    phoneNumber: '',
    website1: '',
    website2: '',
    compositionIdeas: '',
    performanceIdeas: '',
    careerHighlights: '',
    equalityDiversityInclusion: '', // Added field
    cvFileUrl: '',
    audioFileUrls: [],
    scoreFileUrl: '',
    confirmOriginalMaterial: false,
    youtubeEmbeds: [],
    spotifyEmbeds: [],
    soundcloudEmbeds: [],
    bandcampEmbeds: [],
    musicLink1: '', // Removed field
    musicLink2: '', // Removed field
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [uploadProgress, setUploadProgress] = useState<{ [key: string]: number }>({
    cv: 0,
    audio: 0,
    score: 0,
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({}); // Error state
  const [fileError, setFileError] = useState<string | null>(null);


  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (event: SelectChangeEvent<IrishCounty>) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const handleFileUploadWrapper = (fileType: 'cv' | 'audio' | 'score') => (file: File) => {
    handleFileUpload(file, fileType);
  };

  const handleFileUpload = async (file: File, fileType: 'cv' | 'audio' | 'score') => {
    const data = new FormData();
    data.append('file', file);
    data.append('fileType', fileType);

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const fullUrl = `${apiUrl}/api/upload`;
      const response = await axios.post(fullUrl, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 1));
          updateUploadProgress(percentCompleted, fileType);
        },
      });

      console.log('Upload successful:', response.data);
      if (response.data.fileUrl) {
        if (fileType === 'cv') {
          setFormData(prev => ({ ...prev, cvFileUrl: response.data.fileUrl }));
        } else if (fileType === 'audio') {
          setFormData(prev => ({ ...prev, audioFileUrls: [...prev.audioFileUrls, response.data.fileUrl] }));
        } else if (fileType === 'score') {
          setFormData(prev => ({ ...prev, scoreFileUrl: response.data.fileUrl }));
        }
      }
    } catch (error) {
      console.error('Upload failed:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  const updateUploadProgress = (progress: number, fileType: 'cv' | 'audio' | 'score') => {
    setUploadProgress(prev => ({ ...prev, [fileType]: progress }));
  };

  const validateForm = (formData: BanBamFormData) => {
    let formIsValid = true;
    let validationErrors: { [key: string]: string } = {};

    // Required Personal Information
    const requiredFields = {
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email Address',
      streetAddress1: 'Street Address',
      city: 'City',
      county: 'County',
      postalCode: 'Postal Code/Eircode',
      phonePrefix: 'Phone Prefix',
      phoneNumber: 'Phone Number',
      website1: 'Website/Social Media',
      compositionIdeas: 'Composition Ideas',
      performanceIdeas: 'Performance Ideas',
      careerHighlights: 'Career Highlights',
      cvFileUrl: 'CV Upload',
      scoreFileUrl: 'Score Upload',
    };

    // Check all required fields are filled
    Object.entries(requiredFields).forEach(([field, label]) => {
      if (!formData[field as keyof BanBamFormData]) {
        formIsValid = false;
        validationErrors[field] = `${label} is required`;
      }
    });

    // Email format validation
    if (formData.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      formIsValid = false;
      validationErrors.email = 'Please enter a valid email address';
    }

    // Music evidence validation
    const hasMediaLinks =
      formData.youtubeEmbeds.length > 0 ||
      formData.spotifyEmbeds.length > 0 ||
      formData.soundcloudEmbeds.length > 0 ||
      formData.bandcampEmbeds.length > 0;

    if (!hasMediaLinks && formData.audioFileUrls.length === 0) {
      formIsValid = false;
      validationErrors.mediaLinks = 'Please provide at least one media link or upload at least one audio file.';
      validationErrors.audioFileUrls = 'Please provide at least one media link or upload at least one audio file.';
    }

    // Confirmation checkbox
    if (!formData.confirmOriginalMaterial) {
      formIsValid = false;
      validationErrors.confirmOriginalMaterial = 'You must confirm that the materials are your own';
    }

    return {
      isValid: formIsValid,
      errors: validationErrors,
    };
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setErrorMessage('');
    setSuccessMessage('');

    // Run validation
    const { isValid, errors } = validateForm(formData);
    setErrors(errors);

    if (!isValid) {
      setLoading(false);
      return;
    }

    const apiUrl = process.env.REACT_APP_API_URL;
    const fullUrl = `${apiUrl}/api/banbam`;

    try {
      console.log('Sending request to:', fullUrl);

      // Create a new object with all form data, including file URLs
      const submissionData = {
        ...formData,
        cvUrl: formData.cvFileUrl, // Rename to match the backend model
        audioUrls: formData.audioFileUrls,
        scoreUrl: formData.scoreFileUrl, // Rename to match the backend model
      };

      console.log('Form data:', submissionData);

      const response = await axios.post(fullUrl, submissionData, {
        headers: {
          'Content-Type': 'application/json',
        },
        timeout: 120000, // 2 minutes
      });

      console.log('Response received:', response.data);
      setSuccessMessage('Application submitted successfully!');

      // Reset form data
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        streetAddress1: '',
        streetAddress2: '',
        city: '',
        county: IrishCounty.Dublin,
        postalCode: '',
        phonePrefix: '',
        phoneNumber: '',
        website1: '',
        website2: '',
        compositionIdeas: '',
        performanceIdeas: '',
        careerHighlights: '',
        equalityDiversityInclusion: '', // Reset field
        cvFileUrl: '',
        audioFileUrls: [],
        scoreFileUrl: '',
        confirmOriginalMaterial: false,
        youtubeEmbeds: [],
        spotifyEmbeds: [],
        soundcloudEmbeds: [],
        bandcampEmbeds: [],
        musicLink1: '', // Reset field
        musicLink2: '', // Reset field
      });
      setUploadProgress({ cv: 0, audio: 0, score: 0 });
      setErrors({});
    } catch (error: any) {
      console.error('Submission error:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        console.error('Error status:', error.response.status);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error setting up request:', error.message);
      }
      setErrorMessage(
        error.response?.data?.message || 'An error occurred while submitting your application.'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSuccessMessage('');
    setErrorMessage('');
  };

  const handlePrePopulate = (profileData: any) => {
    setFormData(prevData => ({
      ...prevData,
      firstName: profileData.firstName || '',
      lastName: profileData.lastName || '',
      email: profileData.email || '',
      streetAddress1: profileData.streetAddress1 || '',
      streetAddress2: profileData.streetAddress2 || '',
      city: profileData.city || '',
      county: profileData.county || IrishCounty.Dublin,
      postalCode: profileData.postalCode || '',
      phonePrefix: profileData.phonePrefix || '',
      phoneNumber: profileData.phoneNumber || '',
      website1: profileData.website || '',
      // ... map other relevant fields ...
    }));
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Typography variant="h4" gutterBottom>
          BAN BAM Commission & Development Award 2025
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Supporting women in jazz, improvised and experimental music
        </Typography>

        <PrePopulateCheckbox onPrePopulate={handlePrePopulate} />

        <Grid container spacing={3}>
          {/* Section 1: Personal Information */}
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              1 - Personal Information
            </Typography>
          </Grid>

          {/* First Name */}
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              name="firstName"
              label="First Name"
              value={formData.firstName}
              onChange={handleChange}
              error={!!errors.firstName}
              helperText={errors.firstName || ''}
            />
          </Grid>

          {/* Last Name */}
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              name="lastName"
              label="Last Name"
              value={formData.lastName}
              onChange={handleChange}
              error={!!errors.lastName}
              helperText={errors.lastName || ''}
            />
          </Grid>

          {/* Email */}
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              name="email"
              label="Email Address"
              type="email"
              value={formData.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email || ''}
            />
          </Grid>

          {/* Street Address 1 */}
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              name="streetAddress1"
              label="Street Address"
              value={formData.streetAddress1}
              onChange={handleChange}
              error={!!errors.streetAddress1}
              helperText={errors.streetAddress1 || ''}
            />
          </Grid>

          {/* Street Address 2 */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="streetAddress2"
              label="Street Address Line 2"
              value={formData.streetAddress2}
              onChange={handleChange}
            />
          </Grid>

          {/* City */}
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              name="city"
              label="City"
              value={formData.city}
              onChange={handleChange}
              error={!!errors.city}
              helperText={errors.city || ''}
            />
          </Grid>

          {/* County */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required error={!!errors.county}>
              <InputLabel>County</InputLabel>
              <Select
                name="county"
                value={formData.county}
                onChange={handleSelectChange}
                label="County"
              >
                {Object.values(IrishCounty).map((county) => (
                  <MenuItem key={county} value={county}>
                    {county}
                  </MenuItem>
                ))}
              </Select>
              {errors.county && <FormHelperText>{errors.county}</FormHelperText>}
            </FormControl>
          </Grid>

          {/* Postal Code */}
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              name="postalCode"
              label="Postal Code / Eircode"
              value={formData.postalCode}
              onChange={handleChange}
              error={!!errors.postalCode}
              helperText={errors.postalCode || ''}
            />
          </Grid>

          {/* Phone Prefix */}
          <Grid item xs={12} sm={4}>
            <TextField
              required
              fullWidth
              name="phonePrefix"
              label="Phone Prefix"
              value={formData.phonePrefix}
              onChange={handleChange}
              error={!!errors.phonePrefix}
              helperText={errors.phonePrefix || ''}
            />
          </Grid>

          {/* Phone Number */}
          <Grid item xs={12} sm={8}>
            <TextField
              required
              fullWidth
              name="phoneNumber"
              label="Phone Number"
              value={formData.phoneNumber}
              onChange={handleChange}
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber || ''}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              2 - Website / Social Media Links
            </Typography>
          </Grid>

          {/* Website 1 */}
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              name="website1"
              label="Website / Social Media"
              type="url"
              value={formData.website1}
              onChange={handleChange}
              error={!!errors.website1}
              helperText={
                errors.website1 ||
                'Please input a valid URL, i.e., Instagram, Facebook, etc.'
              }
            />
          </Grid>

          {/* Website 2 */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="website2"
              label="Website / Social Media (optional)"
              type="url"
              value={formData.website2}
              onChange={handleChange}
              helperText="Please input a valid URL, i.e., Instagram, Facebook, etc."
            />
          </Grid>

          {/* Section 2: Music Evidence */}
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              3 - Media Showcase (Pick any two)
            </Typography>
          </Grid>

          {/* Instruction */}
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              Please provide evidence of your music via link(s) or upload audio files. If you provide
              media links, you don't need to upload audio files.
            </Typography>
          </Grid>

          {/* Media Links Component */}
          <Grid item xs={12}>
            <BanBamMediaLinks formData={formData} setFormData={setFormData} />
            {errors.mediaLinks && (
              <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                {errors.mediaLinks}
              </Typography>
            )}
          </Grid>

          {/* Audio Files Upload */}
          <Grid item xs={12}>
  <FileUploadComponent
    onFileUploaded={handleFileUploadWrapper('audio')}
    onProgressUpdate={(progress) => updateUploadProgress(progress, 'audio')}
    fileType="audio"
    accept=".mp3,audio/mpeg"
    label="Upload Audio Files (MP3 format only - up to 2)"
    multiple
    onError={(message) => setFileError(message)}
  />
  {fileError && (
    <Typography variant="body2" color="error" sx={{ mt: 1 }}>
      {fileError}
    </Typography>
  )}
  {uploadProgress.audio > 0 && (
    <Box sx={{ width: '100%', mt: 2 }}>
      <LinearProgress variant="determinate" value={uploadProgress.audio} />
      <Typography variant="body2" color="text.secondary">
        {uploadProgress.audio}% Uploaded
      </Typography>
    </Box>
  )}
            {formData.audioFileUrls.length > 0 && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                Audio Files Uploaded:
                <ul>
                  {formData.audioFileUrls.map((_, index) => (
                    <li key={index}>Audio File {index + 1} Uploaded Successfully</li>
                  ))}
                </ul>
              </Typography>
            )}
            {errors.audioFileUrls && (
              <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                {errors.audioFileUrls}
              </Typography>
            )}
          </Grid>

          {/* Section 3: Main Content Questions / Rationale */}
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              4 - Main Content / Your Rationale
            </Typography>
          </Grid>

          {/* Composition Ideas */}
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              multiline
              rows={4}
              name="compositionIdeas"
              label="Outline your ideas for your composition and its performance (max 200 words)"
              value={formData.compositionIdeas}
              onChange={handleChange}
              error={!!errors.compositionIdeas}
              helperText={
                errors.compositionIdeas ||
                'This can be a broad sense of a structure or theme, including instrumentation. We are looking for a strong compositional idea and a clear vision in bringing that to fruition.'
              }
            />
          </Grid>

          {/* Performance Ideas */}
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              multiline
              rows={4}
              name="performanceIdeas"
              label="Tell us about yourself and your artistic practice (max 200 words)"
              value={formData.performanceIdeas}
              onChange={handleChange}
              error={!!errors.performanceIdeas}
              helperText={
                errors.performanceIdeas ||
                'This can include education, creative development, career highlights, and relevant achievements/activities to date.'
              }
            />
          </Grid>

          {/* Career Highlights */}
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              multiline
              rows={4}
              name="careerHighlights"
              label="Please outline future ambitions for yourself and your music (max 200 words)"
              value={formData.careerHighlights}
              onChange={handleChange}
              error={!!errors.careerHighlights}
              helperText={
                errors.careerHighlights ||
                'How being a recipient of the BAN BAM award might support you in your ambitions with this particular work, and beyond.'
              }
            />
          </Grid>

          {/* Equality, Diversity, and Inclusion */}
          <Grid item xs={12}>
            <Typography variant="body1">
              IMC is committed to our opportunities being accessible and inclusive to all. You have the
              option here to outline any caring duties and/or access, learning, or health issues below
              to assist us in our work with you. (50 words).
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={2}
              name="equalityDiversityInclusion"
              label="Equality, Diversity, and Inclusion (optional)"
              value={formData.equalityDiversityInclusion}
              onChange={handleChange}
              helperText="Maximum 50 words."
            />
          </Grid>

          {/* Section 4: Uploads (CV and Score) */}
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              5 - Essential Uploads
            </Typography>
          </Grid>

          {/* CV Upload */}
          <Grid item xs={12}>
            <FileUploadComponent
              onFileUploaded={handleFileUploadWrapper('cv')}
              onProgressUpdate={(progress) => updateUploadProgress(progress, 'cv')}
              fileType="cv"
              accept=".pdf"
              label="Upload CV (PDF) *"
            />
            {uploadProgress.cv > 0 && (
              <Box sx={{ width: '100%', mt: 2 }}>
                <LinearProgress variant="determinate" value={uploadProgress.cv} />
                <Typography variant="body2" color="text.secondary">
                  {uploadProgress.cv}% Uploaded
                </Typography>
              </Box>
            )}
            {formData.cvFileUrl && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                Your CV Has Been Uploaded Successfully!
              </Typography>
            )}
            {errors.cvFileUrl && (
              <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                {errors.cvFileUrl}
              </Typography>
            )}
          </Grid>

          {/* Score File Upload */}
          <Grid item xs={12}>
            <FileUploadComponent
              onFileUploaded={handleFileUploadWrapper('score')}
              onProgressUpdate={(progress) => updateUploadProgress(progress, 'score')}
              fileType="score"
              accept=".pdf"
              label="Upload Score (PDF) *"
            />
            {uploadProgress.score > 0 && (
              <Box sx={{ width: '100%', mt: 2 }}>
                <LinearProgress variant="determinate" value={uploadProgress.score} />
                <Typography variant="body2" color="text.secondary">
                  {uploadProgress.score}% Uploaded
                </Typography>
              </Box>
            )}
            {formData.scoreFileUrl && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                Your Score Has Been Uploaded Successfully!
              </Typography>
            )}
            {errors.scoreFileUrl && (
              <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                {errors.scoreFileUrl}
              </Typography>
            )}
          </Grid>

          {/* Section 5: Confirmation of Originality */}
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              6 - Confirmation
            </Typography>
          </Grid>

          {/* Confirm Original Material */}
          <Grid item xs={12}>
            <FormControl required error={!!errors.confirmOriginalMaterial} component="fieldset">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.confirmOriginalMaterial}
                    onChange={handleCheckboxChange}
                    name="confirmOriginalMaterial"
                    color="primary"
                  />
                }
                label="I confirm and verify that the original materials submitted above are composed / predominantly composed by me.*"
              />
              {errors.confirmOriginalMaterial && (
                <FormHelperText>{errors.confirmOriginalMaterial}</FormHelperText>
              )}
            </FormControl>
          </Grid>

          {/* Submit Button */}
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
              sx={{ mt: 2 }}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Submit Application'}
            </Button>
          </Grid>
        </Grid>
      </form>

      {/* Success Snackbar */}
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>

      {/* Error Snackbar */}
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default BanBamForm;