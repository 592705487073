import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Button } from '@mui/material';

const DomainUserNav: React.FC = () => {
  return (
    <AppBar position="static">
      <Toolbar>
        <Button color="inherit" component={Link} to="/bands">Bands</Button>
        <Button color="inherit" component={Link} to="/events">Events</Button>
        <Button color="inherit" component={Link} to="/venues">Venues</Button>
        <Button color="inherit" component={Link} to="/calendar">Calendar</Button>
      </Toolbar>
    </AppBar>
  );
};

export default DomainUserNav;