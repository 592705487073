import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Typography,
  TextField,
  Grid,
  Paper,
  FormControlLabel,
  Switch,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import { PlayArrow, Pause, Stop } from '@mui/icons-material';
import { saveToStorage, loadFromStorage } from './storageUtils';

interface PomodoroLog {
  type: 'work' | 'break';
  startTime: string;
  endTime: string;
}

const PomodoroTimer: React.FC = () => {
  const [workDuration, setWorkDuration] = useState<number>(25);
  const [breakDuration, setBreakDuration] = useState<number>(5);
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [isWorkSession, setIsWorkSession] = useState<boolean>(true);
  const [timeLeft, setTimeLeft] = useState<number>(25 * 60);
  const [soundEnabled, setSoundEnabled] = useState<boolean>(true);
  const [audioInitialized, setAudioInitialized] = useState<boolean>(false);
  const [pomodoroLogs, setPomodoroLogs] = useState<PomodoroLog[]>([]);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const sessionStartTimeRef = useRef<Date | null>(null);

  useEffect(() => {
    const savedState = loadFromStorage('pomodoroState');
    if (savedState) {
      setWorkDuration(savedState.workDuration);
      setBreakDuration(savedState.breakDuration);
      setIsWorkSession(savedState.isWorkSession);
      setTimeLeft(savedState.timeLeft);
      setSoundEnabled(savedState.soundEnabled);
      setPomodoroLogs(savedState.pomodoroLogs);
    }
  }, []);

  useEffect(() => {
    saveToStorage('pomodoroState', {
      workDuration,
      breakDuration,
      isWorkSession,
      timeLeft,
      soundEnabled,
      pomodoroLogs
    });
  }, [workDuration, breakDuration, isWorkSession, timeLeft, soundEnabled, pomodoroLogs]);

  useEffect(() => {
    if (isRunning) {
      timerRef.current = setInterval(() => {
        setTimeLeft(prev => {
          if (prev <= 1) {
            if (soundEnabled && audioRef.current && audioInitialized) {
              audioRef.current.play().catch(error => {
                console.error("Audio playback failed:", error);
              });
            }
            logPomodoro();
            setIsWorkSession(prevSession => !prevSession);
            return isWorkSession ? breakDuration * 60 : workDuration * 60;
          }
          return prev - 1;
        });
      }, 1000);
    } else if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
    };
  }, [isRunning, isWorkSession, breakDuration, workDuration, soundEnabled, audioInitialized]);

  const formatTime = (seconds: number) => {
    const m = Math.floor(seconds / 60).toString().padStart(2, '0');
    const s = (seconds % 60).toString().padStart(2, '0');
    return `${m}:${s}`;
  };

  const handleStartPause = () => {
    if (!isRunning) {
      if (soundEnabled && !audioInitialized) {
        initializeAudio();
      }
      sessionStartTimeRef.current = new Date();
    } else {
      logPomodoro();
    }
    setIsRunning(!isRunning);
  };

  const handleReset = () => {
    if (isRunning) {
      logPomodoro();
    }
    setIsRunning(false);
    setIsWorkSession(true);
    setTimeLeft(workDuration * 60);
    sessionStartTimeRef.current = null;
  };

  const handleWorkDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    if (!isNaN(value) && value > 0) {
      setWorkDuration(value);
      if (isWorkSession) setTimeLeft(value * 60);
    }
  };

  const handleBreakDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    if (!isNaN(value) && value > 0) {
      setBreakDuration(value);
      if (!isWorkSession) setTimeLeft(value * 60);
    }
  };

  const handleSoundToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSoundEnabled = e.target.checked;
    setSoundEnabled(newSoundEnabled);
    if (newSoundEnabled && !audioInitialized) {
      initializeAudio();
    }
  };

  const initializeAudio = () => {
    if (audioRef.current) {
      audioRef.current.play().then(() => {
        audioRef.current!.pause();
        audioRef.current!.currentTime = 0;
        setAudioInitialized(true);
      }).catch(error => {
        console.error("Audio initialization failed:", error);
      });
    }
  };

  const logPomodoro = () => {
    if (sessionStartTimeRef.current) {
      const newLog: PomodoroLog = {
        type: isWorkSession ? 'work' : 'break',
        startTime: sessionStartTimeRef.current.toISOString(),
        endTime: new Date().toISOString()
      };
      setPomodoroLogs(prevLogs => [...prevLogs, newLog]);
      sessionStartTimeRef.current = null;
    }
  };

  return (
    <Paper elevation={3} sx={{ padding: 4, width: '100%', maxWidth: 600 }}>
      <audio ref={audioRef} preload="auto">
        <source src="/alert.mp3" type="audio/mpeg" />
        <source src="/alert.ogg" type="audio/ogg" />
        Your browser does not support the audio element.
      </audio>

      <Typography variant="h4" align="center" gutterBottom>
        {isWorkSession ? 'Work Session' : 'Break Session'}
      </Typography>
      <Typography variant="h1" align="center" gutterBottom>
        {formatTime(timeLeft)}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mb: 2 }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={isRunning ? <Pause /> : <PlayArrow />}
          onClick={handleStartPause}
        >
          {isRunning ? 'Pause' : 'Start'}
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          size="large"
          startIcon={<Stop />}
          onClick={handleReset}
        >
          Reset
        </Button>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label="Work (minutes)"
            type="number"
            value={workDuration}
            onChange={handleWorkDurationChange}
            fullWidth
            inputProps={{ min: 1 }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Break (minutes)"
            type="number"
            value={breakDuration}
            onChange={handleBreakDurationChange}
            fullWidth
            inputProps={{ min: 1 }}
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: 2 }}>
        <FormControlLabel
          control={
            <Switch
              checked={soundEnabled}
              onChange={handleSoundToggle}
              color="primary"
            />
          }
          label="Sound Alerts"
        />
      </Box>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" gutterBottom>
          Pomodoro Log
        </Typography>
        <List>
          {pomodoroLogs.map((log, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={`${log.type.charAt(0).toUpperCase() + log.type.slice(1)} Session`}
                secondary={`${new Date(log.startTime).toLocaleTimeString()} - ${new Date(log.endTime).toLocaleTimeString()}`}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Paper>
  );
};

export default PomodoroTimer;