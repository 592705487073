// src/components/TimelineAccordion.tsx

import React from 'react';
import { 
  Accordion, 
  AccordionSummary, 
  AccordionDetails, 
  Typography, 
  Link 
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { 
  Timeline, 
  TimelineItem, 
  TimelineSeparator, 
  TimelineConnector, 
  TimelineContent, 
  TimelineDot 
} from '@mui/lab';
import EventIcon from '@mui/icons-material/Event';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import { Link as RouterLink } from 'react-router-dom';

// Define the shape of each timeline event
export interface TimelineEvent {
  title: string;
  date: string;
  color?: 'primary' | 'secondary' | 'grey';
  icon?: React.ReactElement;
}

// Define the props for the TimelineAccordion component
interface TimelineAccordionProps {
  title: string;
  introText?: string;
  events: TimelineEvent[];
  additionalNotes?: React.ReactNode;
  contactInfo?: {
    emailLink: string;
    emailText: string;
  };
  applicationLink?: {
    to: string;
    text: string;
  };
}

const TimelineAccordion: React.FC<TimelineAccordionProps> = ({
  title,
  introText,
  events,
  additionalNotes,
  contactInfo,
  applicationLink
}) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {introText && (
          <Typography paragraph>
            {introText}
          </Typography>
        )}

        <Timeline 
          position="alternate" 
          sx={{
            display: 'flex',
            flexDirection: 'row', // Arrange items in a row
            flexWrap: 'nowrap',
            overflowX: 'auto', // Enable horizontal scrolling if necessary
            padding: '0 4px', // Optional: Add horizontal padding
            '& .MuiTimelineItem-root': { 
              minWidth: '100px', 
              flex: '0 0 auto', // Prevent items from shrinking
            },
'& .MuiTimelineSeparator-root': { 
  display: "flex", 
  flexDirection: "column", 
  alignItems: "center",
  margin: '0 4px', // Adjust as needed
},
            '& .MuiTimelineConnector-root': { 
              flexGrow: 0.3, // Adjust the length of the connector 
            },
          }}
        >
{events.map((event, index) => (
  <TimelineItem key={index}>
    <TimelineSeparator>
      <TimelineDot color={event.color || 'grey'}>
        {event.icon || <EventIcon />}
      </TimelineDot>
      {index < events.length - 1 && <TimelineConnector />}
    </TimelineSeparator>
    <TimelineContent>
    <Typography variant="subtitle2" component="div">
  {event.title}
</Typography>
<Typography variant="caption" color="textSecondary" component="div">
  {event.date}
</Typography>
    </TimelineContent>
  </TimelineItem>
))}
        </Timeline>

        {additionalNotes && (
          <Typography paragraph sx={{ mt: 2 }}>
            {additionalNotes}
          </Typography>
        )}

        {contactInfo && (
          <Typography paragraph>
            With any queries relating to this opportunity, please contact us at{' '}
            <Link href={contactInfo.emailLink}>{contactInfo.emailText}</Link>
          </Typography>
        )}

        {applicationLink && (
          <Typography paragraph>
            Apply for the BAN BAM Award{' '}
            <Link component={RouterLink} to={applicationLink.to} underline="hover">
              {applicationLink.text}
            </Link>.
          </Typography>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default TimelineAccordion;