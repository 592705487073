import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography
} from '@mui/material';

interface Event {
  _id: string;
  name: string;
  date: string;
}

interface AddToEventDialogProps {
  open: boolean;
  onClose: () => void;
  bandName: string;
  events: Event[];
  selectedEvent: string;
  onEventSelect: (eventId: string) => void;
  onCreateNew: () => void;
  onConfirm: () => void;
}

const AddToEventDialog: React.FC<AddToEventDialogProps> = ({
  open,
  onClose,
  bandName,
  events,
  selectedEvent,
  onEventSelect,
  onCreateNew,
  onConfirm
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add {bandName} to Event</DialogTitle>
      <DialogContent>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>Select Event</InputLabel>
          <Select
            value={selectedEvent}
            onChange={(e) => onEventSelect(e.target.value as string)}
          >
            {events.map((event) => (
              <MenuItem key={event._id} value={event._id}>
                {event.name} - {new Date(event.date).toLocaleDateString()}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="outlined"
          color="primary"
          onClick={onCreateNew}
          fullWidth
          sx={{ mt: 2 }}
        >
          Create New Event
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onConfirm} variant="contained" color="primary">
          Add to Event
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddToEventDialog;