import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  Box,
  Typography,
  Chip,
  Button
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TransformIcon from '@mui/icons-material/Transform';

interface Artist {
  _id: string;
  artistName: string;
}

interface Venue {
  _id: string;
  name: string;
}

interface Prospect {
  _id?: string;
  strandId: string;
  date: Date | null;
  eventConcept: string;
  potentialArtists: Array<string | Artist>;
  venueIdeas: string[];
  fees: number;
  boxOffice: number;
  expenses: number;
  sponsorship: number;
  net: number;
  notes: string;
  status: 'idea' | 'negotiation' | 'prospective' | 'confirmed';
}

interface ProspectListProps {
  prospects: Prospect[];
  venues: Venue[];
  onEdit: (prospect: Prospect) => void;
  onDelete: (id: string) => void;
  onConvert: (prospect: Prospect) => void;
}

const formatDate = (date: Date | null | string): string => {
  if (!date) return 'No date set';
  const dateObj = typeof date === 'string' ? new Date(date) : date;
  return dateObj.toLocaleDateString();
};

const ProspectList: React.FC<ProspectListProps> = ({
  prospects,
  venues,
  onEdit,
  onDelete,
  onConvert
}) => {
  return (
    <List>
      {prospects.map((prospect) => (
        <ListItem
          key={prospect._id}
          sx={{
            mb: 2,
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 1,
            '&:hover': {
              bgcolor: 'action.hover',
            }
          }}
          secondaryAction={
            <Box sx={{ display: 'flex', gap: 1 }}>
              {prospect.status === 'confirmed' && (
                <Button
                  startIcon={<TransformIcon />}
                  onClick={() => onConvert(prospect)}
                  color="primary"
                  variant="outlined"
                  size="small"
                >
                  Create Performance
                </Button>
              )}
              <IconButton 
                onClick={() => onEdit(prospect)}
                color="primary"
              >
                <EditIcon />
              </IconButton>
              <IconButton 
                onClick={() => prospect._id && onDelete(prospect._id)}
                color="error"
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          }
        >
          <ListItemText
            primary={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Typography variant="subtitle1">
                  {prospect.eventConcept}
                </Typography>
                <Chip
                  label={prospect.status}
                  size="small"
                  color={
                    prospect.status === 'confirmed' ? 'success' :
                    prospect.status === 'negotiation' ? 'warning' :
                    prospect.status === 'prospective' ? 'info' :
                    'default'
                  }
                />
              </Box>
            }
            secondary={
              <Box sx={{ mt: 1 }}>
                <Typography variant="body2" color="textSecondary">
                  Date: {formatDate(prospect.date)}
                </Typography>
                {prospect.potentialArtists.length > 0 && (
                  <Box sx={{ mt: 0.5 }}>
                    <Typography variant="body2" component="span">
                      Artists:{' '}
                    </Typography>
                    {prospect.potentialArtists.map((artist, index) => (
                      <Chip
                        key={index}
                        label={typeof artist === 'string' ? artist : artist.artistName}
                        size="small"
                        variant="outlined"
                        sx={{ mr: 0.5, mb: 0.5 }}
                      />
                    ))}
                  </Box>
                )}
                {prospect.venueIdeas.length > 0 && (
                  <Box sx={{ mt: 0.5 }}>
                    <Typography variant="body2" component="span">
                      Venues:{' '}
                    </Typography>
                    {venues
                      .filter(venue => prospect.venueIdeas.includes(venue._id))
                      .map(venue => (
                        <Chip
                          key={venue._id}
                          label={venue.name}
                          size="small"
                          variant="outlined"
                          sx={{ mr: 0.5, mb: 0.5 }}
                        />
                      ))
                    }
                  </Box>
                )}
                <Box sx={{ mt: 0.5, display: 'flex', gap: 2 }}>
                  <Typography variant="body2">
                    Fees: €{prospect.fees?.toLocaleString()}
                  </Typography>
                  <Typography variant="body2">
                    Box Office: €{prospect.boxOffice?.toLocaleString()}
                  </Typography>
                  <Typography variant="body2">
                    Net: €{prospect.net?.toLocaleString()}
                  </Typography>
                </Box>
                {prospect.notes && (
                  <Typography 
                    variant="body2" 
                    color="textSecondary"
                    sx={{ mt: 0.5, fontStyle: 'italic' }}
                  >
                    Notes: {prospect.notes}
                  </Typography>
                )}
              </Box>
            }
          />
        </ListItem>
      ))}
    </List>
  );
};

export default ProspectList;