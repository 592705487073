import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

// Define allowed roles
type Role = 'admin' | 'domain_user' | 'artist' | 'guest';

interface WithAuthProps {
  allowedRoles: Role[];
}

const withAuth = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  { allowedRoles }: WithAuthProps
): React.FunctionComponent<P> => {
  const WithAuthComponent: React.FunctionComponent<P> = (props) => {
    const { user, isAuthenticated } = useAuth();


    if (!isAuthenticated) {
      return <Navigate to="/login" replace />;
    }

    if (user && allowedRoles.includes(user.role as Role)) {
      return <WrappedComponent {...props} />;
    }

    // If the user is authenticated but doesn't have the required role
    return <Navigate to="/unauthorized" replace />;
  };

  return WithAuthComponent;
};

export default withAuth;