import React, { useState, useEffect } from 'react';
import { 
  TextField, 
  Button, 
  Typography, 
  Container, 
  Box, 
  Checkbox, 
  FormControlLabel, 
  Alert,
  CircularProgress
} from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';

interface FormData {
  isArtist: boolean;
  privacyConsent: boolean;
}

interface FormErrors {
  isArtist?: string;
  privacyConsent?: string;
  server?: string;
}

const ArtistSignupPage: React.FC = () => {
  const { user, loading: authLoading } = useAuth();
  const navigate = useNavigate();
  
  const [formData, setFormData] = useState<FormData>({
    isArtist: false,
    privacyConsent: false,
  });

  const [errors, setErrors] = useState<FormErrors>({});
  const [success, setSuccess] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // Redirect if user is already registered as an artist
  useEffect(() => {
    if (user?.isArtist) {
      navigate('/artist/dashboard');
    }
  }, [user, navigate]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: checked
    }));
  };

  const validateForm = (): boolean => {
    let tempErrors: FormErrors = {};
    tempErrors.isArtist = formData.isArtist ? "" : "You must confirm that you are an artist";
    tempErrors.privacyConsent = formData.privacyConsent ? "" : "You must accept the privacy policy";
    
    setErrors(tempErrors);
    return Object.values(tempErrors).every(x => x === "");
  };

  const handleGoogleSignIn = async () => {
    try {
      // Implement your Google sign-in logic here using your existing auth setup
      // This might involve redirecting to your Google auth endpoint
      window.location.href = `${process.env.REACT_APP_API_URL}/api/auth/google`;
    } catch (error) {
      console.error('Google sign-in failed:', error);
      setErrors(prev => ({ 
        ...prev, 
        server: 'Failed to sign in with Google. Please try again.' 
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSuccess('');
    
    if (!user?.email) {
      setErrors(prev => ({ 
        ...prev, 
        server: 'Please sign in with Google first' 
      }));
      return;
    }

    if (validateForm()) {
      setIsSubmitting(true);
      
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/artists/register`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: user.email,
            name: user.name,
            ...formData,
            privacyConsentDate: new Date(),
          }),
        });

        const data = await response.json();

        if (response.ok) {
          setSuccess('Registration successful! Redirecting to your dashboard...');
          setTimeout(() => {
            navigate('/artist/dashboard');
          }, 2000);
        } else {
          setErrors(prev => ({ 
            ...prev, 
            server: data.message || 'Registration failed' 
          }));
        }
      } catch (error) {
        console.error('Error during registration:', error);
        setErrors(prev => ({ 
          ...prev, 
          server: 'An unexpected error occurred.' 
        }));
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  if (authLoading) {
    return (
      <Container maxWidth="sm">
        <Box sx={{ 
          mt: 8, 
          display: 'flex', 
          justifyContent: 'center' 
        }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  // Initial view - Google Sign In
  if (!user) {
    return (
      <Container maxWidth="sm">
        <Box sx={{ 
          mt: 8, 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          textAlign: 'center'
        }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Sign up as an Artist
          </Typography>
          
          <Typography variant="h6" gutterBottom>
            It's free!
          </Typography>

          <Typography variant="body1" sx={{ mb: 4 }}>
            Create your profile, add your bands, and find opportunities with IMC
          </Typography>

          {errors.server && (
            <Alert severity="error" sx={{ mb: 2, width: '100%' }}>
              {errors.server}
            </Alert>
          )}

          <Button
            variant="contained"
            size="large"
            onClick={handleGoogleSignIn}
            sx={{ 
              py: 1.5,
              px: 4,
              fontSize: '1.1rem'
            }}
          >
            Continue with Google
          </Button>

          <Typography variant="body2" sx={{ mt: 4 }}>
            Already have an account?{' '}
            <MuiLink component={RouterLink} to="/login">
              Sign in
            </MuiLink>
          </Typography>
        </Box>
      </Container>
    );
  }

  // Second view - Additional Info
  return (
    <Container maxWidth="sm">
      <Box 
        sx={{ 
          mt: 8, 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center' 
        }}
      >
        <Typography variant="h5" component="h1" gutterBottom>
          Welcome, {user.name}!
        </Typography>

        <Typography variant="body1" gutterBottom>
          Just a couple more things before we get started...
        </Typography>

        <Box 
          component="form" 
          onSubmit={handleSubmit} 
          sx={{ mt: 3, width: '100%' }}
        >
          {success && (
            <Alert severity="success" sx={{ mb: 2 }}>
              {success}
            </Alert>
          )}
          
          {errors.server && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {errors.server}
            </Alert>
          )}

          <FormControlLabel
            control={
              <Checkbox
                checked={formData.isArtist}
                onChange={handleChange}
                name="isArtist"
                color="primary"
              />
            }
            label="I confirm that I am an artist"
          />
          {errors.isArtist && (
            <Typography 
              color="error" 
              variant="caption" 
              display="block" 
              sx={{ mt: 1 }}
            >
              {errors.isArtist}
            </Typography>
          )}

          <Box sx={{ mt: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.privacyConsent}
                  onChange={handleChange}
                  name="privacyConsent"
                  color="primary"
                  required
                />
              }
              label={
                <Typography variant="body2">
                  I accept the{' '}
                  <MuiLink 
                    component={RouterLink} 
                    to="/privacy-policy" 
                    target="_blank"
                  >
                    privacy policy
                  </MuiLink>
                  {' '}and consent to the collection and processing of my information
                </Typography>
              }
            />
            {errors.privacyConsent && (
              <Typography 
                color="error" 
                variant="caption" 
                display="block" 
                sx={{ mt: 1 }}
              >
                {errors.privacyConsent}
              </Typography>
            )}
          </Box>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Completing Registration...' : 'Complete Registration'}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ArtistSignupPage;