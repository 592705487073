// GuidelineEditor.tsx
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  Snackbar,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Divider,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { debounce } from 'lodash';
import axiosInstance from '../../utils/axiosConfig';
import { useAuth } from '../../contexts/AuthContext';
import { SelectChangeEvent } from '@mui/material';


// Import your editor components
import AccordionEditor from './AccordionEditor';
import TimelineEditor from './TimelineEditor';
import ContactInfoEditor from './ContactInfoEditor';

// Type Definitions
interface ContentSection {
  id: string;
  title: string;
  content: string;
}

interface TimelineEvent {
  id: string;
  title: string;
  date: string;
  color: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  icon: string;
}

interface ContactInfo {
  id: string;
  type: string;
  value: string;
  link?: string;
}

interface GuidelineVersion {
  title: string;
  subtitle?: string;
  contentSections: ContentSection[];
  timeline: TimelineEvent[];
  contactInfo: ContactInfo[];
  createdAt?: Date;
  createdBy?: string;
}

interface Guideline {
  _id?: string;
  strandId: string;
  strandName?: string;
  strandSlug?: string;
  currentVersion: number;
  versions: GuidelineVersion[];
  isPublished: boolean;
  publishedVersion?: number;
  publishedAt?: Date;
  applicationLink?: string;
  deadline?: Date;
  createdAt?: Date;
  updatedAt?: Date;
}

interface Strand {
  _id: string;
  name: string;
  slug: string; // Add this line
}


const GuidelineEditor: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const isNewGuideline = !id;

  

  // Initialize with a default empty guideline structure
  const initialGuideline: Guideline = {
    strandId: '',
    currentVersion: 1,
    versions: [
      {
        title: '',
        subtitle: '',
        contentSections: [],
        timeline: [],
        contactInfo: [],
      },
    ],
    isPublished: false,
  };
  const [guideline, setGuideline] = useState<Guideline>(initialGuideline);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error',
  }); 

  // Utility function to generate unique IDs
  const generateUniqueId = () =>
    Date.now().toString() + Math.random().toString(36).substr(2, 9);

  // API Functions
  const fetchGuideline = async (id: string): Promise<Guideline> => {
    try {
      const response = await axiosInstance.get(`/api/guidelines/${id}`);
      const data: Guideline = response.data;

      // Add IDs to any sections that don't have them
      data.versions = data.versions.map((version: GuidelineVersion) => ({
        ...version,
        contentSections: version.contentSections.map((section) => ({
          ...section,
          id: section.id || generateUniqueId(),
        })),
        timeline: version.timeline.map((event) => ({
          ...event,
          id: event.id || generateUniqueId(),
        })),
        contactInfo: version.contactInfo.map((info) => ({
          ...info,
          id: info.id || generateUniqueId(),
        })),
      }));

      return data;
    } catch (error) {
      console.error('Error fetching guideline:', error);
      throw error;
    }
  };

  const fetchStrands = async (): Promise<Strand[]> => {
    const response = await axiosInstance.get('/api/strands');
    return response.data; // Ensure this data includes `slug`
  };

 // Queries
 const { data: fetchedGuideline, isLoading: isGuidelineLoading } = useQuery(
  ['guideline', id],
  () => fetchGuideline(id as string),
  {
    enabled: !!id, // Only run this query if we have an ID (editing mode)
    onSuccess: (data) => {
      if (data) {
        setGuideline(data);
      }
    },
  }
);

const { data: strands, isLoading: isStrandsLoading } = useQuery<Strand[]>(
  'strands',
  fetchStrands
);

const strandSlug = guideline.strandSlug || guideline.strandName?.toLowerCase().replace(/\s+/g, '-') || '';



  // Effect to initialize the guideline state
  useEffect(() => {
    if (isNewGuideline) {
      setGuideline(initialGuideline);
    }
  }, [isNewGuideline]);

  // Update mutation
  const updateGuideline = async (guidelineData: Guideline): Promise<Guideline> => {
    // Log the incoming data for debugging
    console.log('Raw guideline data:', guidelineData);

    // Check for required user context
    if (!user?._id) {
      throw new Error('User authentication required');
    }

    // Ensure we have a strandId
    if (!guidelineData.strandId) {
      throw new Error('Strand ID is required');
    }
    // Get the current version data
    const currentVersionIndex = guidelineData.currentVersion - 1;
    const currentVersion = guidelineData.versions[currentVersionIndex];

    // Validate title existence
    if (!currentVersion?.title) {
      throw new Error('Title is required for the guideline');
    }

    // Create a properly structured version object with all required fields
    const versionData = {
      title: currentVersion.title,
      subtitle: currentVersion.subtitle || '',
      contentSections: currentVersion.contentSections || [],
      timeline: currentVersion.timeline || [],
      contactInfo: currentVersion.contactInfo || [],
      createdBy: user._id, // Now we know user._id exists
      createdAt: new Date(),
    };

    console.log('Structured version data:', versionData);

    // Create the complete guideline object
    const completeGuideline = {
      strandId: guidelineData.strandId,
      currentVersion: guidelineData.currentVersion || 1,
      versions: [versionData],
      isPublished: guidelineData.isPublished || false,
      publishedVersion: guidelineData.publishedVersion,
      publishedAt: guidelineData.publishedAt,
      applicationLink: guidelineData.applicationLink || '',
      deadline: guidelineData.deadline,
      // Include any metadata if it exists
      ...(guidelineData._id && { _id: guidelineData._id }),
      ...(guidelineData.createdAt && { createdAt: guidelineData.createdAt }),
      updatedAt: new Date(),
    };

    // Log the final structure being sent
    console.log(
      'Complete guideline data being sent:',
      JSON.stringify(completeGuideline, null, 2)
    );

    try {
      // Make the API request
      const response = await axiosInstance({
        method: id ? 'PUT' : 'POST',
        url: `/api/guidelines/${id || ''}`,
        data: completeGuideline,
      });

      console.log('Server response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Request error:', error);
      throw error; // Re-throw to be handled by the mutation error handler
    }
  };

  const updateMutation = useMutation(updateGuideline, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['guideline', id]);
      setSnackbar({
        open: true,
        message: 'Guideline saved successfully',
        severity: 'success',
      });
      if (!id) {
        navigate(`/guidelines/${data._id}/edit`, { replace: true });
      }
    },
    onError: (error: any) => {
      console.error('Error updating guideline:', error);
      console.error('Error response:', error.response?.data);

      // More descriptive error message based on the type of error
      const errorMessage =
        error.response?.data?.message ||
        (error instanceof Error ? error.message : 'An unknown error occurred');

      setSnackbar({
        open: true,
        message: `Failed to save guideline: ${errorMessage}`,
        severity: 'error',
      });
    },
  });

  // Handlers
  
  const handleStrandChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setGuideline((prev) => (prev ? { ...prev, strandId: value } : prev));
  };

  const handleChange = (field: keyof Guideline) => (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setGuideline((prev) => (prev ? { ...prev, [field]: event.target.value } : prev));
  };

  const handleDateChange = (newDate: Date | null) => {
    if (newDate) {
      setGuideline((prev) => (prev ? { ...prev, deadline: newDate } : prev));
    }
  };

  const handleVersionChange = (field: keyof GuidelineVersion) => (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (guideline) {
      const updatedVersions = [...guideline.versions];
      updatedVersions[guideline.currentVersion - 1] = {
        ...updatedVersions[guideline.currentVersion - 1],
        [field]: event.target.value,
      };

      setGuideline({
        ...guideline,
        versions: updatedVersions,
      });
    }
  };

  const handleContentSectionChange = (newSections: ContentSection[]) => {
    if (guideline) {
      setGuideline((prevGuideline) => {
        if (!prevGuideline) return prevGuideline;

        const updatedVersions = [...prevGuideline.versions];
        updatedVersions[prevGuideline.currentVersion - 1] = {
          ...updatedVersions[prevGuideline.currentVersion - 1],
          contentSections: newSections,
        };

        return {
          ...prevGuideline,
          versions: updatedVersions,
        };
      });
    }
  };

  const handleTimelineChange = (newTimeline: TimelineEvent[]) => {
    if (guideline) {
      setGuideline((prevGuideline) => {
        if (!prevGuideline) return prevGuideline;

        const updatedVersions = [...prevGuideline.versions];
        updatedVersions[prevGuideline.currentVersion - 1] = {
          ...updatedVersions[prevGuideline.currentVersion - 1],
          timeline: newTimeline,
        };

        return {
          ...prevGuideline,
          versions: updatedVersions,
        };
      });
    }
  };

  const handleContactInfoChange = (newContactInfo: ContactInfo[]) => {
    if (guideline) {
      setGuideline((prevGuideline) => {
        if (!prevGuideline) return prevGuideline;

        const updatedVersions = [...prevGuideline.versions];
        updatedVersions[prevGuideline.currentVersion - 1] = {
          ...updatedVersions[prevGuideline.currentVersion - 1],
          contactInfo: newContactInfo,
        };

        return {
          ...prevGuideline,
          versions: updatedVersions,
        };
      });
    }
  };

  // Save the guideline when the user clicks "Save"
  const handleSave = () => {
    if (guideline) {
      updateMutation.mutate(guideline);
    }
  };
// Updated loading condition
if (isGuidelineLoading || isStrandsLoading || !guideline) {
  return <Typography>Loading...</Typography>;
}

  const currentVersion = guideline.versions[guideline.currentVersion - 1];

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container maxWidth="lg">
        <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
          <Box mb={4}>
            <Typography variant="h4" gutterBottom>
              {id ? 'Edit Guideline' : 'Create New Guideline'}
            </Typography>
          </Box>

          <FormControl fullWidth margin="normal" required>
  <InputLabel id="strand-select-label">Strand</InputLabel>
  <Select
    labelId="strand-select-label"
    value={guideline.strandId?.toString() || ''}
    onChange={handleStrandChange}
    label="Strand"
  >
    {strands?.map((strand) => (
      <MenuItem key={strand._id} value={strand._id.toString()}>
        {strand.name}
      </MenuItem>
    ))}
  </Select>
</FormControl>

          <TextField
            label="Title"
            value={currentVersion.title}
            onChange={handleVersionChange('title')}
            fullWidth
            margin="normal"
            required
          />

          <TextField
            label="Subtitle"
            value={currentVersion.subtitle}
            onChange={handleVersionChange('subtitle')}
            fullWidth
            margin="normal"
          />

          <Box mb={4}>
            <Typography variant="h6" gutterBottom>
              Application Details
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <TextField
              label="Application Link"
              value={guideline.applicationLink || ''}
              onChange={handleChange('applicationLink')}
              fullWidth
              margin="normal"
            />
            <Box display="flex" gap={2} mt={2}>
              <DatePicker
                label="Deadline"
                value={guideline.deadline ? new Date(guideline.deadline) : null}
                onChange={handleDateChange}
              />
            </Box>
          </Box>

          <Box mb={4}>
            <Typography variant="h6" gutterBottom>
              Content Sections
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <AccordionEditor
              sections={currentVersion.contentSections}
              onChange={handleContentSectionChange}
            />
          </Box>

          <Box mb={4}>
            <Typography variant="h6" gutterBottom>
              Timeline
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <TimelineEditor
              events={currentVersion.timeline}
              onChange={handleTimelineChange}
            />
          </Box>

          <Box mb={4}>
            <Typography variant="h6" gutterBottom>
              Contact Information
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <ContactInfoEditor
              contactInfo={currentVersion.contactInfo}
              onChange={handleContactInfoChange}
            />
          </Box>


<Box mt={3} display="flex" gap={2}>
  <Button variant="contained" color="primary" onClick={handleSave}>
    Save
  </Button>
  <Button variant="outlined" onClick={() => navigate('/guidelines')}>
    Cancel
  </Button>
  {guideline._id && strandSlug && (
    <Button
      component={Link}
      to={`/guidelines/strand/${strandSlug}`}
      sx={{ mr: 1 }}
    >
      View
    </Button>
  )}
</Box>
        </Paper>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
        >
          <Alert
            onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
            severity={snackbar.severity}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </LocalizationProvider>
  );
};

export default GuidelineEditor;