// src/components/TimelineEditor.tsx

import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Switch,
  FormControlLabel,
  Slider,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import {
  Event as EventIcon,
  CalendarToday as CalendarIcon,
  People as PeopleIcon,
  Announcement as AnnouncementIcon,
  MusicNote as MusicNoteIcon,
  Star as StarIcon,
  EditCalendar as EditCalendarIcon,
  Groups as GroupsIcon,
  Celebration as CelebrationIcon,
  EmojiEvents as AwardIcon,
  LocationCity as VenueIcon,
  Campaign as CampaignIcon,
  LibraryMusic as LibraryMusicIcon,
  RecordVoiceOver as VoiceIcon,
  TheaterComedy as TheaterIcon
} from '@mui/icons-material';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { SelectChangeEvent } from '@mui/material/Select';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

// Add this interface for icon options
interface IconOption {
  value: string;
  label: string;
  icon: React.ReactElement;
}

interface TimelineEvent {
  id: string;
  title: string;
  date: string;
  color: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  icon: string;
}

interface TimelineEditorProps {
  events: TimelineEvent[];
  onChange: (newEvents: TimelineEvent[]) => void;
}

const iconOptions: IconOption[] = [
  { value: 'Event', label: 'Default Event', icon: <EventIcon /> },
  { value: 'Calendar', label: 'Calendar', icon: <CalendarIcon /> },
  { value: 'People', label: 'People', icon: <PeopleIcon /> },
  { value: 'Announcement', label: 'Announcement', icon: <AnnouncementIcon /> },
  { value: 'Music', label: 'Music', icon: <MusicNoteIcon /> },
  { value: 'Star', label: 'Star', icon: <StarIcon /> },
  { value: 'EditCalendar', label: 'Schedule', icon: <EditCalendarIcon /> },
  { value: 'Groups', label: 'Groups', icon: <GroupsIcon /> },
  { value: 'Celebration', label: 'Celebration', icon: <CelebrationIcon /> },
  { value: 'Award', label: 'Award', icon: <AwardIcon /> },
  { value: 'Venue', label: 'Venue', icon: <VenueIcon /> },
  { value: 'Campaign', label: 'Announcement', icon: <CampaignIcon /> },
  { value: 'LibraryMusic', label: 'Library', icon: <LibraryMusicIcon /> },
  { value: 'Voice', label: 'Performance', icon: <VoiceIcon /> },
  { value: 'Theater', label: 'Theater', icon: <TheaterIcon /> },
];

// Update the IconRenderer component to render the actual icon
const IconRenderer: React.FC<{ iconName: string }> = ({ iconName }) => {
  const iconOption = iconOptions.find(option => option.value === iconName);
  return iconOption ? iconOption.icon : <EventIcon />;
};


const TimelineEditor: React.FC<TimelineEditorProps> = ({ events, onChange }) => {
  const [isHorizontal, setIsHorizontal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentEvent, setCurrentEvent] = useState<TimelineEvent | null>(null);
  const [timelineScale, setTimelineScale] = useState<number>(1);
  const [timelineColor, setTimelineColor] = useState<string>('#000000');


  const handleAddEvent = () => {
    setCurrentEvent({
      id: '',
      title: '',
      date: '',
      color: 'primary',
      icon: 'Event',
    });
    setOpenDialog(true);
  };

  const handleEditEvent = (event: TimelineEvent) => {
    setCurrentEvent(event);
    setOpenDialog(true);
  };

  const handleDeleteEvent = (id: string) => {
    const newEvents = events.filter(event => event.id !== id);
    onChange(newEvents);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setCurrentEvent(null);
  };

  const handleDialogSave = () => {
    if (currentEvent) {
      if (!isValidDate(currentEvent.date)) {
        alert('Please enter a valid date.');
        return;
      }

      const newEvents = currentEvent.id
        ? events.map(event => (event.id === currentEvent.id ? currentEvent : event))
        : [...events, { ...currentEvent, id: Date.now().toString() }];

      // Sort events by date
      newEvents.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

      onChange(newEvents);
      handleDialogClose();
    }
  };

  const handleInputChange = (field: keyof TimelineEvent) => (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent
  ) => {
    if (currentEvent) {
      setCurrentEvent({
        ...currentEvent,
        [field]: event.target.value,
      });
    }
  };

  const isValidDate = (dateString: string): boolean => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (!regex.test(dateString)) return false;
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(events);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    onChange(items);
  };

  const renderIconSelect = () => (
    <FormControl fullWidth margin="dense">
      <InputLabel>Icon</InputLabel>
      <Select
        value={currentEvent?.icon || 'Event'}
        onChange={handleInputChange('icon')}
        label="Icon"
      >
        {iconOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {option.icon}
              <Typography>{option.label}</Typography>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );


  return (
      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6">Timeline Events</Typography>
          <FormControlLabel
            control={
              <Switch
                checked={isHorizontal}
                onChange={() => setIsHorizontal(!isHorizontal)}
                name="isHorizontal"
              />
            }
            label={isHorizontal ? "Horizontal View" : "Vertical View"}
          />
        </Box>
        <Box mb={2}>
          <Typography gutterBottom>Timeline Scale</Typography>
          <Slider
            value={timelineScale}
            onChange={(_, newValue) => setTimelineScale(newValue as number)}
            min={0.5}
            max={2}
            step={0.1}
            valueLabelDisplay="auto"
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Timeline Color"
            type="color"
            value={timelineColor}
            onChange={(e) => setTimelineColor(e.target.value)}
            fullWidth
          />
        </Box>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="timeline" direction={isHorizontal ? "horizontal" : "vertical"}>
            {(provided) => (
              <Timeline 
                position={isHorizontal ? "alternate" : "right"} 
                sx={isHorizontal ? { flexDirection: 'row' } : {}}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {events.map((event, index) => (
                  <Draggable key={event.id} draggableId={event.id} index={index}>
                    {(provided) => (
                      <TimelineItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        sx={{ transform: `scale(${timelineScale})`, transformOrigin: 'center left' }}
                      >
                        <TimelineSeparator>
                          <TimelineDot color={event.color} />
                          <IconRenderer iconName={event.icon} />
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                          <Box display="flex" alignItems="center">
                            <div {...provided.dragHandleProps}>
                              <DragIndicatorIcon />
                            </div>
                            <Box ml={1}>
                              <Typography variant="h6" component="span">
                                {event.title}
                              </Typography>
                              <Typography>{event.date}</Typography>
                              <IconButton onClick={() => handleEditEvent(event)} size="small">
                                <EditIcon />
                              </IconButton>
                              <IconButton onClick={() => handleDeleteEvent(event.id)} size="small">
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </Box>
                        </TimelineContent>
                      </TimelineItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Timeline>
            )}
          </Droppable>
        </DragDropContext>
        <Button startIcon={<AddIcon />} onClick={handleAddEvent} variant="outlined" sx={{ mt: 2 }}>
          Add Event
        </Button>

        <Dialog open={openDialog} onClose={handleDialogClose}>
          <DialogTitle>{currentEvent?.id ? 'Edit Event' : 'Add New Event'}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Title"
              type="text"
              fullWidth
              value={currentEvent?.title || ''}
              onChange={handleInputChange('title')}
            />
            <TextField
              margin="dense"
              label="Date"
              type="date"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={currentEvent?.date || ''}
              onChange={handleInputChange('date')}
            />
            <FormControl fullWidth margin="dense">
              <InputLabel>Color</InputLabel>
              <Select
                value={currentEvent?.color || 'primary'}
                onChange={handleInputChange('color')}
                label="Color"
              >
                <MenuItem value="primary">Primary</MenuItem>
                <MenuItem value="secondary">Secondary</MenuItem>
                <MenuItem value="error">Error</MenuItem>
                <MenuItem value="info">Info</MenuItem>
                <MenuItem value="success">Success</MenuItem>
                <MenuItem value="warning">Warning</MenuItem>
              </Select>
            </FormControl>
            {renderIconSelect()}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Cancel</Button>
            <Button onClick={handleDialogSave}>Save</Button>
          </DialogActions>
        </Dialog>
      </Box>
  );
};

export default TimelineEditor;