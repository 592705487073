import React from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { BandRecommendation } from '../../../../types/BandRecommendations';
import { useBandView } from '../../../../contexts/BandViewContext';
import GenreTags from './GenreTags';
import StatusActionDisplay from './StatusActionDisplay';
import ActivityStatusDisplay from './ActivityStatusDisplay';


interface BandHeroProps {
  band: BandRecommendation;
}

const BandHero: React.FC<BandHeroProps> = ({ band }) => {
  const { viewConfig } = useBandView();

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '50vh',
        minHeight: '400px',
        mb: 4,
        overflow: 'hidden',
      }}
    >
      {/* Background Image */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url(${band.mainImage || '/placeholder-band-image.jpg'})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: `linear-gradient(
              to bottom,
              transparent 0%,
              transparent 50%,
              rgba(0,0,0,0.4) 70%,
              rgba(0,0,0,0.7) 85%,
              rgba(0,0,0,0.85) 100%
            )`,
            zIndex: 1,
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '80px',
            background: 'linear-gradient(to bottom, rgba(0,0,0,0.4) 0%, transparent 100%)',
            zIndex: 1,
          }
        }}
      />

      {/* Content */}
      <Box
        sx={{
          position: 'relative',
          zIndex: 2,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          p: { xs: 2, sm: 3, md: 4 },
          color: 'white',
        }}
      >
        {/* Status/Action Display */}
        <StatusActionDisplay status={band.status} bandName={band.artistName} />
    <ActivityStatusDisplay status={band.activityStatus} />

        {/* Band Name and Info */}
        <Box>
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: '2.5rem', sm: '3rem', md: '4rem' },
              fontWeight: 'bold',
              mb: 2,
              textShadow: '2px 2px 8px rgba(0,0,0,0.8)',
              lineHeight: 1.2,
              color: 'yellow',
            }}
          >
            {band.artistName}
          </Typography>

          <GenreTags genres={band.genres} />

          {/* Quick Stats */}
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={2}
            sx={{
              mt: 2,
              '& > *': {
                bgcolor: 'rgba(0,0,0,0.5)',
                backdropFilter: 'blur(10px)',
                borderRadius: 1,
                px: 2,
                py: 1,
                display: 'inline-flex',
                alignItems: 'center',
                textShadow: '1px 1px 4px rgba(0,0,0,0.5)',
              },
            }}
          >
            <Typography>
              {band.maleMembers + band.femaleMembers} Members
            </Typography>
            <Typography>
              {band.recommenderCountry}
            </Typography>
            {viewConfig.showContactInfo && (
              <Typography
                sx={{
                  maxWidth: { xs: '100%', sm: '300px' },
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {band.contactEmail}
              </Typography>
            )}
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default BandHero;