import React, { useState, useEffect } from 'react';
import { 
  TextField, 
  Select, 
  MenuItem, 
  Button, 
  FormControlLabel, 
  Checkbox, 
  SelectChangeEvent,
  Autocomplete,
  CircularProgress
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import axios from 'axios';
import { getIMCCodeOptions } from '../constants/imcCodes';
import { EXPENSE_CATEGORIES, getRelatedIMCCodes } from '../constants/expenseCategories';
import { PAYMENT_METHODS, isPersonalPayment } from '../constants/paymentMethods';

interface Staff {
  _id: string;
  name: string;
}

interface ExpenseFormData {
  staffId: string;
  category: string;
  amount: string;
  description: string;
  location: string;
  date: Date | null;
  paymentMethod: string;
  companyReimburse: boolean;
  imcCode: string;
  makeReport: boolean;
  vat: boolean;
  vatAmount: string;
}

const ExpenseForm: React.FC = () => {
  const [formData, setFormData] = useState<ExpenseFormData>({
    staffId: '',
    category: '',
    amount: '',
    description: '',
    location: '',
    date: new Date(), // Set default date to today
    paymentMethod: '',
    companyReimburse: false,
    imcCode: '',
    makeReport: false,
    vat: false,
    vatAmount: '',
  });
  const [receiptPhoto, setReceiptPhoto] = useState<File | null>(null);
  const [staff, setStaff] = useState<Staff[]>([]);
  const [relatedIMCCodes, setRelatedIMCCodes] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const imcCodeOptions = getIMCCodeOptions();

  useEffect(() => {
    const fetchStaff = async () => {
      try {
        const response = await axios.get<Staff[]>(`${process.env.REACT_APP_API_URL}/api/personnel`);
        setStaff(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching staff:', err);
        setError('Failed to fetch staff members. Please try again later.');
        setLoading(false);
      }
    };
    fetchStaff();

    // Get user's current location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setFormData(prevState => ({
            ...prevState,
            location: `${position.coords.latitude}, ${position.coords.longitude}`
          }));
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    }
  }, []);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>
  ) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));

    if (name === 'paymentMethod') {
      setFormData(prevState => ({
        ...prevState,
        companyReimburse: isPersonalPayment(value)
      }));
    }

    if (name === 'category') {
      setRelatedIMCCodes(getRelatedIMCCodes(value));
      setFormData(prevState => ({
        ...prevState,
        imcCode: ''
      }));
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: checked
    }));
  };

  const handleDateChange = (date: Date | null) => {
    setFormData(prevState => ({ ...prevState, date }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setReceiptPhoto(e.target.files[0]);
    }
  };

  const handleIMCCodeChange = (event: React.SyntheticEvent, value: { value: string; label: string } | null) => {
    setFormData(prevState => ({
      ...prevState,
      imcCode: value ? value.value : ''
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const expenseData = new FormData();
    Object.keys(formData).forEach(key => {
      if (key === 'date' && formData[key]) {
        expenseData.append(key, (formData[key] as Date).toISOString());
      } else {
        expenseData.append(key, formData[key as keyof ExpenseFormData]?.toString() || '');
      }
    });
    if (receiptPhoto) {
      expenseData.append('receiptPhoto', receiptPhoto);
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/expenses`, expenseData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      console.log('Expense submitted successfully:', response.data);
      // Handle successful submission (e.g., show success message, reset form)
    } catch (error) {
      console.error('Error submitting expense:', error);
      // Handle error (e.g., show error message)
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <div>{error}</div>;

  return (
    <form onSubmit={handleSubmit}>
      <Select
        name="staffId"
        value={formData.staffId}
        onChange={handleInputChange}
        fullWidth
        margin="none"
        label="Staff Member"
      >
        {staff.map((member) => (
          <MenuItem key={member._id} value={member._id}>
            {member.name}
          </MenuItem>
        ))}
      </Select>

      <Select
        name="category"
        value={formData.category}
        onChange={handleInputChange}
        fullWidth
        margin="none"
        label="Category"
      >
        {EXPENSE_CATEGORIES.map((category) => (
          <MenuItem key={category.name} value={category.name}>
            {category.name}
          </MenuItem>
        ))}
      </Select>

      <Autocomplete
        options={imcCodeOptions.filter(option => relatedIMCCodes.includes(option.value))}
        getOptionLabel={(option) => option.label}
        value={imcCodeOptions.find(option => option.value === formData.imcCode) || null}
        onChange={handleIMCCodeChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="IMC Code"
            margin="normal"
            fullWidth
            required
          />
        )}
      />

      <TextField
        name="amount"
        value={formData.amount}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        label="Amount"
        type="number"
      />

      <TextField
        name="description"
        value={formData.description}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        label="Description"
        multiline
      />

      <TextField
        name="location"
        value={formData.location}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        label="Location"
      />

      <DatePicker
        label="Date"
        value={formData.date}
        onChange={(newValue) => handleDateChange(newValue)}
        slotProps={{
          textField: {
            fullWidth: true,
            margin: "normal"
          }
        }}
      />

      <input
        type="file"
        accept="image/*"
        capture="environment"
        onChange={handleFileChange}
      />

      <Select
        name="paymentMethod"
        value={formData.paymentMethod}
        onChange={handleInputChange}
        fullWidth
        margin="none"
        label="Payment Method"
      >
        {PAYMENT_METHODS.map((method) => (
          <MenuItem key={method.id} value={method.id}>
            {method.name}
          </MenuItem>
        ))}
      </Select>

      <FormControlLabel
        control={
          <Checkbox
            name="companyReimburse"
            checked={formData.companyReimburse}
            onChange={handleCheckboxChange}
          />
        }
        label="Company Reimburse"
      />

      <FormControlLabel
        control={
          <Checkbox
            name="makeReport"
            checked={formData.makeReport}
            onChange={handleCheckboxChange}
          />
        }
        label="Make Report"
      />

      <FormControlLabel
        control={
          <Checkbox
            name="vat"
            checked={formData.vat}
            onChange={handleCheckboxChange}
          />
        }
        label="VAT"
      />

      {formData.vat && (
        <TextField
          name="vatAmount"
          value={formData.vatAmount}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          label="VAT Amount"
          type="number"
        />
      )}

      <Button type="submit" variant="contained" color="primary">
        Submit Expense
      </Button>
    </form>
  );
};

export default ExpenseForm;