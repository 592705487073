import React from 'react';
import {
  Grid,
  TextField,
  Typography,
  Tooltip,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { BandRecommendationFormData } from '../../types/BandRecommendations';

interface FinancialInfoProps {
  formData: BandRecommendationFormData;
  setFormData: React.Dispatch<React.SetStateAction<BandRecommendationFormData>>;
}

const FinancialInfo: React.FC<FinancialInfoProps> = ({ formData, setFormData }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;

    setFormData(prevData => ({
      ...prevData,
      financialDetails: {
        ...prevData.financialDetails,
        [name]: type === 'checkbox' ? checked : value,
      },
    }));
  };

  const financialDetails = formData.financialDetails || {};

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Financial Information
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Tooltip title="Minimum fee expected for the performance.">
          <TextField
            name="minimumFee"
            label="Minimum Fee"
            type="number"
            value={financialDetails.minimumFee ?? ''}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            InputProps={{ inputProps: { min: 0 } }}
          />
        </Tooltip>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Tooltip title="Preferred currency for payment.">
          <TextField
            name="preferredCurrency"
            label="Preferred Currency"
            value={financialDetails.preferredCurrency ?? ''}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
        </Tooltip>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={financialDetails.accommodationNeeded ?? false}
              onChange={handleChange}
              name="accommodationNeeded"
            />
          }
          label="Accommodation Needed"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={financialDetails.transportationNeeded ?? false}
              onChange={handleChange}
              name="transportationNeeded"
            />
          }
          label="Transportation Needed"
        />
      </Grid>
    </Grid>
  );
};

export default FinancialInfo;