// src/components/layout/PublicLayout.tsx
import React from 'react';
import { Outlet } from 'react-router-dom';
import { Container, Box } from '@mui/material';
import PublicNav from '../navigation/PublicNav';
import Footer from '../Footer';

const PublicLayout: React.FC = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <PublicNav />
      <Container component="main" sx={{ flexGrow: 1, mt: 4, mb: 4 }}>
        <Outlet />
      </Container>
      <Footer />
    </Box>
  );
};

export default PublicLayout;