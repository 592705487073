// components/jury/BanBamApplicantDetail.tsx

import React, { useState, useEffect } from 'react';
import {
  Typography,
  Paper,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Alert,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LanguageIcon from '@mui/icons-material/Language';
import PhoneIcon from '@mui/icons-material/Phone';
import ReactPlayer from 'react-player';
import { BanBamApplication } from '../../types/BanBam';

interface BanBamApplicantDetailProps {
  application: BanBamApplication;
}

const BanBamApplicantDetail: React.FC<BanBamApplicantDetailProps> = ({ application }) => {
  const [proxyPdfUrls, setProxyPdfUrls] = useState({
    cvUrl: '',
    scoreUrl: '',
  });
  const [currentAudioIndex, setCurrentAudioIndex] = useState(0);

  useEffect(() => {
    setProxyPdfUrls({
      cvUrl: application.cvUrl || '',
      scoreUrl: application.scoreUrl || '',
    });
  }, [application]);

  const renderPDF = (url: string, title: string) => (
    <Box sx={{ my: 3 }}>
      <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#fff' }}>
        {title}
      </Typography>
      {url ? (
        <>
          <Box
            sx={{
              border: '1px solid #ccc',
              my: 2,
              height: '50vh',
              overflow: 'hidden',
              backgroundColor: '#fff',
            }}
          >
            <iframe
              src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${encodeURIComponent(
                url
              )}`}
              width="100%"
              height="100%"
              frameBorder="0"
            />
          </Box>
          <Button variant="contained" href={url} download>
            Download {title}
          </Button>
        </>
      ) : (
        <Alert
          severity="info"
          sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)', color: '#fff' }}
        >
          No {title} provided by the applicant
        </Alert>
      )}
    </Box>
  );

  const renderMediaEmbeds = () => {
    const { youtubeEmbeds, spotifyEmbeds, soundcloudEmbeds, bandcampEmbeds } = application;

    const hasMediaEmbeds =
      (youtubeEmbeds && youtubeEmbeds.length > 0) ||
      (spotifyEmbeds && spotifyEmbeds.length > 0) ||
      (soundcloudEmbeds && soundcloudEmbeds.length > 0) ||
      (bandcampEmbeds && bandcampEmbeds.length > 0);

    if (!hasMediaEmbeds) {
      return null;
    }

    return (
      <Box sx={{ my: 3 }}>

        {/* YouTube Embeds */}
        {youtubeEmbeds && youtubeEmbeds.length > 0 && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#fff' }}>
              YouTube Links
            </Typography>
            {youtubeEmbeds.map((embedCode, index) => (
              <Box key={index} sx={{ my: 2 }}>
                <div dangerouslySetInnerHTML={{ __html: embedCode }} />
              </Box>
            ))}
          </Box>
        )}

        {/* Spotify Embeds */}
        {spotifyEmbeds && spotifyEmbeds.length > 0 && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#fff' }}>
              Spotify Links
            </Typography>
            {spotifyEmbeds.map((embedCode, index) => (
              <Box key={index} sx={{ my: 2 }}>
                <div dangerouslySetInnerHTML={{ __html: embedCode }} />
              </Box>
            ))}
          </Box>
        )}

        {/* SoundCloud Embeds */}
        {soundcloudEmbeds && soundcloudEmbeds.length > 0 && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#fff' }}>
              SoundCloud Links
            </Typography>
            {soundcloudEmbeds.map((embedCode, index) => (
              <Box key={index} sx={{ my: 2 }}>
                <div dangerouslySetInnerHTML={{ __html: embedCode }} />
              </Box>
            ))}
          </Box>
        )}

        {/* Bandcamp Embeds */}
        {bandcampEmbeds && bandcampEmbeds.length > 0 && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#fff' }}>
              Bandcamp Links
            </Typography>
            {bandcampEmbeds.map((embedCode, index) => (
              <Box key={index} sx={{ my: 2 }}>
                <div dangerouslySetInnerHTML={{ __html: embedCode }} />
              </Box>
            ))}
          </Box>
        )}
      </Box>
    );
  };

  const renderMusicEvidence = () => {
    const hasAudioUploads = application.audioUrls && application.audioUrls.length > 0;
    const hasMusicLinks = application.musicLink1 || application.musicLink2;
    const hasMediaEmbeds = renderMediaEmbeds() !== null;

    if (!hasAudioUploads && !hasMusicLinks && !hasMediaEmbeds) {
      return (
        <Box sx={{ my: 3 }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#fff' }}>
            Music Evidence
          </Typography>
          <Alert
            severity="info"
            sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)', color: '#fff' }}
          >
            No music evidence provided by the applicant
          </Alert>
        </Box>
      );
    }

    return (
      <Box sx={{ my: 3 }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#fff' }}>
          Music Evidence
        </Typography>

        {/* Render Media Embeds */}
        {renderMediaEmbeds()}

        {/* Music Links */}
        {(application.musicLink1 || application.musicLink2) && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#fff' }}>
              Music Links
            </Typography>
            <List sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', borderRadius: '4px' }}>
              {application.musicLink1 && (
                <ListItem
                  sx={{
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    },
                  }}
                >
                  <ListItemText
                    primary="Music Link 1"
                    secondary={
                      <a
                        href={application.musicLink1}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: '#3498db' }}
                      >
                        {application.musicLink1}
                      </a>
                    }
                    primaryTypographyProps={{ sx: { fontWeight: 'bold', color: '#fff' } }}
                    secondaryTypographyProps={{ sx: { color: 'rgba(255, 255, 255, 0.7)' } }}
                  />
                </ListItem>
              )}
              {application.musicLink2 && (
                <ListItem
                  sx={{
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    },
                  }}
                >
                  <ListItemText
                    primary="Music Link 2"
                    secondary={
                      <a
                        href={application.musicLink2}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: '#3498db' }}
                      >
                        {application.musicLink2}
                      </a>
                    }
                    primaryTypographyProps={{ sx: { fontWeight: 'bold', color: '#fff' } }}
                    secondaryTypographyProps={{ sx: { color: 'rgba(255, 255, 255, 0.7)' } }}
                  />
                </ListItem>
              )}
            </List>
          </Box>
        )}

        {/* Audio Uploads */}
        {hasAudioUploads && (
          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#fff' }}>
              Uploaded Audio Files
            </Typography>
            <ReactPlayer
              url={application.audioUrls[currentAudioIndex]}
              controls
              width="100%"
              height="50px"
              onEnded={() => {
                if (currentAudioIndex < application.audioUrls.length - 1) {
                  setCurrentAudioIndex(currentAudioIndex + 1);
                }
              }}
            />
            <List
              sx={{
                bgcolor: 'rgba(255, 255, 255, 0.1)',
                borderRadius: '4px',
                mt: 2,
              }}
            >
              {application.audioUrls.map((audioUrl, index) => (
                <ListItem
                  key={index}
                  button
                  onClick={() => setCurrentAudioIndex(index)}
                  selected={index === currentAudioIndex}
                  sx={{
                    '&.Mui-selected': {
                      backgroundColor: 'rgba(255, 255, 255, 0.2)',
                      '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.3)',
                      },
                    },
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    },
                  }}
                >
                  <ListItemText
                    primary={`Audio ${index + 1}`}
                    secondary={audioUrl.split('/').pop()}
                    primaryTypographyProps={{ sx: { fontWeight: 'bold', color: '#fff' } }}
                    secondaryTypographyProps={{
                      sx: { color: 'rgba(255, 255, 255, 0.7)' },
                    }}
                  />
                  <ListItemSecondaryAction>
                    <Button
                      variant="outlined"
                      href={audioUrl}
                      download
                      sx={{ color: '#fff', borderColor: '#fff' }}
                    >
                      Download
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </Box>
    );
  };

  const renderEqualityInclusion = () => (
    <Accordion sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', color: '#fff', mt: 2 }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Equality, Diversity, and Inclusion
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography sx={{ mb: 2, color: 'rgba(255, 255, 255, 0.7)', fontStyle: 'italic' }}>
          IMC is committed to our opportunities being accessible and inclusive to all. You have the
          option here to outline any caring duties and/or access, learning, or health issues below
          to assist us in our work with you. (50 words).
        </Typography>
        {application.equalityDiversityInclusion ? (
          <Typography>{application.equalityDiversityInclusion}</Typography>
        ) : (
          <Alert
            severity="info"
            sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)', color: '#fff' }}
          >
            This was not filled by the applicant.
          </Alert>
        )}
      </AccordionDetails>
    </Accordion>
  );

  return (
    <Paper elevation={3} sx={{ p: 3, my: 2, bgcolor: '#2c3e50', color: '#fff' }}>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
        {application.firstName} {application.lastName}
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Email: <strong>{application.email}</strong>
      </Typography>

      <Grid container spacing={2}>
        {/* Personal Information */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Personal Information
          </Typography>
          <Typography>
            <strong>Address:</strong> {application.streetAddress1}
          </Typography>
          {application.streetAddress2 && <Typography>{application.streetAddress2}</Typography>}
          <Typography>
            {application.city}, {application.county} {application.postalCode}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
            <PhoneIcon sx={{ mr: 1 }} />
            <Typography>
              {application.phonePrefix} {application.phoneNumber}
            </Typography>
          </Box>
        </Grid>

        {/* Online Presence */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Online Presence
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
            <LanguageIcon sx={{ mr: 1 }} />
            <Typography>
              <a
                href={application.website1}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#3498db' }}
              >
                {application.website1}
              </a>
            </Typography>
          </Box>
          {application.website2 && (
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              <LanguageIcon sx={{ mr: 1 }} />
              <Typography>
                <a
                  href={application.website2}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#3498db' }}
                >
                  {application.website2}
                </a>
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>

      {/* Composition and Performance Ideas */}
      <Box sx={{ my: 3 }}>
        <Accordion sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', color: '#fff' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Composition/Performance Ideas
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              sx={{ mb: 2, color: 'rgba(255, 255, 255, 0.7)', fontStyle: 'italic' }}
            >
              Outline your ideas for your composition and its performance (max 200 words).
            </Typography>
            <Typography>{application.compositionIdeas}</Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', color: '#fff', mt: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              My Artistic Practice
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              sx={{ mb: 2, color: 'rgba(255, 255, 255, 0.7)', fontStyle: 'italic' }}
            >
              Tell us about yourself and your artistic practice (max 200 words).
            </Typography>
            <Typography>{application.performanceIdeas}</Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', color: '#fff', mt: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Future Ambitions
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              sx={{ mb: 2, color: 'rgba(255, 255, 255, 0.7)', fontStyle: 'italic' }}
            >
              Please outline future ambitions for yourself and your music (max 200 words).
            </Typography>
            <Typography>{application.careerHighlights}</Typography>
          </AccordionDetails>
        </Accordion>

        {/* Equality, Diversity, and Inclusion */}
        {renderEqualityInclusion()}
      </Box>

      {/* CV PDF */}
      {renderPDF(proxyPdfUrls.cvUrl, 'CV')}

      {/* Music Evidence */}
      {renderMusicEvidence()}

      {/* Score PDF */}
      {renderPDF(proxyPdfUrls.scoreUrl, 'Score')}
    </Paper>
  );
};

export default BanBamApplicantDetail;