// BanBamFormSchema.ts

import { FieldType, FormSchema, FormField } from '../../types/FormSchema';

const banBamFields: FormField[] = [
  // Generic User Information
  {
    id: 'ID1',
    type: FieldType.Text,
    name: 'firstName',
    label: 'First Name',
    placeholder: 'Enter your first name',
    validation: { required: true },
    strandSpecific: false,
  },
  {
    id: 'ID2',
    type: FieldType.Text,
    name: 'lastName',
    label: 'Last Name',
    placeholder: 'Enter your last name',
    validation: { required: true },
    strandSpecific: false,
  },
  {
    id: 'ID3',
    type: FieldType.Email,
    name: 'email',
    label: 'Email Address',
    placeholder: 'Enter your email',
    validation: { required: true },
    strandSpecific: false,
  },
  // ... (Include other generic fields following the same pattern)

  // Strand Specific URLs
  {
    id: 'ID6',
    type: FieldType.Url,
    name: 'musicLink1',
    label: 'URL link to your original live or recorded music',
    placeholder: 'Enter a valid URL',
    helperText: 'YouTube, Bandcamp, Spotify',
    validation: { required: true },
    strandSpecific: true,
    strands: ['Ban Bam'],
  },
  {
    id: 'ID7',
    type: FieldType.Url,
    name: 'musicLink2',
    label: 'URL link (#2) to your original live or recorded music (optional)',
    placeholder: 'Enter a valid URL',
    helperText: 'YouTube, Bandcamp, Spotify',
    validation: { required: false },
    strandSpecific: true,
    strands: ['Ban Bam'],
  },

  // Strand Specific Questions
  {
    id: 'ID8',
    type: FieldType.TextArea,
    name: 'compositionIdeas',
    label: 'Outline your ideas for your composition and its performance',
    placeholder: '',
    helperText:
      'This can be a broad sense of a structure or theme, including instrumentation. We are looking for a strong compositional idea and a clear vision in bringing that to fruition. A focus on high artistic quality. Work that exhibits an understanding of technique. Consideration of /focus on instruments and instrumental interplay, and the live performance of the work.',
    validation: { required: true, maxLength: 200 },
    strandSpecific: true,
    strands: ['Ban Bam'],
  },
  {
    id: 'ID9',
    type: FieldType.TextArea,
    name: 'performanceIdeas',
    label: 'Tell us about yourself and your artistic practice',
    placeholder: '',
    helperText:
      'This can include education, creative development, career highlights and relevant achievements/activities to date.',
    validation: { required: false, maxLength: 200 },
    strandSpecific: true,
    strands: ['Ban Bam'],
  },
  // ... (Include other strand-specific questions)

  // EDI Question (across the board)
  {
    id: 'ID16',
    type: FieldType.TextArea,
    name: 'equalityDiversityInclusion',
    label: 'Equality, Diversity, and Inclusion',
    placeholder: '',
    helperText:
      'IMC are committed to our opportunities being accessible and inclusive to all. You have the option here to outline any caring duties and/or access, learning or health issues below to assist us in our work with you. (50 words).',
    validation: { required: false, maxLength: 50 },
    strandSpecific: false,
  },

  // Uploads
  {
    id: 'ID17',
    type: FieldType.Upload,
    name: 'cv',
    label: 'Upload CV (PDF)',
    accept: '.pdf',
    validation: { required: true },
    strandSpecific: true,
    strands: ['Ban Bam'],
  },
  {
    id: 'ID18',
    type: FieldType.Upload,
    name: 'audioFiles',
    label: 'Upload Audio Files (mp3 - up to 3)',
    accept: 'audio/*',
    multiple: true,
    validation: { required: false },
    strandSpecific: true,
    strands: ['Ban Bam'],
  },
  // ... (Include other upload fields)

  // Checkboxes
  {
    id: 'ID22',
    type: FieldType.Checkbox,
    name: 'confirmOriginalMaterial',
    label:
      'I confirm and verify that the original materials submitted above are composed / predominantly composed by me.',
    validation: { required: true },
    strandSpecific: true,
    strands: ['Ban Bam'],
  },
  // ... (Include other checkboxes if needed)
];

export const banBamFormSchema: FormSchema = {
  formId: 'ban_bam_form',
  title: 'BAN BAM Application Form',
  description:
    'Please fill out the form below to apply for the BAN BAM initiative.',
  strand: 'Ban Bam',
  fields: banBamFields,
};