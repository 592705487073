import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography } from '@mui/material';
import EventForm from '../components/EventForm';

const EventFormPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const isEditing = !!id;

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        {isEditing ? 'Edit Event' : 'Create Event'}
      </Typography>
      <EventForm eventId={id} />
    </Container>
  );
};

export default EventFormPage;