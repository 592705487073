import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Alert, Snackbar, Button } from '@mui/material';
import { Save } from 'lucide-react';
import axios, { AxiosError } from 'axios';
import { useAuth } from '../../contexts/AuthContext';
import { BandRecommendationFormData } from '../../types/BandRecommendations';

interface FormSaveHandlerProps {
  formData: BandRecommendationFormData;
  formId?: string;
  isEditMode: boolean;
  enabled?: boolean;
  autoSaveInterval?: number;
}

type SaveStatus = 'idle' | 'saving' | 'saved' | 'error';

const FormSaveHandler: React.FC<FormSaveHandlerProps> = ({
  formData,
  formId,
  isEditMode,
  enabled = true,
  autoSaveInterval = 30000, // 30 seconds
}) => {
  const [saveStatus, setSaveStatus] = useState<SaveStatus>('idle');
  const [lastSaved, setLastSaved] = useState<Date | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { getToken } = useAuth();
  const autoSaveTimer = useRef<NodeJS.Timeout | null>(null);

  const saveFormData = useCallback(
    async (showFeedback = true) => {
      if (!enabled) return;

      try {
        setSaveStatus('saving');
        const token = await getToken();

        if (!token) {
          throw new Error('Authentication required');
        }

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        };

        const endpoint = isEditMode
          ? `${process.env.REACT_APP_API_URL}/api/bandrecommendations/${formId}`
          : `${process.env.REACT_APP_API_URL}/api/bandrecommendations/draft`;

        const method = isEditMode ? 'put' : 'post';

        await axios[method](endpoint, formData, config);

        setSaveStatus('saved');
        setLastSaved(new Date());
        setError(null);

        if (showFeedback) {
          setTimeout(() => setSaveStatus('idle'), 3000);
        }
      } catch (err) {
        const error = err as AxiosError;
        console.error('Error saving form:', error);
        setError(
          (error.response?.data as { message?: string })?.message ||
            'Failed to save changes'
        );
        setSaveStatus('error');
      }
    },
    [formId, isEditMode, enabled, getToken] // Exclude formData to prevent unnecessary re-creations
  );

  useEffect(() => {
    if (!enabled) return;
    if (autoSaveTimer.current) clearInterval(autoSaveTimer.current);

    autoSaveTimer.current = setInterval(() => {
      saveFormData(false);
    }, autoSaveInterval);

    return () => {
      if (autoSaveTimer.current) clearInterval(autoSaveTimer.current);
    };
  }, [enabled, autoSaveInterval, saveFormData]);

  useEffect(() => {
    if (!enabled) return;
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (saveStatus === 'saving') {
        e.preventDefault();
        e.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [saveStatus, enabled]);

  return (
    <div style={{ position: 'fixed', bottom: 633, right: 110 }}>
      {enabled && (
        <Button
          variant="contained"
          color={saveStatus === 'error' ? 'error' : 'primary'}
          onClick={() => saveFormData(true)}
          disabled={saveStatus === 'saving'}
          startIcon={<Save size={16} />}
          size="small"
          sx={{ mr: 2 }}
        >
          {saveStatus === 'saving' ? 'Saving...' : 'Save Progress'}
        </Button>
      )}

      <Snackbar
        open={saveStatus === 'saved' || saveStatus === 'error'}
        autoHideDuration={3000}
        onClose={() => setSaveStatus('idle')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          severity={saveStatus === 'saved' ? 'success' : 'error'}
          sx={{ width: '100%' }}
        >
          {saveStatus === 'saved'
            ? `Changes saved ${
                lastSaved ? `at ${lastSaved.toLocaleTimeString()}` : ''
              }`
            : error}
        </Alert>
      </Snackbar>
    </div>
  );
};

// Wrap the component with React.memo when exporting
export default React.memo(FormSaveHandler);