import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { 
  Checkbox, 
  List, 
  ListItem, 
  ListItemText, 
  ListItemIcon, 
  Button, 
  Typography,
  CircularProgress
} from '@mui/material';

interface Staff {
  _id: string;
  name: string;
  role: string;
}

interface StaffRosterProps {
  performanceId: string;
  onAssignmentComplete?: (updatedPerformance: any) => void;
}

const StaffRoster: React.FC<StaffRosterProps> = ({ performanceId, onAssignmentComplete }) => {
  const [staff, setStaff] = useState<Staff[]>([]);
  const [selectedStaff, setSelectedStaff] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchStaff = async () => {
      try {
        const response = await axios.get<Staff[]>(`${process.env.REACT_APP_API_URL}/api/personnel`);
        console.log('Fetched staff:', response.data);
        setStaff(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching staff:', err);
        setError('Failed to fetch staff members. Please try again later.');
        setLoading(false);
      }
    };
    fetchStaff();
  }, []);

  const handleToggle = (staffId: string) => {
    setSelectedStaff(prev => 
      prev.includes(staffId) ? prev.filter(id => id !== staffId) : [...prev, staffId]
    );
  };

  const handleAssignStaff = async () => {
    try {
      console.log('Assigning staff:', selectedStaff);
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/performances/${performanceId}/assign-staff`, {
        staffIds: selectedStaff
      });
      console.log('Staff assignment response:', response.data);
      if (onAssignmentComplete) {
        onAssignmentComplete(response.data);
      }
      setSelectedStaff([]);
    } catch (err) {
      console.error('Error assigning staff:', err);
      setError('Failed to assign staff members. Please try again.');
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (staff.length === 0) return <Typography>No staff members available.</Typography>;

  return (
    <div>
      <Typography variant="h6" gutterBottom>Assign Staff</Typography>
      <List>
        {staff.map((member) => (
          <ListItem key={member._id} dense button onClick={() => handleToggle(member._id)}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={selectedStaff.includes(member._id)}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText primary={`${member.name} - ${member.role}`} />
          </ListItem>
        ))}
      </List>
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleAssignStaff}
        disabled={selectedStaff.length === 0}
      >
        Assign Selected Staff
      </Button>
    </div>
  );
};

export default StaffRoster;