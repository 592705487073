import React, { useState } from 'react';
import { 
  Box, 
  Card, 
  CardContent, 
  Typography, 
  Grid,
  ImageList,
  ImageListItem,
  Dialog,
  IconButton,
  Link,
  Tabs,
  Tab
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { BaseBandComponentProps } from '../types';
import { useBandView } from '../../../contexts/BandViewContext';
import AdaptiveImage from '../../artists/AdaptiveBandImage';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => (
  <div role="tabpanel" hidden={value !== index}>
    {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
  </div>
);

const BandMedia: React.FC<BaseBandComponentProps> = ({ band }) => {
  const { viewMode } = useBandView();
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [tabValue, setTabValue] = useState(0);

  const handleImageClick = (imageUrl: string) => {
    setSelectedImage(imageUrl);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Card sx={{ mb: 3 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>Media</Typography>

        {/* Media Tabs */}
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label="Videos" />
            <Tab label="Audio" />
            <Tab label="Images" />
            <Tab label="Links" />
          </Tabs>
        </Box>

        {/* Images Tab */}
        <TabPanel value={tabValue} index={2}>
          {band.additionalImages && band.additionalImages.length > 0 && (
            <ImageList cols={3} gap={8}>
              {band.additionalImages.map((imageUrl, index) => (
                <ImageListItem key={index} onClick={() => handleImageClick(imageUrl)}>
                  <img
                    src={imageUrl}
                    alt={`${band.artistName} - ${index + 1}`}
                    loading="lazy"
                    style={{ cursor: 'pointer' }}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          )}
        </TabPanel>

        {/* Videos Tab */}
        <TabPanel value={tabValue} index={0}>
          <Grid container spacing={2}>
            {band.youtubeEmbeds.map((embed, index) => (
              <Grid item xs={12} key={index}>
                <Box
                  dangerouslySetInnerHTML={{ __html: embed }}
                  sx={{
                    position: 'relative',
                    paddingBottom: '56.25%', // 16:9 aspect ratio
                    height: 0,
                    '& iframe': {
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                    },
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </TabPanel>

        {/* Audio Tab */}
        <TabPanel value={tabValue} index={1}>
          <Grid container spacing={2}>
            {band.spotifyEmbeds.map((embed, index) => (
              <Grid item xs={12} key={`spotify-${index}`}>
                <Box dangerouslySetInnerHTML={{ __html: embed }} />
              </Grid>
            ))}
            {band.soundcloudEmbeds.map((embed, index) => (
              <Grid item xs={12} key={`soundcloud-${index}`}>
                <Box dangerouslySetInnerHTML={{ __html: embed }} />
              </Grid>
            ))}
            {band.bandcampEmbeds.map((embed, index) => (
              <Grid item xs={12} key={`bandcamp-${index}`}>
                <Box dangerouslySetInnerHTML={{ __html: embed }} />
              </Grid>
            ))}
          </Grid>
        </TabPanel>

        {/* Links Tab */}
        <TabPanel value={tabValue} index={3}>
          {band.performanceLink && (
            <Box mb={2}>
              <Typography variant="subtitle1">Performance Link:</Typography>
              <Link href={band.performanceLink} target="_blank" rel="noopener noreferrer">
                {band.performanceLink}
              </Link>
            </Box>
          )}
        </TabPanel>

        {/* Image Modal */}
        <Dialog open={!!selectedImage} onClose={handleClose} maxWidth="lg">
          <Box sx={{ position: 'relative' }}>
            <IconButton
              sx={{ position: 'absolute', right: 8, top: 8, color: 'white' }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
            {selectedImage && (
              <img
                src={selectedImage}
                alt={band.artistName}
                style={{ width: '100%', height: 'auto' }}
              />
            )}
          </Box>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default BandMedia;