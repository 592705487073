// src/pages/PersonnelFormPage.tsx
import React from 'react';
import { useParams } from 'react-router-dom';
import PersonnelForm from '../components/PersonnelForm';

const PersonnelFormPage: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  return (
    <div>
      <h1>{id ? 'Edit Personnel' : 'Add New Personnel'}</h1>
      <PersonnelForm />
    </div>
  );
};
export default PersonnelFormPage;
