import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Stack,
  Chip
} from '@mui/material';
import { BandRecommendation } from '../../../../types/BandRecommendations';

interface SimilarArtistsSectionProps {
  band: BandRecommendation;
}

const SimilarArtistsSection: React.FC<SimilarArtistsSectionProps> = ({ band }) => {
  if (!band.similarArtists?.length) {
    return null;
  }

  return (
    <Card sx={{ mb: 3 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>Similar Artists</Typography>
        <Stack direction="row" spacing={1} flexWrap="wrap">
          {band.similarArtists.map((artist, index) => (
            <Chip
              key={index}
              label={artist}
              variant="outlined"
              size="small"
              sx={{ mb: 1 }}
            />
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default SimilarArtistsSection;