// src/components/navigation/PublicNav.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, Box } from '@mui/material';
import ShowrunnrLogo from '../ShowrunnrLogo';

const PublicNav: React.FC = () => {
  return (
    <AppBar position="static" color="primary">
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <ShowrunnrLogo width={150} height={50} />
        <Box>
          <Button color="inherit" component={Link} to="/publicstrands">Published Strands</Button>
          <Button color="inherit" component={Link} to="/login">Login</Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default PublicNav;