import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import { BandRecommendation } from '../../../../types/BandRecommendations';
import { useBandView } from '../../../../contexts/BandViewContext';

interface FinancialSectionProps {
  band: BandRecommendation;
}

const FinancialSection: React.FC<FinancialSectionProps> = ({ band }) => {
  const { viewConfig } = useBandView();

  if (!viewConfig.showFinancialDetails || !band.financialDetails) {
    return null;
  }

  return (
    <Card sx={{ mb: 3 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>Financial Requirements</Typography>
        <List>
          {band.financialDetails.minimumFee && (
            <ListItem>
              <ListItemText
                primary="Minimum Fee"
                secondary={`${band.financialDetails.minimumFee} ${band.financialDetails.preferredCurrency || ''}`}
              />
            </ListItem>
          )}
          <ListItem>
            <ListItemText
              primary="Accommodation Needed"
              secondary={band.financialDetails.accommodationNeeded ? 'Yes' : 'No'}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Transportation Needed"
              secondary={band.financialDetails.transportationNeeded ? 'Yes' : 'No'}
            />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
};

export default FinancialSection;