// components/PersonnelForm.tsx

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  TextField, 
  Select, 
  MenuItem, 
  Button, 
  Grid, 
  FormControl, 
  InputLabel,
  SelectChangeEvent 
} from '@mui/material';
import { PersonnelFormData, PersonnelRole, SkillLevel } from '../types/Personnel';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL;

const PersonnelForm: React.FC = () => {
  const [formData, setFormData] = useState<PersonnelFormData>({
    name: '',
    email: '',
    phone: '',
    role: PersonnelRole.Staff,
    skillLevel: SkillLevel.Beginner,
    experience: 0,
    performances: [],
    availability: {
      from: new Date(),
      to: new Date()
    }
  });
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      const fetchPerson = async () => {
        try {
          const response = await axios.get<PersonnelFormData>(`${API_BASE_URL}/api/personnel/${id}`);
          setFormData(response.data);
        } catch (error) {
          console.error('Error fetching personnel data:', error);
        }
      };
      fetchPerson();
    }
  }, [id]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (id) {
        await axios.put(`${API_BASE_URL}/api/personnel/${id}`, formData);
      } else {
        await axios.post(`${API_BASE_URL}/api/personnel`, formData);
      }
      navigate('/personnel');
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Role</InputLabel>
            <Select
              name="role"
              value={formData.role}
              onChange={handleChange}
              required
            >
              {Object.values(PersonnelRole).map((role) => (
                <MenuItem key={role} value={role}>{role}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Skill Level</InputLabel>
            <Select
              name="skillLevel"
              value={formData.skillLevel}
              onChange={handleChange}
              required
            >
              {Object.values(SkillLevel).map((level) => (
                <MenuItem key={level} value={level}>{level}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Experience (years)"
            name="experience"
            type="number"
            value={formData.experience}
            onChange={handleChange}
            required
          />
        </Grid>
        {/* Add more fields as needed */}
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            {id ? 'Update' : 'Create'} Personnel
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default PersonnelForm;