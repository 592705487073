import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Box,
  Button,
  useTheme,
  useMediaQuery,
  ListItemButton,
  Divider,
  Typography,
  Menu,
  MenuItem
} from '@mui/material';
import {
  Menu as MenuIcon,
  ChevronDown,
  ChevronUp,
  Home,
  Calendar,
  Music,
  Building,
  User,
  LogIn,
  LogOut,
  ChevronRight,
  FileText
} from 'lucide-react';

interface MenuItem {
  label: string;
  path: string;
  icon?: React.ReactNode;
}

interface NavigationSection {
  mainItems: MenuItem[];
  dropdowns?: Record<string, MenuItem[]>;
}

const getNavigationItems = (role: string): NavigationSection => {
  switch (role) {
    case 'admin':
      return {
        mainItems: [
          { label: 'Home', path: '/admin', icon: <Home className="w-4 h-4" /> },
          { label: 'Bands', path: '/admin/bands', icon: <Music className="w-4 h-4" /> },
          { label: 'Submit', path: '/submit', icon: <ChevronRight className="w-4 h-4" /> },
          { label: 'Calendar', path: '/calendar', icon: <Calendar className="w-4 h-4" /> },
        ],
        dropdowns: {
          Events: [
            { label: 'Create Event', path: '/admin/events/new' },
            { label: 'Event List', path: '/admin/events' },
            { label: 'Performances', path: '/admin/performances' },
            { label: 'Sales', path: '/admin/sales' },
          ],
          Venues: [
            { label: 'Add Venue', path: '/admin/venues/new' },
            { label: 'Venue List', path: '/admin/venues' },
          ],
          Strands: [
            { label: 'Create Strand', path: '/strands/new' },
            { label: 'Strand List', path: '/admin/strands' },
            { label: 'Published Strands', path: '/publicstrands' },
            { label: 'Guidelines Management', path: '/guidelines' },
          ],
          Admin: [
            { label: 'User Management', path: '/admin/users' },
            { label: 'Domain Management', path: '/admin/domains' },
            { label: 'Personnel', path: '/admin/personnel' },
            { label: 'Navigator Applications', path: '/admin/navigator-applications' },
            { label: 'BanBam Applications', path: '/admin/banbam-applications' },
            { label: 'All Applications', path: '/admin/applications' },
          ],
          Testbed: [
            { label: 'Prospects', path: '/admin/prospects' },
            { label: 'Monday Test', path: '/monday' },
            { label: 'Stage Designer', path: '/stage-designer' },
            { label: 'Tix', path: '/tix' },
            { label: 'Pomodoro', path: '/musicadmin' },
            { label: 'Dynamic Form', path: '/dynamicform' },
            { label: 'Form Builder', path: '/Formbuilder' },
            { label: 'Cooler Promo', path: '/investor/dashboard' },
          ],
        },
      };
    case 'artist':
      return {
        mainItems: [
          { label: 'My Dashboard', path: '/artist/dashboard', icon: <Home className="w-4 h-4" /> },
          { label: 'My Profile', path: '/artist/profile', icon: <User className="w-4 h-4" /> },
          { label: 'Submit A New Band', path: '/submit', icon: <Music className="w-4 h-4" /> },
        ],
        dropdowns: {
          More: [
            { label: 'My Performances', path: '/artist/performances' },
            { label: 'Calendar', path: '/artist/calendar' },
            { label: 'My Pomodoro', path: '/musicadmin' },
            { label: 'Stage Plot Creator', path: '/stage-designer' },
          ],
        },
      };
    case 'guest':
      return {
        mainItems: [
          { label: 'Dashboard', path: '/dashboard', icon: <Home className="w-4 h-4" /> },
          { label: 'Events', path: '/events', icon: <Calendar className="w-4 h-4" /> },
          { label: 'Bands', path: '/bands', icon: <Music className="w-4 h-4" /> },
          { label: 'Venues', path: '/venues', icon: <Building className="w-4 h-4" /> },
          { label: 'Public Strands', path: '/publicstrands', icon: <FileText className="w-4 h-4" /> },
        ],
      };
    default:
      return {
        mainItems: [
          { label: 'Published Strands', path: '/publicstrands', icon: <FileText className="w-4 h-4" /> },
        ],
      };
  }
};

interface ResponsiveNavProps {
  role: string;
  logo: React.ReactNode;
  onLogout?: () => void;
  userEmail?: string;
}

const ResponsiveNav: React.FC<ResponsiveNavProps> = ({ role, logo, onLogout, userEmail }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [expandedSection, setExpandedSection] = useState<string | null>(null);
  const [anchorEls, setAnchorEls] = useState<{ [key: string]: HTMLElement | null }>({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const navigation = getNavigationItems(role);

  const handleDropdownOpen = (event: React.MouseEvent<HTMLElement>, section: string) => {
    setAnchorEls(prev => ({
      ...prev,
      [section]: event.currentTarget
    }));
  };

  const handleDropdownClose = (section: string) => {
    setAnchorEls(prev => ({
      ...prev,
      [section]: null
    }));
  };

  const handleMobileSectionClick = (section: string) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  const renderDesktopNav = () => (
    <AppBar position="static" color="transparent" elevation={0}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {logo}
        </Box>
        
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          {navigation.mainItems.map((item) => (
            <Button
              key={item.path}
              component={Link}
              to={item.path}
              color="inherit"
              sx={{ textTransform: 'none' }}
            >
              {item.label}
            </Button>
          ))}

          {navigation.dropdowns && Object.entries(navigation.dropdowns).map(([section, items]) => (
            <Box key={section}>
              <Button
                color="inherit"
                endIcon={<ChevronDown className="w-4 h-4" />}
                onClick={(e) => handleDropdownOpen(e, section)}
                sx={{ textTransform: 'none' }}
              >
                {section}
              </Button>
              <Menu
                anchorEl={anchorEls[section]}
                open={Boolean(anchorEls[section])}
                onClose={() => handleDropdownClose(section)}
              >
                {items.map((item) => (
                  <MenuItem
                    key={item.path}
                    component={Link}
                    to={item.path}
                    onClick={() => handleDropdownClose(section)}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          ))}

          <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
            {userEmail && (
              <>
                <Typography variant="body2" sx={{ mr: 2 }}>
                  {userEmail}
                </Typography>
                <Button color="inherit" onClick={onLogout}>
                  Logout
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );

  const renderMobileNav = () => (
    <>
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setMobileMenuOpen(true)}
          >
            <MenuIcon className="w-6 h-6" />
          </IconButton>
          {logo}
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="left"
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
        ModalProps={{ keepMounted: true }}
      >
        <Box sx={{ width: 280 }}>
          <List>
            {navigation.mainItems.map((item) => (
              <ListItemButton
                key={item.path}
                component={Link}
                to={item.path}
                onClick={() => setMobileMenuOpen(false)}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            ))}
          </List>

          {navigation.dropdowns && (
            <>
              <Divider />
              <List>
                {Object.entries(navigation.dropdowns).map(([section, items]) => (
                  <Box key={section}>
                    <ListItemButton onClick={() => handleMobileSectionClick(section)}>
                      <ListItemText primary={section} />
                      {expandedSection === section ? 
                        <ChevronUp className="w-4 h-4" /> : 
                        <ChevronDown className="w-4 h-4" />
                      }
                    </ListItemButton>
                    <Collapse in={expandedSection === section}>
                      <List>
                        {items.map((item) => (
                          <ListItemButton
                            key={item.path}
                            component={Link}
                            to={item.path}
                            onClick={() => setMobileMenuOpen(false)}
                            sx={{ pl: 4 }}
                          >
                            <ListItemText primary={item.label} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                  </Box>
                ))}
              </List>
            </>
          )}

          {userEmail && (
            <>
              <Divider />
              <List>
                <ListItem>
                  <ListItemIcon><User className="w-4 h-4" /></ListItemIcon>
                  <ListItemText primary={userEmail} secondary={role} />
                </ListItem>
                {onLogout && (
                  <ListItemButton onClick={onLogout}>
                    <ListItemIcon><LogOut className="w-4 h-4" /></ListItemIcon>
                    <ListItemText primary="Logout" />
                  </ListItemButton>
                )}
              </List>
            </>
          )}
        </Box>
      </Drawer>
    </>
  );

  return isMobile ? renderMobileNav() : renderDesktopNav();
};

export default ResponsiveNav;