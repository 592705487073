// src/components/FinancialOverview.tsx

import React from 'react';
import { Typography, TableContainer, Table, TableBody, TableCell, TableRow, Paper } from '@mui/material';

interface FinancialOverviewProps {
  totalIncome: number;
  totalCost: number;
  profitLoss: number;
}

const FinancialOverview: React.FC<FinancialOverviewProps> = ({ totalIncome, totalCost, profitLoss }) => {
  return (
    <>
      <Typography variant="h5" gutterBottom>
        Financial Overview
      </Typography>
      <TableContainer component={Paper} sx={{ mb: 4 }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Total Income</TableCell>
              <TableCell align="right">€{totalIncome.toFixed(2)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Total Costs</TableCell>
              <TableCell align="right">€{totalCost.toFixed(2)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Profit / Loss</strong>
              </TableCell>
              <TableCell align="right">
                <strong style={{ color: profitLoss >= 0 ? 'green' : 'red' }}>
                  €{profitLoss.toFixed(2)}
                </strong>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default FinancialOverview;