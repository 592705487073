import React from 'react';
import { Card, CardContent, CardActions, Typography, Button, Chip, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DateRangeIcon from '@mui/icons-material/DateRange';

interface Event {
  _id: string;
  name: string;
  date?: string;
  startDate?: string;
  endDate?: string;
  eventType?: string;
  status?: string;
  venue?: {
    _id: string;
    name: string;
  };
}

interface EventListProps {
  events: Event[];
  emptyMessage?: string;
}

const EventList: React.FC<EventListProps> = ({ 
  events, 
  emptyMessage = "No events found." 
}) => {
  const isUpcoming = (event: Event) => {
    const now = new Date();
    
    if (event.date) {
      const eventDate = new Date(event.date);
      eventDate.setHours(23, 59, 59, 999);
      return eventDate >= now;
    }
    
    if (event.endDate) {
      const endDate = new Date(event.endDate);
      endDate.setHours(23, 59, 59, 999);
      return endDate >= now;
    }
    
    return true; // If no date info, show by default
  };

  const formatDateDisplay = (event: Event) => {
    if (event.date) {
      return {
        text: new Date(event.date).toLocaleDateString(),
        isSingleDate: true
      };
    }
    
    if (event.startDate && event.endDate) {
      const start = new Date(event.startDate);
      const end = new Date(event.endDate);
      
      // If the event spans multiple months/years
      if (start.getMonth() !== end.getMonth() || start.getFullYear() !== end.getFullYear()) {
        return {
          text: `${start.toLocaleDateString()} - ${end.toLocaleDateString()}`,
          isSingleDate: false
        };
      }
      
      // If it's in the same month
      return {
        text: `${start.getDate()} - ${end.getDate()} ${start.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}`,
        isSingleDate: false
      };
    }

    return {
      text: 'Date not specified',
      isSingleDate: true
    };
  };

  const filteredEvents = events.filter(isUpcoming);

  return filteredEvents.length > 0 ? (
    <>
      {filteredEvents.map(event => {
        const dateInfo = formatDateDisplay(event);
        
        return (
          <Card key={event._id} sx={{ mb: 2 }}>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
                <Typography variant="h5" component="h2">
                  {event.name}
                </Typography>
                {event.eventType && (
                  <Chip 
                    label={event.eventType} 
                    size="small"
                    color="primary"
                    variant="outlined"
                  />
                )}
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                {dateInfo.isSingleDate ? (
                  <CalendarTodayIcon sx={{ mr: 1, fontSize: '1rem' }} />
                ) : (
                  <DateRangeIcon sx={{ mr: 1, fontSize: '1rem' }} />
                )}
                <Typography color="text.secondary">
                  {dateInfo.text}
                </Typography>
              </Box>

              <Typography variant="body2">
                Venue: {event.venue ? event.venue.name : 'Not specified'}
              </Typography>

              {event.status && (
                <Box sx={{ mt: 1 }}>
                  <Chip
                    label={event.status}
                    size="small"
                    color={event.status === 'confirmed' ? 'success' : 
                           event.status === 'cancelled' ? 'error' : 'default'}
                  />
                </Box>
              )}
            </CardContent>
            <CardActions>
              <Button 
                size="small" 
                component={Link} 
                to={`/events/${event._id}`}
              >
                View Details
              </Button>
            </CardActions>
          </Card>
        );
      })}
    </>
  ) : (
    <Typography>{emptyMessage}</Typography>
  );
};

export default EventList;