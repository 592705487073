import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Paper,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Chip,
} from '@mui/material';
import axios from 'axios';
import { Event, Performance, BandRecommendation, EventType } from '../types/mongodb';

const EventDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [event, setEvent] = useState<Event | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        setLoading(true);
        const response = await axios.get<Event>(`${process.env.REACT_APP_API_URL}/api/events/${id}`);
        console.log('Fetched event data:', response.data);
        setEvent(response.data);
        setError(null);
      } catch (error) {
        console.error('Error fetching event:', error);
        setError('Failed to fetch event details. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
    fetchEvent();
  }, [id]);

  const handleDelete = () => {
    setOpenDeleteDialog(true);
  };

  const confirmDelete = async () => {
    if (event) {
      try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/api/events/${event._id}`);
        setOpenDeleteDialog(false);
        navigate('/events');
      } catch (error) {
        console.error('Error deleting event:', error);
        setError('Failed to delete the event. Please try again later.');
      }
    }
  };

  const formatEventDate = (event: Event) => {
    if (!event) return 'Date not available';
    
    if (event.eventType === 'Single' && event.date) {
      return new Date(event.date).toLocaleDateString();
    } else if (event.startDate && event.endDate) {
      return `${new Date(event.startDate).toLocaleDateString()} - ${new Date(event.endDate).toLocaleDateString()}`;
    }
    return 'Date not available';
  };

  const renderEventTypeSpecificInfo = (event: Event) => {
    if (!event) return null;

    switch (event.eventType) {
      case 'Series':
      case 'Clinic':
        return (
          <>
            <Typography variant="body1">
              Frequency: {event.seriesFrequency || 'Weekly'}
            </Typography>
            <Typography variant="body1">
              Recurring Days: {event.recurringDays?.join(', ')}
            </Typography>
            {event.seriesStartTime && event.seriesEndTime && (
              <Typography variant="body1">
                Series Time: {new Date(event.seriesStartTime).toLocaleTimeString()} - {new Date(event.seriesEndTime).toLocaleTimeString()}
              </Typography>
            )}
          </>
        );
      case 'Tour':
      case 'Festival':
        return (
          <Typography variant="body1">
            Duration: {formatEventDate(event)}
          </Typography>
        );
      default:
        return null;
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!event) return <Typography>Event not found.</Typography>;

  return (
    <Container>
      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          {event.name}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              Date: {formatEventDate(event)}
            </Typography>
            <Typography variant="body1">
              Event Type: {event.eventType} {event.isOnline ? '(Online)' : ''}
            </Typography>
            {event.isOnline ? (
              <Typography variant="body1">
                Zoom Link: <a href={event.zoomLink} target="_blank" rel="noopener noreferrer">{event.zoomLink}</a>
              </Typography>
            ) : (
              <Typography variant="body1">
                Venue: {typeof event.venue === 'string' ? event.venue : event.venue?.name || 'Not specified'}
              </Typography>
            )}
            <Typography variant="body1">
              Ticket Price: {event.ticketPrice === 0 ? 'Free' : `€${event.ticketPrice?.toFixed(2) || 'Not specified'}`}
            </Typography>
            <Typography variant="body1">
              Status: <Chip label={event.status} color={event.status === 'confirmed' ? 'success' : event.status === 'cancelled' ? 'error' : 'default'} />
            </Typography>
            {event.focus && (
              <Typography variant="body1">
                Focus: {event.focus}
              </Typography>
            )}
            {renderEventTypeSpecificInfo(event)}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Description</Typography>
            <Typography variant="body1">{event.description || 'No description provided.'}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Performances</Typography>
            {event.performances && event.performances.length > 0 ? (
              <List>
                {(event.performances as Performance[]).map((performance: Performance) => {
                  const band = performance.band as BandRecommendation;
                  return (
                    <ListItem key={performance._id} divider>
                      <ListItemText
                        primary={
                          <Typography variant="subtitle1">
                            <Link
                              to={`/performances/${performance._id}`}
                              style={{ textDecoration: 'none', color: '#1976d2' }}
                            >
                              {band && band.artistName ? band.artistName : 'Unknown Artist'}
                            </Link>
                          </Typography>
                        }
                        secondary={`${performance.startTime
                          ? new Date(performance.startTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                          : 'Start Time'} - ${performance.endTime
                          ? new Date(performance.endTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                          : 'End Time'}`}
                      />
                      <Box>
                        {band && band._id ? (
                          <Button
                            variant="outlined"
                            color="primary"
                            component={Link}
                            to={`/band/${band._id}`}
                          >
                            View Band
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            color="primary"
                            disabled
                          >
                            Band Info Unavailable
                          </Button>
                        )}
                      </Box>
                    </ListItem>
                  );
                })}
              </List>
            ) : (
              <Typography>No performances scheduled yet.</Typography>
            )}
            <Button
              component={Link}
              to={`/events/${event._id}/add-performance`}
              variant="contained"
              color="primary"
              style={{ marginTop: '10px' }}
            >
              Add Performance
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: '20px' }}>
          <Grid item>
            <Button
              component={Link}
              to={`/events/edit/${event._id}`}
              variant="contained"
              color="primary"
            >
              Edit Event
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="error"
              onClick={handleDelete}
            >
              Delete Event
            </Button>
          </Grid>
          <Grid item>
            <Button
              component={Link}
              to="/events"
              variant="outlined"
              color="secondary"
            >
              Back to Events
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete the event{' '}
            <strong>{event.name}</strong>?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>
            Cancel
          </Button>
          <Button
            onClick={confirmDelete}
            variant="contained"
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default EventDetailPage;