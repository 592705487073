// src/components/NotesSection.tsx

import React from 'react';
import { Typography, Box } from '@mui/material';

interface NotesSectionProps {
  notes?: string;
}

const NotesSection: React.FC<NotesSectionProps> = ({ notes }) => {
  if (!notes) return null;

  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h5" gutterBottom>
        Notes
      </Typography>
      <Typography variant="body1">{notes}</Typography>
    </Box>
  );
};

export default NotesSection;