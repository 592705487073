import React, { useState, useEffect } from 'react';
import { Container, Typography, CircularProgress } from '@mui/material';
import axios from 'axios';
import axiosInstance from '../utils/axiosConfig';
import { MUSIC_QUOTES } from '../constants/musicQuotes';
import { Event, Performance, Application, TitoEvent } from '../types/dashboard';
import { getTitoEvents, getTitoRegistrations, getTitoReleases } from '../services/titoApi';

// Import components
import UserGreeting from '../components/UserGreeting';
import QuoteCard from '../components/dashboard/QuoteCard';
import DashboardStatsCards from '../components/dashboard/DashboardStatsCards';
import DashboardTabs from '../components/dashboard/DashboardTabs';
import SalesAnalysisSection from '../components/dashboard/SalesAnalysisSection';

const Dashboard: React.FC = () => {
  // State declarations
  const [upcomingEvents, setUpcomingEvents] = useState<Event[]>([]);
  const [performances, setPerformances] = useState<Performance[]>([]);
  const [titoEvents, setTitoEvents] = useState<TitoEvent[]>([]);
  const [banBamApplications, setBanBamApplications] = useState<Application[]>([]);
  const [navigatorApplications, setNavigatorApplications] = useState<Application[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [tabValue, setTabValue] = useState(0);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [currentQuote, setCurrentQuote] = useState(MUSIC_QUOTES[0]);
  const [lastLogin, setLastLogin] = useState<string | null>(null);
  const [ticketsSoldSinceLastLogin, setTicketsSoldSinceLastLogin] = useState<number>(0);
  const [totalBands, setTotalBands] = useState<number>(0);


  // Get random quote
  const getRandomQuote = () => {
    const randomIndex = Math.floor(Math.random() * MUSIC_QUOTES.length);
    return MUSIC_QUOTES[randomIndex];
  };

  useEffect(() => {
    setCurrentQuote(getRandomQuote());
  }, []);

  // Fetch dashboard data
  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        setLoading(true);
        // Fetch main data
        const [eventsResponse, performancesResponse, banBamResponse, navigatorResponse, bandsResponse] = 
        await Promise.all([
            axiosInstance.get<Event[]>('/api/events'),
            axiosInstance.get<Performance[]>('/api/performances'),
            axiosInstance.get<Application[]>('/api/banbam'),
            axiosInstance.get<Application[]>('/api/navigator'),
            axiosInstance.get<any[]>('/api/bandrecommendations')  // Add this line

          ]);

        setUpcomingEvents(eventsResponse.data);
        setPerformances(performancesResponse.data);
        setBanBamApplications(banBamResponse.data);
        setNavigatorApplications(navigatorResponse.data);
        setTotalBands(bandsResponse.data.length);  // Add this line


        // Fetch last login
        try {
          const userResponse = await axiosInstance.get<{ lastLogin: string }>('/api/users/me/lastLogin');
          setLastLogin(userResponse.data.lastLogin);
        } catch (err) {
          console.error('Error fetching last login:', err);
        }

        // Fetch Tito events
        try {
          const titoResponse = await getTitoEvents();
          if (titoResponse.data && titoResponse.data.events) {
            const titoEventsData = await Promise.all(
              titoResponse.data.events
                .filter((event: any) => event.live)
                .map(async (event: any) => {
                  const [registrationsResponse, releasesResponse] = await Promise.all([
                    getTitoRegistrations(event.slug),
                    getTitoReleases(event.slug)
                  ]);

                  const totalSold = registrationsResponse.data.registrations.reduce(
                    (sum: number, reg: any) => sum + reg.tickets_count,
                    0
                  );

                  const totalAvailable = releasesResponse.data.releases.reduce(
                    (sum: number, release: any) => sum + release.quantity,
                    0
                  );

                  return {
                    title: event.title,
                    totalSold,
                    totalAvailable,
                    start_date: event.start_date,
                    releases: releasesResponse.data.releases,
                    registrations: registrationsResponse.data.registrations,
                    last_ticket_sold_at: registrationsResponse.data.registrations[0]?.completed_at
                  };
                })
            );

            setTitoEvents(titoEventsData.filter(event => new Date(event.start_date) > new Date()));
          }
        } catch (err) {
          console.error('Error fetching Tito data:', err);
        }

        setError(null);
      } catch (err) {
        console.error('Error fetching dashboard data:', err);
        setError('Failed to fetch dashboard data');
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  // Calculate tickets sold since last login
  useEffect(() => {
    if (titoEvents.length > 0 && lastLogin) {
      const lastLoginDate = new Date(lastLogin);
      const soldSinceLastLogin = titoEvents.reduce((total, event) => {
        return total + event.registrations.filter(reg => 
          new Date(reg.completed_at) > lastLoginDate
        ).length;
      }, 0);
      setTicketsSoldSinceLastLogin(soldSinceLastLogin);
    }
  }, [titoEvents, lastLogin]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ textAlign: 'center', mt: 4 }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ textAlign: 'center', mt: 4 }}>
        <Typography color="error">{error}</Typography>
      </Container>
    );
  }

  const pendingPerformances = performances.filter(p => p.status === 'pending');
  const confirmedPerformances = performances.filter(p => p.status === 'confirmed');

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h3" component="h1" gutterBottom>
        Show Runnr Dashboard
      </Typography>

      {/* User Greeting */}
      <UserGreeting ticketsSold={ticketsSoldSinceLastLogin} />
      
      {/* Quote Card */}
      <QuoteCard quote={currentQuote} />
      
      {/* Stats Cards */}
<DashboardStatsCards 
  stats={{
    upcomingEvents: upcomingEvents.length,
    totalBands: totalBands,
    pendingPerformances: pendingPerformances.length,  // Add this line
    confirmedPerformances: confirmedPerformances.length,
    banBamApplications: banBamApplications.length,
    navigatorApplications: navigatorApplications.length
  }}
  onTabChange={setTabValue}
/>

      {/* Sales Analysis */}
      <SalesAnalysisSection 
        events={titoEvents}
        expanded={expanded}
        onAccordionChange={handleAccordionChange}
      />

      {/* Tabs Section */}
      <DashboardTabs 
        upcomingEvents={upcomingEvents}
        pendingPerformances={pendingPerformances}
        confirmedPerformances={confirmedPerformances}
        tabValue={tabValue}
        onTabChange={handleTabChange}
      />
    </Container>
  );
};

export default Dashboard;